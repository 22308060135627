<template>

    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
              <p v-if="store.state.all.data.cliente.data.tipo_client != 2">Rappresentante</p>
              <p v-else>Contatto</p>
            </template>
            <template v-slot:subtitle>
              <p v-if="store.state.all.data.cliente.data.tipo_client != 2">Inserisci i dati del rappresentante</p>
              <p v-else>Inserisci i dati del contatto</p>
            </template> 
            <template v-slot:content>
              <div v-if="store.state.all.data.cliente.type == 'existent'">
                <h5>Rappresentante associato in fase di creazione del cliente</h5>
                 
                <b>Procedi allo step successivo</b>
              </div>
              <div  v-if="store.state.all.data.cliente.type == 'new'">
                <div v-if="store.state.all.data.cliente.data.tipo_client == 2">
                    <!-- <h5>Cliente domestico non necessita di rappresentante legale</h5> -->


                  <div class="grid">
                    <div class="p-field col-12  md:col-3">
                        <label for="toponimo">Toponimo</label><br>
                        <Dropdown v-model="store.state.all.data.contatto.toponimo" :options="toponimi" optionLabel="name" :filter="true" :editable="true" optionValue="name" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_toponimo && submitted}]" />
                        <small v-show="validationErrors.contatto_toponimo && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-3">
                        <label for="address">Indirizzo</label><br>
                        <InputText id="address" v-model="store.state.all.data.contatto.address" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_address && submitted}]" />
                        <small v-show="validationErrors.contatto_address && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-3">
                        <label for="civico">Civico</label><br>
                        <InputText id="civico" v-model="store.state.all.data.contatto.civico" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_civico && submitted}]" />
                        <small v-show="validationErrors.contatto_civico && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-3">
                        <label for="cap">Cap</label><br>
                        <InputText id="cap" v-model="store.state.all.data.contatto.cap" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_cap && submitted}]" />
                        <small v-show="validationErrors.contatto_cap && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="phone">Telefono</label><br>
                        <InputText id="phone" v-model="store.state.all.data.contatto.phone" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_phone && submitted}]" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask id="mobile" mask="999-9999999" v-model="store.state.all.data.contatto.mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_mobile && submitted}]" />
                        <small v-show="validationErrors.contatto_mobile && submitted" class="p-error">Campo richiesto.</small>
                    </div>               
                    <div class="p-field col-12 md:col-6">
                        <label for="email">Email</label><br>
                        <InputText id="email" v-model="store.state.all.data.contatto.email" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_email || validationErrors.contatto_isEmail && submitted}]" />
                        <div v-show="validationErrors.contatto_email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.contatto_isEmail && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pec">Pec</label><br>
                        <InputText id="pec" v-model="store.state.all.data.contatto.pec" class="inputfield w-full"/>
                    </div>
                    <div class="p-field col-12  md:col-6">
                          <label for="district">Provincia</label><br>
                          <Dropdown @change="onSelectedDistrict($event)" v-model="store.state.all.data.contatto.selectedDistrict" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_selectedDistrict && submitted}]" />
                          <small v-show="validationErrors.contatto_selectedDistrict && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12  md:col-6">
                          <label for="city">Comune</label><br>
                          <Dropdown id="city" @change="onSelectedCity($event)" v-model="store.state.all.data.contatto.cityId" :options="cities" optionLabel="name" optionValue="id" :filter="true" :title="cap" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.contatto_selectedCity && submitted}]" />
                          <small v-show="validationErrors.contatto_selectedCity && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                  </div>
                </div>
                <div  v-else >
                  <TabView v-model:activeIndex="activeTabIndex">
                  <TabPanel header="Rappresentante esistente">
                    <div class="formgrid grid mt-4">     
                    <div class="p-field col-10">
                        <Dropdown v-model="selectedRapp" :options="rappresentanti" optionLabel="name" :filter="true"  placeholder="Seleziona rappresentante"  :class="['inputfield w-full',{'p-invalid': validationErrors.rappex && submitted}]" />
                        <div v-show="validationErrors.rappex && submitted" class="p-error">Seleziona un rappresentante.</div>
                    </div>
                    <div v-if="selectedRapp" class="p-field col-2 flex justify-content-center align-items-center">
                      <i @click="removeExRapp" class="btn-remove-selected p-error pi pi-times" style="font-size: 1.2rem" v-tooltip="'Rimuovi selezionato'"></i>
                    </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Crea rappresentante" :disabled="selectedRapp != null">
                  
                      <div class="formgrid grid">
                      <div class="p-field col-12 md:col-12">
                        <label for="cod_fiscale">Codice fiscale rappresentante</label><br>
                        <InputText id="cod_fiscale" v-model="store.state.all.data.rappresentante.cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.format && submitted}]"  />
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                      </div>
                  </div>
                    <div class="formgrid grid">         
                      <div class="p-field col-12 md:col-6">
                          <label for="name">Nome</label><br>
                          <InputText id="name" v-model="store.state.all.data.rappresentante.name" :class="['inputfield w-full',{'p-invalid': validationErrors.name && submitted}]" />
                          <small v-show="validationErrors.name && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 md:col-6">
                          <label for="surname">Cognome</label><br>
                          <InputText id="surname" v-model="store.state.all.data.rappresentante.surname" :class="['inputfield w-full',{'p-invalid': validationErrors.surname && submitted}]" />
                          <small v-show="validationErrors.surname && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                  </div>
                  <!-- <div  v-if="cod_fiscale == ''" class="formgrid grid">
                      <div class="p-field col-12 md:col-6">
                          <label for="birth">Data di nascita</label>
                          <Calendar id="birth"  v-model="store.state.all.data.rappresentante.birth"  dateFormat="dd-mm-yy" :monthNavigator="true" :yearNavigator="true" yearRange="1900:2050" :class="['inputfield w-full',{'p-invalid': validationErrors.birth && submitted}]"/>
                          <small v-show="validationErrors.birth && submitted" class="p-error">Campo richiesto.</small> 
                      </div>
                      <div class="field col-12 md:col-6">
                          <div class="field-radiobutton">
                              <RadioButton id="gender1" name="gender" value="m" v-model="store.state.all.data.rappresentante.gender" />
                              <label for="gender1">Maschio</label>
                          </div>
                          <div class="field-radiobutton">
                              <RadioButton id="gender2" name="gender" value="f" v-model="store.state.all.data.rappresentante.gender" />
                              <label for="gender2">Femmina</label>  
                          </div>
                          <div v-show="validationErrors.gender && submitted" class="p-error">Campo richiesto.</div>
                      </div>
                      <div class="p-field col-12">
                          <label for="city">Comune di nascita</label><br>
                          <Dropdown id="city"  v-model="store.state.all.data.rappresentante.selectedBirthCity" :options="cities" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedBirthCity && submitted}]" :filter="true" />
                          <small v-show="validationErrors.selectedBirthCity && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                  </div>  -->
                  <div class="formgrid grid">       
                      <div class="p-field col-12 md:col-6">
                          <label for="phone">Telefono</label><br>
                          <InputText id="phone" v-model="store.state.all.data.rappresentante.phone" :class="['inputfield w-full',{'p-invalid': validationErrors.phone && submitted}]" />
                      </div>
                      <div class="p-field col-12 md:col-6">
                          <label for="mobile">Cellulare</label><br>                   
                          <InputMask id="mobile" mask="999-9999999" v-model="store.state.all.data.rappresentante.mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.mobile && submitted}]" />
                          <small v-show="validationErrors.mobile && submitted" class="p-error">Campo richiesto.</small>
                      </div>               
                      <div class="p-field col-12 md:col-6">
                          <label for="email">Email</label><br>
                          <InputText id="email" v-model="store.state.all.data.rappresentante.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" />
                          <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                          <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                      </div>
                      <div class="p-field col-12 md:col-6">
                          <label for="pec">Pec</label><br>
                          <InputText id="pec" v-model="store.state.all.data.rappresentante.pec" class="inputfield w-full"/>
                      </div>
                                  
                  </div>
                  
                  <div class="formgrid grid mt-3">
                    <!-- <div class="p-field col-12" >
                        <Button v-if="cod_fiscale== ''" label="Genera codice fiscale" @click="generate()" icon="pi pi-replay" iconPos="right" class="p-button-success" />
                    </div>   -->
                      <div class="p-field col-12  md:col-6">
                          <label for="toponimo">Toponimo</label><br>
                          <Dropdown v-model="store.state.all.data.rappresentante.toponimo" :options="toponimi" optionLabel="name" :filter="true" :editable="true" optionValue="name" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo && submitted}]" />
                          <small v-show="validationErrors.toponimo && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 md:col-6">
                          <label for="address">Indirizzo</label><br>
                          <InputText id="address" v-model="store.state.all.data.rappresentante.address" :class="['inputfield w-full',{'p-invalid': validationErrors.address && submitted}]" />
                          <small v-show="validationErrors.address && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 md:col-6">
                          <label for="civico">Civico</label><br>
                          <InputText id="civico" v-model="store.state.all.data.rappresentante.civico" :class="['inputfield w-full',{'p-invalid': validationErrors.civico && submitted}]" />
                          <small v-show="validationErrors.civico && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12 md:col-6">
                          <label for="cap">Cap</label><br>
                          <InputText id="cap" v-model="store.state.all.data.rappresentante.cap" :class="['inputfield w-full',{'p-invalid': validationErrors.cap && submitted}]" />
                          <small v-show="validationErrors.cap && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <!-- <div class="p-field col-12 md:col-4">
                          <label for="region">Regione</label><br>
                          <Dropdown @change="onSelectedRegion($event)" v-model="store.state.all.data.rappresentante.selectedRegionId" :options="regions" optionLabel="name" optionValue="id" placeholder="Seleziona regione" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedRegionId && submitted}]" />
                          <small v-show="validationErrors.selectedRegionId && submitted" class="p-error">Campo richiesto.</small>
                      </div> -->
                      <div class="p-field col-12  md:col-6">
                          <label for="district">Provincia</label><br>
                          <Dropdown @change="onSelectedDistrict($event)" v-model="store.state.all.data.rappresentante.selectedDistrict" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedDistrict && submitted}]" />
                          <small v-show="validationErrors.selectedDistrict && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                      <div class="p-field col-12  md:col-6">
                          <label for="city">Comune</label><br>
                          <Dropdown id="city" @change="onSelectedCity($event)" v-model="store.state.all.data.rappresentante.cityId" :options="cities" optionLabel="name" optionValue="id" :filter="true" :title="cap" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedCity && submitted}]" />
                          <small v-show="validationErrors.selectedCity && submitted" class="p-error">Campo richiesto.</small>
                      </div>
                  </div>
                  </TabPanel>
                  
                  </TabView> 
                </div>

              </div>                     
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                  <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                  <i></i>
                  <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex'

import Service from '@/services/ask-pratiche/pratiche-wizard'
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'

// import { useToast } from 'primevue/usetoast';

import Card from 'primevue/card';
import Button from 'primevue/button';
import moment from 'moment'
// import Dropdown from 'primevue/dropdown';


export default {
  props: {
    formData: Object
  },

  components: {
    Card,
    Button,
    // Dropdown,
  },

  setup (props, {emit}) {
    const store = useStore()
    // const toast = useToast();
    const currentUser = ref(store.state.auth.user)
    const stepIndex = 1
    const activeTabIndex = ref(0)

    const rappresentanti = ref([])
    const selectedRapp = ref(null)
    const format = ref(false) 
    const exist = ref(false)
    const checked = ref(false); 
    const disabled = ref(false);
    const isEmail = ref(false)
    const cf_valid = ref(false)

    const cod_fiscale = ref('')
    const name = ref('')
    const surname = ref('') 
    const birth = ref(null) 
    const phone = ref('') 
    const mobile = ref(null) 
    const email = ref('')
    const pec = ref('')
    const gender = ref(null)

    const regions = ref([])
    const districts = ref([])
    const cities = ref([])
    const toponimi = ref([])

    const toponimo = ref(null)
    const address = ref('')
    const civico = ref('')
    const cap = ref(null)
    const selectedRegionId = ref(null)
    const selectedRegionName = ref('')
    const selectedDistrict = ref(null)
    const selectedBirthCity = ref(null)
    const selectedCity = ref(null)
    const selectedCityName = ref('')

    const validationCFErrors = ref({})
    const validationErrors = ref({})
    const submitted = ref(false)

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)

    onUpdated(() => {
      if (store.state.all.data.cliente.type == 'new') {
        if (store.state.all.data.rappresentante.selectedDistrict != '') {
          service.getDistricts().then((res) => {
            districts.value = res.items
          })
        }
      }
    })
    onMounted(() => {
  
      getRegions();
      getRapps();

      if (store.state.all.data.cliente.type == 'new') {
        if (store.state.all.data.rappresentante.selectedDistrict != '') {
          service.getDistricts().then((res) => {
           districts.value = res.items
          })
        }
        //if (store.state.all.data.contatto.selectedDistrict != '') {
          // service.getDistricts().then((res) => {
          //   districts.value = res.items
          // })
        //}
      }
      service.getCities().then((res) => {
        cities.value = res.items
      })
    })

    const getRapps = () =>{
      if (store.state.all.data.cliente.type == 'new') {
        emit("activeLoader", true)
        service.getRappresentanti().then((res) => {
          rappresentanti.value = res.items
        }).then(() =>{
          //selectedRapp.value =  rappresentanti.value.filter(el=> el.id == store.state.all.data.cliente.data.idRapp)[0]
          if (store.state.all.bozzaId != 0) {
            if (store.state.all.data.rappresentante.type == 'existent') {
              selectedRapp.value = rappresentanti.value.filter(el=> el.id == store.state.all.data.rappresentante.id_existent)[0]
              activeTabIndex.value = 0
            } else if(store.state.all.data.rappresentante.type == 'new'){
              activeTabIndex.value = 1
            }
          } 
        }).finally(() => {
          emit("activeLoader", false)
        })
      }
    }


    const getRegions = () => {
      service.getToponimi().then((res) => {
        toponimi.value = res.items
      })
      service.getRegions().then((res) => {
        regions.value = res.items
      })
    }

    const onSelectedRegion = (evt) => {
      store.state.all.data.rappresentante.cityId = ''
      selectedRegionName.value = evt.originalEvent.target.ariaLabel
      service.getDistricts(store.state.all.data.rappresentante.selectedRegionId).then((res) => {
        districts.value = res.items
      })
    }

    const onSelectedDistrict = (evt) => {
      service.getCities(evt.value).then((res) => {
        cities.value = res.items
      })
    }
        
    const onSelectedCity = (evt) => {
      selectedCityName.value = evt.originalEvent.target.ariaLabel
    }

    const removeExRapp = () => {
      selectedRapp.value = null
    }

    const validateCodFiscaleForm = () => {
      if (!store.state.all.data.rappresentante.name.trim()){
        validationErrors.value['name'] = true;
        validationCFErrors.value['name'] = true;
      }else{
        delete validationErrors.value['name'];
        delete validationCFErrors.value['name'];
      }
        

      if (!store.state.all.data.rappresentante.surname.trim()){
        validationErrors.value['surname'] = true;
        validationCFErrors.value['surname'] = true;
      }else{
        delete validationErrors.value['surname'];
        delete validationCFErrors.value['surname'];
      }

      if (store.state.all.data.rappresentante.cod_fiscale == '') {
        if (store.state.all.data.rappresentante.birth == null){
          validationCFErrors.value['birth'] = true;
        }else{
          delete validationCFErrors.value['birth'];
        }
          
        if (store.state.all.data.rappresentante.selectedBirthCity == null){
          validationCFErrors.value['selectedBirthCity'] = true;
        }else{
          delete validationCFErrors.value['selectedBirthCity'];
        }
          
        if (store.state.all.data.rappresentante.gender == null){
          validationCFErrors.value['gender'] = true;
        }else{
          delete validationCFErrors.value['gender'];
        }
      }

      return !Object.keys(validationCFErrors.value).length
    }

    const validateForm = () => {

      validationErrors.value = []

      let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let regex_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i

      if (store.state.all.data.rappresentante.cod_fiscale == null || store.state.all.data.rappresentante.cod_fiscale.trim() == '') 
        validationErrors.value['format'] = true;
      else
        delete validationErrors.value['format'];

      if (!regex_cf.test(store.state.all.data.rappresentante.cod_fiscale)) 
        validationErrors.value['format'] = true;
      else
        delete validationErrors.value['format'];
      

      if (store.state.all.data.rappresentante.name == null || store.state.all.data.rappresentante.name.trim() == '')
        validationErrors.value['name'] = true;
      else
        delete validationErrors.value['name'];
      

      if (store.state.all.data.rappresentante.surname == null || store.state.all.data.rappresentante.surname.trim() == '')
        validationErrors.value['surname'] = true;
      else
        delete validationErrors.value['surname'];
      
      if (regex_email.test(store.state.all.data.rappresentante.email)) {
        delete validationErrors.value['isEmail'];
      } else {
        validationErrors.value['isEmail'] = true;
      }

      if (store.state.all.data.rappresentante.mobile == null || store.state.all.data.rappresentante.mobile.trim() == '')
        validationErrors.value['mobile'] = true;
      else
        delete validationErrors.value['mobile'];

      if (store.state.all.data.rappresentante.toponimo == null || store.state.all.data.rappresentante.toponimo == '')
        validationErrors.value['toponimo'] = true;
      else
        delete validationErrors.value['toponimo'];
          
      if (store.state.all.data.rappresentante.address == null || store.state.all.data.rappresentante.address.trim() == '')
        validationErrors.value['address'] = true;
      else
        delete validationErrors.value['address'];
      
      if (store.state.all.data.rappresentante.civico == null || store.state.all.data.rappresentante.civico.trim() == '')
        validationErrors.value['civico'] = true;
      else
        delete validationErrors.value['civico'];

      if (!Number.isInteger(parseInt(store.state.all.data.rappresentante.cap)))          
          validationErrors.value['cap'] = true;
      else 
        delete validationErrors.value['cap'];
                    
      // if (store.state.all.data.rappresentante.selectedRegionId == '' || store.state.all.data.rappresentante.selectedRegionId == null)
      //   validationErrors.value['selectedRegionId'] = true;
      // else
      //   delete validationErrors.value['selectedRegionId'];

      if (store.state.all.data.rappresentante.selectedDistrict == '' || store.state.all.data.rappresentante.selectedDistrict == null)
        validationErrors.value['selectedDistrict'] = true;
      else
        delete validationErrors.value['selectedDistrict'];

      if (store.state.all.data.rappresentante.cityId == '' || store.state.all.data.rappresentante.cityId == null)
        validationErrors.value['selectedCity'] = true;
      else
        delete validationErrors.value['selectedCity'];
      

      return !Object.keys(validationErrors.value).length;
    }

    const validateContattoDomestico = () => {

      validationErrors.value = []

      let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      
      
      if (regex_email.test(store.state.all.data.contatto.email)) {
        delete validationErrors.value['contatto_isEmail'];
      } else {
        validationErrors.value['contatto_isEmail'] = true;
      }
      
      if (store.state.all.data.contatto.toponimo == null || store.state.all.data.contatto.toponimo == '')
        validationErrors.value['contatto_toponimo'] = true;
      else
        delete validationErrors.value['contatto_toponimo'];
          
      if (store.state.all.data.contatto.address == null || store.state.all.data.contatto.address.trim() == '')
        validationErrors.value['contatto_address'] = true;
      else
        delete validationErrors.value['contatto_address'];
      
      if (store.state.all.data.contatto.civico == null || store.state.all.data.contatto.civico.trim() == '')
        validationErrors.value['contatto_civico'] = true;
      else
        delete validationErrors.value['contatto_civico'];

      if (!Number.isInteger(parseInt(store.state.all.data.contatto.cap)))          
          validationErrors.value['contatto_cap'] = true;
      else 
        delete validationErrors.value['contatto_cap'];


      if (store.state.all.data.contatto.mobile == null || store.state.all.data.contatto.mobile.trim() == '')
        validationErrors.value['contatto_mobile'] = true;
      else
        delete validationErrors.value['contatto_mobile'];
                    

      if (store.state.all.data.contatto.selectedDistrict == '' || store.state.all.data.contatto.selectedDistrict == null)
        validationErrors.value['contatto_selectedDistrict'] = true;
      else
        delete validationErrors.value['contatto_selectedDistrict'];

      if (store.state.all.data.contatto.cityId == '' || store.state.all.data.contatto.cityId == null)
        validationErrors.value['contatto_selectedCity'] = true;
      else
        delete validationErrors.value['contatto_selectedCity'];
      

      return !Object.keys(validationErrors.value).length;
    }

    const validateExistRapp = () =>{
      validationErrors.value = []
      if (selectedRapp.value == null){
        validationErrors.value['rappex'] = true;
        return false
      }else {
        delete validationErrors.value['rappex'];
        return true
      }
    }

    const generate = () => {
      submitted.value = true;
      if (validateCodFiscaleForm()) {  
        emit("activeLoader", true)  
        calculateCF().then( (res) => {
          cf_valid.value = res
        }).finally(()=> {
          emit("activeLoader", false)
        })      
      }           
    }

    const calculateCF = () => {
      let dateBirth = moment(store.state.all.data.rappresentante.birth).format('YYYY-MM-DD')        
      return service.calculateCF(store.state.all.data.rappresentante.name, store.state.all.data.rappresentante.surname, dateBirth, store.state.all.data.rappresentante.gender, store.state.all.data.rappresentante.selectedBirthCity).then((res) => {          
        if (res.cf.data.status) { 
          cod_fiscale.value = res.cf.data.cf             
          delete validationErrors.value['format'];

          return true
        } else {
          validationErrors.value['format'] = true;
          
          return false
        }
      }).catch(()=> {           
          validationErrors.value['format'] = true;
      })
    }


    const saveBozza = () => {

      store.state.all.data.realod = true
      store.state.all.data.step = 'rappresentante'
      store.state.all.data.wizard = 'agenti/inserimento'
      store.state.all.data.azienda = currentUser.value.area
      store.state.all.data.pageIndex = stepIndex
        
      if(store.state.all.bozzaId == 0){

        praticheWizardBozzeService.create("agenti/inserimento", JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
          if(res.success){
            store.state.all.bozzaId = res.item.id
            emit('next-page', stepIndex);
          }
        })

      }else{
        praticheWizardBozzeService.update(store.state.all.bozzaId, JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
          if(res.success){
            store.state.all.bozzaId = res.item.id
              emit('next-page', stepIndex);
          }
        })

      }

    }

  

    const nextPage = () => {
      submitted.value = true;
      if (store.state.all.data.cliente.type == 'existent') {
        store.state.all.data.rappresentante.type = 'existent'
        saveBozza()
      } else if (store.state.all.data.cliente.type == 'new'){
        
          if (activeTabIndex.value == 0 && store.state.all.data.cliente.data.tipo_client != 2) {
              let passCheck = validateExistRapp()
              if (passCheck) {
                store.state.all.data.rappresentante.type = 'existent'
                store.state.all.data.rappresentante.id_existent = selectedRapp.value.id
                saveBozza()
              }
          } else {
            
            if (store.state.all.data.cliente.data.tipo_client == 2) {
              if (validateContattoDomestico()) {
                saveBozza()
              }
            } else {
              if (validateForm()) {
                saveBozza()
              }
            }
          }       
      }
    }

    const prevPage = () => {
      emit('prev-page', stepIndex);
    }


      
    return {
      store,
      activeTabIndex,
      cod_fiscale,
      format ,
      exist,
      checked,
      disabled,
      rappresentanti,
      selectedRapp,
      name,
      surname, 
      birth, 
      phone, 
      mobile, 
      email,
      pec,
      gender,
      submitted,
      isEmail,
      cf_valid,
      toponimi,
      toponimo,
      address,
      civico,
      cap,
      regions,
      selectedRegionId,
      selectedRegionName,
      districts,
      selectedDistrict,
      cities,
      selectedBirthCity,
      selectedCity,
      nextPage,
      prevPage,
      validationErrors,
      generate,
      removeExRapp,
      selectedCityName, 
      onSelectedRegion,
      onSelectedDistrict, 
      onSelectedCity,
    }
  },
}
</script>

<style>
.form-step .p-card-title, .form-step .p-card-subtitle {
    text-align: center;
}
#cod_fiscale {
    text-transform: uppercase;
}

.btn-remove-selected {
  cursor: pointer;
}

</style>

<template>
    <div class="stepsdemo-content" id="verifica-ctr">
        <Card >
            <template v-slot:title>
                Verifica
            </template>
            <template v-slot:subtitle>
                Contratti precedenti:
            </template>
            <template v-slot:content>
                    <Accordion v-if="contracts.length != 0">
                        <AccordionTab v-for="contract in contracts" :key="contract.id">
                            <template #header>
                                <div>{{contract.code}}</div>
                                <div class="ctr-badge" :class="compareCtrDate(contract.data_fine) ? 'ctr-badge-valid' : 'ctr-badge-invalid'"></div>
                            </template>
                            <div class="grid">
                                <div class="col">
                                    <p><b>Tipo</b></p>
                                    <span v-if="contract.cod_type_contratto == 1">Energia</span>
                                    <span v-else-if="contract.cod_type_contratto == 2">Gas</span>
                                    <span v-else>Nessuno</span>
                                </div>
                                <div class="col">
                                    <p><b>Data Inizio</b></p>
                                    {{reverseDate(contract.data_inizio)}}
                                </div>
                                <div class="col">
                                    <p><b>Data Fine</b></p>
                                    {{reverseDate(contract.data_fine)}}
                                </div>
                                <div class="col">
                                    <p><b>Offerta</b></p>
                                    {{contract.offerta_code}}
                                </div>
                              
                                <div class="col-12" style="text-align:center">
                                    <Button @click="showDialog(contract)" v-if="compareCtrDate(contract.data_fine)" label="Cessa contratto" class="p-button-danger" />
                                </div>                           
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <div v-else class="text-center">
                        Il cliente non ha nessun contratto associato
                    </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>

    <Dialog v-model:visible="modalDialog" :style="{width: '450px'}" header="Conferma cessazione" :modal="true">
        <div class="confirmation-content">          
            <div>Data della cessazione: </div><br>
            <Calendar  @date-select="selectDate" v-model="ctr.data_fine" dateFormat="dd-mm-yy" />
        </div>
        <template #footer>
            <Button label="Annulla" icon="pi pi-times" class="p-button-text" @click="modalDialog = false"/>
            <Button label="Cessa ora" icon="pi pi-check" class="p-button-text" @click="stopCtr" />
        </template>
    </Dialog>

</template>

<script>
import {ref, getCurrentInstance, onBeforeUpdate, onMounted} from 'vue';
import moment from 'moment'
import Service from '@/services/ask-pratiche/pratiche-wizard'   
import Card from 'primevue/card';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';



export default {
    components: {
        Card,
        Button,
        Accordion,
        AccordionTab,
        Dialog,
        Calendar
    },
    props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete"],
    setup (props, {emit}) {
        const contracts = ref([])
        const ctr = ref({});
        const modalDialog = ref(false);
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        
        onBeforeUpdate(()=> {
            getContracts()
        })
        onMounted(()=> {
            getContracts()
        })

        const getContracts = () => {
             service.getCtrExsist(props.formData.client.id).then((res) => {
                contracts.value = res.items
            })
        }


        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }

        const nextPage = () => {
            emit('next-page', {formData: {},pageIndex: 1});       
        }

        const showDialog = (item) => {
            ctr.value = {}
            ctr.value = {...item};
            ctr.value.data_fine = moment(ctr.value.data_fine).format('DD-MM-YYYY')     
            modalDialog.value = true;
        }

        const stopCtr = () => {
            service.stopCtr(ctr.value).then(() => {
            }).finally(()=>{
                modalDialog.value = false;
            })
        }
      
        const selectDate = (value) => {         
            ctr.value.data_fine = moment(value).format('DD-MM-YYYY')        
        }

        const reverseDate = (value) => {
            let array = value.split("-");
            return `${array[2]}-${array[1]}-${array[0]}`
        }
        const compareCtrDate = (dateCtr) => {
            let todayDate = new Date().toISOString().slice(0, 10);
            if ( dateCtr > todayDate) {
                return true         
            } else {
                return false
            }
            
        }

        return {
           validationErrors, submitted, service, nextPage, prevPage, contracts, reverseDate, compareCtrDate, showDialog,
           ctr, modalDialog, stopCtr, selectDate
        }

    },
}
</script>
<style>
#verifica-ctr .p-accordion-tab {
    margin-bottom: 8px !important;
}
#verifica-ctr .p-accordion-header-link {
    border: none !important;
}
#verifica-ctr .ctr-badge {
    widows: 10px;
    height: 10px; 
    position: absolute;
    top: -3px;
    right: -2px;
    font-size: 10px;
    text-transform: uppercase;
    padding: 6px;
    border-radius: 50%;
}
#verifica-ctr .ctr-badge-valid {
    background-color: rgb(108, 214, 8);
}
#verifica-ctr .ctr-badge-invalid {
    background-color: rgb(247, 49, 49);
}
</style>
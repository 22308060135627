import { createRouter, createWebHistory, /*createWebHashHistory*/ } from 'vue-router';
import Cookies from "js-cookie";
import service from '@/config/services'
import store from './store/index'
import roles from './config/roles'

import LoginView from '@/LoginView.vue';
import NotAuthorized from '@/NotAuthorized.vue'
import DashboardView from '@/DashboardView.vue'

import Home from '@/views/Home.vue'

//Clienti
import Clienti from '@/views/Clienti.vue'
import StepTipo from '@/components/pratiche/form-step/cliente/StepTipo.vue'
import StepCheck from '@/components/pratiche/form-step/cliente/StepCheck.vue'
import StepAnagrafica from '@/components/pratiche/form-step/cliente/StepAnagrafica.vue'
import StepDettagli from '@/components/pratiche/form-step/cliente/StepDettagli.vue'
import StepAssocia from '@/components/pratiche/form-step/cliente/StepAssocia.vue'
import StepDocumenti from '@/components/pratiche/form-step/cliente/StepDocumenti.vue'
import StepRiepilogo from '@/components/pratiche/form-step/cliente/StepRiepilogo.vue'
import FormLinkCliente from '@/components/pratiche/form-step/cliente/FormLink.vue'

//Rappresentanti
import Rappresentanti from '@/views/Rappresentanti.vue'
import StepCf from '@/components/pratiche/form-step/rappresentante/StepCf.vue'
import StepAnagraficaRapp from '@/components/pratiche/form-step/rappresentante/StepAnagrafica.vue'
import StepSedeRapp from '@/components/pratiche/form-step/rappresentante/StepSede.vue'
import StepRiepilogoRapp from '@/components/pratiche/form-step/rappresentante/StepRiepilogo.vue'

//Contratto
import Contratto from '@/views/Contratto.vue'
import StepAssociaCtr from '@/components/pratiche/form-step/contratto/StepAssocia.vue'
import StepVerificaCtr from '@/components/pratiche/form-step/contratto/StepVerifica.vue'
import StepContratto from '@/components/pratiche/form-step/contratto/StepContratto.vue'
import StepDettagliCtr from '@/components/pratiche/form-step/contratto/StepDettagli.vue'
import StepDocumentiCtr from '@/components/pratiche/form-step/contratto/StepDocumenti.vue'
import StepRiepilogoCtr from '@/components/pratiche/form-step/contratto/StepRiepilogo.vue'
import FormLinkContratto from '@/components/pratiche/form-step/contratto/FormLink.vue'

//Offerte
import Offerte from '@/views/Offerte.vue'
import StepTipologiaOfferta from '@/components/pratiche/form-step/offerta/StepTipologia.vue'
import StepPrezziOfferta from '@/components/pratiche/form-step/offerta/StepPrezzi.vue'
import StepGettoneOfferta from '@/components/pratiche/form-step/offerta/StepGettone.vue'
import StepRiepilogoOfferta from '@/components/pratiche/form-step/offerta/StepRiepilogo.vue'

//Fornitori
import Fornitori from '@/views/Fornitori.vue'
import StepIvaFornitore from '@/components/pratiche/form-step/fornitore/StepIva.vue'
import StepAnagraficaFornitore from '@/components/pratiche/form-step/fornitore/StepAnagrafica.vue'
import StepSedeFornitore from '@/components/pratiche/form-step/fornitore/StepSede.vue'
import StepRiepilogoFornitore from '@/components/pratiche/form-step/fornitore/StepRiepilogo.vue'

//Agenti
import Agenti from '@/views/Agenti.vue'
import StepAnagraficaAgente from '@/components/pratiche/form-step/agente/StepAnagrafica.vue'
import StepRiepilogoAgente from '@/components/pratiche/form-step/agente/StepRiepilogo.vue'

//Forniture energia
import FornitureEE from '@/views/FornitureEE.vue'
import StepPod from '@/components/pratiche/form-step/forniture-ee/StepPod.vue'
import StepClientePod from '@/components/pratiche/form-step/forniture-ee/StepCliente.vue'
import StepContrattoPod from '@/components/pratiche/form-step/forniture-ee/StepContratto.vue'
import StepAnagraficaPod from '@/components/pratiche/form-step/forniture-ee/StepAnagrafica.vue'
import StepDettagliPod from '@/components/pratiche/form-step/forniture-ee/StepDettagli.vue'
import StepDocumentiPod from '@/components/pratiche/form-step/forniture-ee/StepDocumenti.vue'
import StepRiepilogoPod from '@/components/pratiche/form-step/forniture-ee/StepRiepilogo.vue'

//Forniture gas
import FornitureGas from '@/views/FornitureGas.vue'
import StepPdr from '@/components/pratiche/form-step/forniture-gas/StepPdr.vue'
import StepClientePdr from '@/components/pratiche/form-step/forniture-gas/StepCliente.vue'
import StepContrattoPdr from '@/components/pratiche/form-step/forniture-gas/StepContratto.vue'
import StepAnagraficaPdr from '@/components/pratiche/form-step/forniture-gas/StepAnagrafica.vue'
import StepDettagliPdr from '@/components/pratiche/form-step/forniture-gas/StepDettagli.vue'
import StepDocumentiPdr from '@/components/pratiche/form-step/forniture-gas/StepDocumenti.vue'
import StepRiepilogoPdr from '@/components/pratiche/form-step/forniture-gas/StepRiepilogo.vue'

//All-in-one form-step for agenti
import AgentiAllStep from '@/views/AllStep.vue'
import AgentiStepCliente from '@/components/pratiche/form-step-agenti/StepCliente.vue'
import AgentiStepRappresentante from '@/components/pratiche/form-step-agenti/StepRappresentante.vue'
import AgentiStepContratto from '@/components/pratiche/form-step-agenti/StepContratto.vue'
import AgentiStepFornitura from '@/components/pratiche/form-step-agenti/StepFornitura.vue'
import AgentiStepDocumenti from '@/components/pratiche/form-step-agenti/StepDocumenti.vue'
import AgentiStepRiepilogo from '@/components/pratiche/form-step-agenti/StepRiepilogo.vue'

//Form step multisito
import Multisito from '@/views/Multisito.vue'
import MultisitoStepRappresentante from '@/components/pratiche/multisito/StepRappresentante.vue'
import MultisitoStepInfo from '@/components/pratiche/multisito/StepInfo.vue'
import MultisitoStepUpload from '@/components/pratiche/multisito/StepUpload.vue'
import MultisitoStepDocumenti from '@/components/pratiche/multisito/StepDocumenti.vue'

import CaricamentiMultisito from '@/views/CaricamentiMultisito.vue'
import Convenzioni from '@/views/Convenzioni.vue'
import ValidaCaricamenti from '@/views/ValidaCaricamenti.vue'

import Bozze from '@/views/Bozze.vue'
import GestionePratiche from '@/views/GestionePratiche.vue'
import ReportCaricamenti from '@/views/ReportCaricamenti.vue'
import ReportCaricamentiAgente from '@/views/ReportCaricamentiAgente.vue'

import ReportFornitura from '@/views/ReportFornitura.vue'
import ConvalidaModifiche from '@/views/ConvalidaModifiche.vue'
import FornitureContrattualizzate from '@/views/FornitureContrattualizzate.vue'

import Service from '@/services/ask-pratiche/pratiche-wizard'

const routes = [
    {
      path: '/login',
      name: 'Login',
      component: LoginView,
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',
      component: NotAuthorized,
    },
    {
      path: '/',
      component: DashboardView,
      children: [
          {
              path: '/',
              component: Home
          },
          {
            path: '/clienti',
            name: 'Clienti',
            component: Clienti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice']  },
            children: [
              {
                path: '/clienti/step-tipo',
                component: StepTipo 
              },
              {
                path: '/clienti/step-check',
                component: StepCheck 
              },
              {
                path: '/clienti/step-anagrafica',
                component: StepAnagrafica 
              },
              {
                path: '/clienti/step-dettagli',
                component: StepDettagli 
              },
              {
                path: '/clienti/step-associa',
                component: StepAssocia,
              },
              {
                path: '/clienti/step-documenti',
                component: StepDocumenti 
              },
              {
                path: '/clienti/step-riepilogo',
                component: StepRiepilogo 
              },
              {
                path: '/clienti/step-link/:id',
                component: FormLinkCliente
              },
            ]
          },
          {
            path: '/rappresentanti',
            name: 'Rappresentanti',
            component: Rappresentanti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice'] },
            children: [
              {
                path: '/rappresentanti/step-cf',
                component: StepCf 
              },
              {
                path: '/rappresentanti/step-anagrafica',
                component: StepAnagraficaRapp
              },
              {
                path: '/rappresentanti/step-sede',
                component: StepSedeRapp
              },
              {
                path: '/rappresentanti/step-riepilogo',
                component: StepRiepilogoRapp 
              },
            ]
          },
          {
            path: '/contratti',
            name: 'Contratto',
            component: Contratto,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice'] },
            children: [
              {
                path: '/contratto/step-associa/:idClient?/:idContract?',
                component: StepAssociaCtr 
              },
              {
                path: '/contratto/step-verifica',
                component: StepVerificaCtr 
              },
              {
                path: '/contratto/step-contratto',
                component: StepContratto 
              },
              {
                path: '/contratto/step-dettagli',
                component: StepDettagliCtr 
              },
              {
                path: '/contratto/step-documenti',
                component: StepDocumentiCtr 
              },
              {
                path: '/contratto/step-riepilogo',
                component: StepRiepilogoCtr 
              },
              {
                path: '/contratto/step-link/:idContratto/:idCliente/:tipo',
                component: FormLinkContratto
              },
            ]
          },
          {
            path: '/offerte/:id?',
            name: 'Offerta', 
            component: Offerte,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice'] },
            children: [
              {
                path: '/offerta/step-tipologia/:id?',
                component: StepTipologiaOfferta 
              },
              {
                path: '/offerta/step-prezzi/:id?',
                component: StepPrezziOfferta 
              },
              {
                path: '/offerta/step-gettone/:id?',
                component: StepGettoneOfferta 
              },
              {
                path: '/offerta/step-riepilogo/:id?',
                component: StepRiepilogoOfferta 
              },
            ]
          },
          {
            path: '/fornitori',
            name: 'Fornitori', 
            component: Fornitori,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice', 'credito','pratiche'] },
            children: [
              {
                path: '/fornitori/partita-iva',
                component: StepIvaFornitore
              },
              {
                path: '/fornitori/anagrafica',
                component: StepAnagraficaFornitore
              },
              {
                path: '/fornitori/sede',
                component: StepSedeFornitore
              },
              {
                path: '/fornitori/riepilogo',
                component: StepRiepilogoFornitore
              },   
            ]
          },
          {
            path: '/agenti',
            name: 'Agenti', 
            component: Agenti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice', 'credito','pratiche', 'contabilita'] },
            children: [
              {
                path: '/agenti/step-anagrafica',
                component: StepAnagraficaAgente
              },
              {
                path: '/agenti/step-riepilogo',
                component: StepRiepilogoAgente
              },
            ]
          },
          {
            path: '/forniture-ee',
            name: 'Forniture EE', 
            component: FornitureEE,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all', 'backoffice'] },
            children: [
              {
                path: '/forniture-ee/step-pod/:idContratto?/:idCliente?',
                component: StepPod
              },
              {
                path: '/forniture-ee/step-cliente',
                component: StepClientePod
              },
              {
                path: '/forniture-ee/step-contratto',
                component: StepContrattoPod
              },
              {
                path: '/forniture-ee/step-anagrafica',
                component: StepAnagraficaPod
              },
              {
                path: '/forniture-ee/step-dettagli',
                component: StepDettagliPod
              },
              {
                path: '/forniture-ee/step-documenti',
                component: StepDocumentiPod 
              },
              {
                path: '/forniture-ee/step-riepilogo',
                component: StepRiepilogoPod
              },
            ]
          },
          {
            path: '/forniture-gas',
            name: 'Forniture Gas', 
            component: FornitureGas,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice']  },
            children: [
              {
                path: '/forniture-gas/step-pdr/:idContratto?/:idCliente?',
                component: StepPdr
              },
              {
                path: '/forniture-gas/step-cliente',
                component: StepClientePdr
              },
              {
                path: '/forniture-gas/step-contratto',
                component: StepContrattoPdr
              },
              {
                path: '/forniture-gas/step-anagrafica',
                component: StepAnagraficaPdr
              },
              {
                path: '/forniture-gas/step-dettagli',
                component: StepDettagliPdr
              },
              {
                path: '/forniture-gas/step-documenti',
                component: StepDocumentiPdr 
              },
              {
                path: '/forniture-gas/step-riepilogo',
                component: StepRiepilogoPdr
              },
            ]
          },
          // {
          //   path: '/agenti/inserimento',
          //   name: 'Nuovo Inserimento', 
          //   component: AgentiAllStep,
          //   children: [
          //     {
          //       path: '/agenti/inserimento/step-cliente',
          //       component: AgentiStepCliente
          //     },
          //     {
          //       path: '/agenti/inserimento/step-rappresentante',
          //       component: AgentiStepRappresentante
          //     },
          //     {
          //       path: '/agenti/inserimento/step-contratto',
          //       component: AgentiStepContratto
          //     },
          //     {
          //       path: '/agenti/inserimento/step-fornitura',
          //       component: AgentiStepFornitura
          //     },
          //     {
          //       path: '/agenti/inserimento/step-documenti',
          //       component: AgentiStepDocumenti
          //     },
          //     {
          //       path: '/agenti/inserimento/step-riepilogo',
          //       component: AgentiStepRiepilogo
          //     },
          //   ]
          // },
          {
            path: '/multisito',
            name: 'Nuovo Multisito', 
            component: Multisito,
            children: [
              {
                path: '/multisito/step-rappresentante',
                component: MultisitoStepRappresentante
              },
              {
                path: '/multisito/step-info',
                component: MultisitoStepInfo
              },
              {
                path: '/multisito/step-upload',
                component: MultisitoStepUpload
              },
              {
                path: '/multisito/step-documenti',
                component: MultisitoStepDocumenti
              },
            ]
          },
          {
            path: '/caricamenti-multisito/:id?',
            name: 'Caricamenti Multisito', 
            component: CaricamentiMultisito,
            // beforeEnter: [hasPermission],
            // meta: { authorize: []},
          },
          {
            path: '/convenzioni-agf',
            name: 'Convenzioni', 
            component: Convenzioni,
          },
          {
            path: '/validazione-caricamenti',
            name: 'Validazione Documentale', 
            component: ValidaCaricamenti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role] , profiles: ['all', 'backoffice'] },
          },
          // {
          //   path: '/bozze',
          //   name: 'Bozze', 
          //   component: Bozze,
          // },
          {
            path: '/precheck-iv',
            name: 'Gestione Pratiche', 
            component: GestionePratiche,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'pratiche'] },
          },
          {
            path: '/scarico-agf',
            name: 'Scarico Agf', 
            component: ReportCaricamenti,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice'] },
          },
          {
            path: '/report-caricamenti-agente',
            name: 'Report mensile', 
            component: ReportCaricamentiAgente,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role, roles.agent.role], profiles: ['all'] },
          },
          {
            path: '/stato-fornitura',
            name: 'Report fornitura', 
            component: ReportFornitura,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all']},
          },
          {
            path: '/convalida-modifiche',
            name: 'Convalida Modifiche Dati contratto', 
            component: ConvalidaModifiche,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice']},
          },
          {
            path: '/forniture-in-wizard',
            name: 'Forniture contrattualizzate', 
            component: FornitureContrattualizzate,
            beforeEnter: [hasPermission],
            meta: { authorize: [roles.admin.role], profiles: ['all', 'backoffice']},
          }
      ]   
    }
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
});

function hasPermission(to, from, next) {
  const { authorize, profiles } = to.meta;
  const role = store.state.auth.user.role;
  const userProfile = store.state.auth.user.profile ?? [];

  if (authorize.length && !authorize.includes(role)) {
    console.log('access:denied')
      next({ path: '/' });
  } else {
    if (role == 'admin') {
      let allowed = profiles.some(profile => profile in userProfile && userProfile[profile] == 1)
      if (allowed) {
        next()
      } else {
        console.log('access:denied-profiles')
        next({ path: '/' });
      }      
    } else {
      next()
    }
  }
}

const setCookie = (name, cookie) => {
  if (cookie) {
    Cookies.set(name, JSON.stringify(cookie),{ domain: `${service.main_domain.cookie}`, expires: 1 }) // 1 day  
  }
}

const getAddSourceCookie = () => {
  let cookie = null
  if (Cookies.get('google_access_token')) {
    cookie =  JSON.parse(Cookies.get('google_access_token'))
    cookie.source = 'google'
    //cookie.role = 'agent' // test
    setCookie('google_access_token', cookie)
  } else if (Cookies.get('ask_access_token')) {
    cookie =  JSON.parse(Cookies.get('ask_access_token'))
    cookie.source = 'jwt'
    setCookie('ask_access_token', cookie)
  }

  return cookie

}

// refresh accessToken when expired
import AuthRefresh from '@/services/auth/auth.refresh'
router.beforeEach((to, from, next) => {
    const path = to.path
    let cookie = getAddSourceCookie()

    if (path != '/unauthorized' && cookie) {
        AuthRefresh.getLifetimeToken(cookie).then((secondToExpire)=>{
          if (secondToExpire < 300) {
            // console.log('scaduto')
            AuthRefresh.getNewAccessToken(cookie).then(() =>{
              next()
            })
          } else {
            next()
          }
        });
    } else {
      next()
    }
})

// Signin from cookie
import AuthService from './services/auth/auth.service';
// import PicoEmitter from 'pico-emitter';
// const api_service = new Service(new PicoEmitter());
router.beforeEach((to, from, next) => {
  // if (to.name in ['Landing-caricamento','Caricamento-libero']) {
  //   if (to.params.token) {
  //     let response = null
  //     api_service.verifyTokenLinkLibero(to.params.token)
  //     .then((res) => {
  //       response = res.items
  //     })
  //     .finally(() => {
  //       if (response) {
  //         next()
  //       } else {
  //         next({ name: 'Unauthorized'})
  //       }
  //     })
  //   } else {
  //     next({ name: 'Unauthorized' })
  //   }
  // }else{
    let cookieToken = getAddSourceCookie()  
    if(cookieToken){
      if(to.name !== 'Unauthorized'){
    
        AuthService.loginUserFromCookie(JSON.stringify(cookieToken)).then((res)=>{

          if (res === true) {
            next()
          } else {
            next({ name: 'Unauthorized' })
          }     
        }).catch((err)=>{
          console.log(err)
          next({ name: 'Unauthorized' })
        })
      } else {
        next()
      }
    }else{
      if(to.name !== 'Unauthorized'){
        next({ name: 'Unauthorized' })
      }else{
        next()
      }
      
    }
  // }
})



export default router;

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Gettone
            </template>
            <template v-slot:subtitle>
                Gettone offerta
            </template>
            <template v-slot:content>         
                <div class="formgrid grid">
                     <div class="p-field col-12 md:col-4">
                        <label>Gettone consumo</label>
                        <InputNumber  v-model="gettone_consumo" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"  :class="['inputfield w-full',{'p-invalid': validationErrors.gettone_consumo && submitted}]" />
                        <div v-show="validationErrors.gettone_consumo && submitted" class="p-error">Valore non valido</div>  
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label>Gettone ricorrente punto</label>
                        <InputNumber  v-model="gettone_ric_punto" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"  :class="['inputfield w-full',{'p-invalid': validationErrors.gettone_ric_punto && submitted}]" />
                        <div v-show="validationErrors.gettone_ric_punto && submitted" class="p-error">Valore non valido</div>
                    </div>
                     <div class="p-field col-12 md:col-4">
                        <label>Giorni ricorrente consumo</label>
                        <InputNumber  v-model="gettone_ric_consumo" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"  :class="['inputfield w-full',{'p-invalid': validationErrors.gettone_ric_punto && submitted}]" />
                        <div v-show="validationErrors.gettone_ric_consumo && submitted" class="p-error">Valore non valido</div>             
                    </div>
                     <div class="p-field col-12">
                        <hr>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Gettone1 <br><small>Valore</small></label>
                        <InputNumber  v-model="gettone1" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"  class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Gettone1 <br><small>Mesi</small></label>
                        <InputNumber v-model="gettone1_giorni" :min="0" :max="12" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Gettone2 <br><small>Valore</small></label>
                        <InputNumber  v-model="gettone2" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"  class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Gettone2 <br><small>Mesi</small></label>
                        <InputNumber v-model="gettone2_giorni" :min="0" :max="12" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Gettone3 <br><small>Valore</small></label>
                        <InputNumber  v-model="gettone3" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"  class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Gettone3 <br><small>Mesi</small></label>
                        <InputNumber v-model="gettone3_giorni" :min="0" :max="12" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12">
                        <hr>
                    </div>
                    <div class="p-field  col-12 md:col-6">
                        <label for="dataInizio">Data Inizio</label>
                        <Calendar  v-model="dataInizio" dateFormat="dd-mm-yy" class="inputfield w-full" />
                        <div v-show="validationErrors.data_inizio && submitted" class="p-error">Valore non valido</div>
                    </div>
                     <div class="p-field  col-12 md:col-6">
                        <label for="dataFine">Data Fine</label>
                        <Calendar  v-model="dataFine" dateFormat="dd-mm-yy" class="inputfield w-full" />
                        <div v-show="validationErrors.data_fine && submitted" class="p-error">Valore non valido</div>
                    </div>
                    
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                     <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment'
import {ref, getCurrentInstance, onMounted, onUpdated} from 'vue';
// import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';

export default {
    components: {
        Card,
        Button,
        Calendar,
        InputNumber

    },
    props: {
      formData: Object
    },
    setup (props, {emit}) {
        const gettone_consumo = ref(0);
        const gettone_ric_punto = ref(0);
        const gettone_ric_consumo = ref(0);
        const gettone1 = ref(0);
        const gettone1_giorni = ref(0);
        const gettone2 = ref(0);
        const gettone2_giorni = ref(0);
        const gettone3 = ref(0);
        const gettone3_giorni = ref(0);
        const dataInizio = ref(new Date());
        const dataFine = ref(new Date('2099-12-31'));
        const submitted = ref(false)
        const validationErrors = ref({})
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const selectedTipoFornitura = ref()
        // const service = new Service(self.$eventBus)

        onMounted (()=>{
            mount()
        })

        onUpdated (()=>{
            mount()
        })
        
        const mount = () => {
            selectedTipoFornitura.value = props.formData.tipo.id
    
            if (props.formData.offerta_clone && selectedTipoFornitura.value == props.formData.offerta_clone.tipologia) {
                setCloneData()
            }else{
                initData()
            }
        }

        const setCloneData = () => {
            gettone_consumo.value = props.formData.offerta_clone.gettone_consumo
            gettone_ric_punto.value = props.formData.offerta_clone.gettone_ricorrente_punto
            gettone_ric_consumo.value = props.formData.offerta_clone.gettone_ricorrente_consumo
            gettone1.value = props.formData.offerta_clone.gettone1
            gettone1_giorni.value = props.formData.offerta_clone.giorni_gettone1
            gettone2.value = props.formData.offerta_clone.gettone2
            gettone2_giorni.value = props.formData.offerta_clone.giorni_gettone2
            gettone3.value = props.formData.offerta_clone.gettone3
            gettone3_giorni.value = props.formData.offerta_clone.giorni_gettone3
        }
        const initData = () => {
            gettone_consumo.value = 0
            gettone_ric_punto.value = 0
            gettone_ric_consumo.value = 0
            gettone1.value = 0
            gettone1_giorni.value = 0
            gettone2.value = 0
            gettone2_giorni.value = 0
            gettone3.value = 0
            gettone3_giorni.value = 0
        }

        

        
        const prevPage = () => { 
            emit('prev-page', {pageIndex: 2});             
        }
      
        const nextPage = () => {
            submitted.value = true;
            if (validateForm()) {
                emit('next-page', {
                    formData: {
                        gettone_consumo: gettone_consumo.value,
                        gettone_ric_punto: gettone_ric_punto.value,
                        gettone_ric_consumo: gettone_ric_consumo.value,
                        gettone1: gettone1.value,
                        gettone1_giorni: gettone1_giorni.value,
                        gettone2: gettone2.value,
                        gettone2_giorni: gettone2_giorni.value,
                        gettone3: gettone3.value,
                        gettone3_giorni: gettone3_giorni.value,
                        dataInizio: dataInizio.value,
                        dataFine: dataFine.value
                    },
                    pageIndex: 2
                });                       
            }
        }

      
        const validateForm = () => {
            if (parseInt(gettone_consumo.value) < 0)
                validationErrors.value['gettone_consumo'] = true;
            else 
                delete validationErrors.value['gettone_consumo'];

             if (parseInt(gettone_ric_punto.value) < 0)
                validationErrors.value['gettone_ric_punto'] = true;
            else 
                delete validationErrors.value['gettone_ric_punto'];

             if (parseInt(gettone_ric_consumo.value) < 0)
                validationErrors.value['gettone_ric_consumo'] = true;
            else 
                delete validationErrors.value['gettone_ric_consumo'];

            
            if (!moment(dataInizio.value, 'DD-MM-YYYY', true).isValid())
                validationErrors.value['data_inizio'] = true;
            else 
                delete validationErrors.value['data_inizio'];

             if (!moment(dataFine.value, 'DD-MM-YYYY', true).isValid())
                validationErrors.value['data_fine'] = true;
            else 
                delete validationErrors.value['data_fine'];
                                  
            return !Object.keys(validationErrors.value).length;
        }

        return {
          gettone_consumo,gettone_ric_punto, gettone_ric_consumo, gettone1, gettone1_giorni,
          gettone2, gettone2_giorni,gettone3, gettone3_giorni,dataInizio, dataFine, 
          validationErrors, nextPage, prevPage, validateForm, submitted
        }

    },
}
</script>
<style scoped>
hr {
   border-top: 1px solid rgba(71, 71, 71, 0.034);
}
</style>

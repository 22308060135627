<template>

    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Cliente
            </template>
            <template v-slot:subtitle>
                Inserisci i dati del cliente
            </template> 
            <template v-slot:content>
              <TabView v-model:activeIndex="activeTabIndex">
                <TabPanel header="Cliente esistente">
                  <div class="formgrid grid mt-4">     
                    <div class="p-field col-10">
                        <Dropdown  
                          dataKey="id"
                          v-model="selectedClient" 
                          :options="clients"            
                          placeholder="Seleziona un cliente" 
                          :class="['inputfield w-full',{'p-invalid': validationErrors.client && submitted}]" 
                          :filterFields="['ragsoc', 'nome','cognome','piva','codfis']"
                          :filter="true"
                          :virtualScrollerOptions="{ itemSize: 38 }"
                          >

                          <template #value="{value, placeholder}">
                              <div class="client-item client-item-value" v-if="value">
                                  <div>{{(value.ragsoc) ? value.ragsoc : value.nome +" "+ value.cognome}}</div>
                              </div>
                              <span v-else>
                                  {{placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                              <div class="client-item">
                                  <div v-if="slotProps.option.ragsoc">{{slotProps.option.ragsoc}} - <small>{{(slotProps.option.piva) ? slotProps.option.piva : slotProps.option.codfis}}</small></div>
                                  <div v-else>{{slotProps.option.nome}}&nbsp;{{slotProps.option.cognome}} - <small>{{slotProps.option.codfis}}</small></div>
                              </div>
                          </template>
                        </Dropdown>
                        <div v-show="validationErrors.client && submitted" class="p-error">Seleziona un cliente.</div>
                    </div>
                    <div v-if="selectedClient" class="p-field col-2 flex justify-content-center align-items-center">
                      <i @click="removeExClient" class="btn-remove-selected p-error pi pi-times" style="font-size: 1.2rem" v-tooltip="'Rimuovi selezionato'"></i>
                    </div>
                  </div>
                  <div v-show="validationErrors.spentPossibleInsert && submitted" class="p-error">Non puoi firmare altri contratti il numero di cellulare associato a questo cliente.</div>

                </TabPanel>

                <TabPanel header="Crea cliente" :disabled="selectedClient != null">
                  <div class="formgrid grid mt-4">
                    <div class="field col-12">
                      <label>Tipo cliente</label>
                      <Dropdown @change="onSelectedTipo($event)" v-model="store.state.all.data.cliente.data.tipo_client" :options="tipoCliente" optionLabel="name" optionValue="id" placeholder="Seleziona tipo cliente" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedTipo && submitted}]" />
                      <div v-show="validationErrors.selectedTipo && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                  </div>
                  <div v-if="store.state.all.data.cliente.data.tipo_client">
                    <div class="formgrid grid" v-if="store.state.all.data.cliente.data.tipo_client != 2">
                      <div class="p-field col-9">
                        <label>Partita iva</label>
                        <InputText type="number" v-model="store.state.all.data.cliente.data.partita_iva" :class="['inputfield w-full',{'p-invalid': (validationErrors.partita_iva || validationErrors.exist_iva || validationErrors.format_iva) && submitted}]" />
                        <div v-if="validationErrors.partita_iva && submitted" class="p-error">Lunghezza campo non valida.</div>
                        <div v-if="validationErrors.exist_iva && submitted" class="p-error">Partita Iva esistente.</div>
                        <div v-if="validationErrors.format_iva && submitted" class="p-error">Formato errato.</div>
                      </div>
                      <div class="p-field col-3 flex justify-content-end align-items-center">
                        <Button @click="getInfoToCreditSafe" :disabled="store.state.all.data.cliente.data.partita_iva == null || store.state.all.data.cliente.data.partita_iva.length != 11" label="Autocompletamento" icon="pi pi-check-square" iconPos="right" class="w-full mt-3" />
                      </div>
                  </div>
                  <div class="p-fluid" v-else>
                      <div class="p-field">
                        <label>Codice Fiscale</label>
                        <InputText id="cod_fiscale" v-model="store.state.all.data.cliente.data.cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.cod_fiscale || validationErrors.format_cf || validationErrors.exist_cf && submitted}]" />
                        <div v-if="validationErrors.cod_fiscale && submitted" class="p-error">Campo richiesto.</div>
                        <div v-if="validationErrors.format_cf && submitted" class="p-error">Formato errato.</div>
                        <div v-if="validationErrors.exist_cf && submitted" class="p-error">Codice fiscale esistente.</div>
                      </div>
                    </div>
                  </div>
                <div  v-if="store.state.all.data.cliente.data.tipo_client != 2" class="formgrid grid">
                    <div class="p-field col-12 md:col-6">
                        <label for="rag_sociale">Rag. Sociale</label><br>
                        <InputText id="rag_sociale" v-model="store.state.all.data.cliente.data.rag_sociale" :class="[{'p-invalid': validationErrors.rag_sociale && submitted},'inputfield w-full']"/>
                        <small v-show="validationErrors.rag_sociale && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="cod_fiscale_azienda">Codice fiscale</label><br>
                        <InputText id="cod_fiscale_azienda" v-model="store.state.all.data.cliente.data.cod_fiscale_azienda" :class="[{'p-invalid': validationErrors.format_cf_azienda || validationErrors.exist_cf_azienda && submitted},'inputfield w-full']"/>
                        <div v-if="validationErrors.format_cf_azienda && submitted" class="p-error">Formato errato.</div>
                        <div v-if="validationErrors.exist_cf_azienda && submitted" class="p-error">Codice fiscale esistente.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pec">Pec</label><br>
                        <InputText id="pec" v-model="store.state.all.data.cliente.data.pec" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="score">Score</label><br>
                        <InputText id="score" v-model="store.state.all.data.cliente.data.score" class="inputfield w-full" />
                    </div>
                </div> 

                <!-- ANAGRAFICA -->
                <div  v-else class="formgrid grid"> 
                    <div class="p-field col-12 md:col-6">
                        <label for="name">Nome</label><br>
                        <InputText id="name" v-model="store.state.all.data.cliente.data.name" :class="['inputfield w-full',{'p-invalid': validationErrors.name && submitted}]" />
                        <small v-show="validationErrors.name && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="surname">Cognome</label><br>
                        <InputText id="surname" v-model="store.state.all.data.cliente.data.surname" :class="['inputfield w-full',{'p-invalid': validationErrors.surname && submitted}]" />
                        <small v-show="validationErrors.surname && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div>  
                 <div class="formgrid grid"> 
                    <div class="p-field col-12 md:col-6">
                        <label for="phone">Telefono</label><br>
                        <InputText id="phone" v-model="store.state.all.data.cliente.data.phone" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="mobile">Cellulare</label><br>            
                        <InputMask @keypress="removeErrorPossibleInsert" id="mobile" mask="999-9999999" v-model="store.state.all.data.cliente.data.mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.mobile && submitted}]" />
                        <small v-show="validationErrors.mobile && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12">
                        <label for="email">Email</label><br>
                        <InputText @keypress="removeErrorPossibleInsert" id="email" v-model="store.state.all.data.cliente.data.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" />
                        <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                    </div>
                </div>
                <div v-show="validationErrors.spentPossibleInsert && submitted" class="p-error">Non puoi firmare altri contratti con questo numero di cellulare</div>


                <!-- DETTAGLI -->
                <div class="formgrid grid">
                    <div class="p-field col-12">
                      <small class="suggerimento-indirizzo" v-if="suggestCompleteAddress">Suggerimento: <i>{{suggestCompleteAddress}}</i></small>
                    </div>
                    <div class="p-field col-12  md:col-4">
                        <label for="toponimo">Toponimo</label><br>
                        <Dropdown v-model="store.state.all.data.cliente.data.toponimo" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" :editable="true"  placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo && submitted}]" />
                        <small v-show="validationErrors.toponimo && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="address">Indirizzo</label><br>
                        <InputText id="address" v-model="store.state.all.data.cliente.data.address" :class="['inputfield w-full',{'p-invalid': validationErrors.address && submitted}]" />
                        <small v-show="validationErrors.address && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="civico">Civico</label><br>
                        <InputText id="civico" v-model="store.state.all.data.cliente.data.civico" :class="['inputfield w-full',{'p-invalid': validationErrors.civico && submitted}]" />
                        <small v-show="validationErrors.civico && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-4">
                        <label for="district">Provincia</label><br>
                        <Dropdown @change="onSelectedDistrict($event)" v-model="store.state.all.data.cliente.data.district" :options="districts" optionLabel="name" :filter="true" optionValue="code" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.district && submitted}]" />
                        <small v-show="validationErrors.district && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                     <div class="p-field col-12  md:col-4">
                        <label for="city">Comune</label><br>
                        <Dropdown id="city" @change="onSelectedCity($event)" v-model="store.state.all.data.cliente.data.cityId" :options="cities" optionLabel="name" :filter="true" optionValue="id"  placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.city && submitted}]" />
                        <small v-show="validationErrors.city && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-4">
                        <label for="cap">Cap</label><br>
                        <InputText id="cap" v-model="store.state.all.data.cliente.data.cap" :class="['inputfield w-full',{'p-invalid': validationErrors.cap && submitted}]" />
                        <small v-show="validationErrors.cap && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div>
                <div v-if="store.state.all.data.cliente.data.tipo_client != 2" class="formgrid grid">       
                    <div class="p-field col-12 md:col-6">
                        <label for="codice_destinatario">Codice Destinatario</label><br>
                        <InputText id="codice_destinatario" v-model="store.state.all.data.cliente.data.codice_destinatario" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="codice_ateco">Codice Ateco</label><br>
                        <InputText id="codice_ateco" v-model="store.state.all.data.cliente.data.codice_ateco" class="inputfield w-full" />
                    </div>
                </div>
                <div class="formgrid grid">            
                    <div class="p-field col-12">
                        <label for="note">Note</label><br>
                        <InputText id="note" v-model="store.state.all.data.cliente.data.note" class="inputfield w-full" />
                    </div>
                </div>

                <div class="field-checkbox md:col-6" v-if="store.state.all.data.cliente.data.tipo_client != '' && store.state.all.data.cliente.data.tipo_client != 2">
                  <Checkbox  v-model="copyData" :binary="true" />
                  <label>Clona dati per rappresentante <small>(prossimo step)</small></label>
                </div>
                <div class="field-checkbox md:col-6" v-if="store.state.all.data.cliente.data.tipo_client != '' && store.state.all.data.cliente.data.tipo_client == 2">
                  <Checkbox  v-model="copyData" :binary="true" />
                  <label>Clona dati per contatto <small>(prossimo step)</small></label>
                </div>


                </TabPanel>
              </TabView>        
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex'

import Service from '@/services/ask-pratiche/pratiche-wizard'
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'

import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { useToast } from 'primevue/usetoast';

export default {
  components: {
      Card,
      Button,
      Dropdown,
      TabView,
      TabPanel,
  },

  setup (props, {emit}) {
    
    const store = useStore()
    const toast = useToast();
    
    const stepIndex = 0
    const activeTabIndex = ref(0);
    const currentUser = ref(store.state.auth.user)
    const selectedClient = ref(null)
    const clients = ref([])
    const selectedTipo = ref(null)
    const selectedTipoName = ref('')
    const tipoCliente = ref([])
    const cod_fiscale = ref('')
    const partita_iva = ref('')
    const name = ref('')
    const surname = ref('') 
    const rag_sociale = ref('')
    const cod_fiscale_azienda = ref('') 
    const phone = ref('') 
    const mobile = ref(null) 
    const email = ref('')
    const pec = ref('')
    const score = ref('')
    const toponimi = ref([])
    const selectedToponimo = ref(null)
    const address = ref()
    const civico = ref('')
    const cap = ref(null)
    const districts = ref([])
    const selectedDistrict = ref(null)
    const cities = ref([])
    const selectedCity = ref(null)
    const selectedCityName = ref('')
    const codice_destinatario = ref('')
    const codice_ateco = ref('')
    const note = ref('')
    const cod_fiscaleRapp = ref('')
    const selectedRappId = ref(0) 
    const selectedRappName = ref('') 
    
    const suggestCompleteAddress = ref();
    const copyData = ref(true)
    const validationErrors = ref({})
    const submitted = ref(false)

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus

    const service = new Service(self.$eventBus)
    const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)

    onMounted(() => {
        getAllTipoClient();
        getClients();
        getToponimi();
        getDistricts();
        getCities();
    })

    const getClients = () => {
      emit("activeLoader", true)
      service.getClients().then((res) => {
        clients.value = res.items
      }).then(()=>{
        if (store.state.all.bozzaId != 0) {
          if (store.state.all.data.cliente.type == 'existent') {
            selectedClient.value = clients.value.filter(el=> el.id == store.state.all.data.cliente.data.id)[0]
            activeTabIndex.value = 0
          } else if(store.state.all.data.cliente.type == 'new'){
            activeTabIndex.value = 1
          }
        } 
      }).finally(()=>{
        emit("activeLoader", false)
      })
    }

    const getAllTipoClient = () => {
      service.getAllTipoClient().then((res) => {
        tipoCliente.value = res.items
        store.state.all.data.cliente.data.tipo_client = 2; 
      })

    }

    const getToponimi = () => {
      service.getToponimi().then((res) => {
          toponimi.value = res.items
          store.state.all.data.cliente.data.toponimo = 'VIA'
      })               
    }

    const getDistricts = () => {
      service.getDistricts().then((res) => {
          districts.value = res.items
      }) 
    }
    const getCities = () => {
      service.getCities().then((res) => {
          cities.value = res.items
      })
    }



    const onSelectedDistrict = (evt) => {
      selectedCity.value = null
      service.getCities(evt.value).then((res) => {
        cities.value = res.items
      })
    }

    const onSelectedCity = (evt) => {
      selectedCityName.value = evt.originalEvent.target.ariaLabel
    }

    const onSelectedTipo = (evt) => {
      selectedTipoName.value = evt.originalEvent.target.ariaLabel  
    }

    const removeExClient = () => {
      selectedClient.value = null
      removeErrorPossibleInsert ()
    }

    const getInfoToCreditSafe = async () => {
      if (store.state.all.data.cliente.data.partita_iva.length == 11) {
        const pivaExsist = await validateIvaExist()
        if (pivaExsist) {
          emit("activeLoader", true)
          service.getInfoCreditSafe(store.state.all.data.cliente.data.partita_iva).then((res) => {
            updateData(res) 
          }).finally(() => {
            emit("activeLoader", false)
          })
        }          
      }
    }
    const removeErrorPossibleInsert = () => {
      delete validationErrors.value['spentPossibleInsert'] 
    }

    const updateData = (res) => {

      suggestCompleteAddress.value = '';

      if (res.item.totalSize > 0) {
          let company;
          company = res.item.companies.filter(el=> el.status == 'active')[0]
          if (!company) {
              company = res.item.companies[0]
              toast.add({severity:'warn', summary:'Attenzione:', detail: 'Azienda non attiva'});
          }

          suggestCompleteAddress.value = company.address.simpleValue;

          store.state.all.data.cliente.data.rag_sociale = company.name
          store.state.all.data.cliente.data.phone = ("phoneNumbers" in company) ? company.phoneNumbers[0] : ''
          store.state.all.data.cliente.data.civico = company.address.houseNo
          store.state.all.data.cliente.data.cap = company.address.postCode
          store.state.all.data.cliente.data.district = company.address.province
          service.getCityId(company.address.city).then((res) => {
            if (res.item.length > 0) {
              store.state.all.data.cliente.data.cityId = res.item[0].id
            } 
          })
          extractToponimo(company.address.street)
      } 
    }

    const extractToponimo = (indirizzo) => {
      let addr = indirizzo.split(" ")[0]
      toponimi.value.every(el=>{
        let isPresentName = addr.includes(el.name);
        let isPresentCode = addr.includes(el.code);
        if (isPresentCode || isPresentName) {
          store.state.all.data.cliente.data.toponimo =  el.name
          let temp = (indirizzo.replace( el.name,'')).trim()     
          temp = (temp.replace( el.code,'')).trim()     
          store.state.all.data.cliente.data.address = temp
          return false
        }
        return true
      })
    }


    const validateSelectedTipo = () => {
      const isValid = !!store.state.all.data.cliente.data.tipo_client
      if (!store.state.all.data.cliente.data.tipo_client)
          validationErrors.value['selectedTipo'] = true;
      else
          delete validationErrors.value['selectedTipo'];

      return isValid
    }

    const validateInputCFDomestisco = () => {
      if (store.state.all.data.cliente.data.cod_fiscale == "") {
          validationErrors.value['cod_fiscale'] = true;      
          return false
      } else {
        delete validationErrors.value['cod_fiscale'];
        return true  
      }
    }

    const validateCFDomestico = () => {
      // validate regex cf
      let reg = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i   
      
      const isValid = reg.test(store.state.all.data.cliente.data.cod_fiscale)
      if (!isValid) {
          validationErrors.value['format_cf'] = true;
      } else {
          delete validationErrors.value['format_cf'];
      }    
      
      return isValid
    }

    const validateCFBusiness = async () => {
      let isValid = false
      if (store.state.all.data.cliente.data.cod_fiscale_azienda.length == 16) {
        // validate regex cf
        let reg = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i   
        
        isValid = reg.test(store.state.all.data.cliente.data.cod_fiscale_azienda)
        if (!isValid) {
            validationErrors.value['format_cf_azienda'] = true;
        } else {
            delete validationErrors.value['format_cf_azienda'];
        }  
        
      } else if(store.state.all.data.cliente.data.cod_fiscale_azienda.length == 11){
        isValid = true
      }
        
      
      return isValid
    }

    const validateCFBusinessExist = async () => {
      const res = await service.checkCodeExist(store.state.all.data.cliente.data.cod_fiscale_azienda)

      if (res.items === true ) {
          delete validationErrors.value['exist_cf_azienda'];
          toast.removeAllGroups();

          return true
      } else {
          validationErrors.value['exist_cf_azienda'] = true;
          toast.add({severity:'warn', summary:'Attenzione:', detail: 'Cliente esistente'});
          
          return false        
      } 
    }

    const validateCFDomesticoExist = async () => {
      const res = await service.checkCodeExist(store.state.all.data.cliente.data.cod_fiscale)

      if (res.items === true ) {
          delete validationErrors.value['exist_cf'];
          toast.removeAllGroups();

          return true
      } else {
          validationErrors.value['exist_cf'] = true;
          toast.add({severity:'warn', summary:'Attenzione:', detail: 'Cliente esistente'});
          
          return false        
      } 
    }

    const validateFormIva = () => {
      if (store.state.all.data.cliente.data.partita_iva == null || store.state.all.data.cliente.data.partita_iva.toString().length == 11)
        delete validationErrors.value['partita_iva'];
      else
        validationErrors.value['partita_iva'] = true;
          
      return !Object.keys(validationErrors.value).length;
    }

    const ivaIsNumeric = () => {
      const isNum = /^\d+$/.test(store.state.all.data.cliente.data.partita_iva);
      if (isNum) {
        delete validationErrors.value['format_iva'];     
      } else {
        validationErrors.value['format_iva'] = true;             
      }

      return isNum
    }

    const validateIvaExist = async () => {
      const res = await service.checkIvaExist(store.state.all.data.cliente.data.partita_iva)
      if (res.items == true) {                 
          delete validationErrors.value['exist_iva'];
          toast.removeAllGroups();

          return true
      } else {
          validationErrors.value['exist_iva'] = true;
          toast.add({severity:'warn', summary:'Attenzione:', detail: 'Cliente Esistente'});   
          
          return false
      }
    }

    const validateBusiness = async () => {
       validationErrors.value = []

      if (store.state.all.data.cliente.data.rag_sociale == null || !store.state.all.data.cliente.data.rag_sociale.trim())
          validationErrors.value['rag_sociale'] = true;
      else 
          delete validationErrors.value['rag_sociale'];

      let tipo = store.state.all.data.cliente.data.tipo_client

      if (tipo == 3 || tipo == 5 || tipo == 6) {
        if (store.state.all.data.cliente.data.cod_fiscale_azienda == null || !store.state.all.data.cliente.data.cod_fiscale_azienda.trim()){
          validationErrors.value['format_cf_azienda'] = true;
        }else{
          if (await validateCFBusiness()) {
            delete validationErrors.value['format_cf_azienda'];
            if (await validateCFBusinessExist()) {
              delete validationErrors.value['exist_cf_azienda'];
                if (store.state.all.data.cliente.data.partita_iva != null){
                  if (store.state.all.data.cliente.data.partita_iva != '') {
                    if (store.state.all.data.cliente.data.partita_iva.toString().length == 11) {
                    delete validationErrors.value['partita_iva'];
                    }else{
                    validationErrors.value['partita_iva'] = true;
                    }
                  }
                }
                  
            } else {
              validationErrors.value['exist_cf_azienda'] = true;
            }
          } else {
            validationErrors.value['format_cf_azienda'] = true;
          }  
        }
      } else {

        let valid = false;
        valid = validateFormIva()
        if (valid) {
          valid = ivaIsNumeric()
          if (valid) {
            valid = await validateIvaExist()
          }
        }

      }
        
      validateAll()

      return !Object.keys(validationErrors.value).length;  
    }

    const validateDomestico = async () => {
      validationErrors.value = []

      if (store.state.all.data.cliente.data.name == null || store.state.all.data.cliente.data.name.trim() == '')
          validationErrors.value['name'] = true;
      else 
          delete validationErrors.value['name'];
      
      if (store.state.all.data.cliente.data.surname == null || store.state.all.data.cliente.data.surname.trim() == '')
          validationErrors.value['surname'] = true;
      else 
          delete validationErrors.value['surname'];

      let valid = false;
      valid = validateInputCFDomestisco()
      if (valid) {
        valid = validateCFDomestico()
        if (valid) {
          valid = await validateCFDomesticoExist()
        }
      }

      validateAll()

      return !Object.keys(validationErrors.value).length;  
    }

    const validateAll = () => {

      let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


      if (store.state.all.data.cliente.data.mobile == null || !store.state.all.data.cliente.data.mobile.trim())
          validationErrors.value['mobile'] = true;
      else 
          delete validationErrors.value['mobile'];

      if (store.state.all.data.cliente.data.email == null || !store.state.all.data.cliente.data.email.trim())
          validationErrors.value['email'] = true;
      else 
          delete validationErrors.value['email'];

      if (regex_email.test(store.state.all.data.cliente.data.email)) 
            delete validationErrors.value['isEmail'];
      else 
          validationErrors.value['isEmail'] = true;

      if (store.state.all.data.cliente.data.toponimo == null || !store.state.all.data.cliente.data.toponimo.trim())
          validationErrors.value['toponimo'] = true;
      else 
          delete validationErrors.value['toponimo'];
      
      if (store.state.all.data.cliente.data.address == null || !store.state.all.data.cliente.data.address.trim())
          validationErrors.value['address'] = true;
      else 
          delete validationErrors.value['address'];
      
      if (store.state.all.data.cliente.data.civico == null || !store.state.all.data.cliente.data.civico.trim())
          validationErrors.value['civico'] = true;
      else 
          delete validationErrors.value['civico'];
      
      if (store.state.all.data.cliente.data.district == null || !store.state.all.data.cliente.data.district.trim())
          validationErrors.value['district'] = true;
      else 
          delete validationErrors.value['district'];

      if (!Number(store.state.all.data.cliente.data.cityId))
          validationErrors.value['city'] = true;
      else 
          delete validationErrors.value['city'];
      
      if (store.state.all.data.cliente.data.cap == null || !store.state.all.data.cliente.data.cap.trim())
          validationErrors.value['cap'] = true;
      else 
          delete validationErrors.value['cap'];
      
       return !Object.keys(validationErrors.value).length;  
    }

    const validateExistClient = async () =>{
      if (selectedClient.value == null){
        validationErrors.value['client'] = true;
        return false
      }else {
        delete validationErrors.value['client'];
        return true
      }
    }

    const nextPage = async () => {

      submitted.value = true;
      let passCheck = false;
      let cell;
      let email;
      if (activeTabIndex.value == 1) {
        if(validateSelectedTipo()){
          if(store.state.all.data.cliente.data.tipo_client == 2){
            if (await validateDomestico()) {
              passCheck = true
            } 
          }else{
            if (await validateBusiness()) {
                passCheck = true      
            }
          }
        }  
        cell = store.state.all.data.cliente.data.mobile.replace('-','');
        // email = store.state.all.data.cliente.data.email;
      } else {
        passCheck = await validateExistClient()
        cell = selectedClient.value.cellulare.replace('-','');
        // email = selectedClient.value.email;
      }
      let pass = await countUseContact(cell)


      if (passCheck && pass) {
        
        store.state.all.data.realod = true
        store.state.all.data.step = 'cliente'
        store.state.all.data.wizard = 'agenti/inserimento'
        store.state.all.data.azienda = currentUser.value.area
        store.state.all.data.pageIndex = stepIndex
        store.state.all.data.cliente = selectedClient.value == null ? store.state.all.data.cliente : {data:{id:selectedClient.value.id, code:selectedClient.value.code, codfis: selectedClient.value.codfis, piva: selectedClient.value.piva, typeClient: selectedClient.value.cod_type_client, idRapp: selectedClient.value.cod_rapp, labelName: selectedClient.value == 2 ? selectedClient.value.nome + "" + selectedClient.value.cognome : selectedClient.value.ragsoc}}
        store.state.all.data.cliente.type = selectedClient.value == null ? 'new' : 'existent'
        if (store.state.all.data.cliente.type == 'new') {
          store.state.all.data.cliente.data.labelName = store.state.all.data.cliente.data.tipo_client == 2 ? store.state.all.data.cliente.data.name + " " + store.state.all.data.cliente.data.surname : store.state.all.data.cliente.data.rag_sociale;
        }else{
          store.state.all.data.cliente.data.labelName = selectedClient.value.cod_type_client == 2 ? selectedClient.value.nome + " " + selectedClient.value.cognome : selectedClient.value.ragsoc;
        }
        // store.state.all.data.cliente.data.iban = selectedClient.value.iban ?? '';
        // store.state.all.data.cliente.data.istituto = selectedClient.value.istituto ?? '';
        copyDataClientToRapp()

        if (store.state.all.data.cliente.type == 'new') {
          if (store.state.all.data.cliente.data.tipo_client == 2) {
              store.state.all.data.cliente.data.partita_iva = ''
              store.state.all.data.cliente.data.rag_sociale = ''
              store.state.all.data.cliente.data.cod_fiscale_azienda = ''
              store.state.all.data.cliente.data.pec = ''
              store.state.all.data.cliente.data.score = ''
              store.state.all.data.cliente.data.codice_destinatario = ''
              store.state.all.data.cliente.data.codice_ateco = ''

          } else {
              store.state.all.data.cliente.data.cod_fiscale =  store.state.all.data.cliente.data.cod_fiscale_azienda
              store.state.all.data.cliente.data.name =  ''
              store.state.all.data.cliente.data.surname =  ''
          }
          // reset object if comeback and select new client (from exist client)
          delete store.state.all.data.cliente.data.id
          delete store.state.all.data.cliente.data.code
          delete store.state.all.data.cliente.data.piva
          delete store.state.all.data.cliente.data.typeClient
          delete store.state.all.data.cliente.data.idRapp
          // delete store.state.all.data.cliente.data.codfis
          // delete store.state.all.data.cliente.data.labelName
        }
        

        if(store.state.all.bozzaId == 0){

          praticheWizardBozzeService.create("agenti/inserimento", JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
            if(res.success){
              store.state.all.bozzaId = res.item.id
              emit('next-page', stepIndex);
            }
          })

        }else{
          praticheWizardBozzeService.update(store.state.all.bozzaId, JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
            if(res.success){
              store.state.all.bozzaId = res.item.id
                emit('next-page', stepIndex);
            }
          })

        }
      
      }else{
        validationErrors.value['spentPossibleInsert'] = true; 
      }
    }

    const countUseContact = async (cell) => {
      const res = await service.countUseContact(cell)
      return res
    }


    const copyDataClientToRapp = () =>{

      if (store.state.all.data.cliente.data.tipo_client != 2 ) {
        if (copyData.value && store.state.all.data.cliente.type == 'new') {
          store.state.all.data.rappresentante.email = store.state.all.data.cliente.data.email
          store.state.all.data.rappresentante.pec = store.state.all.data.cliente.data.pec
          store.state.all.data.rappresentante.mobile = store.state.all.data.cliente.data.mobile
          store.state.all.data.rappresentante.phone = store.state.all.data.cliente.data.phone
          store.state.all.data.rappresentante.toponimo = store.state.all.data.cliente.data.toponimo
          store.state.all.data.rappresentante.address = store.state.all.data.cliente.data.address
          store.state.all.data.rappresentante.civico = store.state.all.data.cliente.data.civico
          store.state.all.data.rappresentante.cap = store.state.all.data.cliente.data.cap
          store.state.all.data.rappresentante.selectedDistrict = store.state.all.data.cliente.data.district
          store.state.all.data.rappresentante.cityId = store.state.all.data.cliente.data.cityId
        }
      } else {
        if (copyData.value && store.state.all.data.cliente.type == 'new') {
          store.state.all.data.contatto.email = store.state.all.data.cliente.data.email
            // store.state.all.data.contatto.pec = store.state.all.data.cliente.data.pec
          store.state.all.data.contatto.mobile = store.state.all.data.cliente.data.mobile
          store.state.all.data.contatto.phone = store.state.all.data.cliente.data.phone
          store.state.all.data.contatto.toponimo = store.state.all.data.cliente.data.toponimo
          store.state.all.data.contatto.address = store.state.all.data.cliente.data.address
          store.state.all.data.contatto.civico = store.state.all.data.cliente.data.civico
          store.state.all.data.contatto.cap = store.state.all.data.cliente.data.cap
          store.state.all.data.contatto.selectedDistrict = store.state.all.data.cliente.data.district
          store.state.all.data.contatto.cityId = store.state.all.data.cliente.data.cityId
        }
      }

    }
    

    return {
      store,
      activeTabIndex,
      removeExClient,
      copyData,
      selectedClient,
      clients,
      selectedTipo, 
      selectedTipoName, 
      tipoCliente,
      partita_iva,
      cod_fiscale,
      name,
      surname, 
      rag_sociale,
      cod_fiscale_azienda, 
      phone, 
      mobile,
      email,
      pec,
      score,
      toponimi,
      selectedToponimo,
      address,
      civico,
      cap,
      districts,
      selectedDistrict,
      cities,
      selectedCity,
      selectedCityName,
      codice_destinatario,
      codice_ateco,
      note,
      cod_fiscaleRapp,
      selectedRappId,
      selectedRappName,
      getInfoToCreditSafe,
      suggestCompleteAddress,
      submitted,  
      onSelectedDistrict,
      onSelectedCity,
      onSelectedTipo,
      nextPage,
      validationErrors,
      removeErrorPossibleInsert
      // validateIvaExist,
      // validateForm,
      // validateInput,
      // validateCf 
    }
  },
}
</script>
<style scoped>
.pi-times {
  cursor: pointer;
}
</style>
<style>
.form-step .p-card-title, .form-step .p-card-subtitle {
    text-align: center;
}
#cod_fiscale {
    text-transform: uppercase;
}

.suggerimento-indirizzo i{
  color: var(--primary-color);
}

.btn-remove-selected {
  cursor: pointer;
}

</style>

import axios from 'axios'
import services from '@/config/services'
import authHeader from '../auth/auth-header';
import Cookies from 'js-cookie';

export default class PraticheWizardBozzeService{
  constructor(eventBus){
    this.http = axios
    this.eventBus = eventBus
    this.baseUrl = `${services.ask_pratiche_api.url}/management` 

    axios.interceptors.request.use(
      (req) => {
        req.headers["Authorization"] = authHeader()
        req.headers["Params"] = Cookies.get('google_access_token') || Cookies.get('ask_access_token')
        this.eventBus.emit('data-loading');
        return req
      }
    )
  }

  async getAll(userIds, role, wizardName = null){
    let item = {}

    const url = `${this.baseUrl}/wizard/bozze/search`

    try {
      const filters = {
        "user.ids": userIds,
        role,
      }

      if(wizardName != null){
        filters["wizard_name"] = wizardName
      }

      let res = await this.http.post(url, {
        filters
      })
      res = res.data

      item = res.data
    } catch (err) {
      //this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
     // this.eventBus.emit('data-loaded')
      
      // eslint-disable-next-line no-unsafe-finally
      return {
        item,
      }
    }
  }

  async create(wizardName, obj){
    let result = {
      item: null,
      success: false,
    }

    const data = {
      wizard: wizardName,
      json: obj
    }

    const url = `${this.baseUrl}/wizard/bozze`

    try {
      let res = await this.http.post(url, {
        data
      })
    
      res = res.data

      result = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')
      
      // eslint-disable-next-line no-unsafe-finally
      return {
        item: result.item,
        success: result.success,
      }
    }
  }

  async update(id, obj){
    let result = {
      item: null,
      success: false,
    }

    const data = {
      json: obj
    }

    const url = `${this.baseUrl}/wizard/bozze/${id}`

    try {
      let res = await this.http.put(url, {
        data
      })
    
      res = res.data

      result = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded') 
      
      // eslint-disable-next-line no-unsafe-finally
      return {
        item: result.item,
        success: result.success,
      }
    }
  }

  async delete(id){
    let success = false

    const url = `${this.baseUrl}/wizard/bozze/${id}`

    try {
      let res = await this.http.delete(url)
    
      res = res.data

      success = res.data
    } catch (err) {
      this.eventBus.emit('data-load-error', {message: err, url})
    } finally {
      this.eventBus.emit('data-loaded')
      
      // eslint-disable-next-line no-unsafe-finally
      return {
        success,
      }
    }
  }

}

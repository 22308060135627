<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Contratto
            </template>
            <template v-slot:subtitle>
                Dati contrattuali
            </template>
            <template v-slot:content>
                <div class="formgrid grid">     
                    <div class="p-field col-12 md:col-6">
                        <label for="tipologia">Tipologia</label>
                        <Dropdown  v-model="selectedTipoCtr" :options="tipiCtr" optionLabel="name"   placeholder="Seleziona tipologia contratto" :class="['inputfield w-full',{'p-invalid': validationErrors.tipologia && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.tipologia && submitted" class="p-error">Seleziona una tipologia.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Reseller</label>
                        <Dropdown  v-model="selectedReseller" :options="resellers" optionLabel="ragsoc" placeholder="Seleziona reseller" :filter="true" class="inputfield w-full">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.ragsoc}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.ragsoc}}</div>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="p-field  col-12 md:col-4">
                        <label for="data_firma">Data Firma</label>
                        <Calendar  v-model="dataFirma" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_firma && submitted}]" /> <!-- @date-select="selectDateFirma" -->
                        <div v-show="validationErrors.data_firma && submitted" class="p-error">Valore non valido</div>
                    </div>
                    <div class="p-field  col-12 md:col-4">
                        <label for="data_inizio">Data Inizio</label>
                        <Calendar  v-model="dataInizio" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_inizio && submitted}]" />
                        <div v-show="validationErrors.data_inizio && submitted" class="p-error">Valore non valido</div>
                    </div>
                    <div class="p-field  col-12 md:col-4">
                        <label for="data_fine">Data Fine</label>
                        <Calendar  v-model="dataFine" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_fine && submitted}]" />
                        <div v-show="validationErrors.data_fine && submitted" class="p-error">Valore non valido</div>
                    </div>
                    <div class="p-field col-12">
                        <label for="offerta">Offerta</label>
                        <Dropdown  v-model="selectedOfferta" :options="offerte"  :filter="true"  optionLabel="name"  placeholder="Seleziona offerta" :class="['inputfield w-full',{'p-invalid': validationErrors.offerta && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}} <small>(dal {{slotProps.option.data_inizio}} al {{slotProps.option.data_fine}})</small></div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.offerta && submitted" class="p-error">Seleziona un'offerta.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Agente</label>
                        <Dropdown  v-model="selectedAgente" :options="agenti" optionLabel="name" placeholder="Seleziona agente" :filter="true" :class="['inputfield w-full',{'p-invalid': validationErrors.agente && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.agente && submitted" class="p-error">Seleziona un agente.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Acquisizione</label>
                        <Dropdown  v-model="selectedAcquisizione" :options="acquisizioni" optionLabel="name" placeholder="Seleziona acquisizione" :class="['inputfield w-full',{'p-invalid': validationErrors.acquisizione && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.acquisizione && submitted" class="p-error">Seleziona tipo acquisizione.</div>
                    </div>
                    <div class="field-checkbox md:col-6">
                       <Checkbox id="rinnovo" v-model="rinnovo" :binary="true" />
                       <label for="rinnovo">Rinnovo automatico</label>
                    </div>
                     <div class="field-checkbox md:col-6">
                       <Checkbox id="documentazione" v-model="documentazione" :binary="true" />
                       <label for="documentazione">Documentazione fornita in originale</label>
                    </div>                
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment'
import {ref, getCurrentInstance, onMounted} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import { useStore } from 'vuex'
import roles from '@/config/roles'


export default {
    components: {
        Card,
        Button,
        Dropdown,
        Calendar,
        Checkbox

    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {
        const selectedTipoCtr = ref('')
        const tipiCtr = ref([])
        const selectedReseller = ref(0)
        const resellers = ref([])
        const dataFirma = ref(new Date()) 
        const dataInizio = ref(new Date())
        const dataFine = ref(new Date('2099-12-31'))
        const selectedOfferta = ref(0)
        const offerte = ref([])
        const selectedAgente = ref('')
        const agenti = ref([])
        const selectedAcquisizione = ref('')
        const acquisizioni = ref([])
        const rinnovo = ref(false)
        const documentazione = ref(false)
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const store = useStore()
        const currentUser = ref(store.state.auth.user)
        

        

        onMounted (()=>{
            const getTipiCtrPromise = service.getTipiCtr()
            getTipiCtrPromise.then((res) => {
                tipiCtr.value = res.items
            })

            const getResellersPromise = service.getResellers()
            getResellersPromise.then((res) => {
                resellers.value = res.items
            })

            const getOffertePromise = service.getOfferte()
            getOffertePromise.then((res) => {
                offerte.value = res.items
            })

            const getAgentiPromise = service.getAgenti()
            getAgentiPromise.then((res) => {
              if (currentUser.value.role == roles.agent.role) {
                selectedAgente.value = (res.items.filter(el => el.id == currentUser.value.id))[0] ?? ''
              } else {
                agenti.value = res.items
              }           
            })

            const getAcquisizioniPromise = service.getAcquisizioni()
            getAcquisizioniPromise.then((res) => {
                acquisizioni.value = res.items
            })

            Promise.all([
              getTipiCtrPromise, 
              getResellersPromise, 
              getOffertePromise, 
              getAgentiPromise, 
              getAcquisizioniPromise
            ]).then(() => {
              populateExCtr()
            })
        })


        const populateExCtr = () => {
          
          if (props.formData.exCtr != null) {
            selectedTipoCtr.value = (tipiCtr.value.filter(el => props.formData.exCtr[0].cod_type_contratto == el.id ))[0]
            selectedReseller.value = (resellers.value.filter(el => props.formData.exCtr[0].cod_reseller == el.id ))[0] ?? 0

            selectedOfferta.value = (offerte.value.filter(el => props.formData.exCtr[0].cod_offerta == el.id ))[0] ?? 0
            selectedAgente.value = (agenti.value.filter(el => props.formData.exCtr[0].cod_agente == el.id ))[0] ?? ''
            selectedAcquisizione.value = (acquisizioni.value.filter(el => props.formData.exCtr[0].cod_type_acquisizione == el.id ))[0] ?? 1
            props.formData.exCtr[0].cod_type_documentazione == 1 ? documentazione.value = true : false
            props.formData.exCtr[0].cod_type_rinnovo == 1 ? rinnovo.value = true : false
          } 
        }

        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }

        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
                emit('next-page', 
                {
                    formData: {
                        tipoCtr: selectedTipoCtr.value,
                        reseller: selectedReseller.value,
                        dataFirma:moment( dataFirma.value).format("YYYY-MM-DD"),
                        dataInizio: moment( dataInizio.value).format("YYYY-MM-DD"),
                        dataFine: moment( dataFine.value).format("YYYY-MM-DD"),
                        offerta: selectedOfferta.value,
                        agente: selectedAgente.value,
                        acquisizione: selectedAcquisizione.value,
                        rinnovo: rinnovo.value,
                        documentazione: documentazione.value,
                    },
                    pageIndex: 1
                });
            }                  
        }

      
        const validateForm = () => {
            
            if (selectedTipoCtr.value == undefined || selectedTipoCtr.value == '')
                validationErrors.value['tipologia'] = true;
            else 
                delete validationErrors.value['tipologia'];
            
            if (selectedOfferta.value == undefined || selectedOfferta.value == '')
                validationErrors.value['offerta'] = true;
            else 
                delete validationErrors.value['offerta'];

            if (currentUser.value.role == roles.agent.role) {
              if (selectedAgente.value == undefined || selectedAgente.value == '')
                validationErrors.value['agente'] = true;
              else 
                delete validationErrors.value['agente'];
            }
            
            if (selectedAcquisizione.value == undefined || selectedAcquisizione.value == '')
                validationErrors.value['acquisizione'] = true;
            else 
                delete validationErrors.value['acquisizione'];
            
            if (!moment(dataFirma.value, 'DD-MM-YYYY', true).isValid())
                validationErrors.value['data_firma'] = true;
            else 
                delete validationErrors.value['data_firma'];

            if (!moment(dataInizio.value, 'DD-MM-YYYY', true).isValid())
                validationErrors.value['data_inizio'] = true;
            else 
                delete validationErrors.value['data_inizio'];
            
            if (!moment(dataFine.value, 'DD-MM-YYYY', true).isValid())
                validationErrors.value['data_fine'] = true;
            else 
                delete validationErrors.value['data_fine'];
                                  
            return !Object.keys(validationErrors.value).length;
        }

        return {
           selectedTipoCtr, tipiCtr, validationErrors, submitted, service, nextPage, prevPage,
           selectedReseller, resellers, dataFirma, dataInizio, dataFine, selectedOfferta, offerte,
           selectedAgente, agenti, selectedAcquisizione, acquisizioni, rinnovo, documentazione
        }

    },
}
</script>

<template>
	<ul v-if="items">
		<template v-for="(item,i) of items">
					
			<li v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{'layout-menuitem-category': root, 'active-menuitem': activeIndex === i && !item.to && !item.disabled}]" role="none">
				<template v-if="root">
					<div class="layout-menuitem-root-text">{{item.label}}</div>			
					<appsubmenu :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
				</template>
				<template v-else>

					<!-- visibilità in base al ruolo -->
					<div v-if="item.visibility.includes(role) && (role == 'admin' ? item.profiles.some(profile => profile in userProfile && userProfile[profile] == 1) : true)" :key="i">
						<router-link v-if="item.to" :to="item.to" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :style="item.style" @click="onMenuItemClick($event,item,i)" :target="item.target" exact role="menuitem" v-ripple>
							<i :class="item.icon"></i>
							<span>{{item.label}}</span>
							<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
							<Badge class="badge-modifiche" v-if="item.badge" :value="item.badge" style="color: #0098f0 !important;"></Badge>
							<!-- <span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span> -->
						</router-link>
						<a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" @click="onMenuItemClick($event,item,i)" :target="item.target" role="menuitem" v-ripple>
							<i :class="item.icon"></i>
							<span>{{item.label}}</span>
							<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
							<!-- <span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span> -->
							<Badge class="badge-modifiche" v-if="item.badge" :value="item.badge"  style="color: #0098f0 !important;"></Badge>
						</a>
						<transition name="layout-submenu-wrapper">
							<appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
						</transition>
					</div>
          
				</template>
			</li>
			<li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
		</template>
	</ul>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
	emits: ["menuitem-click"],
	name: 'appsubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	setup(props, { emit }) {
		// const self = getCurrentInstance();
		// const eventBus =  self.appContext.config.globalProperties.$eventBus
		const activeIndex = ref(null)
		const store = useStore()
		const role = ref(store.state.auth.user.role)
		const userProfile = ref(store.state.auth.user.profile)
		
	
		const onMenuItemClick = (event, item, index) => {
			if (item.disabled) {
				event.preventDefault();
				return;
            }

            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
				item.command({originalEvent: event, item: item});
			}

			activeIndex.value = index === activeIndex.value ? null : index;

			emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		}

		const visible = (item) => {
			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		}

		return {onMenuItemClick, visible, role, activeIndex, userProfile}
	}
}



// export default {
// 	name: 'appsubmenu',
// 	props: {
// 		items: Array,
// 		root: {
// 			type: Boolean,
// 			default: false
// 		}
// 	},
// 	data() {
// 		return {
// 			activeIndex : null
// 		}
// 	},
// 	methods: {
// 		onMenuItemClick(event, item, index) {
// 			if (item.disabled) {
// 				event.preventDefault();
// 				return;
//             }

//             if (!item.to && !item.url) {
//                 event.preventDefault();
//             }

// 			//execute command
// 			if (item.command) {
//                 item.command({originalEvent: event, item: item});
// 			}

// 			this.activeIndex = index === this.activeIndex ? null : index;

// 			this.$emit('menuitem-click', {
// 				originalEvent: event,
// 				item: item
// 			});
// 		},
// 		visible(item) {
// 			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
// 		}
// 	}
// }
</script>
<style>
	.badge-modifiche{
		background-color: #dee2e6 !important;
	}
</style>

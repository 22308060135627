<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Pdr
            </template>
            <template v-slot:subtitle>
                Inserisci il Pdr
            </template>
            <template v-slot:content>
                <div class="p-fluid">         
                    <div class="p-field">
                        <InputText id="pdr"  v-model="pdr" :class="['inputfield w-full',{'p-invalid': validationErrors.pdr || validationErrors.format || validationErrors.lunghezza || validationErrors.exist && submitted}]"  />
                        <small v-show="validationErrors.pdr && submitted" class="p-error">Campo richiesto.<br></small>
                        <small v-show="validationErrors.format && submitted" class="p-error">Formato non valido.<br></small>
                        <small v-show="validationErrors.lunghezza && submitted" class="p-error">Lunghezza non valida.</small>
                        <small v-show="validationErrors.exist && submitted" class="p-error">Pdr già registrato.</small>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button  label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance} from 'vue';
import { useRoute } from 'vue-router';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useToast } from "primevue/usetoast";



export default {
    components: {
        Card,
        InputText,
        Button,
    },
     props: {
        formData: Object
    },
    setup (props, {emit}) {
        const toast = useToast();
        const route = useRoute();
        const pdr = ref('')
        const idContratto = ref(route.params.idContratto)
        const idCliente = ref(route.params.idCliente)
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        
       
        const nextPage = () => {

            submitted.value = true;
             if (validateForm()) {
                checkPdrExist().then((exist) => {
                    if (exist) {            
                        emit('next-page', {
                            formData: {
                                pdr: pdr.value,
                                idContratto: idContratto.value,
                                idCliente: idCliente.value,
                            },
                            pageIndex: 0
                        });        
                    }          
                }) 
             }
        }

        const isValid = () => {
           //regex validate code pdr
            return  /^\d+$/.test(pdr.value);
        }

        const checkPdrExist = async () => {
            
            const res = await service.checkPdrExist(pdr.value)

            if (!res.item.exist) {
                toast.removeAllGroups();            
            } else {
                toast.add({severity:'warn', summary:'Attenzione:', detail: 'Pdr già registrato'});                   
            }
            
            //return true
            return !res.item.exist         
        }

        
           
        const validateForm = () => {
     
            if (!pdr.value.trim()) 
                validationErrors.value['pdr'] = true;
             else 
                delete validationErrors.value['pdr'];

            if (pdr.value.length > 13 && pdr.value.length < 15) 
               delete validationErrors.value['lunghezza'];
             else 
                 validationErrors.value['lunghezza'] = true;
        
            if (!isValid() && pdr.value != '') 
                validationErrors.value['format'] = true;
             else 
                delete validationErrors.value['format'];
            
                       
            return !Object.keys(validationErrors.value).length;
        }

        return {
           pdr, idContratto, idCliente, validationErrors, submitted, nextPage, checkPdrExist
        }

    },
}
</script>

<style scoped>
#pod {
    text-transform: uppercase;
}
</style>

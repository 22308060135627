<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Sede
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="p-field col-12  md:col-6">
                        <label for="toponimo">Toponimo</label><br>
                        <Dropdown v-model="selectedToponimo" :options="toponimi" optionLabel="name" :filter="true" :editable="true" optionValue="name" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo && submitted}]" />
                        <small v-show="validationErrors.toponimo && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="address">Indirizzo</label><br>
                        <InputText id="address" v-model="address" :class="['inputfield w-full',{'p-invalid': validationErrors.address && submitted}]" />
                        <small v-show="validationErrors.address && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="civico">Civico</label><br>
                        <InputText id="civico" v-model="civico" :class="['inputfield w-full',{'p-invalid': validationErrors.civico && submitted}]" />
                        <small v-show="validationErrors.civico && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="cap">Cap</label><br>
                        <InputText id="cap" v-model="cap" :class="['inputfield w-full',{'p-invalid': validationErrors.cap && submitted}]" />
                        <small v-show="validationErrors.cap && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="region">Regione</label><br>
                        <Dropdown @change="onSelectedRegion($event)" v-model="selectedRegionId" :options="regions" optionLabel="name" optionValue="id" placeholder="Seleziona regione" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedRegionId && submitted}]" />
                        <small v-show="validationErrors.selectedRegionId && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="district">Provincia</label><br>
                        <Dropdown @change="onSelectedDistrict($event)" v-model="selectedDistrict" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedDistrict && submitted}]" />
                        <small v-show="validationErrors.selectedDistrict && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                     <div class="p-field col-12  md:col-6">
                        <label for="city">Comune</label><br>
                        <Dropdown id="city" @change="onSelectedCity($event)" v-model="selectedCity" :options="cities" optionLabel="name" optionValue="id" :filter="true" :title="cap" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedCity && submitted}]" />
                        <small v-show="validationErrors.selectedCity && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                     <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
// import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        Card,
        InputText,
        Button,
        // InputNumber,
        Dropdown
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {
        const toponimi = ref([])
        const selectedToponimo = ref(null)
        const address = ref('')
        const civico = ref('')
        const cap = ref(null)
        const regions = ref([])
        const selectedRegionId = ref(null)
        const selectedRegionName = ref('')
        const districts = ref([])
        const selectedDistrict = ref(null)
        const cities = ref([])
        const selectedCity = ref(null)
        const selectedCityName = ref('')
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)


        onMounted(() => { 
             getRegions();
        })
        
        const getRegions = () => {
            service.getToponimi().then((res) => {
                toponimi.value = res.items
            })
            service.getRegions().then((res) => {
                regions.value = res.items
            })
        }

        const onSelectedRegion = (evt) => {
            selectedCity.value = ''
            selectedRegionName.value = evt.originalEvent.target.ariaLabel
            service.getDistricts(selectedRegionId.value).then((res) => {
                districts.value = res.items
            })
        }

        const onSelectedDistrict = (evt) => {
            service.getCities(evt.value).then((res) => {
                cities.value = res.items
            })
        }
        
        const onSelectedCity = (evt) => {
            selectedCityName.value = evt.originalEvent.target.ariaLabel
        }

        const prevPage = () => {
            emit('prev-page', {pageIndex: 2});
        }
        const nextPage = () => {

            submitted.value = true;
            if (validateForm()) {
                emit('next-page',{formData: {
                     toponimo: selectedToponimo.value,
                     address: address.value,
                     civico: civico.value,
                     cap: cap.value,
                     regionId: selectedRegionId.value,
                     regionName:  selectedRegionName.value,
                     district: selectedDistrict.value,
                     cityId: selectedCity.value,
                     cityName: selectedCityName.value,
                    },
                     pageIndex: 2
                });
            }        
        }

        const validateForm = () => {
            let regCap = /^[0-9]{5}$/

             if (selectedToponimo.value == null || selectedToponimo.value == '')
                validationErrors.value['toponimo'] = true;
            else
                delete validationErrors.value['toponimo'];
                
            if (!address.value.trim())
                validationErrors.value['address'] = true;
            else
                delete validationErrors.value['address'];
            
            if (!civico.value.trim())
                validationErrors.value['civico'] = true;
            else
                delete validationErrors.value['civico'];

            if (!regCap.test(cap.value))          
                validationErrors.value['cap'] = true;
            else 
                delete validationErrors.value['cap'];
                         
            if (selectedRegionId.value == null)
                validationErrors.value['selectedRegionId'] = true;
            else
                delete validationErrors.value['selectedRegionId'];

            if (selectedDistrict.value == null)
                validationErrors.value['selectedDistrict'] = true;
            else
                delete validationErrors.value['selectedDistrict'];

            if (selectedCity.value == null)
                validationErrors.value['selectedCity'] = true;
            else
                delete validationErrors.value['selectedCity'];
            


            return !Object.keys(validationErrors.value).length;
        }

        return {toponimi, selectedToponimo, address, civico, cap, regions, validationErrors, submitted, prevPage, validateForm, nextPage,
         selectedRegionId, selectedRegionName, getRegions, districts, selectedDistrict, cities, selectedCity, onSelectedRegion,
         selectedCityName, onSelectedDistrict, onSelectedCity}
        },
 
}
</script>

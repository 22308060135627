<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="../src/assets/img/logo_small.svg" width="65" height="auto" />
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<!-- <li v-if="currentUser.role == roles.admin.role" class="flex justify-content-center align-items-center flex-row status">
				<div v-if="store.state.statusSII == null" class="pulse mr-2"> </div> 
				<div v-else class="pulse mr-2" :class="[store.state.statusSII ? 'pulse-ok' : 'pulse-err']" > </div> 
				<span>STATUS SII</span>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li> 
			<div v-if="currentUser.role == roles.admin.role">
				<Button class="p-link layout-topbar-button" @click="$refs.menu1.toggle($event)">
					<i class="pi pi-cog"></i>
					<Menu  ref="menu1" :popup="true" :model="items"></Menu>
					<span>Dropdown</span>
				</Button>
			</div>
			<li>
				<button @click="showConfirmDialog" class="p-link layout-topbar-button">
					<i  class="pi pi-download icon-logout"></i>
					<span>Logout</span>
				</button>
			</li> -->
			<li v-if="currentUser"  class="email-box">
				<small>{{currentUser.email}}</small>		
			</li>
		</ul>
	</div>
	<ConfirmDialog></ConfirmDialog>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue'
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
//import {useRouter} from 'vue-router';
import {computed } from "vue";
import { useToast } from 'primevue/usetoast';
//import { useConfirm } from "primevue/useconfirm";
import roles from '@/config/roles'

export default ({
	emits: ["menu-toggle", "topbar-menu-toggle"],
	setup() {
		const self = getCurrentInstance();
    const eventBus =  self.appContext.config.globalProperties.$eventBus
    const service = new Service(eventBus)
		const appState = self.appContext.config.globalProperties.$appState
		const store = useStore()
    const toast = useToast();
		//const router = useRouter()
		const currentUser = ref(store.state.auth.user)
		//const confirm = useConfirm();
		const items = ref([
			// {
			// 	label: 'items1',
			// 	to: '/'			
			// },
			// {
			// 	label: 'items2',
			// 	to: '/'
			// },
		])

		onMounted(()=> {
      getStatusSii()
		})

    const getStatusSii = async () => {

      if (process.env.NODE_ENV === 'production') {
        store.state.statusSII = null
        // store.state.statusSII =  await service.getStatusProviderSii()
        store.state.statusSII =  true
        if (!store.state.statusSII) {
          toast.add({severity:'warn', summary:'Attenzione:', detail: 'Sembra che il portale SII non sia raggiungibile'});
        }
      }
      
    }

    setInterval(() =>{
      getStatusSii()
    },600000)
		

        // const showConfirmDialog = () => {
        //     confirm.require({
        //         message: 'Vuoi effettuare il logout?',
        //         header: 'Conferma',
        //         icon: 'pi pi-info-circle',
        //         accept: () => {
		// 			handleLogout()
        //         },
        //         reject: () => {
        //         }
        //     });
        // }

		// const handleLogout = () => {
		// 	store.dispatch("auth/logout").then(
		// 		() => {
		// 			window.close(); 
		// 		},
		// 		(error) => {
		// 			console.log(error)
		// 		}
		// 	);
		// }



		const onMenuToggle = (event) => {
			eventBus.emit('menu-toggle', event);
		}

		const onTopbarMenuToggle = (event) => {
			eventBus.emit('topbar-menu-toggle', event);
		}

		const darkTheme = computed(() => {
			return appState.theme.startsWith('saga');
		})


		return {items, onMenuToggle, onTopbarMenuToggle, darkTheme, currentUser, store, roles}
	}
	
    
})
</script>
<style scoped>
.icon-logout {
	transform: rotate(-90deg);
}
.email-box {
	display: flex;
	justify-content: center;
	align-items: center;
}
.status span {
  font-size: 10px;
}
.pulse {
  height: 6px;
  width: 6px;
  background-color: #c3c3c3;
  border-radius: 50%;
}

.pulse-ok {
    background-color: #2bcb12;
    animation: 2s pulse-anim-ok infinite;
}
.pulse-err{
    background-color: #fa2121;
    animation: 2s pulse-anim-err infinite;
}

@keyframes pulse-anim-ok {
  0% {
    box-shadow: #2bcb12 0 0 0 0;
  }
  100% {
    box-shadow: #2198fa00 0 0 0 8px;
  }
}
@keyframes pulse-anim-err {
  0% {
    box-shadow: #fa2121 0 0 0 0;
  }
  100% {
    box-shadow: #2198fa00 0 0 0 8px;
  }
}

</style>

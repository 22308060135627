<template>
  <div class="stepsdemo-content riepilogo-all">
    <Card>
      <template v-slot:title>
          Riepilogo
      </template>
      <template v-slot:subtitle>
          <!-- I dati che hai inserito -->
      </template>
      <template v-slot:content>
        <h6 class="text-center">Clicca sul tasto salva per confermare il salvataggio</h6>


      <Accordion v-for="(tab, index) in tabs" :key="tab" :header="index">

        <AccordionTab v-if="index == 'cliente'" header="Cliente">
          <div v-if="tab.type == 'existent'">
            <p>Cliente esistente</p>
            <h6>{{tab.data.labelName}} </h6>
          </div>
          <div v-if="tab.type == 'new'">
            <div v-if="tab.data.tipo_client == 2">
              <h6>Nome e cognome: <small>{{tab.data.name}} {{tab.data.surname}}</small> </h6>
              <h6>Cod. Fiscale: <small>{{tab.data.cod_fiscale}}</small> </h6>
            </div>
            <div  v-if="tab.data.tipo_client != 2">
              <h6>Rag. Sociale: <small>{{tab.data.rag_sociale}}</small> </h6>
              <h6>Partita iva: <small>{{tab.data.partita_iva}}</small> </h6>
              <h6>C.F. Azienda: <small>{{tab.data.cod_fiscale_azienda}}</small> </h6>
              <h6>Pec: <small>{{tab.data.pec}}</small> </h6>
              <h6>Score: <small>{{tab.data.score}}</small> </h6>
              <h6>Codice Destinatario: <small>{{tab.data.codice_destinatario}}</small> </h6>
              <h6>Codice Ateco: <small>{{tab.data.codice_ateco}}</small> </h6>
            </div>
            <h6>Email: <small>{{tab.data.email}}</small> </h6>
            <h6>Telefono: <small>{{tab.data.phone}}</small> </h6>
            <h6>Cellulare: <small>{{tab.data.mobile}}</small> </h6>
            <h6>Indirizzo: <small>{{tab.data.toponimo}} {{tab.data.address}}, {{tab.data.civico}} ({{tab.data.district}}) - {{tab.data.cap}}</small> </h6>
            <h6>Note: <small>{{tab.data.note}}</small> </h6>
          </div>
        </AccordionTab>

        <AccordionTab v-if="index == 'contratto'" header="Contratto">
            <h6>Tipo Contratto: <small>{{tab.tipoCtr.name}}</small> </h6>
            <h6>Data Inizio: <small>{{tab.dataInizio}}</small> </h6>
            <h6>Data Fine: <small>{{tab.dataFine}}</small> </h6>
            <h6>Data Firma: <small>{{tab.dataFirma}}</small> </h6>
            <h6>Rinnovo: <small>{{tab.rinnovo == true ? 'Si' : 'No'}}</small> </h6>
            <h6>Documentazione: <small>{{tab.documentazione == true ? 'Si' : 'No'}}</small> </h6>
            <h6>Iva: <small>{{tab.iva.name}}%</small> </h6>
            <h6>Regime: <small>{{tab.regime.name}}</small> </h6>
            <h6>Offerta: <small>{{tab.offerta.name}}</small> </h6>
            <h6>Periodo: <small>{{tab.periodo.name}}</small> </h6>
            <h6>Scadenza: <small>{{tab.scadenza.name}}</small> </h6>
            <h6>Spedizione: <small>{{tab.spedizione.name}}</small> </h6>
            <h6>Acquisizione: <small>{{tab.acquisizione.name}}</small> </h6>
            <h6>Fatturazione: <small>{{tab.fatturazione.name}}</small> </h6>
            <h6>Pagamento: <small>{{tab.pagamento.name}}</small> </h6>
            <div v-if="tab.pagamento.name == 'RID'" class="rid-box">
              <h5>Dati Rid</h5>
              <h6>Iban: <small>{{tab.rid.iban}}</small> </h6>
              <h6>Sottoscrittore: <small>{{tab.rid.sottoscrittore}}</small> </h6>
              <h6>Intestazione: <small>{{tab.rid.intestazione}}</small> </h6>
              <h6>Istituto: <small>{{tab.rid.istituto}}</small> </h6>
              <h6>Cod.Fiscale Conto: <small>{{tab.rid.codiceFiscaleCc}}</small> </h6>
              <h6>Tipo Rid: <small>{{tab.rid.selectedTypeRid.name}}</small> </h6>
            </div>
        </AccordionTab>
        <AccordionTab v-if="index == 'fornitura_power' && tabs.contratto.tipoCtr.id == 1" header="Fornitura Power">
            <h6>Pod: <small>{{tab.data.pod ?? 'Nuova Attivazione'}}</small> </h6>
            <h6>Tipo Uso: <small>{{tab.data.uso.name}}</small> </h6>
            <h6>Indirizzo Fornitura: <small>{{tab.data.toponimo}} {{tab.data.address}}, {{tab.data.civico}} ({{tab.data.selectedDistrict}}) - {{tab.data.cap}}</small> </h6>
            <h6>Voltaggio: <small>{{tab.data.voltaggio}}</small> </h6>
            <h6>Potenza Impegnata: <small>{{tab.data.potenza_imp}}</small> </h6>
            <h6>Potenza Disponibile: <small>{{tab.data.potenza_disp}}</small> </h6>
            <h6>Consumo Annuo: <small>{{tab.data.consumo_annuo}}</small> </h6>
            <h6>Tensione: <small>{{tab.data.tensione.name}}</small> </h6>
            <h6>Residente: <small>{{tab.data.residente.name}}</small> </h6>
            <h6>Distributore: <small>{{tab.data.distributore.name}}</small> </h6>
            <h6>Stato: <small>{{tab.data.stato.name ?? '/'}}</small> </h6>
            <h6>Stima: <small>{{tab.data.stima.name ?? '/'}}</small> </h6>
            <h6>Misuratore: <small>{{tab.data.misuratore.name ?? '/'}}</small> </h6>
            <h6>Cod. Fiscale Attivazione: <small>{{tab.data.cod_fiscale}}</small> </h6>
            <h6>P. Iva Attivazione: <small>{{tab.data.partita_iva}}</small> </h6>
            <h6>Note: <small>{{tab.data.note}}</small> </h6>
        </AccordionTab>
        <AccordionTab v-if="index == 'fornitura_gas' && tabs.contratto.tipoCtr.id == 2" header="Fornitura Gas">
            <h6>Pdr: <small>{{tab.data.pdr}}</small> </h6>
            <h6>Tipo Uso: <small>{{tab.data.uso.name}}</small> </h6>
            <h6>Indirizzo Fornitura: <small>{{tab.data.toponimo}} {{tab.data.address}}, {{tab.data.civico}} ({{tab.data.selectedDistrict}}) - {{tab.data.cap}}</small> </h6>
            <h6>Consumo Annuo: <small>{{tab.data.consumo_annuo}}</small> </h6>
            <h6>Remi: <small>{{tab.data.remi}}</small> </h6>
            <h6>Matricola: <small>{{tab.data.matricola}}</small> </h6>
            <h6>Classe: <small>{{tab.data.classe}}</small> </h6>
            <h6>Categoria: <small>{{tab.data.categoria.name}} - ({{tab.data.categoria.code}})</small> </h6>
            <h6>Cod. Fiscale Attivazione: <small>{{tab.data.cod_fiscale}}</small> </h6>
            <h6>P. Iva Attivazione: <small>{{tab.data.partita_iva}}</small> </h6>
            <h6>Note: <small>{{tab.data.note}}</small> </h6>
        </AccordionTab>
        <AccordionTab v-if="index == 'rappresentante'" header="Rappresentante">
          <div v-if="tab.type == 'existent'">
            <p>Rappresentante inserito in fase di creazione nuovo cliente esistente</p>
          </div>
          <div v-if="tab.type == 'new'">
            <h6>Nome e cognome: <small>{{tab.name}} {{tab.surname}}</small> </h6>
            <h6>Cod. Fiscale: <small>{{tab.cod_fiscale}}</small> </h6>
            <h6>Email: <small>{{tab.email}}</small> </h6>
            <h6>Pec: <small>{{tab.pec}}</small> </h6>
            <h6>Telefono: <small>{{tab.phone}}</small> </h6>
            <h6>Cellulare: <small>{{tab.mobile}}</small> </h6>
            <h6>Indirizzo: <small>{{tab.toponimo}} {{tab.address}}, {{tab.civico}} ({{tab.selectedDistrict}}) - {{tab.cap}}</small> </h6>
          </div>
        </AccordionTab>
        <AccordionTab  v-if="index == 'files'" header="File">
          <div class="grid">
          <div class="col-12">
            <div v-for="(file, index) in store.state.all.data.files" :key="index">
              <i class="pi pi-file" style="font-size: 1rem mr-1"></i>
              {{file.name}}
            </div>
          </div>
        </div> 
        </AccordionTab>
      </Accordion>

      </template>
      <template v-slot:footer>
          <div class="grid justify-content-between">
              <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
              <i></i>
              <Button label="Salva e Firma" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
          </div>
      </template>
    </Card>
  </div>
</template>

<script>
import moment from 'moment'

import {ref, getCurrentInstance, onMounted, onUpdated} from 'vue'
import { useStore } from 'vuex'

import Service from '@/services/ask-pratiche/pratiche-wizard'
// import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
  components: {
    Card,
    Button,
  },

  props: {
    formData: Object
  },

  setup (props, {emit}) {

    const store = useStore()
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    // const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)
    const isDisable = ref(false);
    const currentUser = ref(store.state.auth.user)
    const stepIndex = 5
    const tabs = ref([])

    onMounted(()=>{
      populateTabs()
    })

    onUpdated(()=>{
      populateTabs()
    })

    const populateTabs = () =>{
      tabs.value = []
      tabs.value = store.state.all.data
    }

    const prevPage = () => {
      emit('prev-page', stepIndex);
    }

    const complete = () => {
      emit("activeLoader", true)
      let fd = new FormData();
      for (let i = 0 ; i < store.state.all.data.files.length ; i++) {
          fd.append('files[]', store.state.all.data.files[i]); 
      }

      if (store.state.all.data.contratto.rid.content == false)  {
        store.state.all.data.contratto.rid = ""
      }
              
      fd.append('data', JSON.stringify(store.state.all));
      fd.append('user', JSON.stringify(currentUser.value));
      service.saveALLWizardAgenti(fd).then((res) => {
        isDisable.value = true
        if (res.success) {
          setTimeout(function() {
            emit('complete');
          }, 
          1800); 
        }      
      }).finally(()=>{
        isDisable.value = false
        emit("activeLoader", false)
      })
    }

    const reverseDate = (data) => {
      return moment(data).format("DD-MM-YYYY")
    }

    return {
      store,
      prevPage, 
      complete, 
      isDisable, 
      reverseDate,
      tabs
    }
  },
    
}
</script>

<style scoped>
.rid-box {
  border: 1px solid rgba(51, 51, 51, 0.167);
  padding: 20px;
  border-radius: 6px;
}
.riepilogo-all small {
  color:rgb(114, 114, 114) !important ;
  font-size: 13px;
  font-weight: 400;
}
</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6"><b>Tipo Contratto:</b>{{formData.tipoCtr.name}}</div>
                    <div class="col-12 md:col-6"><b>Cliente:</b>{{(formData.client.ragsoc) ? formData.client.ragsoc : formData.client.nome +"  "+formData.client.cognome}}</div>
                    <div class="col-12 md:col-6" v-if="formData.reseller"><b>Reseller:</b>{{formData.reseller.ragsoc}}</div>  
                    <div class="col-12 md:col-6" v-if="formData.agente"><b>Agente:</b>{{formData.agente.name}}</div>
                    <div class="col-12 md:col-6"><b>Data Inizio:</b>{{reverseDate(formData.dataInizio)}}</div>
                    <div class="col-12 md:col-6"><b>Data Fine:</b>{{reverseDate(formData.dataFine)}}</div>
                    <div class="col-12 md:col-6"><b>Offerta:</b>{{formData.offerta.code}}</div>
                    <div class="col-12 md:col-6"><b>Acquisizione:</b>{{formData.acquisizione.name}}</div>
                    <div class="col-12 md:col-6"><b>Rinnovo automatico:</b>{{(formData.rinnovo) ? 'Si' : 'No'}}</div>
                    <div class="col-12 md:col-6"><b>Documentazione originale:</b>{{(formData.documentazione) ? 'Si' : 'No'}}</div>
                    <div class="col-12 md:col-6"><b>Regime:</b>{{formData.regime.name}}</div>
                    <div class="col-12 md:col-6"><b>Iva:</b>{{formData.iva.name}}%</div>
                    <div class="col-12 md:col-6"><b>Fatturazione:</b>{{formData.fatturazione.name}}</div>
                    <div class="col-12 md:col-6"><b>Spedizione:</b>{{formData.spedizione.name}}</div>
                    <div class="col-12 md:col-6"><b>Scadenza:</b>{{formData.scadenza.name}}</div>
                    <div class="col-12 md:col-6"><b>Pagamento:</b>{{formData.pagamento.name}}</div>
                    <div class="col-12 md:col-6"><b>Periodo:</b>{{formData.periodo.name}}</div>
                    <div class="col-12 md:col-6"><b>Note:</b>{{formData.note}}</div>
                    <div class="col-12 md:col-6">
                      <b>File caricati:</b>
                        <div v-for="(file, index) in formData.files" :key="index">
                          <i class="pi pi-file" style="font-size: 1rem mr-1"></i>
                          {{file.name}}
                        </div>
                    </div>
                </div>
                <div class="grid rid" v-if="formData.pagamento.name == 'RID'">
                  <div class="col-12">
                    <h4>DATI RID</h4> 
                  </div>
                  <div class="col-12 md:col-6"><b>Iban:</b>{{formData.iban}}</div>
                  <div class="col-12 md:col-6" v-if="formData.sottoscrittore"><b>Sottoscrittore:</b>{{formData.sottoscrittore}}</div>
                  <div class="col-12 md:col-6" v-if="formData.intestazione"><b>Intestazione:</b>{{formData.intestazione}}</div>
                  <div class="col-12 md:col-6" v-if="formData.istituto"><b>Istituto:</b>{{formData.istituto}}</div>
                  <div class="col-12 md:col-6" v-if="formData.codiceFiscaleCc"><b>CF Intestatario:</b>{{formData.codiceFiscaleCc}}</div>
                  <div class="col-12 md:col-6"><b>Rid:</b>{{formData.rid.name}}</div>
                  <div class="col-12 md:col-6"><b>Data Firma (rid):</b>{{reverseDate(formData.dataFirmaRid)}}</div>
                  <div class="col-12 md:col-6"><b>Data Rata (rid):</b>{{reverseDate(formData.dataRataRid)}}</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment'
import {ref, getCurrentInstance} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {

      const self = getCurrentInstance();
      self.$eventBus = self.appContext.config.globalProperties.$eventBus
      const service = new Service(self.$eventBus)
      const isDisable = ref(false);
  
      const prevPage = () => {
        emit('prev-page', {pageIndex: 4});
      }
      
      const complete = async () => {
        emit("activeLoader", true)
        let fd = new FormData();
        for (let i = 0 ; i < props.formData.files.length ; i++) {
            fd.append('files[]', props.formData.files[i]); 
        }              
        fd.append('data', JSON.stringify(props.formData));
        
        service.saveContract(fd).then((res) => {
          isDisable.value = true
          if (res.success) {
            setTimeout(function() {
              emit('complete', {idContratto: res.idContratto, idCliente: res.idCliente, tipo: res.tipo});
            }, 
            1800); 
          }
        }).finally(()=>{
          isDisable.value = false
          emit("activeLoader", false)
        })
      }

      const reverseDate = (data) => {
          return moment(data).format("DD-MM-YYYY")
      }

      return {prevPage, complete, reverseDate, isDisable}
  },
    
}
</script>
<style scoped>
.rid {
    border: 1px solid rgba(51, 51, 51, 0.068);
    margin-top: 20px;
}
</style>

<template>
  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Report Mensile dei caricamenti firmati</h3>
    </template>
    <template v-slot:content>
      <div v-if="is_admin">
        <Dropdown v-model="selectedAgent" :options="agents" filter optionLabel="name" optionValue="name" placeholder="Seleziona l'agente" class="w-full md:w-14rem" @update:modelValue="onChangeAgent($event)">
        </Dropdown>
      </div>
      <div class="grid" v-if="loaded">
        <div class="col-12 flex justify-content-center m-3">
          <div>
            <label class="mb-2"><strong>Filtra per Mese:</strong></label>
            <br>
            <Calendar v-model="date" :maxDate="maxDate" :manualInput="false" view="month" dateFormat="mm/yy" @update:modelValue="onChangeDate($event)"/>
          </div>
        </div>
        <div class="mb-3">
          <Card>
            <template v-slot:title>
              <h4>
                Report Agente
              </h4>
            </template>
            <template v-slot:content>
              <DataTable :value="report" stripedRows tableStyle="min-width: 50rem">
                <Column field="nome" header="Agente"></Column>
                <Column field="pods" header="N. PODS"></Column>
                <Column field="pdrs" header="N. PDRS"></Column>
                <Column field="count_total_pp" header="Totale PP">
                  <template #body="{data}">
                    {{ data.count_total_pp }}
                  </template>
                </Column>
                <Column field="rid" header="Percentuale Rid">
                  <template #body="{data}">
                    {{ data.rid }} / {{data.count_total_pp}}
                    <!-- {{ ((data.rid/data.count_total_pp)*100).toFixed(2) }} % -->
                  </template>
                </Column>
                <Column header="Percentuale OTP su caricamenti">
                  <template #body="{data}">
                    {{ data.otp }} / {{data.n_caricamenti}}
                    <!-- <div class="pr-5 flex justify-content-between align-items-center">
                      <p class="m-0">
                      </p>
                      <p>
                        {{ ((data.otp/data.n_caricamenti)*100).toFixed(2) }} %
                      </p>
                    </div> -->
                  </template>
                </Column>
              </DataTable>
            </template>
            <template v-slot:footer>
              <DataTable :value="total_month" tableStyle="min-width: 50rem">
                <Column field="nome" header="">
                  <template #body>
                    {{name_agente}}
                  </template>
                </Column>

                <Column field="pods" header="Totale forniture Energia"></Column>
                <Column field="pdrs" header="Totale forniture Gas"></Column>
                <Column field="total_pp" header="Totale forniture">
                  <template #body="{data}">
                    {{ data.total_pp }}
                  </template>
                </Column>
                <Column field="rid" header="Percentuale di Rid su Totale Forniture">
                  <template #body="{data}">
                    {{ data.rid }} / {{data.total_pp}}

                    <!-- {{ ((data.rid/data.total_pp)*100).toFixed(2) }} % -->
                  </template>
                </Column>
                <Column header="Percentuale OTP su caricamenti">
                  <template #body="{data}">
                    {{ data.otp }} / {{data.n_caricamenti}}
                    <!-- <div class="pr-5 flex justify-content-between">
                      <p class="m-0">
                      </p>
                      <p>
                        {{ ((data.otp/data.n_caricamenti)*100).toFixed(2) }} %
                      </p>
                    </div> -->
                  </template>
                </Column>
              </DataTable>
            </template>
          </Card>
        </div>
        <div class="mb-3">
          <Card>
            <template v-slot:title>
              <h4>Report Caricamenti</h4>
            </template>
            <template v-slot:content>
              <Accordion :multiple="true"  :activeIndex="activeIndex">
                <AccordionTab v-for="settimana in items" :key="settimana.title" :header="settimana.title">
                  <div v-if="settimana.content">
                    <DataTable :value="settimana.content" stripedRows tableStyle="min-width: 50rem">
                        <Column field="intestazione" header="Nome Cliente">
                          <template #body="{data}">
                            <strong>{{ data.intestazione }}</strong>
                          </template>
                        </Column>
                        <Column field="pods" header="N. PODS"></Column>
                        <Column field="pdrs" header="N. PDRS"></Column>
                        <Column field="count_total_pp" header="Totale PP"></Column>
                        <Column field="rid" header="Percentuale Rid">
                          <template #body="{data}">
                            {{ ((data.rid/data.count_total_pp)*100).toFixed(2) }} %
                          </template>
                        </Column>
                        <Column field="agenzia" header="Agenzia"></Column>

                        <Column field="cartaceo" header="Tipologia contratto">
                          <template #body="{data}">
                            <strong>
                              {{ data.cartaceo ? 'CARTACEO' : 'OTP' }}
                            </strong>
                          </template>
                        </Column>
                      </DataTable>
                  </div>
                </AccordionTab>
              </Accordion>
            </template>
          </Card>
        </div>
      </div>
    </template>
    
  </Card>
  
</template>

<script>
import {ref, onMounted, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/ask-pratiche/pratiche-wizard'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

// var CSV = require("xlsx");
import { useToast } from "primevue/usetoast";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import roles from '@/config/roles'

   
export default {
  components: {
    Loader,
    Calendar
  },
  
  setup (props, {emit}) { 

    const self = getCurrentInstance();
    const store = useStore()

    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const currentUser = ref(store.state.auth.user)

    const loading = ref(false)
    const loaded = ref(false)
    const date = ref(new Date())
    const items = ref([])
    const report = ref([])
    const service = new Service(self.$eventBus)
    const activeIndex = ref([])
    const total_month = ref([
      {
        pods:0,
        pdrs:0,
        rid:0,
        total_month:0,
        caricati:0,
        n_caricamenti:0,
        otp:0,
      }
    ])
    const selectedAgent = ref()
    const agents = ref()
    const is_admin = ref()
    const name_agente = ref()

    const maxDate = ref(new Date());
    onMounted(() => { 
      setView()
    })

    const onChangeDate = (evt) => {
      getData()
    }

    const setView = async () => {
      selectedAgent.value = currentUser.value.role == roles.agent.role ? currentUser.value.id : null
      is_admin.value = currentUser.value.role == roles.agent.role ? false : true
      await getAgents()
      if(!is_admin.value){
        name_agente.value = agents.value.find(agent => agent.id == selectedAgent.value).name
        getData()
      }


    } 
    const onChangeAgent = (evt) => {
      selectedAgent.value = agents.value.find(agent => agent.name == evt).id
      getData()
      name_agente.value = agents.value.find(agent => agent.id == selectedAgent.value).name
    } 
    const getAgents = async () => {
      loading.value = true
      await service.getAgents()
      .then((res)=>{
        agents.value = res.items
      }).finally(()=>{
        loading.value = false
      })

    }
    const getData = async () => {
      loaded.value = false

      loading.value = true
      let fd = new FormData();
      
      let mese = date.value.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = date.value.getFullYear() 
      let filtro = `${anno}-${mese}`
      fd.append('filtro', filtro);
      fd.append('agente_id', selectedAgent.value)

      await service.getReportCaricamentiAgente(fd)
      .then((res)=>{
        items.value = res.items
        items.value.forEach((item, index)=>{
          if (item.content.length) {
            activeIndex.value.push(index)
          }
        })
        report.value = res.report

        let pods = 0
        let pdrs = 0
        let rid = 0
        let caricati = 0
        let n_caricamenti = 0
        let otp = 0

        report.value.forEach((agenzia)=>{
          pods += agenzia.pods
          pdrs += agenzia.pdrs
          rid += agenzia.rid
          caricati += agenzia.caricati
          n_caricamenti += agenzia.n_caricamenti
          otp += agenzia.otp
        })
        let total_pp = pods + pdrs
        total_month.value[0]['total_pp'] = total_pp
        total_month.value[0]['pods'] = pods
        total_month.value[0]['pdrs'] = pdrs
        total_month.value[0]['rid'] = rid
        total_month.value[0]['caricati'] = caricati
        total_month.value[0]['n_caricamenti'] = n_caricamenti
        total_month.value[0]['otp'] = otp

      }).finally(()=>{
        loaded.value = true
        loading.value = false
      })
    }


    return {
      loading,
      items,
      report,
      date,
      maxDate,
      onChangeDate,
      activeIndex,
      total_month,
      roles,
      currentUser,
      agents,
      is_admin,
      onChangeAgent,
      loaded,
      name_agente,
    }
  },
}
</script>

<style scoped>
</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6"><b>P. Iva:</b>{{formData.partita_iva}}</div>
                    <div class="col-12 md:col-6"><b>Azienda:</b>{{formData.azienda}}</div>
                    <div class="col-12 md:col-6"><b>Rag. Sociale:</b>{{formData.rag_sociale}}</div>
                    <div class="col-12 md:col-6"><b>Cod. Fiscale:</b>{{formData.codFiscale}}</div>
                    <div class="col-12 md:col-6"><b>Telefono:</b>{{formData.phone}}</div>
                    <div class="col-12 md:col-6"><b>Cellulare:</b>{{formData.mobile}}</div>
                    <div class="col-12 md:col-6"><b>Email:</b>{{formData.email}}</div>
                    <div class="col-12 md:col-6"><b>Pec:</b>{{formData.pec}}</div>
                    <div class="col-12 md:col-6"><b>Sede:</b>{{formData.city}} - ({{formData.district}})</div>
                    <div class="col-12 md:col-6"><b>Indirizzo:</b>{{formData.toponimo}} {{formData.address}}, {{formData.civico}}</div>
                    <div class="col-12 md:col-6"><b>Cap:</b>{{formData.cap}}</div>
                    <div class="col-12 md:col-6"><b>Ateco:</b>{{formData.codiceAteco}}</div>
                    <div class="col-12 md:col-6"><b>Score:</b>{{formData.score}}</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {

      const self = getCurrentInstance();
      self.$eventBus = self.appContext.config.globalProperties.$eventBus
      const service = new Service(self.$eventBus)
      const isDisable = ref(false);

      const prevPage = () => {
          emit('prev-page', {pageIndex: 3});
      }
      const complete = async () => {               
          service.saveFornitore(props.formData).then((res) => {
            isDisable.value = true
            if (!res) {
              setTimeout(function() {
                emit('complete');
                }, 
              1800); 
            }
          }) 
      }

        return {prevPage, complete, isDisable}
    },
    
}
</script>

import Cookies from "js-cookie";

const user = Cookies.get('google_access_token')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    loginWithCookie({ commit }, user) {
      try {
        commit('loginSuccess', user);
      } catch (error) {
        commit('loginFailure');
      }
    },
    logout({ commit }) {
      commit('logout');
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    
  }
};
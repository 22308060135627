<template>
  <Loader v-if="loading" />
  <!-- <Card style="width: fit-content;"> -->
    
  <Card style="width: fit-content;">
    <template v-slot:title>
      <h3>Stato forniture</h3>
    </template>
    <template v-slot:content>
      <div class="grid">
        <div class="mb-3">
          <Card>
            <template v-slot:title>
            </template>
            <template v-slot:content>
              <div class="col-12 flex justify-content-start m-3">
                <div>
                  <label class="mb-2"><strong>Filtra per Mese:</strong></label>
                  <br>
                  <Calendar v-model="date" :maxDate="maxDate" :manualInput="false" view="month" dateFormat="mm/yy" @update:modelValue="onChangeDate($event)"/>
                  <Button class="ml-2 p-button-text" icon="pi pi-refresh" v-tooltip="`Aggiorna`" @click="mount()"></Button>
                </div>
              </div>
              <TabView>
                <TabPanel v-for="tab in tabs" :key="tab.title" :header="tab.title">
                  <DataTable v-model:filters="filters" :globalFilterFields="['matrice_id', 'data_caricamento','code', 'cod_internal_pratica', 'stato', 'cod_stato_caricamento', 'in_ask', 'agente', 'cliente', 'rappresentante', 'precheck', 'caricato']" filterDisplay="row" :value="tab.content" tableStyle="width: fit-content" :breakpoint="breakpoint" paginator :rows="20" :rowsPerPageOptions="[20, 50, 100]" sortField="data_caricamento" :sortOrder="-1" :rowClass="rowClass" :responsiveLayout="responsiveLayout">
                    <template #empty>
                      <div>Nessun dato</div>
                    </template>
                    <template #header>
                      <div class="flex justify-content-between">
                        <div>
                          <Button v-for="error in filters_error" :key="error.name" :class="[`btn-error-filter m-2 ${error.severity} p-button-raised`]" @click="initFilters(error.option)">{{ error.name }}</Button>
                          <i v-if="is_filtered" @click="clearFilter" class="btn-remove-selected p-error pi pi-times m-2" style="font-size: 1.2rem" v-tooltip="'Rimuovi filtro'"></i>
                        </div> 
                        <div class="flex justify-content-end">
                          <IconField iconPosition="left" class="flex align-items-center">
                            <InputIcon class="m-2">
                              <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Ricerca" @keydown.enter="filterCallback()"/>
                            <i v-if="filters['global'].value" @click="clearFilter" class="btn-remove-selected p-error pi pi-times m-2" style="font-size: 1.2rem" v-tooltip="'Rimuovi filtro'"></i>
                          </IconField>
                        </div>
                      </div>
                    </template>
                    <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :showFilterMenu="false" :filterMenuStyle="{ width: '5rem' }">
                      <template #body="{data}">
                        {{data[col.field]}}
                      </template>
                      <template #filter="{ filterModel, filterCallback }">
                        <div v-if="col.field in filters">
                          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="col.option[tab.title == 'Elettrico' ? 'ee' : 'gas']" :placeholder="`Filtra`" class="p-column-filter" :showClear="true" filter optionLabel="key" optionValue="key" @keydown.enter="filterCallback()"/>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </TabPanel>
              </TabView>
            </template>
          </Card>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import {ref, onMounted, getCurrentInstance, onUpdated, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/ask-pratiche/pratiche-wizard'
import moment from 'moment'
import { FilterMatchMode } from 'primevue/api';

   
export default {
  components: {
    Loader,
  },
  
  setup (props, {emit}) { 

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus

    const loading = ref(false)
    const items_ee = ref([])
    const items_gas = ref([])

    const tabs = ref([]);
    const stati_precheck = ref()
    const stati_contratto = ref()
    const stati_internal_pratica = ref()
    const filters = ref();
    const stati = ref([
      {
        id:1,
        name:'in validazione'
      },
      {
        id:2,
        name:'in precheck'
      },
      {
        id:3,
        name:'ko documenti'
      },
      {
        id:6,
        name:'caricato'
      },
      {
        id:7,
        name:'caricato con ko'
      },
      {
        id:8,
        name:'annullato'
      },
      {
        id:9,
        name:'bloccato'
      },
      {
        id:11,
        name:'standby'
      }
    ]);
    const date = ref(new Date())
    const maxDate = ref(new Date());

    const columns = ref([])
    const service = new Service(self.$eventBus)
    const windowSize = ref(window.innerWidth)
    const breakpoint = ref('800px') 
    const responsiveLayout = ref()
    const filters_error = ref([
      {
        name:'Pronto per lo switch con esito precheck KO',
        option:{
          cod_internal_pratica:'pronto per lo switch',
          precheck:'precheck eseguito, esito KO'
        },
        severity:"warn1"
      },
      {
        name:'Caricato ma non presente su ask',
        option:{
          caricato:'Si',
          in_ask:'No'
        },
        severity:"warn2"
      },
      {
        name:'Pronto per lo switch con precheck da rieseguire',
        option:{
          cod_internal_pratica:'pronto per lo switch',
          precheck:'precheck da rieseguire'
        },
        severity:"warn3"
      },
      {
        name:'Pronto per lo switch con stato contratto OK',
        option:{
          cod_internal_pratica:'pronto per lo switch',
          stato:'OK'
        },
        severity:"warn4"
      },
      {
        name:'Pronto per lo switch con KO documentale',
        option:{
          cod_internal_pratica:'pronto per lo switch',
          cod_stato_caricamento:'ko documenti'
        },
        severity:"warn5"
      },
      {
        name:'Switch in sospeso con esito precheck OK',
        option:{
          cod_internal_pratica:'switch in sospeso',
          precheck:'precheck eseguito, esito OK'
        },
        severity:"warn6"
      }
    ])
    const properties_filter = ref({
      global: FilterMatchMode.CONTAINS,
      matrice_id: FilterMatchMode.CONTAINS,
      code: FilterMatchMode.STARTS_WITH,
      cod_stato_caricamento: FilterMatchMode.EQUALS,
      in_ask: FilterMatchMode.EQUALS,
      caricato: FilterMatchMode.EQUALS,
      stato: FilterMatchMode.EQUALS,
      cod_internal_pratica: FilterMatchMode.EQUALS,
      agente: FilterMatchMode.CONTAINS,
      cliente: FilterMatchMode.CONTAINS,
      rappresentante: FilterMatchMode.CONTAINS,
      precheck: FilterMatchMode.CONTAINS
    })
    const is_filtered = computed(()=>{
      let is_filtered = false
      
      Object.keys(filters.value).forEach(filter=>{
        if (filters.value[filter].value) {
          return is_filtered = true
        }
      })
      return is_filtered
    })



    const ids_ee = ref([]);
    const ids_gas = ref([]);
    const initRef = () => {
      items_ee.value = []
      items_gas.value = []
      tabs.value = []
      stati_precheck.value = []
      stati_contratto.value = []
      stati_internal_pratica.value = []
      filters.value = []
    }

    onMounted(() => { 
      mount()
    })
    onUpdated(() => {
      responsiveLayout.value = windowSize.value > breakpoint.value.replace('px','') ? 'scroll' : 'stack'
      setResponsive()
    })
    const onChangeDate = (evt) => {
      mount()
    }
    const mount = () => {
      responsiveLayout.value = windowSize.value > breakpoint.value.replace('px','') ? 'scroll' : 'stack'
      setResponsive()

      loading.value = true
      initRef()
      const promises_1 = [
        getStatiPrecheck(),
        getStatiInternalPratica(),
        getStatiContratto(),
      ]
      Promise.all(promises_1)

      initColumns()
      initFilters()

      const promises = [
        getData('ee'),
        getData('gas')
      ]


      Promise.all(promises)
      .then(()=>{
        let types = ['ee', 'gas']
        types.forEach(type => {
          setData(type)
        })
      })
      .finally(()=>{
        loading.value = false
      })
    } 

    const setData = (type) => {
      let items = []
      eval(`items_${type}`).value.forEach(row => {
        let item = {}
        Object.keys(row).forEach((prop,i) => {
          item[prop] = setItem(prop,row[prop] ?? '')
        })
        item.severity = setValid(item)
        items.push(item)
        columns.value.forEach((col,index) => {
          columns.value[index].option[type] = populateOption(items, col.field)
        })
      })
      tabs.value.push({
        title: type == 'ee' ? 'Elettrico' : 'Gas',
        content: items
      })
    }

    const setResponsive = () => {
      window.addEventListener('resize', () => {
        windowSize.value = window.innerWidth
        responsiveLayout.value = windowSize.value > breakpoint.value.replace('px','') ? 'scroll' : 'stack'
      })
    }
    const setValid = (data) => {
      let severity = ''
      let array_filtro = []
      filters_error.value.forEach((error,i) => {
        let array_i = []
        Object.keys(error.option).forEach(e=>{
          array_i.push(data[e] == error.option[e])
        })
        array_filtro.push(array_i)
      })        
      for (let id = 0; id < array_filtro.length; id++) {
        if (array_filtro[id][0] && array_filtro[id][1]) {
          severity = filters_error.value[id].severity
          break;
        }
      }
      return severity
    }
    const populateOption = (data, prop) => {
      let list = []
      let item = []
      let fix_option = {
        cod_stato_caricamento: {val:stati.value,property:'name'},
        precheck: {val:stati_precheck.value, property: 'description'},
        in_ask: {val:[{name:'Si'},{name:'No'}],property:'name'},
        stato: {val:stati_contratto.value,property:'name'},
        cod_internal_pratica: {val:stati_internal_pratica.value,property:'description'}
      } 

      if (data.length > 0) {
        if (prop in fix_option) {
          Object.keys(fix_option[prop].val).forEach((i,fix_op) => {
            item.push({key:fix_option[prop].val[i][fix_option[prop].property]})
          })          

        } else {
          data.forEach(el => {
            if (el[prop]) {
              list.push(el[prop])
            }
          });
          list = [...new Set(list)];
  
          list.forEach(prop => {
            item.push({key:prop})
          })          
        }
      }
      return item
    }
    
    const rowClass = (data) => {
      // return data.valid == false ? 'bg-tr-warning' : null ;
      return data.severity != '' ? data.severity : null ;
    };
    const initColumns = () => {
      columns.value = [
        { field: 'matrice_id', header: '#' },
        { field: 'code', header: 'Codice fornitura' },
        { field: 'agente', header: 'Agente' },
        { field: 'cliente', header: 'Cliente' },
        { field: 'rappresentante', header: 'Rappresentante' },
        { field: 'pratica', header: 'Tipo pratica' },
        { field: 'cod_stato_caricamento', header: 'Stato inserimento contratto' },
        { field: 'data_caricamento', header: 'Data inserimento contratto' },
        { field: 'precheck', header: 'Stato Precheck' },
        { field: 'dettaglio_esito_precheck', header: 'Dettaglio esito precheck' },
        { field: 'caricato', header: 'Caricato' },
        { field: 'in_ask', header: 'Presente su Ask' },
        { field: 'stato', header: 'Stato Contratto' },
        { field: 'data_inizio', header: 'Data inizio fornitura'},
        { field: 'data_fine', header: 'Data fine fornitura'},
        { field: 'cod_internal_pratica', header: 'Codice interno'},
      ]

      columns.value.forEach((col,index) => {
        columns.value[index]['option'] = []
      })
    }
    const initFilters = (option = []) => {
      filters.value = {}

      Object.keys(properties_filter.value).forEach(prop=>{
        filters.value[prop] = {
          value: prop in option ? option[prop] : null,
          matchMode: properties_filter.value[prop]
        }
      })
    };

    const setItem = (field, data) => {
      switch (field) {
        case 'cod_stato_caricamento':
          data = data ? stati.value.find(stato => stato.id == data).name : ''
          break;
        case 'precheck':
          data = data ? stati_precheck.value.find(stato => stato.code == data).description : ''
          break;
        case 'stato':
          data = data ? stati_contratto.value.find(stato => stato.value == data).name : ''
          break;
        case 'cod_internal_pratica':
          data = data ? stati_internal_pratica.value.find(stato => stato.code == data).description : ''
          break;

        case 'data_caricamento':
        case 'data_firma':
        case 'data_inizio':
        case 'data_fine':
          data = data ? moment(data).format('DD-MM-YYYY') : '--'
          break;

        case 'in_ask':
        case 'caricato':
          data = data ? 'Si' : 'No'
          break;

        default:
          break;
      }
      return data
    }
    const getData = async (type) => {
      let fd = new FormData();
      
      let mese = date.value.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = date.value.getFullYear() 
      let filtro = `${anno}-${mese}`
      fd.append('filtro', filtro);

      await service.getDetailsSupplies(type, fd)
      .then((res)=>{
        eval(`items_${type}`).value = res.items
      })
    }

    const getStatiPrecheck = async () => {
      await service.getStatiPrecheck()
      .then((res) => {
        stati_precheck.value = res.items
      })
    }
    const getStatiContratto = async () => {
      await service.getStatiContratto()
      .then((res) => {
        stati_contratto.value = res.items
      })
    }
    const getStatiInternalPratica = async () => {
      await service.getStatiInternalPratica()
      .then((res) => {
        stati_internal_pratica.value = res.items
      })
    }

    const clearFilter = () => {
      initFilters();
    };


    return {
      loading,
      items_ee,
      items_gas,
      tabs,
      columns,
      stati,
      setItem,
      moment,
      filters,
      initFilters,
      clearFilter,
      rowClass,
      responsiveLayout,
      breakpoint,
      windowSize,
      date,
      maxDate,
      onChangeDate,
      filters_error,
      is_filtered,
      mount,
    }
  },
}
</script>

<style>
  /* .bg-tr-warning{
    background: #ffe034 !important;
  }  */
  .warn1{
    color: #ffffff !important;

    background: #ff0000 !important;
    border: 1px solid #ff0000 !important;
  }
  .warn2{
    color: #ffffff !important;

    background: #ff4242 !important;
    border: 1px solid #ff4242 !important;

  }
  .warn3{
    color: #ffffff !important;

    background: #ff6a00 !important;
    border: 1px solid #ff6a00 !important;

  }
  .warn4{
    color: #ffffff !important;

    background: #ff7834 !important;
    border: 1px solid #ff7834 !important;

  }
  .warn5{
    color: #212529 !important;
    background: #ffd900 !important;
    border: 1px solid #ffd900 !important;

  }
  .warn6{
    color: #212529 !important;
    background: #fade42 !important;
    border: 1px solid #fade42 !important;

  }
</style>
<style scoped>
  .layout-main-container{
    width: fit-content !important;
  }
  .btn-error-filter{
    width: 200px;
  }
</style>

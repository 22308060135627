<template>

  <Loader v-if="loader" />

  <Dialog v-model:visible="visibleUpdate" modal header="Nuovo Aggiornamento" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @hide="updateTutorial">
      <div class="m-3">
        <Fieldset v-for="content in content_update" :key="content.title" :legend="content.title" class="m-2">
          <p v-if="content.content.length">
            <ul>
              <li v-for="item in content.content" :key="item">
                {{ item }}
              </li>
            </ul>
          </p>
        </Fieldset>
      </div>
      
      <div class="m-3">
        <Checkbox v-model="is_inactive_tutorial" id="is_inactive_tutorial" :binary="true"/>
        <label for="is_inactive_tutorial"> Non visualizzare più </label>
      </div>
    </Dialog>

  <div class="card">
    <h2>Benvenuto</h2>
    <div>
      <small>Hai effettuato l'accesso come: <span class="email">{{currentUser.email}}</span></small>
    </div> 
    <div class="grid justify-content-around">
      <Button class="btn-big" label="Nuovo Contratto" v-tooltip="'Vai a Nuovo Contratto'" @click="goToPath('newContract')"/>
      <Button class="btn-big" label="Storico Caricamenti" v-tooltip="'Vai a Storico caricamenti'" @click="goToPath('uploaded')"/>
    </div>
    <!-- <div class="grid">
      <div class="xl:col-6 lg:col-6 md:col-7 col-9 mx-auto">
        <img class="w-full" src="@/assets/img/energy-place.gif" />
      </div>
    </div> -->
     
    <!-- <div class="col-12" v-if="currentUser.role == roles.agent.role">
      <div class="card mt-4">
        <h5 class="mb-0">I miei contratti </h5>
        <div style="font-size:12px" class="mb-3">Ultimi 6 mesi</div>
        <div v-if=" ctrs.datasets[0].data.length > 0">
          <small>Numero</small>
          <Chart ref="chartRefCtrs" type="bar" :data="ctrs" />
          <i class="pi pi-download" @click="exportChart($refs.chartRefCtrs)"></i>
        </div>
        <div v-else>
          <Skeleton class="mb-2"></Skeleton>
          <Skeleton width="10rem" class="mb-2"></Skeleton>
          <Skeleton width="5rem" class="mb-2"></Skeleton>
          <Skeleton height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="10rem" height="4rem"></Skeleton> 
        </div>
      </div>  
    </div> -->
  </div>

</template>

<script>


import { getCurrentInstance, onMounted, ref, onUpdated } from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Loader from '@/components/Loader.vue'
import roles from '@/config/roles'
import { useRouter } from 'vue-router'

export default {
  components: {
    Loader,
  },

  setup () {
    const router = useRouter()
    const loader = ref(false);
    const store = useStore()
    const currentUser = ref(store.state.auth.user)
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const chartRefBanks = ref(null);
    const ctrs = ref({
      labels: [],
      datasets: [
          {
              label: 'Contratti Validi',
              backgroundColor: '#34d5eb',
              data: []
          },
          {
              label: 'Contratti Errati',
              backgroundColor: '#eb4f34',
              data: []
          },
          {
              label: 'Contratti Da Validare',
              backgroundColor: '#b3b3b3',
              data: []
          },    
      ]
    });
    const visibleUpdate = ref(false);
    const is_inactive_tutorial = ref()
    const content_update = ref()

    onMounted(() => { 
      if (currentUser.value.role == 'agent') {
        Promise.all([getUpdate()])
        .then(()=>{
          if (content_update.value.length) {
            Promise.all([getIsActiveTutorial()])
          }
        })
      }      
      getCtr();
    })   

    // onUpdated(() => { 
    //   if (currentUser.value.role == 'agent') {
    //     Promise.all([getUpdate()])
    //     .then(()=>{
    //       if (content_update.value.length) {
    //         Promise.all([getIsActiveTutorial()])
    //       }
    //     })
    //   }      
    //   getCtr();
    // })   

    const getUpdate = () => {
      return service.getUpdate()
      .then((res) => {
        content_update.value = res.items
      })
    }
    const getIsActiveTutorial = async () => {
      await service.getIsActiveTutorial(currentUser.value.id)
      .then((res) => {
        visibleUpdate.value = res.items.is_visible_tutorial
        is_inactive_tutorial.value = !visibleUpdate.value
      })
    }
    const updateTutorial = () => {
      if(is_inactive_tutorial.value){
        setInactiveTutorial()
      }
    }

    const setInactiveTutorial = async () => {
      await service.setInactiveTutorial(currentUser.value.id)
    }
     

    const getCtr = async () => {

      // const response = await service.getCtrsComputed().then((res) => {
      //     return res          
      // })
      // const res = await response.items;
      // const data = Object.entries(res)
      // console.log(res)
      // let tot_valid = []
      // let tot_invalid = []
      // let tot_tovalid = []
      // for (const [i,el] of data) {
      //   ctrs.value.labels.push(i)
      //   if (el.length == 0) {
      //     tot_valid.push(0)
      //     tot_invalid.push(0)
      //     tot_tovalid.push(0)
      //   } else {
      //     let tv = 0
      //     el.forEach(e => {
      //       if (e.stato == 1) {
      //         tv++
      //       }
      //     });
      //     tot_valid.push(tv)
      //     let ti = 0
      //     el.forEach(e => {
      //       if (e.stato == 0) {
      //         ti++
      //       }
      //     });
      //     tot_invalid.push(ti)
      //     let tt = 0
      //     el.forEach(e => {
      //       if (e.stato == 2) {
      //         tt++
      //       }
      //     });
      //     tot_tovalid.push(tt)

      //   }
          
      // }
      // ctrs.value.datasets[0].data = tot_valid
      // ctrs.value.datasets[1].data = tot_invalid
      // ctrs.value.datasets[2].data = tot_tovalid  
            
    };
    const goToPath = (id) => {
      switch (id) {
        case 'newContract':
          router.push({path: '/multisito'})
          break;
      
        case 'uploaded':
          router.push({path: '/caricamenti-multisito'})
          break;
      }

    }

    const exportChart = (refGraph) => {
      let imgGraph = refGraph.getBase64Image()       
      let a = document.createElement('a')   
      a.href = imgGraph
      a.download = 'chart-export.png'
      a.click()
      a = null
    }

    return {
      ctrs,
      chartRefBanks,
      loader,
      currentUser,
      roles,
      exportChart,
      goToPath,
      visibleUpdate,
      updateTutorial,
      is_inactive_tutorial,
      content_update,
    }
 
  }

}
</script>
<style scoped>

li{
    list-style-type:disc;
  }

.btn-big{
  width: 23%;
  min-width: 300px;
  aspect-ratio: 4/3;
  border-radius: 20px;
  margin: 5%;
  font-size: x-large;
}
.email{
  font-size:13px;
  color: rgb(42, 182, 252)
}
</style>

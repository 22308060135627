<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6"><b>Pdr:</b>{{formData.pdr}}</div> 
                    <div class="col-12 md:col-6"><b>Cliente:</b>{{(formData.client.nome == null ) ? formData.client.ragsoc : formData.client.nome+" "+formData.client.cognome }}</div>
                    <div class="col-12 md:col-6" v-if="formData.client.cod_fiscale"><b>Cf attivazione:</b>{{formData.client.cod_fiscale}}</div>
                    <div class="col-12 md:col-6" v-if="formData.client.partita_iva"><b>Piva attivazione:</b>{{formData.client.partita_iva}}</div>
                    <div class="col-12 md:col-6"><b>Contratto associato:</b>{{formData.contratto.code}}</div> 
                    <div class="col-12 md:col-6"><b>Tipo Contratto:</b>{{formData.contratto.tipo_ctr}}</div> 
                    <div class="col-12 md:col-6"><b>Indirizzo:</b>{{formData.toponimo}} {{formData.address}}, {{formData.civico}}</div>
                    <div class="col-12 md:col-6"><b>Cap:</b>{{formData.cap}}</div>
                    <div class="col-12 md:col-6"><b>Sede:</b>{{formData.city}} -  ({{formData.district}})</div>
                    <div class="col-12 md:col-6"><b>Uso:</b>{{formData.uso.name}}</div>
                    <div class="col-12 md:col-6"><b>Matricola:</b>{{formData.matricola}}</div>
                    <div class="col-12 md:col-6" v-if="formData.remi"><b>Remi:</b>{{formData.remi}}</div>
                    <div class="col-12 md:col-6" v-if="formData.consumo_annuo"><b>Consumo annuo:</b>{{formData.consumo_annuo}}</div>
                    <div class="col-12 md:col-6"><b>Categoria:</b>{{formData.categoria.name}} - {{formData.categoria.code}}</div>
                    <div class="col-12 md:col-6"><b>Classe:</b>{{formData.classe}}</div>
                    <div class="col-12 md:col-6" v-if="formData.cod_fiscale"><b>Cf attivazione:</b>{{formData.cod_fiscale}}</div>
                    <div class="col-12 md:col-6" v-if="formData.partita_iva"><b>P.iva attivazione:</b>{{formData.partita_iva}}</div>
                    <div class="col-12 md:col-6"><b>Note:</b>{{formData.note}}</div>
                    <div class="col-12 md:col-6">
                      <b>File caricati:</b>
                        <div v-for="(file, index) in formData.files" :key="index">
                          <i class="pi pi-file" style="font-size: 1rem mr-1"></i>
                          {{file.name}}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';


export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {

  
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const isDisable = ref(false);
   
        const prevPage = () => {
            emit('prev-page', {pageIndex: 5});
        }
        // const complete = async () => {                 
        //     service.saveFornituraGas(props.formData).then((res) => {
        //       isDisable.value = true
        //       if (!res) {
        //         setTimeout(function() {
        //           emit('complete');
        //           }, 
        //         1800); 
        //       } 
        //     }) 
        // }

        const complete = async () => {
        emit("activeLoader", true)
        let fd = new FormData();
        for (let i = 0 ; i < props.formData.files.length ; i++) {
            fd.append('files[]', props.formData.files[i]); 
        }              
        fd.append('data', JSON.stringify(props.formData));
        
        service.saveFornituraGas(fd).then((res) => {
          isDisable.value = true
          if (!res) {
            setTimeout(function() {
              emit('complete');
            }, 
            1800); 
          }
        }).finally(()=>{
          isDisable.value = false
          emit("activeLoader", false)
        })
      }

        return {prevPage, complete, isDisable}
    },
    
}
</script>

<template>
    <div class="container form-step">
        <Loader v-if="loading" />
        <div class="p-grid p-jc-center">
            <div class="p-col-8">

                <div class="card">
                    <Steps :model="items" :readonly="true" />
                </div>

                <router-view :key="reloadKey" v-slot="{Component}" :formData="formObject" @activeLoader="activeLoader" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete($event)">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
                
            </div>       
        </div>
    </div>
</template>

<script>

import { onBeforeMount, ref } from 'vue';
import { useRouter,useRoute } from 'vue-router';
import Steps from 'primevue/steps';
import Loader from "@/components/Loader.vue"
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";

export default {
    components: {
        Steps,
        Loader
    },
    setup() {

        const loading = ref(false)
        const reloadKey = ref(0);
        const formObject = ref({});
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const toast = useToast();

        
      
        
        onBeforeMount(() => {
            store.state.all.bozzaId = 0
            if (route.query.restore){
              let bozza = JSON.parse(atob(route.query.restore))
              store.state.all.data = bozza.data
              store.state.all.bozzaId = bozza.id
              router.push(items.value[store.state.all.data.pageIndex].to); 
            } else {
              router.push(items.value[0].to); 
            }    
        })
        
        const items = ref([
          {
            label: 'Cliente',
            to: "/agenti/inserimento/step-cliente"
          },
          {
            label: 'Rappresentante/Contatto',
            to: "/agenti/inserimento/step-rappresentante"
          },
          {
            label: 'Contratto',
            to: "/agenti/inserimento/step-contratto"
          },
          {
            label: 'Fornitura',
            to: "/agenti/inserimento/step-fornitura"
          },
          {
            label: 'Documenti',
            to: "/agenti/inserimento/step-documenti"
          },
          {
            label: 'Riepilogo',
            to: "/agenti/inserimento/step-riepilogo"
          },
        ]);
        
        
        const nextPage = (index) => {
          router.push(items.value[index + 1].to);
        };

        const prevPage = (index) => {
          router.push(items.value[index - 1].to);
        };

        const complete = (evt) => {
            // use for re-render component (clear data)
            reloadKey.value = Date.now();
            formObject.value = {}
          
            router.push('/agenti/inserimento');         
        };

        const activeLoader = (bool) => {
            loading.value = bool
        }

        
        return { items, formObject, nextPage, prevPage, complete, reloadKey, loading, activeLoader }
    }
}
</script>

<style>
.form-step {
    position: relative;
}
 b {
     display: block;
 }

.form-step .p-card-body {
     padding: 2rem;
 }
 .form-step .p-card {
     box-shadow: none !important;
 }
 
  .form-step .p-field input ,  .form-step .p-dropdown{
     background-color: rgba(219, 219, 219, 0.45);
     border: 1px solid rgba(255, 255, 255, 0);
     height: 44px;
 }
 .form-step .p-field {
     text-align: left;
 }

 .form-step .p-error {
     font-size: 13px;
 }
 
</style>

<template>
  <div class="stepsdemo-content">
    <Card>
      <template v-slot:title>
        <div class="grid">
          <!-- <div class="col-12 xl:col-1 d-button">
            <Button label="Tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>
          </div>
          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false">
              <Carousel :value="slides" :numVisible="1">
                  <template #item="slotProps">
                      <div class="border-1 surface-border border-round text-center py-5 px-3">
                          <div class="mb-1">
                              <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+slotProps.data.size]"/>
                          </div>
                          <div>
                              <p class="mb-1"><strong>{{ slotProps.data.description }}</strong></p>
                          </div>
                      </div>
                  </template>
              </Carousel>
          </Dialog> -->
          <p class="col-12">{{typeStep}}</p>
        </div>
      </template>
      <template v-slot:subtitle>
        <p>Inserisci i dati del {{typeStep}}</p>
      </template> 
      <template v-slot:content>
        <div style="position: relative;">
          <Button class="btn-offerte btn-tutorial" icon="pi pi-question-circle" @click="openPosition" severity="success" v-tooltip="'Clicca per visualizzare il tutorial'"/>

          <Dialog v-model:visible="visible" header="Tutorial" :dismissableMask="true" modal :draggable="false" style="min-width: 300px;">
            <Carousel :value="slides" :numVisible="1">
              <template #item="slotProps">
                <div :class="['h-full',{'flex align-items-center': windowSize < 700}]">   
                  <div  v-if="windowSize > 700" class="border-1 surface-border border-round text-center py-5 px-3">
                    <div class="mb-1">
                      <img :src="slotProps.data.name" :alt="slotProps.data.name" :class="['shadow-2 w-'+(windowSize < 1400 ? slotProps.data.size+3 : slotProps.data.size)]"/>
                    </div>
                  </div>
                  <p class="m-2 text-center"><strong>{{ slotProps.data.description }}</strong></p>
                </div>
              </template>
            </Carousel>
          </Dialog>
        </div>

        <div style="margin-top: 50px;">
          <TabView v-model:activeIndex="activeTabIndex" selectOnFocus @click="onSelectedView">
            <TabPanel header="Rappresentante esistente">
              <div class="formgrid grid mt-4">
                <div class="p-field col-12 grid">
                  <!-- Dropdown selezione Rappresentante -->
                  <div :class="['p-field', {'col-12 xl:col-6': selectedRapp == null},{'col-11 xl:col-5': selectedRapp != null}]">
                    <Dropdown v-model="selectedRapp" :options="rappresentanti" optionLabel="name" :filter="true"  placeholder="Seleziona rappresentante" :class="['inputfield w-full',{'p-invalid': validationErrors.rappresentante && submitted}]" @change="onSelectedRapp"/>
                    <div v-show="validationErrors.rappresentante && submitted" class="p-error">Seleziona un rappresentante.</div>
                  </div>
                    
                  <!-- Icona rimozione Rappresentante -->
                  <div v-if="selectedRapp" class="p-field col-1 flex justify-content-center align-items-center">
                    <i @click="removeExistentRapp" class="btn-remove-selected p-error pi pi-times" style="font-size: 1.2rem" v-tooltip="'Rimuovi selezionato'"></i>
                  </div>
                </div>
                  
                <!-- Dropdown selezione contatto -->
                <div class="p-field col-12 xl:col-6">
                  <label v-if="selectedContact">
                    <strong>Indirizzo di spedizione</strong>
                  </label>
  
                  <Dropdown :disabled="newContatto == true" v-if="selectedRapp" v-model="selectedContact" :options="contatti" :placeholder="contatti.length == 0 ? `Non ci sono contatti disponibili per questo cliente. Creane uno nuovo` : `Seleziona un contatto`" class="inputfield w-full mt-2" @change="onSelectedContact">
                    <template #empty>
                      Crea un nuovo indirizzo di spedizione
                    </template>
                    <template #option="slotProps">
                      <div v-if="slotProps.option" class="flex align-items-center">
                        {{ `${slotProps.option.toponimo ?? ''} ${slotProps.option.indirizzo ?? ''} ${slotProps.option.civico ?? ''}, ${slotProps.option.cap ?? ''} - ${slotProps.option.name ?? ''} ${slotProps.option.provincia ?? ''} - ${slotProps.option.cell ? 'Cellulare: '+slotProps.option.cell : ''} -${slotProps.option.tel ? 'Telefono: '+slotProps.option.tel : ' -'} ${slotProps.option.email ?? ''}` }}
                      </div>
                    </template>
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        {{ `${slotProps.value.toponimo ?? ''} ${slotProps.value.indirizzo ?? ''} ${slotProps.value.civico ?? ''}, ${slotProps.value.cap ?? ''} - ${slotProps.value.name ?? ''} ${slotProps.value.provincia ?? ''}` }}
                      </div>
                    </template>
                  </Dropdown>
                  <div v-show="validationErrors.contact && submitted" class="p-error">Seleziona un contatto.</div>
                  
                  <!-- checkbox nuovo Contatto -->
                  <div v-if="selectedRapp" class="mt-4 p-field col-12 flex flex-wrap justify-content-around align-items-center">
                    <label><strong>Crea un nuovo indirizzo di spedizione</strong></label>
                    <Checkbox :disabled="contatti.length == 0" v-model="newContatto" :binary="true" @change="setNewContatto"/>
                  </div>
                </div>
                <div v-if="newContatto" class="col-12 grid mt-3">
                  <div class="p-field col-12">
                    <h5>Indirizzo di spedizione</h5>
                    <hr>
                  </div> 
                  <div class="p-field col-12 xl:col-6">
                      <label for="telefono">Telefono</label><br>
                      <InputText id="telefono" v-model="contatto.telefono" :class="['inputfield w-full',{'p-invalid': validationErrors.telefono && submitted}]" />
                  </div>
  
                  <div class="p-field col-12 xl:col-6 ">
                  <label for="mobile">Cellulare</label><br>                   
                  <InputMask @change="onSelectedMobile($event)" id="mobile" mask="999-999999?9" v-model="contatto.new_cellulare" :class="['inputfield w-full',{'p-invalid': validationErrors.cellulare && submitted}]" />
                  <small v-show="validationErrors.cellulare && submitted" class="p-error">Campo richiesto.</small>
                  <small v-show="validationErrors.cellulare_incorrect" class="p-error">Ricontrolla il numero di cellulare inserito.</small>
                </div> 
                <Dialog id="modalShow" v-model:visible="showModal" :style="{width: '450px'}" :modal="true">
                  <div class="confirmation-content">          
                    <p>Hai inserito il numero telefonico: {{ contatto.cellulare }}</p> 
                    <p>É corretto?</p>   
                  </div>
                  <template #footer>
                    <Button @click="isCorrect(true)" label="Si" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito m-2" />
                    <Button @click="isCorrect()" label="No" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito m-2" />
                  </template>
                </Dialog>
                <div class="p-field col-12 xl:col-6">
                  <label for="email">Email</label><br>
                  <InputText @change="onSelected($event)" id="email" v-model="contatto.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" @input="onInputText"/>
                  <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                  <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                </div>
                <div class="p-field col-12 xl:col-6">
                      <label for="pec">Pec</label><br>
                      <InputText id="pec" v-model="contatto.pec" class="inputfield w-full" @input="onInputText"/>
                  </div>
                  <div class="p-field col-12 xl:col-6">
                    <label for="toponimo_spedizione">Toponimo</label><br>
                    <Dropdown @change="onSelectedToponimo($event, 'spedizione')" v-model="contatto.toponimo_spedizione" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" :editable="true"  placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo_spedizione && submitted}]" @input="onInputToponimo($event, 'spedizione')"/>
                    <small v-show="validationErrors.toponimo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div> 
  
                  <div class="p-field col-12 xl:col-6">
                    <label for="indirizzo_spedizione">Indirizzo</label><br>
                    <InputText @change="onSelected($event)" id="indirizzo_spedizione" v-model="contatto.indirizzo_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.indirizzo_spedizione && submitted}]" @input="onInputText"/>
                    <small v-show="validationErrors.indirizzo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 xl:col-6">
                    <label for="civico_spedizione">Civico</label><br>
                    <InputText @change="onSelected($event)" id="civico_spedizione" v-model="contatto.civico_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.civico_spedizione && submitted}]" @input="onInputText"/>
                    <small v-show="validationErrors.civico_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 xl:col-6">
                    <label for="cap_spedizione">Cap</label><br>
                    <InputMask mask="99999" @change="onSelected($event)" id="cap_spedizione" v-model="contatto.cap_spedizione_new" :class="['inputfield w-full',{'p-invalid': validationErrors.cap_spedizione && submitted}]" />
                    <small v-show="validationErrors.cap_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  
                  <div class="p-field col-12 xl:col-6">
                    <label>Provincia</label><br>
                    <Dropdown @change="onSelectedDistrict($event, 'spedizione')" v-model="contatto.provincia_spedizione" :options="districts" optionLabel="name" :filter="true" optionValue="code" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.provincia_spedizione && submitted}]" />
                    <small v-show="validationErrors.provincia_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 xl:col-6">
                    <label>Comune</label><br>
                    <Dropdown @change="onSelectedCity($event, 'spedizione')" v-model="contatto.comune_spedizione" :options="cities" :filter="true" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune_spedizione && submitted}]" />
                    <small v-show="validationErrors.comune_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>    
                </div>                 
  
              </div>
            </TabPanel>
            <TabPanel header="Crea rappresentante" :disabled="selectedRapp != null">
                <div class="formgrid grid mt-4">  
                  <div class="col-12 grid mb-4">
                    <p class="col-12"> Modalità creazione Rappresentante: </p>
                    <div class="flex">
                      <div v-for="type in type_insert" :key="type.key" class="m-3">
                        <RadioButton v-model="selectedTypeInsert" :inputId="type.key" :value="type.key" @change="onSelectedTypeInsert"/>
                        <label :for="type.key" class="ml-2">{{ type.name }}</label>
                      </div>
                    </div>
                  </div>
  
                  <div class="p-field col-12">
                    <label v-if="typeClient == 3" for="codfis">Codice fiscale del rappresentante o p.iva dello studio </label>
                    <label v-else for="codfis">Codice fiscale del rappresentante</label>
                    <br>
                    <InputText @change="onSelected($event)" id="codfis" v-model="rappresentante.codfis" :class="['inputfield w-full',{'p-invalid': validationErrors.codfis && submitted}]" @input="onInputText"/>
  
                    <div v-if="validationErrors.codfis && submitted" class="p-error">Campo richiesto.</div>
                    <div v-if="validationErrors.rappex && submitted" class="p-error">Rappresentante esistente.</div>
                    <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                    <div v-if="validationErrors.invalid && submitted" class="p-error">Il rappresentante non può essere una persona giuridica.</div>
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="nome">Nome</label><br>
                      <InputText @change="onSelected($event)" id="nome" v-model="rappresentante.nome" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" @input="onInputText"/>
                      <small v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="cognome">Cognome</label><br>
                      <InputText @change="onSelected($event)" id="cognome" v-model="rappresentante.cognome" :class="['inputfield w-full',{'p-invalid': validationErrors.cognome && submitted}]" @input="onInputText"/>
                      <small v-show="validationErrors.cognome && submitted" class="p-error">Campo richiesto.</small>
                  </div>
  
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-12 mt-2">
                    <h5>Indirizzo di spedizione</h5>
                    <hr>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="telefono">Telefono</label><br>
                      <InputText id="telefono" v-model="contatto.telefono" :class="['inputfield w-full',{'p-invalid': validationErrors.telefono && submitted}]" />
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="cellulare">Cellulare</label><br>                   
                      <InputMask @change="onSelectedMobile($event)" id="cellulare" mask="999-999999?9" v-model="contatto.cellulare" :class="['inputfield w-full',{'p-invalid': validationErrors.cellulare && submitted}]" />
                      <small v-show="validationErrors.cellulare && submitted" class="p-error">Campo richiesto.</small>
                      <small v-show="validationErrors.cellulare_incorrect" class="p-error">Ricontrolla il numero di cellulare inserito.</small>
                  </div> 
                  <Dialog id="modalShow" v-model:visible="showModal" :style="{width: '450px'}" :modal="true">
                    <div class="confirmation-content">          
                      <p>Hai inserito il numero telefonico: {{ contatto.cellulare }}</p> 
                      <p>É corretto?</p>   
                    </div>
                    <template #footer>
                      <Button @click="isCorrect(true)" label="Si" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito m-2" />
                      <Button @click="isCorrect()" label="No" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito m-2" />
                    </template>
                  </Dialog>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="email">Email</label><br>
                      <InputText id="email" v-model="contatto.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" @input="onInputText"/>
                      <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                      <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="pec">Pec</label><br>
                      <InputText id="pec" v-model="contatto.pec" class="inputfield w-full" @input="onInputText"/>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12  md:col-6">
                      <label for="toponimo_spedizione">Toponimo</label><br>
                      <Dropdown @change="onSelectedToponimo($event, 'spedizione')" v-model="contatto.toponimo_spedizione" :options="toponimi" optionLabel="name" :filter="true" :editable="true" optionValue="name" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo_spedizione && submitted}]" @input="onInputToponimo($event, 'spedizione')"/>
                      <small v-show="validationErrors.toponimo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="indirizzo_spedizione">Indirizzo</label><br>
                      <InputText id="indirizzo_spedizione" v-model="contatto.indirizzo_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.indirizzo_spedizione && submitted}]" @input="onInputText"/>
                      <small v-show="validationErrors.indirizzo_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="civico_spedizione">Civico</label><br>
                      <InputText id="civico_spedizione" v-model="contatto.civico_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.civico_spedizione && submitted}]" @input="onInputText"/>
                      <small v-show="validationErrors.civico_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12 md:col-6">
                      <label for="cap_spedizione">Cap</label><br>
                      <InputMask mask="99999" id="cap_spedizione" v-model="contatto.cap_spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.cap_spedizione && submitted}]" />
                      <small v-show="validationErrors.cap_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12  md:col-6">
                      <label for="provincia_spedizione">Provincia</label><br>
                      <Dropdown @change="onSelectedDistrict($event)" v-model="contatto.provincia_spedizione" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.provincia_spedizione && submitted}]" />
                      <small v-show="validationErrors.provincia_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div v-if="selectedTypeInsert == 0" class="p-field col-12  md:col-6">
                      <label for="comune">Comune</label><br>
                      <Dropdown id="comune" @change="onSelectedCity($event)" v-model="contatto.comune_spedizione" :options="cities" optionLabel="name" optionValue="id" :filter="true" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.comune_spedizione && submitted}]" />
                      <small v-show="validationErrors.comune_spedizione && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                </div>
            </TabPanel>
          </TabView>            
        </div>
      </template>
      <template v-slot:footer>
          <div class="grid justify-content-between">
            <Button label="Indietro" @click="prevPage()" icon="pi pi-angle-left" iconPos="left" class="p-btn-footer"/>
            <i></i>
            <Button label="Avanti" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" class="p-btn-footer"/>
          </div>
      </template>
    </Card>
  </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, computed, onUpdated } from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import { useStore } from 'vuex'
// import RadioButton from 'primevue/radiobutton';

export default {
  components: {
  },
  props: {
    formData: Object
  },
  setup (props, {emit}) {
    const store = useStore()
    const currentUser = ref(store.state.auth.user)
    const toast = useToast();
    const stepIndex = 1
    const activeTabIndex = ref(0)
    const rappresentante = ref({})
    const rappresentanti = ref([])
    const selectedRapp = ref(null)
    const newContatto = ref(false)
    const contatto = ref({})
    const contatti = ref([])
    const selectedContact = ref(null)
    
    const districts = ref([])
    const cities = ref([])
    const toponimi = ref([])

    const format = ref(false) 
    const isEmail = ref(false)
    const validationErrors = ref({})
    const submitted = ref(false)
    const type_insert = ref([
      {key:0, name:'Completo'},
      {key:1, name:'Temporaneo'}
    ]);
    const selectedTypeInsert = ref(0)

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const typeClient = ref('');
    const typeStep = ref('')
    const showModal = ref(false)
    const is_complete = ref(true)
    const visible = ref(false)
    const windowSize = ref(window.innerWidth)
    const slides = ref([])
    const isManualContract = ref()

    onMounted(() => {
      setResponsive()
      getSlides()
      isManualContract.value = props.formData.contratto_manuale
      emit("activeLoader", true)
      typeClient.value = props.formData.typeClient
      typeStep.value = typeClient.value == 2 ? 'Contatto cliente' : 'Rappresentante'
      const promises = [
        getToponimi(),
        getDistricts(),
        getRapps()
      ]
      Promise.all(promises)
      .then(()=> {
        setRapps()
      })
      .finally(() => {
        emit("activeLoader", false)
      })
      refresh()
    })

    onUpdated(()=>{
      setResponsive()
      getSlides()
      isManualContract.value = props.formData.contratto_manuale
      emit("activeLoader", true)
      typeClient.value = props.formData.typeClient
      typeStep.value = typeClient.value == 2 ? 'Contatto cliente' : 'Rappresentante'
      const promises = [
        getToponimi(),
        getDistricts(),
        getRapps()
      ]
      Promise.all(promises).finally(() => {
        emit("activeLoader", false)
      })
    }) 

    const setResponsive = () => {
      window.addEventListener('resize', () => {
        windowSize.value = window.innerWidth
      })
    }


    const getSlides = async () => {
      await service.getSlides(stepIndex)
      .then((res) => {
        slides.value = res.items
        slides.value.forEach(slide => {
          slide.name = `/images/Step/step${stepIndex}-${slide.order}.png`
        })
      })
    }


    const refresh = () => {
      window.onbeforeunload = function() {
        emit('complete')
      };
    }

    const onSelectedTypeInsert = () => {
      validationErrors.value = []
      if(selectedTypeInsert.value == 1){
        contatto.value = {}
        delete rappresentante.value.codfis
      }
    }

    const status_percent_rapp = computed(() => {
      let validate_properties = [
          'codfis',
          'nome',
          'cognome',
          'email',
          'cellulare',
          'toponimo_spedizione',
          'indirizzo_spedizione',
          'civico_spedizione',
          'cap_spedizione',
          'provincia_spedizione',
          'comune_spedizione'
        ]
          let total = 0;
          validate_properties.forEach(element => {
            switch (element) {
              case 'codfis':
              case 'nome':
              case 'cognome':
                if (rappresentante.value[element] != '' && rappresentante.value[element] != null && rappresentante.value[element] != 0) {
                  total += 100 / validate_properties.length;
                }
                break;
              default:
                if (contatto.value[element] != '' && contatto.value[element] != null && contatto.value[element] != 0) {
                  total += 100 / validate_properties.length;
                }
                break;
            }
          });
          return total;
    });
    const openPosition = () => {
      visible.value = true;
    }

    const getToponimi = async () =>{
      await service.getToponimi().then((res) => {
        toponimi.value = res.items
      })
    }
    const onSelectedToponimo = (evt, type) => {
      delete validationErrors.value[`toponimo_${type}`];
    }
    const onInputToponimo = (evt, type) => {
      contatto.value.toponimo_spedizione = evt.target.value.toUpperCase()
      delete validationErrors.value[`toponimo_${type}`]
    }
    const onInputText = (evt) => {
      if (evt.target.id == 'email' || evt.target.id == 'pec') {
        contatto.value[evt.target.id] = evt.target.value.toLowerCase()
        delete validationErrors.value[`isEmail`]
      } else {
        contatto.value[evt.target.id] = evt.target.value.toUpperCase()
      }
      delete validationErrors.value[evt.target.id]
    }

    const onSelectedView = () => {
      if (activeTabIndex.value == 0) {
        Object.keys(validationErrors.value).forEach((error) => {
          delete validationErrors.value[`${error}`]
        });
      }else{
        delete validationErrors.value.rappresentante
      }
    }

    const getRapps = async () =>{
      await service.getRappresentanti().then((res) => {
        rappresentanti.value = res.items
      })
    }
    const setRapps = () => {
      selectedRapp.value = props.formData.cliente ? rappresentanti.value.find(rappresentante => rappresentante.id == props.formData.cliente.rapp) : null
        if (selectedRapp.value != null) {
          onSelectedRapp()
        }
    }
    const getContatti = () => {
      service.getContacts(selectedRapp.value.id, 'rapp')
      .then((res) => {
        contatti.value = res.items.filter(el => el.email)
        contatti.value = contatti.value.filter(el => el.cell)
        newContatto.value = contatti.value.length == 0 ? true : false
        selectedContact.value = contatti.value.length == 0 ? null : contatti.value[contatti.value.length-1] 
      })
    }
    const onSelectedRapp = () => {
      selectedContact.value = null
      getContatti()
      validationErrors.value = []
    }
    const setNewContatto = () => {
      delete validationErrors.value.contact
      contatto.value = {}
      let properties_address = [
        'cellulare',
        'email',
        'toponimo_spedizione',
        'indirizzo_spedizione',
        'civico_spedizione',
        'cap_spedizione_new',
        'provincia_spedizione',
        'comune_spedizione',
      ]
      if (newContatto.value) {
        delete validationErrors.value.contact
        selectedContact.value = null
      }else{
        properties_address.forEach(property => {
          delete contatto.value[`${property}`]
        });
      }
    }

    const getDistricts = async () => {
      await service.getDistricts().then((res) => {
        districts.value = res.items
      })     
    }

    const onSelectedDistrict = (evt) => {
      service.getCities(evt.value).then((res) => {
        cities.value = res.items
      })
      delete validationErrors.value['provincia_spedizione'];
    }
        
    const onSelectedCity = (evt) => {
      contatto.value.comune_spedizione = evt.value
      delete validationErrors.value['comune_spedizione'];
    }
    const onSelectedMobile = (evt) => {
      contatto.value.cellulare = evt.target.value;
      showModal.value = true;
      delete validationErrors.value['cellulare_incorrect'];
      delete validationErrors.value['cellulare'];
    }

    const isCorrect = (is_correct = false) => {
      if (!is_correct) {
        validationErrors.value['cellulare_incorrect'] = true;
      }else{
        delete validationErrors.value['cellulare_incorrect'];
      }
      showModal.value = false;
    }


    const removeExistentRapp = () => {
      selectedRapp.value = null
      contatto.value = {}
      newContatto.value = false
      contatti.value = []
      validationErrors.value = []
      selectedContact.value = null
    }
    const onSelectedContact = () => {
      delete validationErrors.value.contact;
    }

    const validateRappExist = async () => {
      emit("activeLoader", true)
      return service.checkRappExist(rappresentante.value, is_complete.value)
      .finally(() => {
        emit("activeLoader", false)
      })         
    }
    const nextPage = async () => {
      submitted.value = true;
      is_complete.value = selectedTypeInsert.value == 0 ? true : false;

      if (Object.keys(validationErrors.value).length > 0) {
        toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3000});
      }else if (validateForm()) {
        emit("activeLoader", true)
        if (activeTabIndex.value == 1) {
          if (rappresentante.value.codfis) {
            let Existent = await validateRappExist()
            if (Existent.items) {
              validationErrors.value.rappex = true
              emit("activeLoader", false)
              return
            }else{
              delete validationErrors.value.rappex
            }            
          }
          rappresentante.value.id = 0
        }else{
          is_complete.value = true
          rappresentante.value.id = selectedRapp.value != null ? selectedRapp.value.id : 0
        }
        if (selectedTypeInsert.value == 1) {
          contatto.value.id = 0
        }
        emit('next-page', {
          formData: 
          {
            "rappresentante": rappresentante.value,
            "contatto": contatto.value ?? props.formData.contatto,
            "is_complete": is_complete.value
          }
          ,pageIndex: stepIndex
        });
      }  
    }
    const prevPage = () => {
      emit('prev-page', {pageIndex: stepIndex});
    }
    const onSelected = (evt) => {
      if (evt.target.id == 'codfis') {
        delete validationErrors.value['codfis'];
        delete validationErrors.value['invalid'];
        delete validationErrors.value['format'];
        delete validationErrors.value['rappex'];
      }else{
        delete validationErrors.value[evt.target.id];
      }
    }

    const validateForm = () => {
      validationErrors.value = []
      if (activeTabIndex.value == 0) {
        if (selectedRapp.value == null) {
          validationErrors.value['rappresentante'] = true;
        } else {
          if (selectedContact.value) {
            contatto.value.id = selectedContact.value.id
          }else if(!newContatto.value){
            validationErrors.value['contact'] = true;
          }else{
            let validate_properties_contatto = [
              'email',
              'cellulare',
              'toponimo_spedizione',
              'indirizzo_spedizione',
              'civico_spedizione',
              'cap_spedizione_new',
              'provincia_spedizione',
              'comune_spedizione'
            ]
            if (isManualContract.value && currentUser.value.role == 'admin') {
              validate_properties_contatto.splice(validate_properties_contatto.findIndex(el => el == 'email'),1)
              validate_properties_contatto.splice(validate_properties_contatto.findIndex(el => el == 'cellulare'),1)
            }

            Object.keys(contatto.value).forEach(element => {
              if(!element.includes('comune')){
                contatto.value[element] = contatto.value[element].trim()
              }
            });
            validate_properties_contatto.forEach(property => {
              if (Object.keys(contatto.value).includes(property) && contatto.value[property] != '') {
                delete validationErrors.value[property]
              } else {
                validationErrors.value[property] = true
              }
            });
          }
          delete validationErrors.value['rappresentante'];
        }
      } else {
        let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let regexPiva = /^[0-9]{11}$/
        let regex_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
        let regex_alphanumeric = /[a-zA-Z]/

        let validate_properties = selectedTypeInsert.value == 0 ? [
          'nome',
          'cognome',
          'codfis',
          'email',
          'cellulare',
          'toponimo_spedizione',
          'indirizzo_spedizione',
          'civico_spedizione',
          'cap_spedizione',
          'provincia_spedizione',
          'comune_spedizione'
        ] : [
          'nome',
          'cognome',
          'codfis',
        ]
        if (selectedTypeInsert.value == 0) {
          if (isManualContract.value && currentUser.value.role == 'admin') {
              validate_properties.splice(validate_properties.findIndex(el => el == 'email'),1)
              validate_properties.splice(validate_properties.findIndex(el => el == 'cellulare'),1)
            }
        }
        validate_properties.forEach(element => {
          let check = contatto.value[`${element}`] == '' || contatto.value[`${element}`] == null 
          switch (element) {          
            case 'codfis':
            case 'nome':
            case 'cognome':
              check = rappresentante.value[`${element}`] == '' || rappresentante.value[`${element}`] == null
              break;

            default:
              break;
          }
          if (check) {
            validationErrors.value[`${element}`] = true;
          }else{
            switch (element) {
              case 'codfis':
                if (regex_alphanumeric.test(rappresentante.value[`${element}`])) {
                  if (!regex_cf.test(rappresentante.value[`${element}`])) 
                    validationErrors.value['format'] = true;
                  else
                    delete validationErrors.value['format'];
                }else{
                  if (props.formData.typeClient == 3) {
                    if (!regexPiva.test(rappresentante.value[`${element}`]))
                      validationErrors.value['format'] = true;
                    else
                      delete validationErrors.value['format'];            
                  } else {
                    validationErrors.value['invalid'] = true;
                  }
                }
                break;
              case 'email':
                if (!regex_email.test(contatto.value[`${element}`])){
                  validationErrors.value['isEmail'] = true;
                }else{
                  delete validationErrors.value['isEmail'];
                }
                break;

              default:
                break;
            }
          }
        });
      }
      return !Object.keys(validationErrors.value).length;
    }

    return {
      rappresentante,
      activeTabIndex,
      format ,
      rappresentanti,
      selectedRapp,
      submitted,
      isEmail,
      toponimi,
      districts,
      cities,
      nextPage,
      prevPage,
      validationErrors,
      removeExistentRapp,
      onSelectedDistrict, 
      onSelectedCity,
      typeClient,
      typeStep,
      onSelectedMobile,
      showModal,
      isCorrect,
      onSelected,
      status_percent_rapp,
      openPosition,
      visible,
      slides,
      contatto,
      newContatto,
      contatti,
      onSelectedRapp,
      setNewContatto,
      selectedContact,
      onSelectedContact,
      onSelectedToponimo,
      onInputToponimo,
      onSelectedView,
      type_insert,
      selectedTypeInsert,
      onSelectedTypeInsert,
      windowSize,
      onInputText,
    }
  },
}
</script>

<style>
.form-step .p-card-title, .form-step .p-card-subtitle {
    text-align: center;
}
.btn-remove-selected {
  cursor: pointer;
}
</style>
<style scoped>
  .btn-offerte{
    border-radius: 50% !important;
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .btn-offerte:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(105, 105, 105);
    border-color: #2196F3;
  }

  .p-card{
    min-width: 335px !important;
  }
</style>

<template>
    <div class="stepsdemo-content">
        <Card >
            <template v-slot:title>
                Documenti
            </template>
            <template v-slot:subtitle>
                Upload Documenti
            </template>
            <template v-slot:content>
              <div class="flex align-items-center flex-column ">
                <div class="button-wrapper mb-2">
                  <span class="label">{{selectedFiles.length == 0 ? 'Carica File' : selectedFiles.length +' files'}}</span>
                  <input id="upload" type="file" ref="file" @change="handleFileUpload( $event )" accept="application/pdf,image/gif, image/jpeg, image/jpg,image/png" multiple/>
                </div>
                <div v-show="validationErrors.files && submitted" class="p-error">Campo richiesto.</div>
                <div v-show="validationErrors.type && submitted" class="p-error">Formato non consentito</div>
              </div>
              <div class="text-center">
                <small>Formati consentiti: {{allowedType.join(", ")}}</small>
              </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>


</template>

<script>
import {ref, getCurrentInstance} from 'vue';

import Service from '@/services/ask-pratiche/pratiche-wizard'   
import Card from 'primevue/card';
import Button from 'primevue/button';


export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    emits: ["next-page","prev-page"],
    setup (props, {emit}) {
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const selectedFiles = ref([])
        const allowedType = ['pdf','jpeg','jpg','png']
        const validationErrors = ref({})
        const submitted = ref(false)

        

        const prevPage = () => {
            emit('prev-page', {pageIndex: 3});
        }

        const nextPage = () => {
          submitted.value = true;
          if (validateForm() ) {
               emit('next-page', {
                    formData: {
                       files: selectedFiles.value,
                    },
                    pageIndex: 3
                });
            }       
        }

        
        const handleFileUpload = (evt) => {
          evt.preventDefault()
          submitted.value = false;
          selectedFiles.value = []
          selectedFiles.value = self.refs.file.files
        }

        const validateForm = () => {
          if (selectedFiles.value.length == 0) {
              validationErrors.value['files'] = true;
              delete validationErrors.value['type'];
          } else {
            delete validationErrors.value['files'];
          }

          if (selectedFiles.value.length > 0) {
            let valid = [...selectedFiles.value].every(file => allowedType.includes(file.type.replace(/(.*)\//g, '')), false);
            if (valid) 
              delete validationErrors.value['type'];
            else
              validationErrors.value['type'] = true;
          }

          return !Object.keys(validationErrors.value).length;
        }

        return {
           validationErrors, 
           submitted, 
           service, 
           nextPage, 
           prevPage, 
           handleFileUpload,
           selectedFiles,
           allowedType
        }

    },
}
</script>
<style>
.button-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #00bfff;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0);
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Cliente
            </template>
            <template v-slot:subtitle>
                Verifica il cliente
            </template>
            <template v-slot:content>
                <div class="formgrid grid">                     
                    <div class="p-field col-12 md:col-9">
                        <Dropdown  v-model="selectedClient" :options="clients"  :filter="true" :filterFields="['ragsoc', 'nome','cognome','piva','codfis']" placeholder="Seleziona un cliente" :class="['inputfield w-full',{'p-invalid': validationErrors.client && submitted}]">
                            <template #value="slotProps">
                                <div class="client-item client-item-value" v-if="slotProps.value">
                                    <div>{{(slotProps.value.ragsoc) ? slotProps.value.ragsoc : slotProps.value.nome +" "+ slotProps.value.cognome}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="client-item">
                                    <div v-if="slotProps.option.ragsoc">{{slotProps.option.ragsoc}} - <small>{{(slotProps.option.piva) ? slotProps.option.piva : slotProps.option.codfis}}</small></div>
                                    <div v-else>{{slotProps.option.nome}}&nbsp;{{slotProps.option.cognome}} - <small>{{slotProps.option.codfis}}</small></div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.client && submitted" class="p-error">Seleziona un cliente.</div>
                    </div>
                    <div class="p-field col-12 md:col-3 btn-reload flex justify-content-center">
                        <Button @click="getClients" label="" icon="pi pi-replay" class="p-button-success"/>
                    </div>
                    <div class="p-field p-col-9">
                        <div class="text-left">
                            <small>Se il cliente non è nella lista, aggiungilo e poi premi il bottone verde per aggiornare la lista</small><br>
                            <small><router-link to="/clienti" target= '_blank'>Aggiungi un cliente</router-link></small>
                        </div>
                    </div>                
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button  label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>


</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { useToast } from "primevue/usetoast";


export default {
    components: {
        Card,
        Button,
        Dropdown
    },
     props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete", "activeLoader"],
    setup (props, {emit}) {
        const toast = useToast();
        const clients= ref([])
        const selectedClient= ref('')
        const c_commerciale = ref('')
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const store = useStore()
        const currentUser = ref(store.state.auth.user)
        
        onMounted (()=>{
            service.getClients().then((res) => {
                clients.value = res.items
            }).then(() =>{
             // se proviene da wizard contratto
            if (props.formData.idCliente) {
              selectedClient.value = clients.value.filter(el=>{
                if (el.id == props.formData.idCliente ) {
                  return el
                }
              })[0]
            }
            // se proviene da wizard contratto
          }) 
        })

        const getClients = () => {
            service.getClients().then((res) => {
                clients.value = res.items
            })
        }


        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }

        const nextPage = () => {
            submitted.value = true;
             if (validateForm()) { 
                precheckPod().then((valid) => {
                    if (valid) {
                        emit('next-page', {
                            formData: {
                                client: selectedClient.value,
                                cc: c_commerciale.value
                            },
                                pageIndex: 1
                        });  
                    }
                })
                     
             }
        }

        const precheckPod = async () => {
          emit("activeLoader", true)
          toast.add({severity:'info', summary: 'Precheck in corso...', detail: 'Non chiudere la schermata'});
          const res = await service.precheckPod(props.formData.pod, selectedClient.value)
          .finally(()=>{
            emit("activeLoader", false)
          })
          if (res.valid.length == 0) {
            toast.add({severity:'error', summary:'Errore', detail: 'Provider non raggiungibile'});
            return false
          } else {
            if (res.valid.data.esito) {
              c_commerciale.value = res.valid.data.controparte_commerciale.ragione_sociale
              toast.removeAllGroups();
              return true            
            } else {
              toast.add({severity:'warn', summary:'Precheck:', detail: res.valid.data.causale}); 
              return false                  
            }
          }
        }

        const validateForm = () => {
           if (selectedClient.value == null || selectedClient.value == '')
                validationErrors.value['client'] = true;
            else 
                delete validationErrors.value['client'];
                       
            return !Object.keys(validationErrors.value).length;
        }

        return {
            validationErrors, submitted, nextPage, prevPage, precheckPod, clients, selectedClient, c_commerciale,
            getClients
        }

    },
}
</script>

<style scoped>
.btn-reload {
    display: flex;
    justify-content: right;
    align-items: flex-end;
}
.btn-reload button{
    width: 45px;
    height: 45px;
}
</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6" v-if="formData.cod_fiscale" ><b>Cod. Fiscale:</b>{{formData.cod_fiscale}}</div>
                    <div class="col-12 md:col-6" v-if="formData.name"><b>Nome:</b>{{formData.name}}</div>
                    <div class="col-12 md:col-6" v-if="formData.surname"><b>Cognome:</b>{{formData.surname}}</div>  
                    <div class="col-12 md:col-6"><b>Telefono:</b>{{formData.phone}}</div>
                    <div class="col-12 md:col-6"><b>Cellulare:</b>{{formData.mobile}}</div>
                    <div class="col-12 md:col-6"><b>Email:</b>{{formData.email}}</div>
                    <div class="col-12 md:col-6"><b>Pec:</b>{{formData.pec}}</div>
                    <div class="col-12 md:col-6"><b>Indirizzo:</b>{{formData.toponimo}} {{formData.address}}, {{formData.civico}}</div>
                    <div class="col-12 md:col-6"><b>Cap:</b>{{formData.cap}}</div>
                    <div class="col-12 md:col-6"><b>Sede:</b>{{formData.cityName}} -  ({{formData.district}})</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>

    <!-- Dialog if referral (proviene) da clienti wizard  -->
    <Dialog id="referralDialog" v-model:visible="referralDialog" header="" :modal="true" :style="{width: '550px'}">

        <p class="success-txt">Rappresentante aggiunto con successo</p>
        <div class="text-center">
          <small>Copia e incolla il nuovo codice fiscale nella scheda precedente</small>
        </div> 
        <div class="copy-text mt-2">
          <InputText  v-on:focus="$event.target.select()" ref="myinput" readonly type="text" class="text" :value="formData.cod_fiscale" />
          <button @click="copyToClipboard" placeholder="Top" v-tooltip.top.focus="'Copiato'">
            <i class="pi pi-copy"></i>
          </button>
        </div>

        <template #footer>
            <Button label="Chiudi questa scheda" icon="pi pi-times" class="p-button-text" @click="closeTabBrowser"/>
        </template>
    </Dialog>

</template>

<script>
import {ref, getCurrentInstance} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default {
    components: {
        Card,
        Button,
        Dialog
    },
    props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete", 'activeLoader'],
    setup (props, {emit}) {

        const referralDialog = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
   
        const prevPage = () => {
            emit('prev-page', {pageIndex: 3});
        }
        const complete = async () => { 

                       
          service.saveRappresentante(props.formData).then((res) => {
              emit("activeLoader", true)  
              if (!res) {
                  if (props.formData.referral == 'clienti') {
                    referralDialog.value = true                  
                  } else {
                     emit('complete');
                  }
              }
          }).finally(()=> {
            emit("activeLoader", false)
          })
        }

        const closeTabBrowser = () => {
          window.open('','_self').close()
        }

        const copyToClipboard = () => {
          navigator.clipboard.writeText(props.formData.cod_fiscale);
        }

        return {prevPage, complete, referralDialog, closeTabBrowser, copyToClipboard}
    },
    
}
</script>

<style>
#referralDialog .p-dialog-header-icon {
  display: none!important;
}
.success-txt {
  color:var(--green-500);
  font-weight: 500;
  font-size: 18px;
}
.copy-text {
	position: relative;
  padding: 3px 6px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 280px;
  display: block;
  margin: auto;
}
.copy-text button i {
  font-size: 1.2rem;
}
.copy-text input.text {
	padding: 8px;
	font-size: 18px;
	color: #555;
	border: none;
	outline: none;
}
.copy-text button {
	padding: 10px;
	background: #cae7ff;
	color: rgb(12, 142, 248);
	font-size: 18px;
	border: none;
	outline: none;
	border-radius: 10px;
	cursor: pointer;
}

.copy-text button:active {
	background: #809ce2;
}
.copy-text button:before {
	content: "Copied";
	position: absolute;
	top: -45px;
	right: 0px;
	background: #5c81dc;
	padding: 8px 10px;
	border-radius: 20px;
	font-size: 15px;
	display: none;
}
.copy-text button:after {
	content: "";
	position: absolute;
	top: -20px;
	right: 25px;
	width: 10px;
	height: 10px;
	background: #5c81dc;
	transform: rotate(45deg);
	display: none;
}
.copy-text.active button:before,
.copy-text.active button:after {
	display: block;
}
</style>

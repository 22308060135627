<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Anagrafica
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="p-field col-12  md:col-6">
                        <label for="consumo_annuo">Consumo annuo</label><br>
                        <InputNumber v-model="consumo_annuo" mode="decimal" :minFractionDigits="2" :maxFractionDigits="6" :class="['inputfield w-full',{'p-invalid': validationErrors.consumo_annuo && submitted}]" />         
                        <small v-show="validationErrors.consumo_annuo && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="remi">Remi</label><br>
                        <InputText v-model="remi" :class="['inputfield w-full',{'p-invalid': validationErrors.remi && submitted}]" />         
                        <small v-show="validationErrors.remi && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="matricola">Matricola</label><br>
                        <InputText v-model="matricola"  :class="['inputfield w-full',{'p-invalid': validationErrors.matricola && submitted}]" />         
                        <small v-show="validationErrors.matricola && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="classe">Classe</label><br>
                        <Dropdown v-model="selectedClasse" :options="classi" optionLabel="name" optionValue="name" placeholder="Seleziona classe" :class="['inputfield w-full',{'p-invalid': validationErrors.classe && submitted}]" />
                        <small v-show="validationErrors.classe && submitted" class="p-error">Campo richiesto.</small> 
                    </div>              
                    <div class="p-field col-12  md:col-6">
                        <label for="uso">Uso</label><br>
                        <Dropdown  v-model="selectedUso" :options="usi" optionLabel="name" placeholder="Seleziona uso" :class="['inputfield w-full',{'p-invalid': validationErrors.uso && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.uso && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="categoria">Categoria</label><br>
                        <Dropdown  v-model="selectedCategoria" :options="categorie" optionLabel="name" placeholder="Seleziona categoria" :class="['inputfield w-full',{'p-invalid': validationErrors.categoria && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.categoria && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="cod_fiscale">Cf attivazione</label><br>
                        <InputText id="cod_fiscale" v-model="cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.format_cf && submitted}]" />
                        <div v-if="validationErrors.format_cf && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="partita_iva">P.iva attivazione</label><br>
                        <InputText type="number" id="partita_iva" v-model="partita_iva" :class="['inputfield w-full',{'p-invalid': validationErrors.format_piva && submitted}]" />
                        <div v-if="validationErrors.format_piva && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12">
                        <label for="note">Note</label><br>
                        <InputText id="note" v-model="note" class="inputfield w-full" />
                    </div>          
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                     <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';

export default {
    components: {
        Card,
        InputText,
        Button,
        Dropdown,
        InputNumber
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {
        const consumo_annuo = ref(0)
        const remi = ref('')
        const matricola = ref('')
        const usi = ref([])
        const selectedUso = ref(null)
        const cod_fiscale = ref('')
        const partita_iva = ref('')
        const note = ref('')
        const classi = ref([])
        const selectedClasse = ref('')
        const categorie = ref([])
        const selectedCategoria = ref('')
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

       

        onMounted(() => {          
            service.getClassiGas().then((res) => {
                classi.value = res.items
            })
            service.getCategorieGas().then((res) => {
                categorie.value = res.items
            })
            service.getUsiGas().then((res) => {
                usi.value = res.items
            })        
        })


        const prevPage = () => {
            emit('prev-page', {pageIndex: 4});
        }
        const nextPage = () => {

            submitted.value = true;
            if (validateForm()) {
                emit('next-page',{
                    formData: {                
                        consumo_annuo: consumo_annuo.value,
                        remi: remi.value,
                        matricola: matricola.value,
                        uso: selectedUso.value,                
                        classe: selectedClasse.value,                
                        categoria: selectedCategoria.value,                
                        cod_fiscale: cod_fiscale.value,
                        partita_iva: partita_iva.value,
                        note: note.value
                    },
                    pageIndex: 4
                });
            }        
        }

        const validateForm = () => {
            let reg_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i   
            let reg_piva = /^\d+$/;

            if (partita_iva.value != '') {
                if (reg_piva.test(partita_iva.value) && partita_iva.value.length == 11)
                     delete validationErrors.value['format_piva'];     
                else 
                    validationErrors.value['format_piva'] = true;                      
            }
            
            if (cod_fiscale.value != '') {
               if (!reg_cf.test(cod_fiscale.value)) 
                    validationErrors.value['format_cf'] = true;
                else 
                    delete validationErrors.value['format_cf']; 
            }
            

            
            if (!matricola.value.trim())
                validationErrors.value['matricola'] = true;
            else
                delete validationErrors.value['matricola'];
            
            if (selectedClasse.value == null || selectedClasse.value == '')
                validationErrors.value['classe'] = true;
            else
                delete validationErrors.value['classe'];
            
            if (selectedUso.value == null || selectedUso.value == '')
                validationErrors.value['uso'] = true;
            else
                delete validationErrors.value['uso'];
            
            if (selectedCategoria.value == null || selectedCategoria.value == '')
                validationErrors.value['categoria'] = true;
            else
                delete validationErrors.value['categoria'];
            
            
            return !Object.keys(validationErrors.value).length;
        }

        return {consumo_annuo, remi, matricola, usi, selectedUso, cod_fiscale, partita_iva, note, classi, selectedClasse,
                 categorie, selectedCategoria, validationErrors, submitted, prevPage, validateForm, nextPage
        }
    }
 
}
</script>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Tipo offerta
            </template>
            <template v-slot:subtitle>
                Seleziona una tipologia
            </template>
            <template v-slot:content>
                
                <div class="formgrid grid">     
                    <div class="p-field col-12">
                         <Dropdown  v-model="selectedTipo" :options="tipi" optionLabel="name" placeholder="Seleziona tipologia" :class="['inputfield w-full',{'p-invalid': validationErrors.tipo && submitted}]" >
                            <template #value="slotProps">
                                <div class="client-item client-item-value" v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="client-item">
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.tipo && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        Card,
        Button,
        Dropdown,

    },
    props: {
      formData: Object
    },

    setup (props, {emit}) {
        const selectedTipo = ref('')
        const tipi = ref([]);
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        onMounted (()=>{
            emit('activeLoader', true)
            service.getTipiCtr().then((res) => {
                tipi.value = res.items
                if (props.formData.offerta_clone) {
                    selectedTipo.value = props.formData.offerta_clone.tipologia ? tipi.value.find(tipo => tipo.id == props.formData.offerta_clone.tipologia) : null
                    if (selectedTipo.value) {
                        setTimeout(()=>{
                            nextPage()
                        }, 2000)
                    }
                }
                emit('activeLoader', false)
            })
        })
        
      
        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
                emit('next-page', {formData: {tipo: selectedTipo.value},pageIndex: 0});
            }                  
        }

      
        const validateForm = () => {
            if (selectedTipo.value == '')
                validationErrors.value['tipo'] = true;
            else 
                delete validationErrors.value['tipo'];
                                  
            return !Object.keys(validationErrors.value).length;
        }

        return {
           selectedTipo, tipi, validationErrors, submitted, service, nextPage
        }

    },
}
</script>
<style scoped>
#cod_fiscale {
    background-color: #689f38;
}
</style>
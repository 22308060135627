<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Associa cliente
            </template>
            <template v-slot:subtitle>
                Seleziona un cliente
            </template>
            <template v-slot:content>
                <div class="formgrid grid">     
                    <div class="p-field col-12">
                        <Dropdown  
                          v-model="selectedClient" 
                          :options="clients"            
                          :filter="true" 
                          :filterFields="['ragsoc', 'nome','cognome','piva','codfis']" 
                          placeholder="Seleziona un cliente" 
                          :class="['inputfield w-full',{'p-invalid': validationErrors.client && submitted}]"
                        >
                            <template #value="{value, placeholder}">
                                <div class="client-item client-item-value" v-if="value">
                                    <div>{{(value.ragsoc) ? value.ragsoc : value.nome +" "+ value.cognome}}</div>
                                </div>
                                <span v-else>
                                    {{placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="client-item">
                                    <div v-if="slotProps.option.ragsoc">{{slotProps.option.ragsoc}} - <small>{{(slotProps.option.piva) ? slotProps.option.piva : slotProps.option.codfis}}</small></div>
                                    <div v-else>{{slotProps.option.nome}}&nbsp;{{slotProps.option.cognome}} - <small>{{slotProps.option.codfis}}</small></div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.client && submitted" class="p-error">Seleziona un cliente.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button label="" :disabled="errorCtr" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import { useRoute } from 'vue-router';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex'

export default {
    components: {
        Card,
        Button,
        Dropdown,
    },
    setup (props, {emit}) {
        const toast = useToast();
        const route = useRoute();
        const selectedClient = ref('')
        const clients = ref([])
        const idClient = ref(route.params.idClient)
        const idContract = ref(route.params.idContract)
        const exCtr = ref(null)
        const errorCtr = ref(false)
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const store = useStore()
        const currentUser = ref(store.state.auth.user)

        onMounted (()=>{
          emit("activeLoader", true)
          service.getClients().then((res) => {
            clients.value = res.items
          }).then(() =>{

             // se proviene da wizard cliente
            if (idClient.value) {
              selectedClient.value = clients.value.filter(el=>{
                if (el.id == idClient.value ) {
                  return el
                }
              })[0]
            }
            // se proviene da mindtab (clona e chiudi)
            if (idContract.value) {
              service.getContract(idContract.value).then((res) => {
                if (res.items.length > 0) {
                  exCtr.value = res.items
                }
              }).finally(()=>{
                checkClientId()
              }) 
            }

          }).finally(()=>{
            emit("activeLoader", false)
          })              
        })

        const checkClientId = () => {
          if (idClient.value != exCtr.value[0].cod_cliente) {
            errorCtr.value = true
            toast.add({severity:'warn', summary:'Attenzione', detail: 'Cliente non coincide con il contratto esistente'});
          }
        }

      
        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
                emit(
                  'next-page',
                  {
                    formData: {
                      client: selectedClient.value,
                      exCtr: exCtr.value
                    },
                    pageIndex: 0
                  }
                );
            }                  
        }

      
        const validateForm = () => {
          
            if (selectedClient.value.id == undefined)
                validationErrors.value['client'] = true;
            else 
                delete validationErrors.value['client'];
                                  
            return !Object.keys(validationErrors.value).length;
        }


        return {
           selectedClient, 
           clients,
           idClient,
           validationErrors, 
           submitted, 
           service,
           errorCtr,
           nextPage,
        }

    },
}
</script>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6"><b>Tipo Cliente:</b>{{obj.tipo_client_name}}</div>
                    <div class="col-12 md:col-6" v-if="obj.cod_fiscale_azienda" ><b>Cod. Fiscale:</b>{{obj.cod_fiscale_azienda}}</div>
                    <div class="col-12 md:col-6" v-if="obj.cod_fiscale" ><b>Cod. Fiscale:</b>{{obj.cod_fiscale}}</div>
                    <div class="col-12 md:col-6" v-if="obj.name"><b>Nome:</b>{{obj.name}}</div>
                    <div class="col-12 md:col-6" v-if="obj.surname"><b>Cognome:</b>{{obj.surname}}</div>
                    <div class="col-12 md:col-6" v-if="obj.partita_iva"><b>P.iva:</b>{{obj.partita_iva}}</div>
                    <div class="col-12 md:col-6" v-if="obj.rag_sociale"><b>Rag. Sociale:</b>{{obj.rag_sociale}}</div>   
                    <div class="col-12 md:col-6"><b>Telefono:</b>{{obj.phone}}</div>
                    <div class="col-12 md:col-6"><b>Cellulare:</b>{{obj.mobile}}</div>
                    <div class="col-12 md:col-6"><b>Email:</b>{{obj.email}}</div>
                    <div class="col-12 md:col-6"><b>Indirizzo:</b>{{obj.toponimo}} {{obj.address}}, {{obj.civico}}</div>
                    <div class="col-12 md:col-6"><b>Sede:</b>{{obj.cityName}} - ({{obj.district}})  </div>
                    <div class="col-12 md:col-6" v-if="obj.codice_destinatario" ><b>Codice Destinatario:</b>{{obj.codice_destinatario}}</div>
                    <div class="col-12 md:col-6" v-if="obj.codice_ateco" ><b>Codice Ateco:</b>{{obj.codice_ateco}}</div>
                    <div class="col-12 md:col-6" v-if="obj.rappresentanteName" ><b>Rappresentante:</b>{{obj.rappresentanteName}}</div>
                    <div class="col-12 md:col-6"><b>Note:</b>{{obj.note}}</div>
                    <div class="col-12 md:col-6">
                      <b>File caricati:</b>
                        <div v-for="(file, index) in formData.files" :key="index">
                          <i class="pi pi-file" style="font-size: 1rem mr-1"></i>
                          {{file.name}}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onUpdated, onMounted, watchEffect} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {
      
        const obj = ref([])
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const isDisable = ref(false);

        onMounted(() => {
            obj.value = props.formData
        })
        watchEffect(() => {
            if (props.formData['tipo_client_name'] == 'Domestico') {
                obj.value['partita_iva'] = ''
                obj.value['rag_sociale'] = ''
                obj.value['cod_fiscale_azienda'] = ''
                obj.value['pec'] = ''
                obj.value['score'] = ''
                obj.value['codice_destinatario'] = ''
                obj.value['codice_ateco'] = ''
            } else {
                obj.value['cod_fiscale'] =  obj.value['cod_fiscale_azienda']
                obj.value['name'] =  ''
                obj.value['surname'] =  ''
            }
        })
        onUpdated(() => {
           
        })
      
        const prevPage = () => {
            emit('prev-page', {pageIndex: 6});
        }
        const complete = async () => {
          emit("activeLoader", true)
          let fd = new FormData();
          for (let i = 0 ; i < props.formData.files.length ; i++) {
              fd.append('files[]', props.formData.files[i]); 
          }              
          fd.append('data', JSON.stringify(obj.value));

          service.saveClient(fd).then((res) => {
            isDisable.value = true
            if (res.success) {
              setTimeout(function() {
                emit('complete', {id: res.item});
              }, 
              1800); 
            }      
          }).finally(()=>{
            isDisable.value = false
            emit("activeLoader", false)
          })
        }


        return {prevPage, complete, obj, isDisable}
    },
    
}
</script>

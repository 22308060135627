<template>
  <Loader v-if="loading" />
  <Card>
    <template v-slot:title>
      <h3>Report Mensile dei caricamenti firmati</h3>
    </template>
    <template v-slot:content>
      <div class="grid">
        <div class="m-3">
          <div>
            <label><strong>Filtra per Mese:</strong></label>
            <br>
            <div class="mt-3">
              <Button icon="pi pi-angle-double-left" class="p-button-rounded p-button-text" @click="onChange(-1)"/>
              <Calendar v-model="date" :maxDate="maxDate" :manualInput="false" view="month" dateFormat="mm/yy" @update:modelValue="onChangeDate($event)"/>
              <Button v-if="moment(date).format('YYYY/MM') != max" icon="pi pi-angle-double-right" class="p-button-rounded p-button-text" @click="onChange(1)"/>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <Card>
            <template v-slot:title>
              <h4>
                Report Agenzie
              </h4>
            </template>
            <template v-slot:content>
              <DataTable :value="report[filtro]" stripedRows showGridlines>
                <ColumnGroup type="header">
                  <Row>
                    <Column header="Agenzia" :rowspan="2" field="nome" style="width: 15%"/>
                    <Column header="PP" :rowspan="2" field="count_total_pp" style="width: 5%"/>
                    <Column header="PP OUT" :rowspan="2" field="count_total_pp_out" style="width: 5%"/>
                    <Column header="Δ" :rowspan="2" field="count_total_pp_out" style="width: 5%"/>
                    <Column header="PODS" :colspan="5"/>
                    <Column header="PDRS" :colspan="5"/>
                    <Column header="Rid" :rowspan="2" style="width: 5%"/>
                    <Column header="OTP" :rowspan="2" style="width: 5%"/>
                  </Row>
                  <Row>
                    <Column header="totali" field="pods"/>
                    <Column header="valida" field="pods_val"/>
                    <Column header="precheck / ko / ok"/>
                    <Column header="caricati / bloccati"/>
                    <Column header="switch out"/>
                    <Column header="totali" field="pdrs"/>
                    <Column header="valida" field="pdrs_val"/>
                    <Column header="precheck / ko / ok"/>
                    <Column header="caricati / bloccati"/>
                    <Column header="switch out"/>
                  </Row>
                </ColumnGroup>
                <Column field="nome" header="Agenzia"></Column>
                <Column field="count_total_pp" header="PP"></Column>
                <Column field="count_total_pp_out" header="PP OUT"></Column>
                <Column header="Δ">
                  <template #body={data}>
                    <div :class="stockClass(data.count_total_pp - data.count_total_pp_out)">
                      {{ data.count_total_pp - data.count_total_pp_out }}
                    </div>
                  </template>
                </Column>

                <Column field="pods" header="pod"></Column>
                <Column field="pods_val" header="valida"></Column>
                <Column header="precheck / ok / ko">
                  <template #body={data}>
                    {{ data.pods_pk }} / {{ data.pods_pk_ko }} / {{ data.pods_pk_ok }}
                  </template>
                </Column>
                <Column header="caricato / bloccato">
                  <template #body={data}>
                    {{ data.pods_ok }} / {{ data.pods_ko }}
                  </template>
                </Column>
                <Column field="pods_out" header="switch out"></Column>
                <Column field="pdrs" header="pdr"></Column>
                <Column field="pdrs_val" header="valida"></Column>
                <Column header="precheck / ok / ko">
                  <template #body={data}>
                    {{ data.pdrs_pk }} / {{ data.pdrs_pk_ko }} / {{ data.pdrs_pk_ok }}
                  </template>
                </Column>

                <Column header="caricato / bloccato">
                  <template #body={data}>
                    {{ data.pdrs_ok }} / {{ data.pdrs_ko }}
                  </template>
                </Column>
                <Column field="pdrs_out" header="switch out"></Column>
                <Column header="Rid">
                  <template #body="{data}">
                    {{ data.rid }} / {{data.count_total_pp}}
                    <!-- <div class="pr-5 flex flex-column">
                      <p class="mb-2">
                      </p>
                      <p>
                        {{ ((data.rid/data.count_total_pp)*100).toFixed(2) }} %
                      </p>
                    </div> -->
                  </template>
                </Column>
                <Column header="OTP">
                  <template #body="{data}">
                    {{ data.otp }} / {{data.n_caricamenti}}
                    <!-- <div class="pr-5 flex flex-column">
                      <p class="mb-2">
                      </p>
                      <p>
                        {{ ((data.otp/data.n_caricamenti)*100).toFixed(2) }} %
                      </p>
                    </div> -->
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row>
                    <Column footer="AGF ENERGY"/>
                    <Column :footer="total_pp" />
                    <Column :footer="total_pp_out" />
                    <Column footer="">
                      <template #footer>
                        <div :class="stockClass(delta)">
                          {{ delta }}
                        </div>
                      </template>
                    </Column>
                    <Column :footer="pods"></Column>
                    <Column :footer="pods_val"></Column>
                    <Column :footer="`${pods_pk} / ${pods_pk_ko} / ${pods_pk_ok}`"></Column>
                    <Column :footer="`${pods_ok} / ${pods_ko}`"></Column>
                    <Column :footer="pods_out"></Column>
                    <Column :footer="pdrs"></Column>
                    <Column :footer="pdrs_val"></Column>
                    <Column :footer="`${pdrs_pk} / ${pdrs_pk_ko} / ${pdrs_pk_ok}`"></Column>
                    <Column :footer="`${pdrs_ok} / ${pdrs_ko}`"></Column>
                    <Column :footer="pdrs_out"></Column>
                    <Column>
                      <template #footer>
                        <div class="flex flex-column">
                          {{`${rid} / ${total_pp}`}}
                          <small>
                            {{ rid != 0 || total_pp != 0 ? Number((rid/total_pp)*100).toFixed(2) : '0.00' }} %
                          </small>
                        </div>
                      </template>
                    </Column>
                    <Column>
                      <template #footer>
                        <div class="flex flex-column">
                          {{`${otp} / ${n_caricamenti}`}}
                          <small>
                            {{ otp != 0 || n_caricamenti != 0 ? Number((otp/n_caricamenti)*100).toFixed(2) : '0.00' }} %
                          </small>
                        </div>
                      </template>
                    </Column>
                  </Row>
                </ColumnGroup>

              </DataTable>
            </template>
            <!-- <template v-slot:footer>
              <DataTable :value="total_month[filtro]" tableStyle="min-width: 50rem">
                <Column header="AGF ENERGY"/>
                <Column field="total_pp" header="Totale forniture"/>
                <Column field="pods" header="Totale forniture Energia(PK in OK)">
                  <template #body="{data}">
                    {{ data.pods }} ({{ data.pods_ok }})
                  </template>
                </Column>
                <Column field="pdrs" header="Totale forniture Gas(PK in OK)">
                  <template #body="{data}">
                    {{ data.pdrs }} ({{ data.pdrs_ok }})
                  </template>
                </Column>
                <Column field="total_pp" header="Totale forniture (Caricati in OK)">
                  <template #body="{data}">
                    {{ data.total_pp }} ({{ data.caricati }})
                  </template>
                </Column>
                <Column field="rid" header="Percentuale di Rid su Totale Forniture">
                  <template #body="{data}">
                    {{ ((data.rid/data.total_pp)*100).toFixed(2) }} %
                  </template>
                </Column>
                <Column header="Percentuale OTP su caricamenti">
                  <template #body="{data}">
                    <div class="pr-5 flex justify-content-between">
                      <p class="m-0">
                        {{ data.otp }} / {{data.n_caricamenti}}
                      </p>
                      <p>
                        {{ ((data.otp/data.n_caricamenti)*100).toFixed(2) }} %
                      </p>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </template> -->
          </Card>
        </div>
        <div class="mb-3">
          <Card>
            <template v-slot:title>
              <h4>Report Caricamenti</h4>
            </template>
            <template v-slot:content class="w-full">
              <Accordion :multiple="true" :activeIndex="activeIndex[filtro]" @tab-open="getDataItem">
                <AccordionTab v-for="settimana in items[filtro]" :key="settimana.title" :header="settimana.title">
                  <div v-if="settimana.loaded">
                    <DataTable :value="settimana.content" stripedRows tableStyle="min-width: 50rem" sortField="id" :sortOrder="-1">
                      <Column field="id" header="#"></Column>
                      <Column field="intestazione" header="Nome Cliente">
                        <template #body="{data}">
                          <strong>{{ data.intestazione }}</strong>
                        </template>
                      </Column>
                      <Column field="pods" header="N. PODS"></Column>
                      <Column field="pdrs" header="N. PDRS"></Column>
                      <Column field="count_total_pp" header="Totale PP"></Column>
                      <Column field="rid" header="Percentuale Rid">
                        <template #body="{data}">
                          {{ ((data.rid/data.count_total_pp)*100).toFixed(2) }} %
                        </template>
                      </Column>
                      <Column field="agenzia" header="Agenzia"></Column>

                      <Column field="cartaceo" header="Tipologia contratto">
                        <template #body="{data}">
                          <strong>
                            {{ data.cartaceo ? 'CARTACEO' : 'OTP' }}
                          </strong>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                  <div v-else class="flex justify-content-center">
                    <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                  </div>
                </AccordionTab>
              </Accordion>
            </template>
          </Card>
        </div>
      </div>
      
    </template>
    
  </Card>
  
</template>

<script>
import {ref, onMounted, computed, getCurrentInstance } from 'vue';
import Loader from "@/components/Loader.vue"
import Service from '@/services/ask-pratiche/pratiche-wizard'
import { useRouter } from 'vue-router'
// var CSV = require("xlsx");
import { useToast } from "primevue/usetoast";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import moment from 'moment'
   
export default {
  components: {
    Loader,
    Calendar
  },
  
  setup (props, {emit}) { 

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus

    const loading = ref(false)
    const date = ref(new Date())
    const maxDate = ref(date.value);
    const report = ref({})
    const items = ref({})
    const service = new Service(self.$eventBus)
    const activeIndex = ref({})
    const total_month = ref({})
    const currentYear = ref()
    const currentMonth = ref()
    const filtro = ref()
    const max = ref(moment(new Date()).format('YYYY/MM'))

    onMounted(() => { 
      getData()
    })

    const onChangeDate = (evt) => {
      getData()
    }

    const onChange = (increment) => {
      date.value = new Date(moment(date.value).add(increment, 'months'))
      getData()
    }

    const getData = async () => {
      let fd = new FormData();

      let mese = date.value.getMonth()+1
      currentMonth.value = mese.toString().padStart(2,'0')
      currentYear.value = date.value.getFullYear() 
      filtro.value = `${currentYear.value}-${currentMonth.value}`
      if (report.value[filtro.value] == undefined || `${currentYear.value}/${currentMonth.value}` == max.value) {
        items.value[filtro.value] = []
        loading.value = true
        fd.append('filtro', filtro.value);
  
        await service.getReportCaricamenti(fd)
        .then((res)=>{
          items.value[filtro.value] = res.items
          activeIndex.value[filtro.value] = []
          setTotaliMese(filtro.value)
          let pods = 0
          let pods_val = 0
          let pods_pk = 0
          let pods_pk_ko = 0
          let pods_pk_ok = 0
          let pods_ok = 0
          let pods_ko = 0
          let pods_out = 0
  
          let pdrs = 0
          let pdrs_val = 0
          let pdrs_pk = 0
          let pdrs_pk_ko = 0
          let pdrs_pk_ok = 0
          let pdrs_ok = 0
          let pdrs_ko = 0
          let pdrs_out = 0
  
          let rid = 0
          let caricati = 0
          let n_caricamenti = 0
          let otp = 0
  
          res.report.forEach((agenzia)=>{
            pods += agenzia.pods
            pods_val += agenzia.pods_val
            pods_pk += agenzia.pods_pk
            pods_pk_ko += agenzia.pods_pk_ko
            pods_pk_ok += agenzia.pods_pk_ok
            pods_ok += agenzia.pods_ok
            pods_ko += agenzia.pods_ko
            pods_out += agenzia.pods_out
  
            pdrs += agenzia.pdrs
            pdrs_val += agenzia.pdrs_val
            pdrs_pk += agenzia.pdrs_pk
            pdrs_pk_ko += agenzia.pdrs_pk_ko
            pdrs_pk_ok += agenzia.pdrs_pk_ok
            pdrs_ok += agenzia.pdrs_ok
            pdrs_ko += agenzia.pdrs_ko
            pdrs_out += agenzia.pdrs_out
  
            rid += agenzia.rid
            caricati += agenzia.caricati
            n_caricamenti += agenzia.n_caricamenti
            otp += agenzia.otp
          })
          report.value[filtro.value] = res.report
  
          let total_pp = pods + pdrs
          let total_pp_out = pods_out + pdrs_out
          total_month.value[filtro.value][0]['total_pp'] = total_pp
          total_month.value[filtro.value][0]['total_pp_out'] = total_pp_out

          total_month.value[filtro.value][0]['pods'] = pods
          total_month.value[filtro.value][0]['pods_val'] = pods_val
          total_month.value[filtro.value][0]['pods_pk'] = pods_pk
          total_month.value[filtro.value][0]['pods_pk_ko'] = pods_pk_ko
          total_month.value[filtro.value][0]['pods_pk_ok'] = pods_pk_ok
          total_month.value[filtro.value][0]['pods_ok'] = pods_ok
          total_month.value[filtro.value][0]['pods_ko'] = pods_ko
          total_month.value[filtro.value][0]['pods_out'] = pods_out

          total_month.value[filtro.value][0]['pdrs'] = pdrs
          total_month.value[filtro.value][0]['pdrs_val'] = pdrs_val
          total_month.value[filtro.value][0]['pdrs_pk'] = pdrs_pk
          total_month.value[filtro.value][0]['pdrs_pk_ko'] = pdrs_pk_ko
          total_month.value[filtro.value][0]['pdrs_pk_ok'] = pdrs_pk_ok
          total_month.value[filtro.value][0]['pdrs_ok'] = pdrs_ok
          total_month.value[filtro.value][0]['pdrs_ko'] = pdrs_ko
          total_month.value[filtro.value][0]['pdrs_out'] = pdrs_out

          total_month.value[filtro.value][0]['rid'] = rid
          total_month.value[filtro.value][0]['caricati'] = caricati
          total_month.value[filtro.value][0]['n_caricamenti'] = n_caricamenti
          total_month.value[filtro.value][0]['otp'] = otp
        }).finally(()=>{
          loading.value = false
        })
      }
    }
    const total_pp = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['total_pp'] : 0;
    });
    const total_pp_out = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['total_pp_out'] : 0;
    })
    const pods = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods'] : 0;
    });
    const pods_val = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_val'] : 0;
    });

    const pods_pk = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_pk'] : 0;
    });
    const pods_pk_ko = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_pk_ko'] : 0;
    });
    const pods_pk_ok = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_pk_ok'] : 0;
    });
    const pods_ok = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_ok'] : 0;
    });
    const pods_ko = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_ko'] : 0;
    });
    const pods_out = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pods_out'] : 0;
    });
    const pdrs = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs'] : 0;
    });
    const pdrs_val = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_val'] : 0;
    });

    const pdrs_pk = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_pk'] : 0;
    });
    const pdrs_pk_ko = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_pk_ko'] : 0;
    });
    const pdrs_pk_ok = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_pk_ok'] : 0;
    });
    const pdrs_ok = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_ok'] : 0;
    });
    const pdrs_ko = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_ko'] : 0;
    });
    const pdrs_out = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['pdrs_out'] : 0;
    });
    const rid = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['rid'] : 0;
    });
    const otp = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['otp'] : 0;
    });
    const n_caricamenti = computed(() => {
      return filtro.value && filtro.value in report.value ? total_month.value[filtro.value][0]['n_caricamenti'] : 0;
    });
    const delta = computed(()=>{
      return total_pp.value - total_pp_out.value
    })

    const getDataItem = async (evt) => {
      let fd = new FormData();
      let {index} = evt

      if (!items.value[filtro.value][index].loaded) {
        let range = []
        let settimana = [...items.value[filtro.value][index].title.split('-')]
        settimana.forEach(giorno => {
          range.push(`${filtro.value}-${giorno}`)
        })
        fd.append('range', JSON.stringify(range))
        await service.getReportSettimana(fd)
        .then((res)=>{
          items.value[filtro.value][index].content = res.items
          items.value[filtro.value][index].loaded = true
        })
      }
    }
    const setTotaliMese = (prop) => {
      total_month.value[prop] = [{
        pods:0,
        pods_val:0,
        pods_pk:0,
        pods_pk_ko:0,
        pods_pk_ok:0,
        pods_ok:0,
        pods_ko:0,
        pods_out:0,

        pdrs:0,
        pdrs_val:0,
        pdrs_pk:0,
        pdrs_pk_ko:0,
        pdrs_pk_ok:0,
        pdrs_ok:0,
        pdrs_ko:0,
        pdrs_out:0,

        rid:0,
        // total_month:0,
        caricati:0,
        n_caricamenti:0,
        otp:0
      }]
    }
    const stockClass = (data) => {
      return [
      'border-circle w-3rem h-3rem inline-flex font-bold justify-content-center align-items-center text-sm',
      {
        'bg-red-100': data < 1,
        'bg-green-100': data > 1
      }
      ];
    };

    return {
      moment,
      loading,
      items,
      report,
      currentMonth,
      currentYear,
      filtro,
      date,
      maxDate,
      onChangeDate,
      activeIndex,
      getDataItem,
      onChange,
      max,
      // total_month,
      total_pp,
      pods,
      pods_val,
      pods_pk,
      pods_pk_ko,
      pods_pk_ok,
      pods_ok,
      pods_ko,
      pods_out,

      pdrs,
      pdrs_val,
      pdrs_pk,
      pdrs_pk_ko,
      pdrs_pk_ok,
      pdrs_ok,
      pdrs_ko,
      pdrs_out,

      rid,
      // caricati,
      n_caricamenti,
      otp,
      total_pp_out,
      delta,
      stockClass,
    }
  },
}
</script>
<style>
  .bg-red-100{
    background-color: rgba(255, 0, 0, 0.8);
    color:antiquewhite;
  }
</style>

<style scoped>
.btn-filter{
    border-radius: 20px;
    width: 140px;
    height: 75px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }
  .btn-filter:hover {
    opacity: 1;
  }

  .btn-filter.selected {
    opacity: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Anagrafica
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div class="formgrid grid">         
                    <div class="p-field col-12 md:col-6">
                        <label for="ragSoc">Rag. Sociale</label>
                        <InputText id="ragSoc" v-model="ragSoc" :class="['inputfield w-full',{'p-invalid': validationErrors.ragSoc && submitted}]" />
                        <small v-show="validationErrors.ragSoc && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                     <div class="p-field col-12 md:col-6">
                        <label for="codFiscale">Cod. fiscale</label><br>
                        <InputText id="codFiscale" v-model="codFiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.formatCf || validationErrors.codFiscale && submitted}]"/>
                        <!-- <div v-show="validationErrors.codFiscale && submitted" class="p-error">Campo richiesto.</div> -->
                        <div v-show="validationErrors.formatCf && submitted" class="p-error">Formato errato.</div>
                    </div>     
                    <div class="p-field col-12 md:col-6">
                        <label for="phone">Telefono</label><br>
                        <InputText id="phone" v-model="phone" :class="['inputfield w-full',{'p-invalid': validationErrors.phone && submitted}]" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask id="mobile" mask="999-9999999" v-model="mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.mobile && submitted}]" />
                        <small v-show="validationErrors.mobile && submitted" class="p-error">Campo richiesto.</small>
                    </div>               
                    <div class="p-field col-12 md:col-6">
                        <label for="email">Email</label><br>
                        <InputText id="email" v-model="email" :class="['inputfield w-full',{'p-invalid': validationErrors.isEmail && submitted}]" />
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pec">Pec</label><br>
                        <InputText id="pec" v-model="pec" class="inputfield w-full"/>
                    </div>             
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, watchEffect} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';


export default {
    components: {
        Card,
        InputText,
        Button,
        InputMask,
    },
     props: {
        formData: Object
    },
    emits: ["prev-page","next-page","reset-data", ],
    setup (props, {emit}) {
        const ragSoc = ref('')
        const codFiscale = ref('') 
        const phone = ref('') 
        const mobile = ref(null) 
        const email = ref('')
        const pec = ref('')
        const isEmail = ref(false)
        const cf_valid = ref(false)
        const formatCf = ref(false)  
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)


       

        watchEffect(() => {
            ragSoc.value = props.formData.rag_sociale ?? ''
            phone.value = props.formData.phone ?? ''
        })

        const prevPage = () => {
            emit('reset-data');
            emit('prev-page', {pageIndex: 1});
        }

        const nextPage = () => {

            submitted.value = true;
             if (validateForm()) { 
                 emit('next-page', {
                    formData: {
                        rag_sociale: ragSoc.value,
                        codFiscale: codFiscale.value,
                        phone: phone.value,
                        mobile: mobile.value,
                        email: email.value,
                        pec: pec.value
                    },
                        pageIndex: 1
                });    
             }
        }

       


        const validateForm = () => {
            let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            let regex_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i   
    
            if (!ragSoc.value.trim())
                validationErrors.value['ragSoc'] = true;
            else 
                delete validationErrors.value['ragSoc'];
            
            // if (!codFiscale.value.trim())
            //     validationErrors.value['codFiscale'] = true;
            // else 
            //     delete validationErrors.value['codFiscale'];
            
            if (codFiscale.value != '') {
                    if (!regex_cf.test(codFiscale.value)) 
                    validationErrors.value['formatCf'] = true;
                else    
                    delete validationErrors.value['formatCf'];
            }
            
            if (email.value != '') {
                if (regex_email.test(email.value)) {
                    delete validationErrors.value['isEmail'];
                } else {
                    validationErrors.value['isEmail'] = true;
                }
            }
            
              
            return !Object.keys(validationErrors.value).length;
        }

        return {
            ragSoc, phone, mobile, email, validationErrors, submitted, service, prevPage, nextPage, isEmail,
            codFiscale, pec, cf_valid, formatCf
        }

    },
}
</script>

<template>
    <div class="container form-step">
      <Loader v-if="loading" />
        <div class="p-grid p-jc-center">
            <div class="p-col-8">
                <div class="card">
                    <Steps :model="items" :readonly="true" />
                </div>

                <router-view :key="reloadKey" v-slot="{Component}" :formData="formObject"  @activeLoader="activeLoader" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>       
        </div>
    </div>
</template>

<script>

import { onBeforeMount,getCurrentInstance, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import Steps from 'primevue/steps';
import Loader from "@/components/Loader.vue"
import Service from '@/services/ask-pratiche/pratiche-wizard'

export default {
    components: {
        Steps,
        Loader
    },
    setup() {
        onBeforeMount(() => {
            if (id_offerta_clone) {
                formObject.value.offerta_clone = getDettaglioOfferta(id_offerta_clone);
            }else{
                router.push(items.value[0].to);            
            }
        })
        const loading = ref(false)
        const reloadKey = ref(0);
        const formObject = ref({});
        const router = useRouter();
        const route = useRoute()
        const id_offerta_clone = route.params.id
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        const items = ref([
            {
                label: 'Tipologia',
                to: "/offerta/step-tipologia" + (id_offerta_clone ? `/${id_offerta_clone}` : '')
            },
            {
                label: 'Prezzi',
                to: "/offerta/step-prezzi" + (id_offerta_clone ? `/${id_offerta_clone}` : '')
            },
            {
                label: 'Gettone',
                to: "/offerta/step-gettone" + (id_offerta_clone ? `/${id_offerta_clone}` : '')
            },
             {
                label: 'Riepilogo',
                to: "/offerta/step-riepilogo" + (id_offerta_clone ? `/${id_offerta_clone}` : '')
            },
        ]);
        const getDettaglioOfferta = async (id) => {
            if (id) {
                await service.getDettaglioOfferta(id)
                .then((res) => {
                    formObject.value.offerta_clone = res.items
                    router.push(items.value[0].to)
                })
            }else{
                formObject.value.offerta_clone = null
            }
        }

        const nextPage = (event) => {
            for (let field in event.formData) {               
                formObject.value[field] = event.formData[field];
            }
            router.push(items.value[event.pageIndex + 1].to);
        };
        const prevPage = (event) => {
            router.push(items.value[event.pageIndex - 1].to);
        };
        const complete = () => {
            // use for re-render component (clear data)
            reloadKey.value = Date.now();
            router.push(items.value[0].to);              
        };

        const activeLoader = (bool) => {
            loading.value = bool
        }

        return { items, formObject, nextPage, prevPage, complete, reloadKey, activeLoader, loading, id_offerta_clone }
    }
}
</script>

<style>
 
 b {
     display: block;
 }

.form-step .p-card-body {
     padding: 2rem;
 }
 .form-step .p-card {
     box-shadow: none !important;
 }
 
  .form-step .p-field input ,  .form-step .p-dropdown{
     background-color: rgba(219, 219, 219, 0.45);
     border: 1px solid rgba(255, 255, 255, 0);
     height: 44px;
 }
 .form-step .p-field {
     text-align: left;
 }

 .form-step .p-error {
     font-size: 13px;
 }
 
</style>

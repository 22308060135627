<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Dettagli
            </template>
            <template v-slot:subtitle>
               Seleziona i dettagli
            </template>
            <template v-slot:content>
                <div class="formgrid grid">     
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Regime</label>
                        <Dropdown  v-model="selectedRegime" :options="regimi" optionLabel="name" placeholder="Seleziona regime" :class="['inputfield w-full',{'p-invalid': validationErrors.regime && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.regime && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Iva</label>
                        <Dropdown  v-model="selectedIva" :options="ivas" optionLabel="name" placeholder="Seleziona iva" :class="['inputfield w-full',{'p-invalid': validationErrors.iva && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.iva && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Fatturazione</label>
                        <Dropdown  v-model="selectedFatturazione" :options="fatturazioni" optionLabel="name" placeholder="Seleziona fatturazione" :class="['inputfield w-full',{'p-invalid': validationErrors.fatt && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.fatt && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Spedizione</label>
                        <Dropdown  v-model="selectedSpedizione" :options="spedizioni" optionLabel="name" placeholder="Seleziona spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.spedizione && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.spedizione && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="reseller">Scadenza gg</label>
                        <Dropdown  v-model="selectedScadenza" :options="scadenze" optionLabel="name" placeholder="Seleziona scadenza" :class="['inputfield w-full',{'p-invalid': validationErrors.scadenza && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.scadenza && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="reseller">Pagamento</label>
                        <Dropdown v-model="selectedPagamento" :options="pagamenti" optionLabel="name" placeholder="Seleziona pagamento" :class="['inputfield w-full',{'p-invalid': validationErrors.pay && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.pay && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="reseller">Periodo</label>
                        <Dropdown  v-model="selectedPeriodo" :options="periodi" optionLabel="name" placeholder="Seleziona periodo" :class="['inputfield w-full',{'p-invalid': validationErrors.periodo && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.periodo && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                </div>
                <!-- start section only rid -->
                <div v-if="selectedPagamento.id == 3" class="formgrid grid rid mt-3">
                    <div class="p-field col-12">
                        <h4 class="text-center mt-3">DATI RID</h4>
                    </div>
                     <div class="p-field col-12 md:col-6">
                        <label for="iban">Iban</label>
                        <InputText id="iban" type="text" v-model="iban" :class="['inputfield w-full',{'p-invalid': validationErrors.iban || validationErrors.iban_format && submitted}]" />
                        <div v-show="validationErrors.iban && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.iban_format && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="sottoscrittore">Sottoscrittore</label>
                        <InputText id="sottoscrittore" type="text" v-model="sottoscrittore" :class="['inputfield w-full',{'p-invalid': validationErrors.sottoscrittore && submitted}]" />
                        <div v-show="validationErrors.sottoscrittore && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="intestazione">Intestazione</label>
                        <InputText id="intestazione" type="text" v-model="intestazione" :class="['inputfield w-full',{'p-invalid': validationErrors.intestazione && submitted}]" />
                        <div v-show="validationErrors.intestazione && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="istituto">Istituto</label>
                        <InputText id="istituto" type="text" v-model="istituto" :class="['inputfield w-full',{'p-invalid': validationErrors.istituto && submitted}]" />
                        <div v-show="validationErrors.istituto && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="codiceFiscaleCc">CF Intestatario C.C.</label>
                        <InputText id="codiceFiscaleCc" type="text" v-model="codiceFiscaleCc" class="inputfield w-full" />             
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="typeRid">Tipo Rid</label>
                        <Dropdown  v-model="selectedTypeRid" :options="rids"  optionLabel="name"  placeholder="Seleziona tipo rid" :class="['inputfield w-full',{'p-invalid': validationErrors.rid && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.rid && submitted" class="p-error">Seleziona tipo rid.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Data firma</label>
                        <Calendar  v-model="dataFirmaRid" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_rid && submitted}]" />
                        <div v-show="validationErrors.data_rid && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Data prima rata</label>
                        <Calendar  v-model="dataRataRid" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_rata_rid && submitted}]" />
                        <div v-show="validationErrors.data_rata_rid && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                </div>
                <!-- end section only rid -->
                <div class="formgrid grid">     
                    <div class="p-field col-12 mt-2">
                       <label for="note">Note</label>
                       <InputText id="note" type="text" v-model="note" class="inputfield w-full" />             
                    </div>
                </div>                   
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment'
import {ref, getCurrentInstance, onMounted} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';


export default {
    components: {
        Card,
        Button,
        Dropdown,
        InputText,
        Calendar
    },
    props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete"],
    setup (props, {emit}) {
        const selectedRegime = ref('')
        const regimi = ref([])
        const selectedIva = ref('')
        const ivas = ref([])
        const selectedFatturazione = ref('')
        const fatturazioni = ref([])
        const selectedSpedizione = ref('')
        const spedizioni = ref([])
        const selectedScadenza = ref('')
        const scadenze = ref([])
        const selectedPagamento = ref('')
        const pagamenti = ref([])
        const selectedPeriodo = ref('')
        const periodi = ref([])
        const iban = ref('')
        const sottoscrittore = ref('')
        const codiceFiscaleCc = ref('')
        const selectedTypeRid = ref('')
        const rids = ref([])
        const intestazione = ref('')
        const istituto = ref('')
        const dataFirmaRid = ref( moment(new Date()).format("YYYY-MM-DD") ) 
        const dataRataRid = ref( moment(new Date()).format("YYYY-MM-DD") )
        const note = ref('')
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        

        onMounted (()=>{
          const getRegimiPromise = service.getRegimi()
          getRegimiPromise.then((res) => {
              regimi.value = res.items
          })
          const getIvasPromise = service.getIvas()
          getIvasPromise.then((res) => {
              ivas.value = res.items
          })
          const getFatturazioniPromise = service.getFatturazioni()
          getFatturazioniPromise.then((res) => {
              fatturazioni.value = res.items
          })
          const getSpedizioniPromise = service.getSpedizioni()
          getSpedizioniPromise.then((res) => {
              spedizioni.value = res.items
          })
          const getScadenzePromise = service.getScadenze()
          getScadenzePromise.then((res) => {
              scadenze.value = res.items
          })
          const getPagamentiPromise =  service.getPagamenti()
          getPagamentiPromise.then((res) => {
              pagamenti.value = res.items
          })
          const getPeriodiPromise = service.getPeriodi()
          getPeriodiPromise.then((res) => {
              periodi.value = res.items
          })
          const getRidsPromise = service.getRids()
          getRidsPromise.then((res) => {
              rids.value = res.items
          })

          Promise.all([
            getRegimiPromise,
            getIvasPromise,
            getFatturazioniPromise,
            getSpedizioniPromise,
            getScadenzePromise,
            getPagamentiPromise,
            getPeriodiPromise,
            getRidsPromise
          ]).then(() => {
            populateExCtr()
          })
        })

        const populateExCtr = () => { 
          if (props.formData.exCtr != null) {
            selectedRegime.value = (regimi.value.filter(el => props.formData.exCtr[0].cod_type_regime == el.id ))[0]
            selectedIva.value = (ivas.value.filter(el => props.formData.exCtr[0].cod_type_iva == el.id ))[0]
            selectedFatturazione.value = (fatturazioni.value.filter(el => props.formData.exCtr[0].cod_type_fatturazione == el.id ))[0]
            selectedSpedizione.value = (spedizioni.value.filter(el => props.formData.exCtr[0].cod_type_spedizione == el.id ))[0]
            selectedScadenza.value = (scadenze.value.filter(el => props.formData.exCtr[0].cod_type_scadenza == el.id ))[0]
            selectedPagamento.value = (pagamenti.value.filter(el => props.formData.exCtr[0].cod_type_pay == el.id ))[0]
            selectedPeriodo.value = (periodi.value.filter(el => props.formData.exCtr[0].cod_type_periodo == el.id ))[0]
            note.value = props.formData.exCtr[0].note
            if (selectedPagamento.value.id == 3) {
                iban.value = props.formData.exCtr[0].iban
                sottoscrittore.value = props.formData.exCtr[0].sottoscrittore
                intestazione.value = props.formData.exCtr[0].intestazione
                istituto.value = props.formData.exCtr[0].istituto
                codiceFiscaleCc.value = props.formData.exCtr[0].cfintestazione
                selectedTypeRid.value = (rids.value.filter(el => props.formData.exCtr[0].cod_type_rid == el.id ))[0]
                dataFirmaRid.value = moment(new Date()).format("YYYY-MM-DD")
                dataRataRid.value = moment(new Date()).format("YYYY-MM-DD")
            }
          } 
        }

        const prevPage = () => {
            emit('prev-page', {pageIndex: 2});
        }
      
        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
               emit('next-page', {
                    formData: {
                       regime: selectedRegime.value,
                       iva: selectedIva.value,
                       fatturazione: selectedFatturazione.value,
                       spedizione: selectedSpedizione.value,
                       scadenza: selectedScadenza.value,
                       pagamento: selectedPagamento.value,
                       periodo: selectedPeriodo.value,
                       iban: iban.value.replaceAll(' ',''),
                       sottoscrittore: sottoscrittore.value,
                       codiceFiscaleCc: codiceFiscaleCc.value,
                       intestazione: intestazione.value,
                       istituto: istituto.value,
                       rid: selectedTypeRid.value,
                       dataFirmaRid: dataFirmaRid.value,
                       dataRataRid: dataRataRid.value,
                       note: note.value
                    },
                    pageIndex: 2
                });
            }                  
        }

        

        const validateForm = () => {
          
            if (selectedRegime.value == '')
                validationErrors.value['regime'] = true;
            else 
                delete validationErrors.value['regime'];

            if (selectedIva.value == '')
                validationErrors.value['iva'] = true;
            else 
                delete validationErrors.value['iva'];
            
            if (selectedFatturazione.value == '')
                validationErrors.value['fatt'] = true;
            else 
                delete validationErrors.value['fatt'];

             if (selectedFatturazione.value == '')
                validationErrors.value['spedizione'] = true;
            else 
                delete validationErrors.value['spedizione'];

            if (selectedScadenza.value == '')
                validationErrors.value['scadenza'] = true;
            else 
                delete validationErrors.value['scadenza'];
            
            if (selectedPagamento.value == '')
                validationErrors.value['pay'] = true;
            else 
                delete validationErrors.value['pay'];
            
            if (selectedPeriodo.value == '')
                validationErrors.value['periodo'] = true;
            else 
                delete validationErrors.value['periodo'];
            
            if (selectedPagamento.value.id == 3) {

              let regexIban = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/gm
              if (iban.value == '')
                  validationErrors.value['iban'] = true;
              else 
                  delete validationErrors.value['iban'];
              
              if (!regexIban.test(iban.value))
                  validationErrors.value['iban_format'] = true;
              else 
                  delete validationErrors.value['iban_format'];

              
              if (selectedTypeRid.value == '')
                  validationErrors.value['rid'] = true;
              else 
                  delete validationErrors.value['rid'];
              
              if (dataFirmaRid.value == '')
                  validationErrors.value['data_rid'] = true;
              else 
                  delete validationErrors.value['data_rid'];
              
              if (dataRataRid.value == '')
                  validationErrors.value['data_rata_rid'] = true;
              else 
                  delete validationErrors.value['data_rata_rid'];

            } else {
                delete validationErrors.value['iban'];
                delete validationErrors.value['rid'];
                delete validationErrors.value['data_rid'];
                delete validationErrors.value['data_rata_rid'];
                iban.value = ''
                sottoscrittore.value = ''
                codiceFiscaleCc.value = ''
                selectedTypeRid.value = ''
                intestazione.value = ''
                istituto.value = ''
                dataFirmaRid.value = ''
                dataRataRid.value = ''
            }
                                  
            return !Object.keys(validationErrors.value).length;
        }

        return {
           selectedRegime, regimi, validationErrors, submitted, service, nextPage, prevPage, selectedIva, ivas,
           selectedFatturazione, fatturazioni, selectedSpedizione, spedizioni, selectedScadenza, scadenze,
           selectedPagamento, pagamenti, selectedPeriodo, periodi, iban, sottoscrittore,
           codiceFiscaleCc, intestazione, istituto, dataFirmaRid, dataRataRid, note, selectedTypeRid, rids
        }

    },
}
</script>
<style scoped>
.rid {
    border: 1px solid rgba(51, 51, 51, 0.068);
}
#iban {
    text-transform: uppercase;
}
</style>

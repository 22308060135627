import Emitter from 'pico-emitter';

const installEventBus = (app) => {
  app.config.globalProperties.$eventBus = new Emitter()
  return app
}

export {
  installEventBus
}

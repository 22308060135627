<template>
<Loader v-if="loading" />
  <h4>Lista delle bozze</h4><br/>

  <div  v-if="wizards && Object.keys(wizards).length > 0">
    <div class="card" v-for="(wizard, nameWizard) in wizards" :key="wizard.id">
      <h5>{{nameWizard.toUpperCase()}}</h5>
      <DataTable :value="wizard" responsiveLayout="scroll" sortField="id" :sortOrder="-1">
          <Column field='id' header="#" :sortable="true">
           <template #body="bozza">
             <span class="num">#{{bozza.data.id}}00</span>
           </template>
          </Column>
          <Column field="data.step" header="STEP"></Column>
          <Column field="date" header="DATA MODIFICA" :sortable="true">
            <template #body="bozza">
              {{reverseDate(bozza.data.date.split(" ")[0])}}
            </template>
          </Column>
          <Column field="date" header="ORA MODIFICA" :sortable="true">
            <template #body="bozza">
              {{bozza.data.date.split(" ")[1]}}
            </template>
          </Column>
          <Column header="AZIONI">
            <template #body="bozza">
                <router-link :to="`/agenti/inserimento?restore=${convertBase64(JSON.stringify(bozza.data))}`" custom v-slot="{ navigate }">
                    <Button @click="navigate" type="button" icon="pi pi-pencil" class="p-button-warning"></Button>
                </router-link>
                <Button @click="deleteBozza(bozza.data.id)" type="button" icon="pi pi-trash" class="ml-3 p-button-danger"></Button>
            </template>
          </Column>
      </DataTable>
    </div>
  </div>
  <div v-else>
    <div class="card text-center">
      <div v-if="loaded">
        <Skeleton class="mb-2"></Skeleton>
        <Skeleton width="10rem" class="mb-2"></Skeleton>
        <Skeleton width="5rem" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
      <p v-else>Nessuna bozza presente</p>
    </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
import {ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex'
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'
import Loader from "@/components/Loader.vue"
   
export default {
  components: {
    Loader
  },
  data() {
    return {
      pageName: 'Bozze',
      reloadKey: 0,
    }
  },
  
  setup (props, {emit}) { 
    const store = useStore()
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)
    const loading = ref(false)
    const currentUser = ref(store.state.auth.user)
    const wizards = ref();
    const loaded = ref(true)

    onMounted(() => { 
      getAllBozze();
    })

    const getAllBozze = () => {
      praticheWizardBozzeService.getAll(currentUser.value.id, currentUser.value.role, "agenti/inserimento").then((res) => {
        wizards.value = res.item
      }).finally(()=>{
        loaded.value = false
      })
    }
    const convertBase64 = (str) => {
      return btoa(str)
    }

    const reverseDate = (data) => {
          return moment(data).format("DD-MM-YYYY")
      }

    const deleteBozza = (id) => {
      loading.value = true
      praticheWizardBozzeService.delete(id).then((res) => {
        if (res.success) {
          getAllBozze()
        }
      }).finally(() =>{
         loading.value = false
      })
    }


    return {
      wizards,
      convertBase64,
      deleteBozza,
      loading,
      loaded,
      reverseDate
    }
  },
  
  beforeUpdate () {
    // fix: force reload component when double click on sidebar empty component
    if (this.$route.fullPath == '/agenti/inserimento') {
      this.reloadKey = Date.now() 
    }
  },
  created(){
    this.$eventBus.emit('page-change', {
      target: this,
      currentRoute: this.$router.currentRoute.value
    })

    this.$eventBus.emit('main-menu-change', {
      target: this,
      menu: this.menu
    })
  }

}
</script>

<style scoped>
.num{
  font-size: 9px;
  color: rgb(0, 140, 255);
}
</style>

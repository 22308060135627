<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Anagrafica
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div  v-if="formData.tipo_client_name != 'Domestico'" class="formgrid grid">
                    <div class="p-field col-12 md:col-6">
                        <label for="rag_sociale">Rag. Sociale</label><br>
                        <InputText id="rag_sociale" v-model="rag_sociale" :class="[{'p-invalid': validationErrors.rag_sociale && submitted},'inputfield w-full']"/>
                        <small v-show="validationErrors.rag_sociale && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="cod_fiscale_azienda">Codice fiscale</label><br>
                        <InputText id="cod_fiscale_azienda" v-model="cod_fiscale_azienda" :class="[{'p-invalid': validationErrors.format && submitted},'inputfield w-full']"/>
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pec">Pec</label><br>
                        <InputText id="pec" v-model="pec" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="score">Score</label><br>
                        <InputText id="score" v-model="score" class="inputfield w-full" />
                    </div>
                </div> 
                <div  v-else class="formgrid grid"> 
                    <div class="p-field col-12 md:col-6">
                        <label for="name">Nome</label><br>
                        <InputText id="name" v-model="name" :class="['inputfield w-full',{'p-invalid': validationErrors.name && submitted}]" />
                        <small v-show="validationErrors.name && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="surname">Cognome</label><br>
                        <InputText id="surname" v-model="surname" :class="['inputfield w-full',{'p-invalid': validationErrors.surname && submitted}]" />
                        <small v-show="validationErrors.surname && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div>  
                <div class="formgrid grid"> 
                    <div class="p-field col-12 md:col-6">
                        <label for="phone">Telefono</label><br>
                        <InputText id="phone" v-model="phone" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask id="mobile" mask="999-9999999" v-model="mobile" class="inputfield w-full" />
                    </div>
                    <div class="p-field col-12">
                        <label for="email">Email</label><br>
                        <InputText id="email" v-model="email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" />
                        <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance,watchEffect} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import validateCodFiscale from '@/validate/validate-cf'

export default {
    components: {
        Card,
        InputText,
        Button,
        InputMask
    },
     props: {
        formData: Object
    },
    setup (props, {emit}) {
        const name = ref('')
        const surname = ref('') 
        const rag_sociale = ref('')
        const cod_fiscale_azienda = ref('') 
        const phone = ref('') 
        const mobile = ref(null) 
        const email = ref('')
        const pec = ref('')
        const score = ref('')
        const isEmail = ref(false)
        const format = ref(false)  
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)


        watchEffect(() => {
            rag_sociale.value = props.formData.rag_sociale ?? ''
            phone.value = props.formData.phone ?? ''
            mobile.value = props.formData.mobile ?? ''
        })
        

        const prevPage = () => {
            emit('prev-page', {pageIndex: 2});
        }

        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
                emit('next-page', {
                    formData: {
                        name: name.value,
                        surname: surname.value,
                        rag_sociale: rag_sociale.value,
                        cod_fiscale_azienda: cod_fiscale_azienda.value,
                        phone: phone.value,
                        mobile: mobile.value,
                        email: email.value,
                        pec: pec.value,
                        score: score.value
                    },
                     pageIndex: 2
                });
            }
        }

        const validateForm = () => {
            let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
           
            if (props.formData.tipo_client_name == 'Domestico') {

                if (!name.value.trim())
                    validationErrors.value['name'] = true;
                else 
                    delete validationErrors.value['name'];
                         
                if (!surname.value.trim())
                    validationErrors.value['surname'] = true;
                else
                    delete validationErrors.value['surname'];
            } else {

                if (!rag_sociale.value.trim())
                    validationErrors.value['rag_sociale'] = true;
                else 
                    delete validationErrors.value['rag_sociale'];
                
                if (cod_fiscale_azienda.value != '') {
                    if ( validateCodFiscale(cod_fiscale_azienda.value)) 
                        delete validationErrors.value['format'];
                    else    
                        validationErrors.value['format'] = true;
                } else {
                     delete validationErrors.value['format'];
                }
                           
            }
           

          
            if (!email.value.trim()) {
                validationErrors.value['email'] = true;
            }else {
             
                delete validationErrors.value['email'];
            }

            if (regex_email.test(email.value)) {
                delete validationErrors.value['isEmail'];
            } else {
                validationErrors.value['isEmail'] = true;
            }
              
            return !Object.keys(validationErrors.value).length;
        }

        return {
            name, surname, phone, mobile, email, validationErrors, submitted, service, prevPage, nextPage, isEmail,
            rag_sociale, cod_fiscale_azienda, pec, score, format
        }

    },
}
</script>

<template>

    <div class="stepsdemo-content">
        <Card>
          <template v-slot:title>
              Contratto
          </template>

          <template v-slot:subtitle>
              Inserisci i dati del contratto
          </template> 

          <template v-slot:content>
            <div class="formgrid grid">     
              <div class="p-field col-12">
                  <label for="tipologia">Tipologia</label>
                  <Dropdown  v-model="store.state.all.data.contratto.tipoCtr" :options="tipiCtr" optionLabel="name"   placeholder="Seleziona tipologia contratto" :class="['inputfield w-full',{'p-invalid': validationErrors.tipologia && submitted}]">
                      <template #value="slotProps">
                          <div v-if="slotProps.value">
                              <div>{{slotProps.value.name}}</div>
                          </div>
                          <span v-else>
                              {{slotProps.placeholder}}
                          </span>
                      </template>
                      <template #option="slotProps">
                          <div>
                              <div>{{slotProps.option.name}}</div>
                          </div>
                      </template>
                  </Dropdown>
                  <div v-show="validationErrors.tipologia && submitted" class="p-error">Seleziona una tipologia.</div>
              </div>
                
              <div class="p-field  col-12 md:col-4">
                <label for="data_firma">Data Firma</label>
                <Calendar  v-model="store.state.all.data.contratto.dataFirma" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_firma && submitted}]" /> <!-- @date-select="selectDateFirma" -->
                <div v-show="validationErrors.data_firma && submitted" class="p-error">Valore non valido</div>
              </div>

              <div class="p-field  col-12 md:col-4">
                <label for="data_inizio">Data Inizio</label>
                <Calendar  v-model="store.state.all.data.contratto.dataInizio" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_inizio || validationErrors.data_inizio_err && submitted}]" />
                <div v-show="validationErrors.data_inizio && submitted" class="p-error">Valore non valido</div>
                <div v-show="validationErrors.data_inizio_err && submitted" class="p-error">Data inizio non può essere successiva alla data fine</div>
              </div>

              <div class="p-field  col-12 md:col-4">
                <label for="data_fine">Data Fine</label>
                <Calendar v-model="store.state.all.data.contratto.dataFine" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_fine && submitted}]" :disabled="currentUser.role == roles.agent.role" />
                <div v-show="validationErrors.data_fine && submitted" class="p-error">Valore non valido</div>
              </div>

              <div class="p-field col-12">
                <label for="offerta">Offerta</label>
                <Dropdown  v-model="store.state.all.data.contratto.offerta" :options="offerte"  :filter="true"  optionLabel="name"  placeholder="Seleziona offerta" :class="['inputfield w-full',{'p-invalid': validationErrors.offerta && submitted}]">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <div>{{slotProps.value.name}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.name}} <small>(dal {{slotProps.option.data_inizio}} al {{slotProps.option.data_fine}})</small></div>
                        </div>
                    </template>
                </Dropdown>
                <div v-show="validationErrors.offerta && submitted" class="p-error">Seleziona un'offerta.</div>
              </div>
              
              <div class="p-field col-12">
                <label for="reseller">Acquisizione</label>
                <Dropdown  v-model="store.state.all.data.contratto.acquisizione" :options="acquisizioni" optionLabel="name" placeholder="Seleziona acquisizione" :class="['inputfield w-full',{'p-invalid': validationErrors.acquisizione && submitted}]">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <div>{{slotProps.value.name}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </Dropdown>
                <div v-show="validationErrors.acquisizione && submitted" class="p-error">Seleziona tipo acquisizione.</div>
              </div>

              <div class="field-checkbox md:col-6">
                <Checkbox id="rinnovo" v-model="rinnovo" :binary="true" />
                <!-- <Checkbox id="rinnovo" v-model="store.state.all.data.contratto.rinnovo" :binary="true" /> -->

                <label for="rinnovo">Rinnovo automatico</label>
              </div>

              <div class="field-checkbox md:col-6">
                <Checkbox id="documentazione" v-model="documentazione" :binary="true" />
                <!-- <Checkbox id="documentazione" v-model="store.state.all.data.contratto.documentazione" :binary="true" /> -->

                <label for="documentazione">Documentazione fornita in originale</label>
              </div>                
            </div>
                
            <div class="formgrid grid">     
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Regime</label>
                        <Dropdown  v-model="store.state.all.data.contratto.regime" :options="regimi" optionLabel="name" placeholder="Seleziona regime" :class="['inputfield w-full',{'p-invalid': validationErrors.regime && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.regime && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Iva</label>
                        <Dropdown  v-model="store.state.all.data.contratto.iva" :options="ivas" optionLabel="name" placeholder="Seleziona iva" :class="['inputfield w-full',{'p-invalid': validationErrors.iva && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.iva && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="reseller">Fatturazione</label>
                        <Dropdown  v-model="store.state.all.data.contratto.fatturazione" :options="fatturazioni" optionLabel="name" placeholder="Seleziona fatturazione" :class="['inputfield w-full',{'p-invalid': validationErrors.fatt && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.fatt && submitted" class="p-error">Campo richiesto.</div>
                    </div>

              <div class="p-field col-12 md:col-6">
                <label for="reseller">Spedizione</label>
                <Dropdown  v-model="store.state.all.data.contratto.spedizione" :options="spedizioni" optionLabel="name" placeholder="Seleziona spedizione" :class="['inputfield w-full',{'p-invalid': validationErrors.spedizione && submitted}]">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <div>{{slotProps.value.name}}</div>
                    </div>
                    <span v-else>
                      {{slotProps.placeholder}}
                    </span>
                  </template>

                  <template #option="slotProps">
                    <div>
                      <div>{{slotProps.option.name}}</div>
                    </div>
                  </template>
                </Dropdown>
                <div v-show="validationErrors.spedizione && submitted" class="p-error">Campo richiesto.</div>
              </div>

              <div class="p-field col-12 md:col-4">
                <label for="reseller">Scadenza gg</label>
                <Dropdown disabled v-model="store.state.all.data.contratto.scadenza" :options="scadenze" optionLabel="name" placeholder="Seleziona scadenza" :class="['inputfield w-full',{'p-invalid': validationErrors.scadenza && submitted}]">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <div>{{slotProps.value.name}}</div>
                    </div>
                    <span v-else>
                      {{slotProps.placeholder}}
                    </span>
                  </template>

                  <template #option="slotProps">
                    <div>
                      <div>{{slotProps.option.name}}</div>
                    </div>
                  </template>
                </Dropdown>
                <div v-show="validationErrors.scadenza && submitted" class="p-error">Campo richiesto.</div>
              </div>

              <div class="p-field col-12 md:col-4">
                <label for="reseller">Pagamento</label>
                <Dropdown v-model="store.state.all.data.contratto.pagamento" :options="pagamenti" optionLabel="name" placeholder="Seleziona pagamento" :class="['inputfield w-full',{'p-invalid': validationErrors.pay && submitted}]">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <div>{{slotProps.value.name}}</div>
                    </div>
                    <span v-else>
                      {{slotProps.placeholder}}
                    </span>
                  </template>

                  <template #option="slotProps">
                    <div>
                      <div>{{slotProps.option.name}}</div>
                    </div>
                  </template>
                </Dropdown>
                <div v-show="validationErrors.pay && submitted" class="p-error">Campo richiesto.</div>
              </div>

              <div class="p-field col-12 md:col-4">
                <label for="reseller">Periodo</label>
                <Dropdown  v-model="store.state.all.data.contratto.periodo" :options="periodi" optionLabel="name" placeholder="Seleziona periodo" :class="['inputfield w-full',{'p-invalid': validationErrors.periodo && submitted}]">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <div>{{slotProps.value.name}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </Dropdown>
                <div v-show="validationErrors.periodo && submitted" class="p-error">Campo richiesto.</div>
              </div>
            </div>
                <!-- start section only rid -->
                <div v-if="store.state.all.data.contratto.pagamento.id == 3" class="formgrid grid rid mt-3">
                    <div class="p-field col-12">
                        <h4 class="text-center mt-3">DATI RID</h4>
                    </div>
                     <div class="p-field col-12 md:col-6">
                        <label for="iban">Iban</label>
                        <InputText id="iban" type="text" v-model="store.state.all.data.contratto.rid.iban" :class="['inputfield w-full',{'p-invalid': validationErrors.iban || validationErrors.iban_format && submitted}]" />
                        <div v-show="validationErrors.iban && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.iban_format && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="sottoscrittore">Sottoscrittore</label>
                        <InputText id="sottoscrittore" type="text" v-model="store.state.all.data.contratto.rid.sottoscrittore" :class="['inputfield w-full',{'p-invalid': validationErrors.sottoscrittore && submitted}]" />
                        <div v-show="validationErrors.sottoscrittore && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="intestazione">Intestazione</label>
                        <InputText id="intestazione" type="text" v-model="store.state.all.data.contratto.rid.intestazione" :class="['inputfield w-full',{'p-invalid': validationErrors.intestazione && submitted}]" />
                        <div v-show="validationErrors.intestazione && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="istituto">Istituto</label>
                        <InputText id="istituto" type="text" v-model="store.state.all.data.contratto.rid.istituto" :class="['inputfield w-full',{'p-invalid': validationErrors.istituto && submitted}]" />
                        <div v-show="validationErrors.istituto && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="codiceFiscaleCc">CF Intestatario C.C.</label>
                        <InputText id="codiceFiscaleCc" type="text" v-model="store.state.all.data.contratto.rid.codiceFiscaleCc" :class="['inputfield w-full',{'p-invalid': validationErrors.cf_cc || validationErrors.cf_cc_format && submitted}]" />
                         <div v-show="validationErrors.cf_cc && submitted" class="p-error">Campo richiesto.</div>            
                         <div v-show="validationErrors.cf_cc_format && submitted" class="p-error">Formato errato.</div>            
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="typeRid">Tipo Rid</label>
                        <Dropdown  v-model="store.state.all.data.contratto.rid.selectedTypeRid" :options="rids"  optionLabel="name"  placeholder="Seleziona tipo rid" :class="['inputfield w-full',{'p-invalid': validationErrors.rid && submitted}]">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.rid && submitted" class="p-error">Seleziona tipo rid.</div>
                    </div>
                    
                    <!-- <div class="p-field col-12 md:col-6">
                        <label>Data firma</label>
                        <Calendar  v-model="store.state.all.data.contratto.rid.dataFirmaRid" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_rid && submitted}]" />
                        <div v-show="validationErrors.data_rid && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label>Data prima rata</label>
                        <Calendar  v-model="store.state.all.data.contratto.rid.dataRataRid" dateFormat="dd-mm-yy" :class="['inputfield w-full',{'p-invalid': validationErrors.data_rata_rid && submitted}]" />
                        <div v-show="validationErrors.data_rata_rid && submitted" class="p-error">Campo richiesto.</div>
                    </div> -->
                </div>
                <!-- end section only rid -->
                <div class="formgrid grid">     
                    <div class="p-field col-12 mt-2">
                       <label for="note">Note</label>
                       <InputText id="note" type="text" v-model="store.state.all.data.contratto.note" class="inputfield w-full" />             
                    </div>
                </div>                   
            
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                  <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                  <i></i>
                  <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment'

import {ref, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex'

import Service from '@/services/ask-pratiche/pratiche-wizard'
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'

import Card from 'primevue/card';
import Button from 'primevue/button';
import roles from '@/config/roles'
// import Dropdown from 'primevue/dropdown';
// import { useToast } from 'primevue/usetoast';

export default {
  components: {
      Card,
      Button,
      // Dropdown,
  },
    
  props: {
    formData: Object
  },

  setup (props, {emit}) {
    const store = useStore()
    // const toast = useToast();
    const currentUser = ref(store.state.auth.user)

    const stepIndex = 2
    const tipiCtr = ref([])
    const offerte = ref([])
    const acquisizioni = ref([])
    const regimi = ref([])
    const ivas = ref([])
    const fatturazioni = ref([])
    const spedizioni = ref([])
    const scadenze = ref([])
    const pagamenti = ref([])
    const periodi = ref([])
    
    const selectedTipoCtr = ref('')
    const offerta = ref(0)
    const acquisizione = ref('')
    const regime = ref('')
    const iva = ref('')
    const fatturazione = ref('')
    const spedizione = ref('')
    const scadenza = ref('')
    const pagamento = ref('')
    const periodo = ref('')
    
    const dataFirma = ref(new Date()) 
    const dataInizio = ref(new Date())
    const dataFine = ref(new Date('2099-12-31'))

    const rinnovo = ref(true)
    const documentazione = ref(true)

    const note = ref('')

    
    const rids = ref([])
    const selectedTypeRid = ref('')

    const iban = ref('')
    const sottoscrittore = ref(store.state.all.data.cliente.data.labelName)
    const intestazione = ref(store.state.all.data.cliente.data.labelName)
    const codiceFiscaleCc = ref(store.state.all.data.cliente.data.codfis)
    
    const istituto = ref('')
    const dataFirmaRid = ref( moment(new Date()).format("YYYY-MM-DD") ) 
    const dataRataRid = ref( moment(new Date()).format("YYYY-MM-DD") )

    const validationErrors = ref({})
    const submitted = ref(false)

    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)

 
    onMounted(() => { 
      store.state.all.data.contratto.dataFine = moment(new Date('2099-12-31')).format("YYYY-MM-DD")
      store.state.all.data.contratto.dataInizio = moment(new Date()).format("YYYY-MM-DD")
      store.state.all.data.contratto.dataFirma = moment(new Date()).format("YYYY-MM-DD")
      store.state.all.data.contratto.rid.sottoscrittore = store.state.all.data.cliente.data.labelName
      store.state.all.data.contratto.rid.intestazione = store.state.all.data.cliente.data.labelName
      store.state.all.data.contratto.rid.codiceFiscaleCc = store.state.all.data.cliente.data.codfis ?? store.state.all.data.cliente.data.cod_fiscale



      const apiPromises = [
        service.getTipiCtr().then((res) => {
            tipiCtr.value = res.items
            store.state.all.data.contratto.tipoCtr = res.items[0];
        }),
        service.getIbanClient(store.state.all.data.cliente.data.id).then((res) => {
          store.state.all.data.contratto.rid.iban = res.items.iban
          store.state.all.data.contratto.rid.istituto = res.items.istituto
        }),

        service.getOfferte().then((res) => {
            offerte.value = res.items
            store.state.all.data.contratto.offerta = res.items[0]
        }),

        service.getRegimi().then((res) => {
            regimi.value = res.items
            store.state.all.data.contratto.regime = res.items[0]
        }),

        service.getIvas().then((res) => {
            ivas.value = res.items
            store.state.all.data.contratto.iva = res.items[1]
        }),

        service.getFatturazioni().then((res) => {
            fatturazioni.value = res.items
            store.state.all.data.contratto.fatturazione = res.items[0]
        }),

        service.getSpedizioni().then((res) => {
            spedizioni.value = res.items
            store.state.all.data.contratto.spedizione = res.items[0]
        }),

        service.getScadenze().then((res) => {
            scadenze.value = res.items
            store.state.all.data.contratto.scadenza = res.items[0]
        }),

        service.getPagamenti().then((res) => {
            pagamenti.value = res.items
            store.state.all.data.contratto.pagamento = res.items[2]
        }),

        service.getPeriodi().then((res) => {
            periodi.value = res.items
            store.state.all.data.contratto.periodo = res.items[0]
        }),

        service.getAcquisizioni().then((res) => {
            acquisizioni.value = res.items
            store.state.all.data.contratto.acquisizione = res.items[0]
        }),

        service.getRids().then((res) => {
          if (currentUser.value.role == roles.agent.role) {
            rids.value = [{id: 4, name: "CORE",tipo_mandato: "CORE"},{id: 3, name: "B2B",tipo_mandato: "B2B"}]
          } else {
            rids.value = res.items
          }
          store.state.all.data.contratto.rid.selectedTypeRid = res.items[0]
        })
      ]

    emit('activeLoader', true)

    Promise.all(apiPromises)
        .finally(() => {
          emit('activeLoader', false)
        });
    })


    const validateForm = () => {
      
      if (store.state.all.data.contratto.tipoCtr == '' || Object.keys(store.state.all.data.contratto.tipoCtr).length === 0)
          validationErrors.value['tipologia'] = true; 
      else 
         delete validationErrors.value['tipologia'];

      if (store.state.all.data.contratto.offerta == '' || Object.keys(store.state.all.data.contratto.offerta).length === 0)
          validationErrors.value['offerta'] = true;
      else 
          delete validationErrors.value['offerta'];

      if (store.state.all.data.contratto.acquisizione == '' || Object.keys(store.state.all.data.contratto.acquisizione).length === 0)
          validationErrors.value['acquisizione'] = true;
      else 
          delete validationErrors.value['acquisizione'];

      if (store.state.all.data.contratto.dataFirma == null || store.state.all.data.contratto.dataFirma == '')
          validationErrors.value['data_firma'] = true;
      else 
          delete validationErrors.value['data_firma'];
      
      
      if (store.state.all.data.contratto.dataInizio == null || store.state.all.data.contratto.dataInizio == ''){
          validationErrors.value['data_inizio'] = true;
      }else{ 
          delete validationErrors.value['data_inizio'];
          if (store.state.all.data.contratto.dataFine != null || store.state.all.data.contratto.dataFine == ''){
            if(moment(store.state.all.data.contratto.dataInizio).isBefore(moment(store.state.all.data.contratto.dataFine))){
              delete validationErrors.value['data_inizio_err'];
            } else {
              validationErrors.value['data_inizio_err'] = true;
            }
          }
      }

      if (store.state.all.data.contratto.dataFine == null || store.state.all.data.contratto.dataFine == '')
          validationErrors.value['data_fine'] = true;
      else 
          delete validationErrors.value['data_fine'];

      if (store.state.all.data.contratto.regime == '' || Object.keys(store.state.all.data.contratto.regime).length === 0 )
          validationErrors.value['regime'] = true;
      else 
          delete validationErrors.value['regime'];

      if (store.state.all.data.contratto.iva == '' || Object.keys(store.state.all.data.contratto.iva).length === 0)
          validationErrors.value['iva'] = true;
      else 
          delete validationErrors.value['iva'];
      
      if (store.state.all.data.contratto.fatturazione == '' || Object.keys(store.state.all.data.contratto.fatturazione).length === 0)
          validationErrors.value['fatt'] = true;
      else 
          delete validationErrors.value['fatt'];

      if (store.state.all.data.contratto.spedizione == '' || Object.keys(store.state.all.data.contratto.spedizione).length === 0)
        validationErrors.value['spedizione'] = true;
      else 
        delete validationErrors.value['spedizione'];

      if (store.state.all.data.contratto.scadenza == '' || Object.keys(store.state.all.data.contratto.scadenza).length === 0)
        validationErrors.value['scadenza'] = true;
      else 
        delete validationErrors.value['scadenza'];
        
      if (store.state.all.data.contratto.pagamento == '' || Object.keys(store.state.all.data.contratto.pagamento).length === 0)
        validationErrors.value['pay'] = true;
      else 
        delete validationErrors.value['pay'];
            
      if (store.state.all.data.contratto.periodo == '' || Object.keys(store.state.all.data.contratto.periodo).length === 0)
        validationErrors.value['periodo'] = true;
      else 
        delete validationErrors.value['periodo'];
            
      if (store.state.all.data.contratto.pagamento.id == 3) {

        let regexIban = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/gm
        if (store.state.all.data.contratto.rid.iban == '' || store.state.all.data.contratto.rid.iban == null)
            validationErrors.value['iban'] = true;
        else 
            delete validationErrors.value['iban'];

        if (store.state.all.data.contratto.rid.iban != null) {
          if (!regexIban.test(store.state.all.data.contratto.rid.iban))
            validationErrors.value['iban_format'] = true;
          else 
            delete validationErrors.value['iban_format'];
        }

        if (store.state.all.data.contratto.rid.sottoscrittore == null || store.state.all.data.contratto.rid.sottoscrittore == '')
            validationErrors.value['sottoscrittore'] = true;
        else 
            delete validationErrors.value['sottoscrittore'];

        if (store.state.all.data.contratto.rid.intestazione == null || store.state.all.data.contratto.rid.intestazione == '')
            validationErrors.value['intestazione'] = true;
        else 
            delete validationErrors.value['intestazione'];

        if (store.state.all.data.contratto.rid.istituto == null || store.state.all.data.contratto.rid.istituto == '')
            validationErrors.value['istituto'] = true;
        else 
            delete validationErrors.value['istituto'];

        let regCf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
        if (store.state.all.data.contratto.rid.codiceFiscaleCc == null || store.state.all.data.contratto.rid.codiceFiscaleCc == ''){
            validationErrors.value['cf_cc'] = true;
        }else{
            delete validationErrors.value['cf_cc'];
            validationErrors.value['cf_cc_format'] = true;
            if(/^[a-zA-Z]/i.test(store.state.all.data.contratto.rid.codiceFiscaleCc)) {
              if (regCf.test(store.state.all.data.contratto.rid.codiceFiscaleCc)) {
                delete validationErrors.value['cf_cc_format'];
              }
            }
            if(/^[0-9]/i.test(store.state.all.data.contratto.rid.codiceFiscaleCc) && store.state.all.data.contratto.rid.codiceFiscaleCc.length == 11) {
              if(/^\d+$/.test(store.state.all.data.contratto.rid.codiceFiscaleCc)){
                delete validationErrors.value['cf_cc_format'];
              }
               
            }
        }
  
        if (store.state.all.data.contratto.rid.selectedTypeRid == null || store.state.all.data.contratto.rid.selectedTypeRid == '' || Object.keys(store.state.all.data.contratto.rid.selectedTypeRid).length === 0)
            validationErrors.value['rid'] = true;
        else 
            delete validationErrors.value['rid'];
        
        // if (store.state.all.data.contratto.rid.dataFirmaRid == null || store.state.all.data.contratto.rid.dataFirmaRid == '')
        //     validationErrors.value['data_rid'] = true;
        // else 
        //     delete validationErrors.value['data_rid'];
        
        // if (store.state.all.data.contratto.rid.dataRataRid == null || store.state.all.data.contratto.rid.dataRataRid == '')
        //     validationErrors.value['data_rata_rid'] = true;
        // else 
        //     delete validationErrors.value['data_rata_rid'];

      } else {
        delete validationErrors.value['iban'];
        delete validationErrors.value['iban_format'];
        delete validationErrors.value['sottoscrittore'];
        delete validationErrors.value['intestazione'];
        delete validationErrors.value['istituto'];
        delete validationErrors.value['cf_cc'];
        delete validationErrors.value['cf_cc_format'];
        delete validationErrors.value['rid'];
        // delete validationErrors.value['data_rid'];
        // delete validationErrors.value['data_rata_rid'];
        // store.state.all.data.contratto.rid.iban = ''
        // store.state.all.data.contratto.rid.sottoscrittore = ''
        // store.state.all.data.contratto.rid.codiceFiscaleCc = ''
        // store.state.all.data.contratto.rid.selectedTypeRid = ''
        // store.state.all.data.contratto.rid.intestazione = ''
        // store.state.all.data.contratto.rid.istituto = ''
        // store.state.all.data.contratto.rid.dataFirmaRid = ''
        // store.state.all.data.contratto.rid.dataRataRid = ''
      }
                                  
      return !Object.keys(validationErrors.value).length;
    }

    const nextPage = () => {
      submitted.value = true;
      if (validateForm()) {

        store.state.all.data.realod = true
        store.state.all.data.step = 'contratto'
        store.state.all.data.wizard = 'agenti/inserimento'
        store.state.all.data.azienda = currentUser.value.area
        store.state.all.data.pageIndex = stepIndex
        store.state.all.data.contratto.agente = currentUser.value.role == roles.agent.role ? {id:currentUser.value.id} : ''
        if (store.state.all.data.contratto.pagamento.name != "RID") {
           store.state.all.data.contratto.rid = {content:false}
        } else {
          store.state.all.data.contratto.rid.id = store.state.all.data.contratto.rid.selectedTypeRid.id
          store.state.all.data.contratto.rid.tipo_mandato = store.state.all.data.contratto.rid.selectedTypeRid.tipo_mandato
          store.state.all.data.contratto.rid.iban = store.state.all.data.contratto.rid.iban ?? ''
          store.state.all.data.contratto.rid.sottoscrittore = store.state.all.data.contratto.rid.sottoscrittore ?? ''
          store.state.all.data.contratto.rid.intestazione = store.state.all.data.contratto.rid.intestazione ?? ''
          store.state.all.data.contratto.rid.codiceFiscaleCc = store.state.all.data.contratto.rid.codiceFiscaleCc ?? ''
          store.state.all.data.contratto.rid.istituto = store.state.all.data.contratto.rid.istituto ?? ''
          store.state.all.data.contratto.rid.content = true
          // store.state.all.data.contratto.rid.dataFirmaRid = moment(store.state.all.data.contratto.rid.dataFirmaRid).format("YYYY-MM-DD")
          // store.state.all.data.contratto.rid.dataRataRid = moment(store.state.all.data.contratto.rid.dataRataRid).format("YYYY-MM-DD")
        }

        store.state.all.data.contratto.dataFirma = moment(store.state.all.data.contratto.dataFirma).format("YYYY-MM-DD")
        store.state.all.data.contratto.dataFine = moment(store.state.all.data.contratto.dataFine).format("YYYY-MM-DD"),
        store.state.all.data.contratto.dataInizio = moment(store.state.all.data.contratto.dataInizio).format("YYYY-MM-DD")

        

        if(store.state.all.bozzaId == 0){

          praticheWizardBozzeService.create("agenti/inserimento", JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
            if(res.success){
              store.state.all.bozzaId = res.item.id
              emit('next-page', stepIndex);
            }
          })

        }else{
          praticheWizardBozzeService.update(store.state.all.bozzaId, JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
            if(res.success){
              store.state.all.bozzaId = res.item.id
                emit('next-page', stepIndex);
            }
          })

        }
      
      }
    }

    const prevPage = () => {
      emit('prev-page', stepIndex);
    }

    

    return {
      currentUser,
      roles,
      store,
      tipiCtr,
      offerte,
      acquisizioni,
      
      spedizioni,
      ivas,

      selectedTipoCtr,
      offerta,
      acquisizione,

      spedizione,
      iva,


      dataFirma, 
      dataInizio,
      dataFine,

      rinnovo,
      documentazione,

      note,

      iban, sottoscrittore,
      codiceFiscaleCc, intestazione, istituto, dataFirmaRid, dataRataRid,  selectedTypeRid, rids,

      submitted,  
      
      nextPage,
      prevPage,
      validationErrors,
      
      validateForm,
      

      regime, regimi, service, 
      fatturazione, fatturazioni,  scadenza, scadenze,
      pagamento, pagamenti, periodo, periodi 
      
    }
  },
}
</script>

<style>
.form-step .p-card-title, .form-step .p-card-subtitle {
    text-align: center;
}
#cod_fiscale {
    text-transform: uppercase;
}

</style>

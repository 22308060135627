<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Nuovo contratto
            </template>
            <template v-slot:subtitle>
                Vuoi inserire un nuovo contratto per questo cliente? <br><br>
                <router-link :to="`/contratto/step-associa/${route.params.id}`" custom v-slot="{ navigate }">
                    <Button label="Si" class="p-button-success" @click="navigate" />
                </router-link>
            </template>      
        </Card>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import Card from 'primevue/card';
import Button from 'primevue/button';


export default {
    components: {
        Card,
        Button
        
    },
    props: {
        formData: Object
    },
    setup () {

        //  const router = useRouter();
         const route = useRoute();


        return {route}
    },
    
}
</script>

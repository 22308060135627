<template>

    <div class="stepsdemo-content">    
        <Card v-if="formData.tipo_client_name == 'Domestico'">
            <template v-slot:title> 
                Codice Fiscale
            </template>
            <template v-slot:subtitle>
                Inserisci il codice fiscale
            </template>
            <template v-slot:content>
                <div class="p-fluid">
                    <div class="p-field">
                        <InputText id="cod_fiscale" v-model="cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.cod_fiscale && submitted}]" />
                        <div v-if="validationErrors.cod_fiscale && submitted" class="p-error">Campo richiesto.</div>
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                        <div v-if="validationErrors.exist && submitted" class="p-error">Codice fiscale esistente.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
        <Card v-else>
            <template v-slot:title>
                Partita iva
            </template>
            <template v-slot:subtitle>
                Inserisci la partita iva
            </template>
            <template v-slot:content>
                <div class="p-fluid">
                    <div class="p-field">
                        <InputText type="number" id="partita_iva" v-model="partita_iva" :class="['inputfield w-full',{'p-invalid': validationErrors.partita_iva && submitted}]" />
                        <div v-if="validationErrors.partita_iva && submitted" class="p-error">Lunghezza campo non valida.</div>
                        <div v-if="validationErrors.existIva && submitted" class="p-error">Partita Iva esistente.</div>
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button label="" @click="nextPageIva()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';



export default {
    components: {
        Card,
        InputText,
        Button,
  
    },
    props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete", 'activeLoader'],
    inheritAttrs: false,
    setup (props, {emit}) {

        const toast = useToast();
        const cod_fiscale = ref('')
        const partita_iva = ref('')
        const format = ref(false) 
        const exist = ref(false) 
        const existIva = ref(false) 
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }
        const nextPage = () => {
            submitted.value = true;
            validateForm ()
        }
        const validateForm = () => {
            validationErrors.value = []           
            if (validateInput() && validateCf()) {
               validateCfExist()
            }               
        }
        const nextPageIva = () => {
            submitted.value = true;
            validationErrors.value = []
            if (validateFormIva() && ivaIsNumeric()) {
                validateIvaExist()
            }
        }

        const validateFormIva = () => {
            if (partita_iva.value.toString().length == 11)
                delete validationErrors.value['partita_iva'];
            else
                validationErrors.value['partita_iva'] = true;
                
            return !Object.keys(validationErrors.value).length;
        }

        const ivaIsNumeric = () => {
            let isNum = /^\d+$/.test(partita_iva.value);
             if (isNum) {
                 delete validationErrors.value['format'];     
            } else {
                validationErrors.value['format'] = true;             
            }
            return isNum    
        }

        const updateData = (res) => {

            if (res.item.totalSize > 0) {
                let company = res.item.companies[0]
                  
                emit('next-page', {
                    formData: {
                        cod_fiscale: cod_fiscale.value,                     
                        partita_iva: partita_iva.value,
                        rag_sociale: company.name,
                        phone: ("phoneNumbers" in company) ? company.phoneNumbers[0] : '',
                        mobile: '',
                        address: company.address.street,
                        civico: company.address.houseNo,
                        cap: company.address.postCode,
                        city: company.address.city,
                        district: company.address.province
                    }, 
                    pageIndex: 1
                  });
            } else {
                emit('next-page', {
                    formData: {
                        partita_iva: partita_iva.value,
                        cod_fiscale: cod_fiscale.value,
                        cap: ''
                    },
                    pageIndex: 1
                  });
            }
        }

        const getInfoCreditSafe = () => {
            emit("activeLoader", true)
            service.getInfoCreditSafe(partita_iva.value).then((res) => {
                updateData(res) 
            }).finally(() => {
                 emit("activeLoader", false)
            })
        }

        const validateIvaExist = () => {
            service.checkIvaExist(partita_iva.value).then((res) => {
                if (res.items == true) {
                    getInfoCreditSafe()                  
                    delete validationErrors.value['existIva'];
                    toast.removeAllGroups();
                } else {
                    validationErrors.value['existIva'] = true;
                    toast.add({severity:'warn', summary:'Già cliente:', detail: res.items[0].ragsoc});        
                }
            })              
        }

        const validateInput = () => {
            if (cod_fiscale.value == "") {
                validationErrors.value['cod_fiscale'] = true;          
                return false
            } else {
                delete validationErrors.value['cod_fiscale'];
              return true  
            }
        }
        const validateCf = () => {
            // validate regex cf
            let reg = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i   
            
            if (!reg.test(cod_fiscale.value)) {
                validationErrors.value['format'] = true;
                return false
            } else {
                delete validationErrors.value['format'];
                return true
            }                 
        }
        const validateCfExist = () => {
            
            service.checkCodeExist(cod_fiscale.value).then((res) => {
                if (res.items == true) {
                    emit('next-page', {formData: {cod_fiscale: cod_fiscale.value}, pageIndex: 1});
                    delete validationErrors.value['exist'];
                    toast.removeAllGroups();
                } else {
                    validationErrors.value['exist'] = true;
                    toast.add({severity:'warn', summary:'Già cliente:', detail: res.items[0].nome +" "+res.items[0].cognome});        
                }
            })
               
        }
        


        return {cod_fiscale, partita_iva, format, exist, existIva, validationErrors, self, service, submitted, prevPage, nextPage,
         validateForm, validateFormIva, validateInput, validateCf, validateCfExist, validateIvaExist, ivaIsNumeric, nextPageIva,
         getInfoCreditSafe}
    },    

}
</script>

<style scoped>
#cod_fiscale {
    text-transform: uppercase;
}
</style>

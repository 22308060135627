<template>
    <div class="stepsdemo-content" id="verifica-ctr-ee">
        <Card >
            <template v-slot:title>
                Contratto
            </template>
            <template v-slot:subtitle>
                Associa un contratto
            </template>
            <template v-slot:content>
                <div v-show="validationErrors.ctr && submitted" class="p-error ctr-error">Associa un contratto</div>
                    <Accordion v-if="contracts.length != 0">
                        <AccordionTab v-for="contract in contracts" :key="contract.id">
                            <template #header>
                                <div>{{contract.code}}</div>
                                <div v-if="contract.associate" class="sync"><i class="pi pi-link"></i></div>
                                <div class="ctr-badge ctr-badge-valid" ></div>
                            </template>
                            <div class="grid">
                                <div class="col">
                                    <p><b>Tipo</b></p>
                                    <span v-if="contract.cod_type_contratto == 1">Energia</span>
                                    <span v-else-if="contract.cod_type_contratto == 2">Gas</span>
                                    <span v-else>Nessuno</span>
                                </div>
                                <div class="col">
                                    <p><b>Data Inizio</b></p>
                                    {{reverseDate(contract.data_inizio)}}
                                </div>
                                <div class="col">
                                    <p><b>Data Fine</b></p>
                                    {{reverseDate(contract.data_fine)}}
                                </div>
                                <div class="col">
                                    <p><b>Offerta</b></p>
                                    {{contract.offerta_code}}
                                </div>
                              
                                <div class="col-12" style="text-align:center">
                                    <Button @click="associateCtr(contract.id)"  label="Associa" class="p-button-success" icon="pi pi-link" iconPos="right" />
                                </div>                           
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <div v-else class="text-center">
                        Il cliente non ha nessun contratto gas associato
                    </div><br>  

                    <div class="grid">
                        <div class="field col-9">
                             <div class="p-text-left">
                                <small>Se il contratto non è presente,  aggiungilo e poi premi il bottone verde per aggiornare la lista</small><br>
                                <small><router-link :to="`/contratto/step-associa/${formData.client.id}`" target= '_blank'>Aggiungi un contratto</router-link></small>
                            </div>
                        </div>
                        <div class="field col-3 btn-reload text-left">
                            <Button @click="getContracts" label="" icon="pi pi-replay" class="p-button-success"/>
                        </div>
                    </div>
                    
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>

    <Dialog v-model:visible="modalDialog" :style="{width: '450px'}" header="Associa contratto" :modal="true">
        <div class="confirmation-content">          
           Vuoi associare la nuova fornitura a questo contratto ?    
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="modalDialog = false"/>
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="associateCtr" />
        </template>
    </Dialog>

</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'   
import Card from 'primevue/card';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
//import { useToast } from "primevue/usetoast";

export default {
    components: {
        Card,
        Button,
        Accordion,
        AccordionTab,
        Dialog,
    },
    props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete","activeLoader"],
    setup (props, {emit}) {
        //const toast = useToast();
        const contracts = ref([])
        const ctr = ref();
        const idContratto = ref(props.formData.idContratto)
        const modalDialog = ref(false);
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        // watch(contracts, (ctrs, prevCtrs) => {
        //     if (prevCtrs.length != 0 && ctrs.length > prevCtrs.length) {
        //         let newCtrs = ctrs.filter(({ id: id1 }) => !prevCtrs.some(({ id: id2 }) => id2 === id1))
        //         newCtrs.forEach(el => {
        //            toast.add({severity:'success', summary:'Lista contratti aggiornata:', detail: el.code}); 
        //         });        
        //     }           
        // })

        onUpdated(()=> {    
            getContracts()
        })
        
        onMounted(()=> {
            getContracts()
        })

        const getContracts = () => {
            let type_ctr = 2 // 1 energy - 2 gas
            emit("activeLoader", true)
            service.getCtrsActive(props.formData.client.id, type_ctr).then((res) => {
                contracts.value = res.items
                contracts.value.map(el => {
                  el.associate = false
                })
            }).then(() =>{
             // se proviene da wizard contratto
            if (idContratto.value) {
              contracts.value.map(el=>{
                if (el.id == idContratto.value ) {
                  idContratto.value = el.id
                  el.associate = true
                  ctr.value = el
                }
              })[0]
            }
            // se proviene da wizard contratto
          }).finally(()=>{
              emit("activeLoader", false)
          })
        }


        const prevPage = () => {
            emit('prev-page', {pageIndex: 2});
        }

        const nextPage = () => {
            submitted.value = true;
            if (validateForm()) { 
               emit('next-page', {
                    formData: {
                        contratto: ctr.value,
                        idContratto: '',
                        idCliente: '',
                    },
                    pageIndex: 2
                });       
            }
        }

        

        const associateCtr = (id) => {
          contracts.value.map(el => {
            el.associate = false
            if (el.id == id) {         
              el.associate = true
              idContratto.value = id
              ctr.value = el 
            }
          })  
        }


        const reverseDate = (value) => {
            let array = value.split("-");
            return `${array[2]}-${array[1]}-${array[0]}`
        }
        // const compareCtrDate = (dateCtr) => {
        //     let todayDate = new Date().toISOString().slice(0, 10);
        //     if ( dateCtr > todayDate) {
        //         return true         
        //     } else {
        //         return false
        //     }      
        // }

        const validateForm = () => {
           if (ctr.value == null)
                validationErrors.value['ctr'] = true;
            else 
                delete validationErrors.value['ctr'];
                       
            return !Object.keys(validationErrors.value).length;
        }

        return {
           validationErrors, submitted, service, nextPage, prevPage, contracts, reverseDate,
           ctr, modalDialog, associateCtr, getContracts, idContratto
        }

    },
}
</script>
<style>
#verifica-ctr-ee .p-accordion-tab {
    margin-bottom: 8px !important;
}
#verifica-ctr-ee .p-accordion-header-link {
    border: none !important;
}
#verifica-ctr-ee .ctr-badge {
    widows: 10px;
    height: 10px; 
    position: absolute;
    top: -3px;
    right: -2px;
    font-size: 10px;
    text-transform: uppercase;
    padding: 6px;
    border-radius: 50%;
    animation: blink 2s linear infinite;
    
}


@keyframes blink{
    0%   {background-color: #a2ec2a; }
    50%  {background-color: #a2ec2a8c; }
    100%   {background-color: #a2ec2a; }
}

.ctr-error {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f44336;
    margin-bottom: 12px;
}
.sync {
    color: #2196F3;
    position: absolute;
    right: 14px;
}

</style>

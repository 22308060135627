<template>

    <!-- <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Rappresentante
            </template>
            <template v-slot:subtitle>
                Associa rappresentante
            </template>
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="p-field col-12 md:col-9">
                        <label for="rappresentante">Rappresentante</label><br>
                        <Dropdown @change="onSelectedRappresentante($event)" v-model="selectedRappId"  :options="rappresentanti" optionLabel="name" optionValue="id" placeholder="Seleziona rappresentante" :filter="true" class="inputfield w-full"/>
                    </div>
                    <div class="p-field col-12 md:col-3 btn-reload">
                       <Button @click="getRappresentanti" label="" icon="pi pi-replay" class="p-button-success"/>
                    </div>
                    <div class="p-field col-12 p-md-12">
                        <small>Se il rappresentante non è nella lista, aggiungilo e poi premi il bottone verde per aggiornare la lista</small><br>
                        <small><router-link to="/rappresentanti" target= '_blank'>Aggiungi un rappresentante</router-link></small>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div> -->




    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Rappresentante
            </template>
            <template v-slot:subtitle>
                Inserisci il codice fiscale del rappresentante
            </template>
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="p-field col-12 md:col-12">
                        <InputText id="cod_fiscale" v-model="cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.format && submitted}]"  />
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-12">
                      <small>
                        Se il rappresentante non è presente:
                        <!-- <span class="p-new-rapp" @click="openRappDialog">Aggiungi un rappresentante</span> -->
                        <router-link :to="{ path: '/rappresentanti', query: { referral: 'clienti' }}" target= '_blank'> Aggiungi rappresentante</router-link>
                        </small>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>

    

</template>

<script>
import {ref, getCurrentInstance} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import validateCodFiscale from '@/validate/validate-cf'


export default {
    components: {
        Card,
        Button,
    },
     props: {
        formData: Object
    },
    emits: ["next-page","prev-page", "complete",'activeLoader'],
    inheritAttrs: false,
    setup (props, {emit}) {

        const cod_fiscale = ref('')
        const selectedRappId = ref(0) 
        const selectedRappName = ref('') 
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const toast = useToast();



        const prevPage = () => {
            emit('prev-page', {pageIndex: 4});
        }
        const nextPage = async () => {
          submitted.value = true;

          if (cod_fiscale.value == '') {
            emit('next-page',{ 
              formData: {
                rappresentanteId: 0, 
                rappresentanteName: ''
              },
              pageIndex: 4})
          } else {
            if (validateForm()) {
              let isPresent = await getRappresentanteFromCF()
              if (isPresent) {

                emit('next-page',{
                  formData: {
                    rappresentanteId: selectedRappId.value, 
                    rappresentanteName: selectedRappName.value
                  },  
                  pageIndex: 4
                });
              }  
            } 
          }
           
        }

        const getRappresentanteFromCF = () => {
          return service.getRappresentanteFromCF(cod_fiscale.value).then( (res) =>{
            if (res.items.length > 0) {
              selectedRappId.value = res.items[0].id
              selectedRappName.value = res.items[0].name
              return true
            } else {
              toast.add({severity:'warn', summary:'Non trovato', detail: 'Nessun rappresentate trovato'});
              return false
            }
          })
        }


        const validateForm = () => {
          if (cod_fiscale.value == '') {
            return true
          }

          if ( validateCodFiscale(cod_fiscale.value) ) {
            delete validationErrors.value['format'];
          } else {
            validationErrors.value['format'] = true;
          } 
          return !Object.keys(validationErrors.value).length;                  
        }
        

        return { 
          cod_fiscale,  selectedRappId, selectedRappName, validationErrors, self, service, submitted, prevPage,
          nextPage
        }
    }    

}
</script>
<style scoped>
#cod_fiscale {
  text-transform: uppercase;
}
</style>
<style>
.p-new-rapp {
  cursor: pointer;
  color:var(--blue-500)
}
#rappDialog .p-dialog-title{
  color:var(--blue-500) !important;
}

#rappDialog .p-card{
  box-shadow: none!important;
}
</style>

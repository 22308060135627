<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6" v-if="formData.name"><b>Nome:</b>{{formData.name}}</div> 
                    <div class="col-12 md:col-6"><b>Cellulare:</b>{{formData.mobile}}</div>
                    <div class="col-12 md:col-6"><b>Email:</b>{{formData.email}}</div>
                    <div class="col-12 md:col-6"><b>Note:</b>{{formData.note}}</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';

export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {

        const obj = ref([])
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const isDisable = ref(false);
   
        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }
        const complete = async () => {
            
          emit("activeLoader", true)
          isDisable.value = true 
          service.saveAgente(props.formData).then((res) => {
            if (!res) {
              setTimeout(function() {
                emit('complete');
                }, 
              1800); 
            }
          }).finally(() => {
            emit("activeLoader", false)
          })
        }      

        return {prevPage, complete, obj, isDisable}
    },
    
}
</script>

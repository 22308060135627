
<template>
    <div class="stepsdemo-content">
      <Loader v-if="loading" />
        <Card>
            <template v-slot:title>
                Anagrafica
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div class="formgrid grid">         
                    <div class="p-field col-12 md:col-6">
                        <label for="name">Nome</label><br>
                        <InputText id="name" v-model="name" :class="['inputfield w-full',{'p-invalid': validationErrors.name && submitted}]" />
                        <small v-show="validationErrors.name && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="surname">Cognome</label><br>
                        <InputText id="surname" v-model="surname" :class="['inputfield w-full',{'p-invalid': validationErrors.surname && submitted}]" />
                        <small v-show="validationErrors.surname && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div>
                <div  v-if="formData.cod_fiscale == ''" class="formgrid grid">
                     <div class="p-field col-12 md:col-6">
                        <label for="birth">Data di nascita</label>
                        <Calendar id="birth"  v-model="birth"  dateFormat="dd-mm-yy" :monthNavigator="true" :yearNavigator="true" yearRange="1900:2050" :class="['inputfield w-full',{'p-invalid': validationErrors.birth && submitted}]"/>
                        <small v-show="validationErrors.birth && submitted" class="p-error">Campo richiesto.</small> 
                    </div>
                    <div class="field col-12 md:col-6">
                        <div class="field-radiobutton">
                            <RadioButton id="gender1" name="gender" value="m" v-model="gender" />
                            <label for="gender1">Maschio</label>
                        </div>
                        <div class="field-radiobutton">
                            <RadioButton id="gender2" name="gender" value="f" v-model="gender" />
                            <label for="gender2">Femmina</label>  
                        </div>
                        <div v-show="validationErrors.gender && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12">
                        <label for="city">Comune</label><br>
                        <Dropdown id="city"  v-model="selectedCity" :options="cities" optionLabel="name" optionValue="id" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedCity && submitted}]" :filter="true" />
                        <small v-show="validationErrors.selectedCity && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div> 
                 <div class="formgrid grid">       
                    <div class="p-field col-12 md:col-6">
                        <label for="phone">Telefono</label><br>
                        <InputText id="phone" v-model="phone" :class="['inputfield w-full',{'p-invalid': validationErrors.phone && submitted}]" />
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask id="mobile" mask="999-9999999" v-model="mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.mobile && submitted}]" />
                        <small v-show="validationErrors.mobile && submitted" class="p-error">Campo richiesto.</small>
                    </div>               
                    <div class="p-field col-12 md:col-6">
                        <label for="email">Email</label><br>
                        <InputText id="email" v-model="email" :class="['inputfield w-full',{'p-invalid': validationErrors.email && submitted}]" />
                        <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pec">Pec</label><br>
                        <InputText id="pec" v-model="pec" class="inputfield w-full"/>
                    </div>
                    <div class="p-field col-12" v-if="formData.cod_fiscale || cod_fiscale">
                        <label for="cod_fiscale">Codice fiscale</label><br>
                        <InputText id="cod_fiscale" v-model="cod_fiscale" :value="formData.cod_fiscale || cod_fiscale" class="inputfield w-full"/>
                        <div v-show="validationErrors.format && submitted" class="p-error">Errore generazione codice fiscale.</div>
                    </div>              
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button v-if="!formData.cod_fiscale" label="Genera codice fiscale" @click="generate()" icon="pi pi-replay" iconPos="right" class="p-button-success" />
                    <Button v-if="cf_valid || formData.cod_fiscale" label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import moment from 'moment'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import Loader from "@/components/Loader.vue"
export default {
    components: {
        Card,
        InputText,
        Button,
        InputMask,
        Calendar,
        RadioButton,
        Dropdown,
        Loader
    },
     props: {
        formData: Object
    },
    setup (props, {emit}) {
        const loading = ref(false)
        const name = ref('')
        const surname = ref('') 
        const birth = ref(null) 
        const cod_fiscale = ref('') 
        const phone = ref('') 
        const mobile = ref(null) 
        const email = ref('')
        const pec = ref('')
        const cities = ref([])
        const selectedCity = ref(null)
        const gender = ref(null)
        const isEmail = ref(false)
        const format = ref(false)
        const cf_valid = ref(false)
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        onMounted (()=>{
             service.getCitiesRapp().then((res) => {
                cities.value = res.items
            })
        })
        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }
        const nextPage = () => {
            submitted.value = true;
             if (validateForm()) { 
                 emit('next-page', {
                    formData: {
                        name: name.value,
                        surname: surname.value,
                        cod_fiscale: cod_fiscale.value || props.formData.cod_fiscale,
                        phone: phone.value,
                        mobile: mobile.value,
                        email: email.value,
                        pec: pec.value
                    },
                        pageIndex: 1
                });    
             }
        }
        const generate = () => {
            submitted.value = true;
            
            if (validateForm()) {  
              loading.value = true   
              calculateCF().then( (res) => {
                  cf_valid.value = res
              }).finally(()=> {
                loading.value = false
              })      
            }           
        }
        const calculateCF = () => {
            let dateBirth = moment(birth.value).format('YYYY-MM-DD')        
            return service.calculateCF(name.value, surname.value, dateBirth, gender.value, selectedCity.value).then((res) => {            
                if (res.cf.data.status) { 
                    cod_fiscale.value = res.cf.data.cf             
                    delete validationErrors.value['format'];
                    return true
                } else {
                    validationErrors.value['format'] = true;
                    return false
                }
            }).catch(()=> {           
                validationErrors.value['format'] = true;
            })
        }
        const validateForm = () => {
            let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
            if (!name.value.trim())
                validationErrors.value['name'] = true;
            else 
                delete validationErrors.value['name'];
                        
            if (!surname.value.trim())
                validationErrors.value['surname'] = true;
            else
                delete validationErrors.value['surname'];
            if (props.formData.cod_fiscale == '') {
                
                if (birth.value == null)
                    validationErrors.value['birth'] = true;
                else
                    delete validationErrors.value['birth'];
                
                if (selectedCity.value == null)
                    validationErrors.value['selectedCity'] = true;
                else
                    delete validationErrors.value['selectedCity'];
             
                
                if (gender.value == null)
                    validationErrors.value['gender'] = true;
                else
                    delete validationErrors.value['gender'];
            }
             
            if (email.value != '') {
                if (regex_email.test(email.value)) {
                    delete validationErrors.value['isEmail'];
                } else {
                    validationErrors.value['isEmail'] = true;
                }
            }
            
              
            return !Object.keys(validationErrors.value).length;
        }
        return {
            name, 
            surname, 
            phone, 
            mobile, 
            email, 
            validationErrors, 
            submitted, 
            service, 
            prevPage, 
            nextPage, 
            isEmail,
            cod_fiscale, 
            pec, 
            birth, 
            gender, 
            cities, 
            selectedCity, 
            calculateCF, 
            format, 
            cf_valid, 
            generate, 
            loading
        }
    },
}
</script>
<style scoped>
#cod_fiscale {
    background-color: #60aa20;
    color: white;
}
.p-button-Success:hover {
  background-color: #60aa20;
  color: white;
} 
</style>

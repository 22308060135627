export default
{
  main_domain:{    
    url: `${process.env.VUE_APP_ASK_DOMAIN}`,
    cookie: `${process.env.VUE_APP_ASK_COOKIE_DOMAIN}`
  },
  admin:{    
    url: `${process.env.VUE_APP_ASK_ADMIN_URL}`,
  },
  ask_api: {
    url: `${process.env.VUE_APP_ASK_API_URL}/api/ask/v1`
  },
  ask_pratiche_api: {
    url: `${process.env.VUE_APP_ASK_PRATICHE_URL}/api/ask/v1`
  },
  ask_flussi_api: {
    url: `${process.env.VUE_APP_ASK_FLUSSI_URL}/api`
  },
  ask_pratiche: {
    url: `${process.env.VUE_APP_ASK_PRATICHE_URL}/api/pratiche/v1`
  },
  ask_pratiche_scraper: {
    url: `${process.env.VUE_APP_ASK_PRATICHE_URL}/api/scraper/v1/acquirente-unico`
  },
  ask_pratiche_token: {
    url: `${process.env.VUE_APP_ASK_PRATICHE_URL}/api/token`
  },
  ask_landing: {
    url: `${process.env.VUE_APP_ASK_LANDING_URL}`
  },
  
}

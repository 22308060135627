<template>

    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Tipo Cliente
            </template>
            <template v-slot:subtitle>
                Seleziona il tipo di cliente
            </template> 
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="field col-12">
                        <Dropdown @change="onSelectedTipo($event)" v-model="selectedTipo" :options="tipoCliente" optionLabel="name" optionValue="id" placeholder="Seleziona tipo cliente"  :class="['inputfield w-full',{'p-invalid': validationErrors.selectedTipo && submitted}]" />
                        <div v-show="validationErrors.selectedTipo && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';



export default {
    components: {
        Card,
        Button,
        Dropdown,
    },
    setup (props, {emit}) {
        const store = useStore()
        const currentUser = ref(store.state.auth.user)
        const selectedTipo = ref(null)
        const selectedTipoName = ref('')
        const tipoCliente = ref([])
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        onMounted(() => { 
          getData();
        })
        
        const getData = () => {
          service.getAllTipoClient().then((res) => {
              tipoCliente.value = res.items
          })
        }

        const onSelectedTipo = (evt) => {
          selectedTipoName.value = evt.originalEvent.target.ariaLabel       
        }

        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
                emit('next-page', 
                {formData: 
                    {
                        tipo_client: selectedTipo.value,
                        tipo_client_name: selectedTipoName.value,
                        azienda: currentUser.value.area,
                    },
                        pageIndex: 0
                });
            }
        }

        const validateForm = () => {
            if (!selectedTipo.value)
                validationErrors.value['selectedTipo'] = true;
            else
                delete validationErrors.value['selectedTipo'];


            return !Object.keys(validationErrors.value).length;
        }

        return {selectedTipo, selectedTipoName, tipoCliente, validationErrors, self, service, getData, nextPage,
            submitted, validateForm, onSelectedTipo}
        },
}
</script>

<style>
.form-step .p-card-title, .form-step .p-card-subtitle {
    text-align: center;
}

</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Prezzi offerta
            </template>
            <template v-slot:subtitle>
                Crea offerta
            </template>
            <template v-slot:content>            
                <div class="formgrid grid">     
                    <div class="p-field col-12 md:col-6">
                        <label for="nomeOfferta">Nome offerta</label>
                        <InputText id="nomeOfferta" type="text" v-model="nomeOfferta" :class="['inputfield w-full',{'p-invalid': validationErrors.nome && submitted}]" />
                        <div v-show="validationErrors.nome && submitted" class="p-error">Campo richiesto.</div>
                        <!-- <div v-show="validationErrors.existOfferta && submitted" class="p-error">Nome esistente.</div> -->
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="">Tipo offerta</label>
                        <Dropdown  v-model="selectedTipoOfferta" :options="tipiOfferta" optionLabel="name" placeholder="Seleziona tipo offerta" :class="['inputfield w-full',{'p-invalid': validationErrors.tipo && submitted}]" >
                            <template #value="slotProps">
                                <div class="client-item client-item-value" v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="client-item">
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.tipo && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                     <div class="p-field col-12" v-if="selectedTipoFornitura == 1">
                        <label for="">Tipo tariffazione</label>
                        <Dropdown  v-model="selectedTariffazione" :options="tariffazioni" optionLabel="name" placeholder="Seleziona tipo tariffazione" :class="['inputfield w-full',{'p-invalid': validationErrors.tariffazione && submitted}]" >
                            <template #value="slotProps">
                                <div class="client-item client-item-value" v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="client-item">
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <div v-show="validationErrors.tariffazione && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pvcFix">PCV FIX</label>
                        <!-- <InputText id="pvcFix" type="number" v-model="pvcFix" :class="['inputfield w-full',{'p-invalid': validationErrors.pvcFix && submitted}]" /> -->
                        <InputNumber id="pvcFix" v-model="pvcFix" inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT" :class="['inputfield w-full',{'p-invalid': validationErrors.pvcFix && submitted}]"/>
                        <div v-show="validationErrors.pvcFix && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pvcVariabile">PCV Variabile</label>
                        <!-- <InputText id="pvcVariabile" type="number" v-model="pvcVariabile" :class="['inputfield w-full',{'p-invalid': validationErrors.pvcVariabile && submitted}]" /> -->
                        <InputNumber id="pvcVariabile" v-model="pvcVariabile" :class="['inputfield w-full',{'p-invalid': validationErrors.pvcVariabile && submitted}]" :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :minFractionDigits="3"/>

                        <div v-show="validationErrors.pvcVariabile && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="pa">PA</label>
                        <!-- <InputText id="pa" type="number" v-model="pa" :class="['inputfield w-full',{'p-invalid': validationErrors.pa && submitted}]" /> -->
                        <InputNumber id="pa" v-model="pa" :class="['inputfield w-full',{'p-invalid': validationErrors.pa && submitted}]" inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT"/>

                        <div v-show="validationErrors.pa && submitted" class="p-error">Campo richiesto.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="sbilanciamento">Sbilanciamento</label>
                        <!-- <InputText id="sbilanciamento" type="number" v-model="sbilanciamento" :class="['inputfield w-full',{'p-invalid': validationErrors.sbilanciamento && submitted}]" /> -->
                        <InputNumber id="sbilanciamento" v-model="sbilanciamento" :class="['inputfield w-full',{'p-invalid': validationErrors.sbilanciamento && submitted}]" :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.sbilanciamento && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                </div>
                <div v-if="selectedTariffazione.id == 1" class="formgrid grid">
                    <div class="p-field col-12">
                        <label for="mono">Mono</label>
                        <!-- <InputText id="mono" type="number" v-model="mono" :class="['inputfield w-full',{'p-invalid': validationErrors.mono && submitted}]" /> -->
                        <InputNumber id="mono" v-model="mono" :class="['inputfield w-full',{'p-invalid': validationErrors.mono && submitted}]"  :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.mono && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                </div>
                <div v-if="selectedTariffazione.id == 2" class="formgrid grid">
                    <div class="p-field col-12 md:col-6">
                        <label for="peak">Peak</label>
                        <!-- <InputText id="peak" type="number" v-model="peak" :class="['inputfield w-full',{'p-invalid': validationErrors.peak && submitted}]" /> -->
                        <InputNumber id="peak" v-model="peak" :class="['inputfield w-full',{'p-invalid': validationErrors.peak && submitted}]" :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.peak && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="opeak">OPeak</label>
                        <!-- <InputText id="opeak" type="number" v-model="opeak" :class="['inputfield w-full',{'p-invalid': validationErrors.opeak && submitted}]" /> -->
                        <InputNumber id="opeak" v-model="opeak" :class="['inputfield w-full',{'p-invalid': validationErrors.opeak && submitted}]"  :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.opeak && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                </div>
                <div v-if="selectedTariffazione.id == 3" class="formgrid grid">
                    <div class="p-field col-12 md:col-4">
                        <label for="f1">F1</label>
                        <!-- <InputText id="f1" type="number" v-model="f1" :class="['inputfield w-full',{'p-invalid': validationErrors.f1 && submitted}]" /> -->
                        <InputNumber id="f1" v-model="f1" :class="['inputfield w-full',{'p-invalid': validationErrors.f1 && submitted}]" :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.f1 && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="f2">F2</label>
                        <!-- <InputText id="f2" type="number" v-model="f2" :class="['inputfield w-full',{'p-invalid': validationErrors.f2 && submitted}]" /> -->
                        <InputNumber id="f2" v-model="f2" :class="['inputfield w-full',{'p-invalid': validationErrors.f2 && submitted}]" :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.f2 && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                    <div class="p-field col-12 md:col-4">
                        <label for="f3">F3</label>
                        <!-- <InputText id="f3" type="number" v-model="f3" :class="['inputfield w-full',{'p-invalid': validationErrors.f3 && submitted}]" /> -->
                        <InputNumber id="f3" v-model="f3" :class="['inputfield w-full',{'p-invalid': validationErrors.f3 && submitted}]" :inputId="`€/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`" :suffix="` €/${selectedTipoFornitura == 1 ? 'kWh' : 'Smc'}`"  :minFractionDigits="4"/>

                        <div v-show="validationErrors.f3 && submitted" class="p-error">Campo richiesto.</div> 
                    </div>
                </div>
                <div  class="formgrid grid">
                  <div class="p-field col-12 md:col-6">
                    <label for="dep_cauzionale">Dep.Cauzionale</label>
                    <!-- <InputText id="dep_cauzionale" type="number" v-model="dep_cauzionale" :class="['inputfield w-full',{'p-invalid': validationErrors.dep_cauzionale && submitted}]" /> -->
                    <InputNumber id="dep_cauzionale" v-model="dep_cauzionale" :class="['inputfield w-full',{'p-invalid': validationErrors.dep_cauzionale && submitted}]"  inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT"/>

                    <div v-show="validationErrors.dep_cauzionale && submitted" class="p-error">Campo richiesto.</div> 
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="area_clienti">Area Clienti</label>
                      <!-- <InputText id="area_clienti" type="number" v-model="area_clienti" :class="['inputfield w-full',{'p-invalid': validationErrors.area_clienti && submitted}]" /> -->
                      <InputNumber id="area_clienti" v-model="area_clienti" :class="['inputfield w-full',{'p-invalid': validationErrors.area_clienti && submitted}]"  inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT"/>

                      <div v-show="validationErrors.area_clienti && submitted" class="p-error">Campo richiesto.</div> 
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="rinnovo">Rinnovo</label>
                      <InputNumber id="rinnovo" v-model="rinnovo" :class="['inputfield w-full',{'p-invalid': validationErrors.rinnovo && submitted}]" inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT"/>
                      <div v-show="validationErrors.rinnovo && submitted" class="p-error">Campo richiesto.</div> 
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="green">Contributo Green</label>
                      <InputNumber id="green" v-model="green" :class="['inputfield w-full',{'p-invalid': validationErrors.green && submitted}]" inputId="currency-italy" mode="currency" currency="EUR" locale="it-IT"/>
                      <div v-show="validationErrors.green && submitted" class="p-error">Campo richiesto.</div> 
                  </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { useToast } from "primevue/usetoast";


export default {
    components: {
        Card,
        Button,
        Dropdown,
        InputText,      
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {
        const toast = useToast();
        const nomeOfferta = ref('')
        const selectedTipoOfferta = ref('')
        const selectedTipoFornitura = ref()
        const tipiOfferta = ref([]);
        const selectedTariffazione = ref('')
        const tariffazioni = ref([]);
        const pvcFix = ref(0.00)
        const pvcVariabile = ref(0.000)
        const pa = ref(0.00)
        const sbilanciamento = ref(0.0000)
        const mono = ref(0.000)
        const peak = ref(0.000)
        const opeak = ref(0.000)
        const f1 = ref(0.000)
        const f2 = ref(0.000)
        const f3 = ref(0.000)
        const rinnovo = ref(0.00)
        const green = ref(0.00)
        const dep_cauzionale = ref(0.00)
        const area_clienti = ref(99.00)
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

        onUpdated (()=>{
            mount()
        })
        onMounted (()=>{
            emit('activeLoader', true)
            Promise.all([
                service.getTipiOfferta().then((res) => {
                    tipiOfferta.value = res.items
                }),
                service.getTariffazioni().then((res) => {
                    tariffazioni.value = res.items
                })
            ]).then(()=>{
                mount()
            }).finally(()=>{        
                emit('activeLoader', false)   
            })
        })

        const mount = () => {
            selectedTipoFornitura.value = props.formData.tipo.id
    
            if (props.formData.offerta_clone && selectedTipoFornitura.value == props.formData.offerta_clone.tipologia) {
                setCloneData()
            }else{
                initData()
            }
        }

        const setCloneData = () => {
            selectedTipoOfferta.value = tipiOfferta.value.find(type => type.id == props.formData.offerta_clone.cod_type_offerta )
            selectedTariffazione.value = tariffazioni.value.find(type => type.id == props.formData.offerta_clone.cod_type_tariffazione )
            if (selectedTariffazione.value.id == 1) {
                mono.value = props.formData.offerta_clone.m                
            } else if(selectedTariffazione.value.id == 2){
                peak.value = props.formData.offerta_clone.p
                opeak.value = props.formData.offerta_clone.op                
            }else if(selectedTariffazione.value.id == 3){
                f1.value = props.formData.offerta_clone.f1
                f2.value = props.formData.offerta_clone.f2
                f3.value = props.formData.offerta_clone.f3
            }
            pvcFix.value = props.formData.offerta_clone.pcv
            pvcVariabile.value = props.formData.offerta_clone.pcv_var
            pa.value = props.formData.offerta_clone.pa
            sbilanciamento.value = props.formData.offerta_clone.sbilanciamento
            rinnovo.value = props.formData.offerta_clone.rinnovo
            green.value = props.formData.offerta_clone.green
            dep_cauzionale.value = props.formData.offerta_clone.cauzionale
            area_clienti.value = props.formData.offerta_clone.areaclienti
        }

        const initData = () => {
            selectedTariffazione.value = selectedTipoFornitura.value == 2 ? tariffazioni.value[0] : ''
            selectedTipoOfferta.value = ''
            pvcFix.value = 0.00
            pvcVariabile.value = 0.000
            pa.value = 0.00
            sbilanciamento.value = 0.0000
            mono.value = 0.000
            peak.value = 0.000
            opeak.value = 0.000
            f1.value = 0.000
            f2.value = 0.000
            f3.value = 0.000
            rinnovo.value = 0.00
            green.value = 0.00
            dep_cauzionale.value = 0.00
            area_clienti.value = 99.00
            rinnovo.value = selectedTipoFornitura.value == 1 ? 0.05 : 0.50
        }
        
        const prevPage = () => {
            emit('prev-page', {pageIndex: 1});
        }
      
        const nextPage = () => {
            submitted.value = true;
            if (validateForm() ) {
                nameOffertaExist().then((isValid) => {
                    if (selectedTariffazione.value.id == 1) {
                        peak.value = 0.000
                        opeak.value = 0.000
                        f1.value = 0.000
                        f2.value = 0.000
                        f3.value = 0.000
                    } else if(selectedTariffazione.value.id == 2){
                        mono.value = 0.000
                        f1.value = 0.000
                        f2.value = 0.000
                        f3.value = 0.000
                    }else if(selectedTariffazione.value.id == 3){
                        mono.value = 0.000
                        peak.value = 0.000
                        opeak.value = 0.000
                    }

                    if(isValid){
                        emit('next-page', {
                            formData: {
                                nomeOfferta: nomeOfferta.value,
                                tipoOfferta: selectedTipoOfferta.value,
                                tariffazione: selectedTariffazione.value,
                                pvcFix: pvcFix.value,
                                pvcVariabile: pvcVariabile.value,
                                pa: pa.value,
                                sbilanciamento: sbilanciamento.value,
                                mono: mono.value,
                                peak: peak.value,
                                opeak: opeak.value,
                                f1: f1.value,
                                f2: f2.value,
                                f3: f3.value,
                                dep_cauzionale: dep_cauzionale.value,
                                area_clienti: rinnovo.value <= 0 ? 0.00 : area_clienti.value,
                                rinnovo: rinnovo.value <= 0 ? 0.00 : rinnovo.value,
                                green: green.value <= 0 ? 0.00 : green.value
                            },
                            pageIndex: 1
                        });
                    }
                })                
            }               
        }

        const nameOffertaExist = async () => {
            const res = await service.nameOffertaExist(nomeOfferta.value)
            if (res.items.data.valid == true) {                
                toast.removeAllGroups();            
            } else {
                toast.add({severity:'warn', summary:'Attenzione:', detail: 'Nome offerta esistente'});                   
            }
            return res.items.data.valid
        }


      
        const validateForm = () => {
            if (nomeOfferta.value == '')
                validationErrors.value['nome'] = true;
            else 
                delete validationErrors.value['nome'];

            if (selectedTipoOfferta.value == '')
                validationErrors.value['tipo'] = true;
            else 
                delete validationErrors.value['tipo'];
            
            if (selectedTariffazione.value == '')
                validationErrors.value['tariffazione'] = true;
            else 
                delete validationErrors.value['tariffazione'];
            
            if (pvcFix.value.toString() == '')
                validationErrors.value['pvcFix'] = true;
            else 
                delete validationErrors.value['pvcFix'];

            if (pvcVariabile.value.toString() == '')
                validationErrors.value['pvcVariabile'] = true;
            else 
                delete validationErrors.value['pvcVariabile'];
            
             if (pa.value.toString() == '')
                validationErrors.value['pa'] = true;
            else 
                delete validationErrors.value['pa'];
            
            if (sbilanciamento.value.toString() == '')
                validationErrors.value['sbilanciamento'] = true;
            else 
                delete validationErrors.value['sbilanciamento'];

            if (selectedTariffazione.value.id == 1) {
                if (mono.value.toString() == '')
                    validationErrors.value['mono'] = true;
                else 
                    delete validationErrors.value['mono'];
                    
                peak.value = 0
                opeak.value = 0
                f1.value = 0
                f2.value = 0
                f3.value = 0
            } else {
                delete validationErrors.value['mono'];           
            }

            if (selectedTariffazione.value.id == 2) {
                if (peak.value.toString() == '')
                    validationErrors.value['peak'] = true;
                else 
                    delete validationErrors.value['peak'];
                
                 if (opeak.value.toString() == '')
                    validationErrors.value['opeak'] = true;
                else 
                    delete validationErrors.value['opeak'];
                
                mono.value = 0
                f1.value = 0
                f2.value = 0
                f3.value = 0
                
               
            } else {
                delete validationErrors.value['peak'];
                delete validationErrors.value['opeak'];
            }

            if (selectedTariffazione.value.id == 3) {
                if (f1.value.toString() == '')
                    validationErrors.value['f1'] = true;
                else 
                    delete validationErrors.value['f1'];           
                
                if (f2.value.toString() == '')
                    validationErrors.value['f2'] = true;
                else 
                    delete validationErrors.value['f2'];

                 if (f3.value.toString() == '')
                    validationErrors.value['f3'] = true;
                else 
                    delete validationErrors.value['f3'];
                
                mono.value = 0
                peak.value = 0
                opeak.value = 0

            } else {
                delete validationErrors.value['f1'];
                delete validationErrors.value['f2'];
                delete validationErrors.value['f3'];
            }

            if (dep_cauzionale.value.toString() == '')
               validationErrors.value['dep_cauzionale'] = true;
            else 
              delete validationErrors.value['dep_cauzionale'];

            if (area_clienti.value.toString() == '')
               validationErrors.value['area_clienti'] = true;
            else 
              delete validationErrors.value['area_clienti']; 
                                  
            return !Object.keys(validationErrors.value).length;
        }

        return {
           nomeOfferta, selectedTipoOfferta, tipiOfferta, selectedTariffazione, tariffazioni, validationErrors, 
           pvcFix, pvcVariabile, pa, sbilanciamento, mono, peak, opeak, f1, f2, f3, submitted, service,
           nextPage, prevPage, nameOffertaExist, dep_cauzionale, area_clienti, rinnovo, selectedTipoFornitura, green
        }

    },
}
</script>
<style scoped>
#cod_fiscale {
    background-color: #689f38;
}
</style>

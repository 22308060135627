<template>

  <FormStep :key="reloadKey" />

</template>

<script>
// @ is an alias to /src

import FormStep from '../components/pratiche/form-step/forniture-ee/FormStep.vue'

export default {
  components: {
    FormStep
  },
  data() {
    return {
      pageName: 'Agenti',
      reloadKey: 0
    }
  },
  beforeUpdate () {
    // fix: force reload component when double click on sidebar empty component
    if (this.$route.fullPath == '/forniture-ee') {
      this.reloadKey = Date.now() 
    }
  },
  created(){
    this.$eventBus.emit('page-change', {
      target: this,
      currentRoute: this.$router.currentRoute.value
    })

    this.$eventBus.emit('main-menu-change', {
      target: this,
      menu: this.menu
    })
  }

}
</script>

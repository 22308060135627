<template>
  <Loader v-if="loading" />
  <h4>Convalida modifiche contratti</h4><br/>
  <div v-if="loaded">
    <div v-if="!has_items">
      <div class="card">Non ci sono modifiche da validare</div>
    </div>
    <div v-else class="card" v-for="(item, i) in items" :key=i style="min-width:380px">
      <h4>Modifiche caricamento #{{i}} da {{item.agente ?? item.admin}} ({{item.agente ? 'agente' : 'admin'}}) del {{ moment(item.data).format('D-M-YYYY') }}</h4>
      <div class="flex">
        <div class="col-5 flex flex-column align-items-start">
          <h5 class="col-12 flex">Dati attuali</h5>
          <div v-for="(modifica,id) in item.modifiche" :key="id" class="col-12 flex flex-column justify-content-center align-items-start bg-attuale" style="height:140px; margin:7px 0 7px -7px;">
            <p :class="[{'bg-attuale-change':modifica.request_delete || !modifica.changes.cf}]">Codice fiscale: <strong>{{modifica.cf_attuale}}</strong></p>
            <p v-if="![2,3].includes(modifica.tipo_client)" :class="[{'bg-attuale-change':modifica.request_delete || !modifica.changes.piva}]">Partita IVA: <strong>{{modifica.piva_attuale}}</strong></p>
            <p :class="[{'bg-attuale-change':modifica.request_delete || !modifica.changes.code}]">Fornitura: <strong>{{modifica.code_attuale}}</strong></p>
          </div>
        </div>
        <div class="col-2 flex flex-column align-items-center justify-content-center">
          <h5 class="col-12 flex justify-content-center">Accetta</h5>
          <div v-for="(modifica,id) in item.modifiche" :key="id" class="col-12 flex justify-content-center align-items-center m-2"  style="height:115px">
            <Checkbox v-model="modifica.accept" :binary="true" @change="checkIsAccepted($event, i)"/>
          </div>
        </div>
        <div class="col-5 flex flex-column align-self-center">
          <h5 class="col-12 flex justify-content-end">Modifiche richieste</h5>
          
          <div v-for="(modifica,id) in item.modifiche" :key="id" class="col-12 flex flex-column align-items-end justify-content-center bg-modifica m-2"  style="height:140px">
            <div v-if="modifica.request_delete">
              <p class="bg-modifica-change">Fornitura da eliminare</p>
            </div>
            <div v-else class="flex flex-column align-items-end">
              <p :class="[{'bg-modifica-change':modifica.request_delete || !modifica.changes.cf}]">Codice fiscale: <strong>{{modifica.cf_modifica}}</strong></p>
              <p v-if="![2,3].includes(modifica.tipo_client)" :class="[{'bg-modifica-change':modifica.request_delete || !modifica.changes.piva}]">Partita IVA: <strong>{{modifica.piva_modifica}}</strong></p>
              <p :class="[{'bg-modifica-change':modifica.request_delete || !modifica.changes.code}]">Fornitura: <strong>{{modifica.code_modifica}}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 flex justify-content-center">
        <div class="col-6 flex justify-content-between align-items-center" style="height:50px">
          <Button :disabled="!item.is_accepted" label="Invia modifiche" class="p-button-rounded p-button-success" @click="sendValidation(i, item.modifiche)"/>
          <Button :disabled="item.is_accepted" label="Elimina modifiche" class="p-button-rounded p-button-danger" @click="sendValidation(i, item.modifiche, true)"/>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
import {ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Loader from "@/components/Loader.vue"
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    Loader
  },  
  setup () {
    const route = useRoute()
    const router = useRouter()
    const toast = useToast();
    const store = useStore()
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const loading = ref(false)
    const loaded = ref(false)
    const currentUser = ref(store.state.auth.user)
    const items = ref();
    const validationErrors = ref({})
    const submitted = ref(false)
    const layout = ref('list');
    const has_items = ref()

    onMounted(() => { 
      Promise.all([getData()])
    })
    const getData = async () => {
      loading.value = true    
      loaded.value = false       

      await service.getAllRequiredChanges()
      .then((res) => {
        Object.keys(res.items).forEach((i,items) => {
          res.items[i]['modifiche'].forEach(item => {
            item.changes = {
              cf:item.cf_attuale == item.cf_modifica,
              piva:item.piva_attuale == item.piva_modifica,
              code:item.code_attuale == item.code_modifica
            }
            item.accept = true
            item.tipo_client = Number(item.tipo_client)
          })
          res.items[i].is_accepted = true
        });
        items.value = res.items
        has_items.value = Object.keys(items.value).length ? true : false;
      })
      .finally(()=> {
        loading.value = false 
        loaded.value = true       
      })
    }

    const checkIsAccepted = (evt, i) => {
      let prop_accept = []
      Object.keys(items.value[i].modifiche).forEach(index => {
        prop_accept.push(items.value[i].modifiche[index].accept)
      })
      items.value[i].is_accepted = prop_accept.some(prop => prop)
    }
    const sendValidation = async (id, modifiche, reject = false) => {
      loading.value = true

      let fd = new FormData()
      fd.append('id', JSON.stringify(id));
      fd.append('modifiche', JSON.stringify(modifiche));
      fd.append('reject', JSON.stringify(reject));

      await service.sendValidation(fd)
        .then((res)=>{
          let severity = res.items.error ? 'warn' : 'success' 
          let detail = res.items.response
          toast.add({severity:severity, summary:'', detail: detail, life:3500})
          getData()
        })
        .finally(()=> {
          loading.value = false
        })
    }

    return {
      moment,
      items,
      loading,
      loaded,
      validationErrors,
      submitted,
      currentUser,
      getData,
      layout,
      sendValidation,
      checkIsAccepted,
      has_items,
    }
  },
}
</script>

<style>
@media (max-width: 400px){
  .layout-main > .card{
    padding-left: unset;
  }
}

</style>
<style scoped>
.bg-modifica,
.bg-attuale,
.bg-attuale-change,
.bg-modifica-change{
  border-radius: 5px;
}
.bg-attuale-change,
.bg-modifica-change{
  padding: 5px;
}

.bg-attuale{
  background-color:rgba(255,0,0,0.4);
  color:white;
}
.bg-attuale-change{
  background-color:rgba(255,0,0,0.9)
}
.bg-modifica{
  background-color:rgba(0,255,0,0.4)
}
.bg-modifica-change{
  background-color:rgba(0,255,0,0.9)
}
.layout-main > .card{
  min-width: 390px !important;
  padding-right: 2rem;
}
@media (max-width: 400px){
  .p-column-header-content .p-column-title{
    font-size: 1vw;
  }

  .layout-main > .card{
    padding-left: unset;
  }
}
.btn-filter{
  /* font-size: 0.7rem !important; */
  aspect-ratio: 4/1.1;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.btn-filter:hover {
  opacity: 1;
}
.btn-filter .p-button-label{
  font-size: 1em;
  line-height: 1em;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
}

.btn-filter.selected {
  opacity: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-file .button-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.form-file .allowed-format {
  font-size: 12px;
  color: rgb(186, 186, 186);
  margin-top: 2px;
}

.form-file .button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgb(217, 217, 217) !important; 
  cursor: pointer;
  color: rgb(168, 168, 168);
  padding: 10px 2px;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  box-shadow: none;
}

.form-file .button-wrapper span.label.active {
  background: #2196F3;
  color: white;
}

.form-file #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.btn-downloads-matrici {
  position:fixed;
  bottom:60px; 
  right:40px;
  z-index:2;
}
.nota{
  border-bottom: 1px solid #6c757d3d;
  margin-bottom: 10px;
}
</style>

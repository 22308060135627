<template>
    
  <toast position="bottom-right" />

	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar />
    
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        
        <div class="layout-main-container">
  
            <div class="layout-main" style="position:relative">
              <!-- <breadcrumb :home="breadcrumb.menu.home" :model="breadcrumb.menu.items" />  -->
              
              <router-view /> <!-- router-view -->
            </div>
            <AppFooter />
        </div>

		<AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode" @layout-change="onLayoutChange" @layout-color-change="onLayoutColorChange" />
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import roles from '@/config/roles'
import Service from '@/services/ask-pratiche/pratiche-wizard'


export default {
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
    },
    data() {
        return {
            loading: false,
            pageName: '',
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            nb: 0,
            service: new Service(this.$eventBus),
            menu : [
                {
                    items: [
                        // {label: 'Home', icon: 'pi pi-fw pi-home', to: '/', visibility:[ roles.admin.role, roles.agent.role]},
                        // {label: "Nuovo Cliente", icon:'pi pi-user', to: '/clienti', visibility:[roles.admin.role]},
                        // {label: 'Nuovo Rappresentante', icon: 'pi pi-user-plus', to: '/rappresentanti', visibility:[roles.admin.role]},
                        // {label: 'Nuovo Contratto', icon: 'pi pi-file-excel', to: '/contratti', visibility:[roles.admin.role]},  
                        // {label: 'Nuova Offerta', icon: 'pi pi-file-o', to: '/offerte', visibility:[roles.admin.role]},  
                        // {label: 'Nuovo Fornitore', icon: 'pi pi-sitemap', to: '/fornitori', visibility:[roles.admin.role]},
                        // {label: 'Nuovo Agente', icon: 'pi pi-eye', to: '/agenti', visibility:[roles.admin.role]},
                        // {label: 'Nuova Fornitura Energia', icon: 'pi pi-power-off', to: '/forniture-ee', visibility:[roles.admin.role]},
                        // {label: 'Nuova Fornitura Gas', icon: 'pi pi-cloud-upload', to: '/forniture-gas', visibility:[roles.admin.role]}, 
                        // {label: 'Nuovo Inserimento', icon: 'pi pi-id-card', to: '/agenti/inserimento', visibility:[roles.admin.role]}, 
                        {label: 'Nuovo Contratto', icon: 'pi pi-align-justify', to: '/multisito', visibility:[roles.admin.role,  roles.agent.role], profiles: ['all', 'backoffice']}, 
                        {label: 'Storico Caricamenti', icon: 'pi pi-align-justify', to: '/caricamenti-multisito', visibility:[roles.admin.role,  roles.agent.role], profiles: ['all', 'backoffice']}, 
                        // {label: 'Valida Contratti', icon: 'pi pi-align-justify', to: '/validazione-caricamenti', visibility:[roles.admin.role], profiles: ['all', 'backoffice']}, 
                        {label: 'Gestione Contratti', icon: 'pi pi-book',visibility:[roles.admin.role],profiles: ['all', 'backoffice'], items: [
                            {label: 'Valida Contratti', icon: 'pi pi-align-justify', to: '/validazione-caricamenti', visibility:[roles.admin.role], profiles: ['all', 'backoffice']},
                            {label: 'Convalida Modifiche', icon: 'pi pi-align-justify', to: '/convalida-modifiche', visibility:[roles.admin.role], profiles: ['all', 'backoffice']},
                        ]},
                        // {label: 'Bozze', icon: 'pi pi-save', to: '/bozze', visibility:[roles.admin.role]}, 
                        {label: 'Gestione Pratiche', icon: 'pi pi-id-card', to: '/precheck-iv', visibility:[roles.admin.role], profiles: ['all', 'pratiche']},
                        {label: 'Scarico mensile AGF', icon: 'pi pi-id-card', to: '/scarico-agf', visibility:[roles.admin.role], profiles: ['all', 'backoffice']},
                        {label: 'Report mensili', icon: 'pi pi-id-card', to: '/report-caricamenti-agente', visibility:[roles.admin.role,  roles.agent.role], profiles: ['all', 'backoffice']},
                        {label: 'Stato fornitura', icon: 'pi pi-id-card', to: '/stato-fornitura', visibility:[roles.admin.role], profiles: ['all']},
                        {label: 'Forniture contrattualizzate', icon: 'pi pi-id-card', to: '/forniture-in-wizard', visibility:[roles.admin.role], profiles: ['all', 'backoffice']},
                        {label: 'Convenzioni', icon: 'pi pi-id-card', to: '/convenzioni-agf', visibility:[roles.admin.role], profiles: ['all', 'backoffice']}
                    ]
                },
            ],
            breadcrumb:{
                menu:{
                    home: {icon: 'pi pi-fw pi-home', to: '/'},
                     items: [
                        {label: 'Dashboard'},
                    ]          
                }
            },
        }
    },
   
    created(){
        this.getNbModifiche()

        // window.setInterval(() => {
        //     this.getNbModifiche()
        // }, 300000)

        this.$eventBus.on('page-change', (evt) => {   
            this.breadcrumb.menu.items = [{label: evt.currentRoute.name}]
        })       
        this.$eventBus.on('data-loading', () => { 
            // this.loading = true
        })
        this.$eventBus.on('data-loaded', () => { 
            // this.loading = false
        })
        this.$eventBus.on('data-load-success', (evt) => {
            this.$toast.add({
                severity:'success', 
                summary: 'Successo', 
                detail: `${evt.message}`, 
                life: 4000
            })
            //   this.loading = false
        })     
        this.$eventBus.on('data-load-error', (evt) => {
            this.$toast.add({
                severity:'warn', 
                summary: 'Error Message', 
                detail: `message: ${evt.message} url: ${evt.url}`, 
                life: 4000
            })
            //   this.loading = false
        })
        this.$eventBus.on('menu-toggle', (event) => {
            this.menuClick = true;
            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if(this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }
                    
                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }
            
            event.preventDefault();
        })
        setTimeout(()=>{
            this.openWrapper()
        },2000)
    },
  
  /////// BEGIN THEME
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
      onWrapperClick() {
        if (!this.menuClick) {
          this.overlayMenuActive = false;
          this.mobileMenuActive = false;
        }

        this.menuClick = false;
      },

      onSidebarClick() {
        this.menuClick = true;
      },

      onMenuItemClick(event) {
        if (event.item && !event.item.items) {
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
        }
      },
      openWrapper(){
        let element = document.querySelector("[href='#']")
        if (element) {
            element.click()
        }
      },
      async getNbModifiche(){

        await this.service.getNbModifiche()
        .then((res)=>{
            this.nb = res.items
            let tree = ['Gestione Contratti','Convalida Modifiche']
            let menu = this.setBadge(tree)

            if (menu) {
                eval(`${menu}`).badge = this.nb
            }
        })
      },
      setBadge(tree){
        let label_i = []
        let menu = 'this.menu[0].items'
        for (let index = 0; index < tree.length; index++) {
            if (index != 0) {
                menu += '['+label_i[index-1].indice+'].items'
            }            
            const label = tree[index];
            let i = eval(`${menu}`).findIndex(item => item.label == label)
            if (i >= 0) {
                label_i.push({
                    nome:label,
                    indice:i
                })
            }else{
                return false;
            }
        }
        menu += '['+label_i[tree.length-1].indice+']'
        return menu
      },

      onLayoutChange(layoutMode) {
        this.layoutMode = layoutMode;
      },

      onLayoutColorChange(layoutColorMode) {
        this.layoutColorMode = layoutColorMode;
      },

      addClass(element, className) {
          if (element.classList)
              element.classList.add(className);
          else
              element.className += ' ' + className;
      },

      removeClass(element, className) {
          if (element.classList)
              element.classList.remove(className);
          else
              element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      },

      isDesktop() {
          return window.innerWidth >= 992;
      },

      isSidebarVisible() {
          if (this.isDesktop()) {
              if (this.layoutMode === 'static')
                  return !this.staticMenuInactive;
              else if (this.layoutMode === 'overlay')
                  return this.overlayMenuActive;
          }

          return true;
      }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false,
                'layout-theme-light': this.$appState.theme.startsWith('saga'),
            }];
        },
        logo() {
            return (this.layoutColorMode === 'dark') ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
}
</script>

<style lang="scss">
// @import './App.scss';
.p-breadcrumb{
    margin-bottom: 20px;
}
</style>

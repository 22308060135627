<template>
  <Loader v-if="loading" />
  <h4>Caricamenti</h4><br/>

  <div v-if="contatori.length">
    <div class="counter grid">
      <div class="col-12 sm:col-6 xl:col-3" v-for="item in contatori" :key="item.title">
        <div class="card" style="padding: 12px 20px;">
          <h4>{{item.title}}</h4>
          <div class="flex flex-wrap justify-content-between mb-2">
            <div class="col-12 sm:col-4">
              <span class="block text-500 font-medium mb-3">Totale</span>
              <div class="text-900 font-medium text-xl">{{item.totale}}</div>
            </div>
            <div class="col-12 sm:col-4">
              <span class="block text-500 font-medium mb-3">Firmati</span>
              <div class="text-900 font-medium text-xl">{{item.firmati}}</div>
            </div>
            <div class="col-12 sm:col-4">
              <span class="block text-500 font-medium mb-3">Non firmati</span>
              <div class="text-900 font-medium text-xl">{{item.non_firmati}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="field card">
    <Skeleton class="mb-2"></Skeleton>
    <Skeleton width="10rem" class="mb-2"></Skeleton>
    <Skeleton width="5rem" class="mb-2"></Skeleton>
    <Skeleton height="2rem" class="mb-2"></Skeleton>
    <Skeleton width="10rem" height="4rem"></Skeleton>
  </div>
  <!-- <div v-else>
    <ProgressSpinner style="width:150px;height:150px" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
  </div> -->
  <div class="grid">
    <div class="col-12">
      <div class="card" style="padding: 12px 20px;">
        <i @click="removeFilters" class="btn-remove-selected p-error pi pi-times removeFilter" style="font-size: 1.2rem" v-tooltip="'Ripristina filtri'"></i>

        <i class="ml-2 pi pi-refresh btn-remove-selected removeFilter" v-tooltip="`Aggiorna`" @click="initData()" style="font-size: 1.2rem"></i>

        <div class="grid m-0">
          <div class="col-12 md:col-4" style="max-height: 90px;">
            <div class="col-12">Filtra per stato caricamento</div>
            <div class="mb-0 flex justify-content-between">
              <Dropdown showClear v-model="selectedStatus" placeholder="Seleziona stato" optionLabel="name" optionValue="name" :options="stati" class="col-12 w-full" @change="onSelectedStatus($event)" style="height: 45px">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <Tag :value="slotProps.value.name" :severity="getSeverityTag(slotProps.value.id)" />
                  </div>
                  <span v-else>
                      {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <Tag :value="slotProps.option.name" :severity="getSeverityTag(slotProps.option.id)"/>
                </template>
              </Dropdown>
            </div>
          </div>
          <div class="col-12 md:col-4" style="max-height: 90px;">
            <div class="col-12">Filtra per data caricamento</div>
            <div class="mb-0 flex justify-content-center">
              <Calendar ref="calendarRef" v-model="selectedDate" @today-click="onTodayClick('data_caricamento')" :maxDate="maxDate" selectionMode="range" :manualInput="false" @update:modelValue="onChangeDate('data_caricamento')" dateFormat="dd/mm/yy" class="w-full" style="height: 45px" showButtonBar/>
            </div>
          </div>
          <div class="col-12 md:col-4" style="max-height: 90px;">
            <div class="col-12">Filtra per data firma</div>
            <div class="mb-0 flex justify-content-center">
              <Calendar ref="calendarRefSign" v-model="selectedSignDate" @today-click="onTodayClick('data_firma')" :maxDate="maxDate" selectionMode="range" :manualInput="false" @update:modelValue="onChangeDate('data_firma')" dateFormat="dd/mm/yy" class="w-full" style="height: 45px" showButtonBar/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="card caricamenti-multisito" v-if="items && items.length > 0">
    <DataTable :value="items" v-model:selection="selectedItems" sortField="id" dataKey="id" :sortOrder="-1" v-model:filters="filters" filterDisplay="row" :globalFilterFields="['agente', 'id', 'rappresentante', 'cliente']" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" breakpoint="1400px" v-model:expandedRows="expandedRows" @rowExpand="onRowExpand">
      <template #header>
        <div class="flex justify-content-between">
          <Button icon="pi pi-external-link" label="Esporta" @click="esportaCaricamenti($event)" />
          <Button class="p-button-help" v-if="currentUser.role == roles.admin.role" icon="pi pi-external-link" label="Esporta Esiti Precheck" @click="esportaEsiti($event)" />
        </div>
      </template>

      <Column expander style="width: 3rem" />
      <!-- @row-select-all="onChangeSelectedItems" -->
      <!-- <Column v-if="currentUser.role == roles.admin.role" selectionMode="multiple" headerStyle="width: 2rem"> -->
        <!-- <template #body="{data, index}">
          <Checkbox v-if="data.is_complete == 1" @change="onCheckRowMultisito(index)"></Checkbox> 
        </template> -->
      <!-- </Column> -->
      <Column field='id' header="#" :showFilterMenu="false" :filterMenuStyle="{ width: '6rem' }" style="width: 10%" :sortable="true">        
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="ids" placeholder="Filtra per id" class="p-column-filter" :showClear="true" filter optionLabel="key" optionValue="key"/>
          <!-- <InputText v-model="filterModel.value" type="numeric" @input="filterCallback()" class="p-column-filter" v-tooltip.bottom="`Filtra per id`" placeholder="Id"/> -->
        </template>
      </Column>

      <!-- <Column field='type_firma' header="Tipologia Contratto" style="min-width: 85px;">
        <template #body="{data}">
          <strong>{{ (data.is_contratto_manuale ? 'Cartaceo' : 'Digitale') + (data.pratica ? ' con ' + data.pratica : '') }}</strong>
        </template>
      </Column> -->
      <Column  field='status' header="Stato" style="width: 5%">
        <template #body="{data}">
          <i :class="`pi pi-circle-on ${getSeverity(data.cod_stato_caricamento)}`" style="font-size: 1rem" v-tooltip="`${getTooltip(data.cod_stato_caricamento)}`"></i>
        </template>
      </Column>
      <Column field="agente" header="Agente" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 30%" >
        <template #body="{data}">
          <div class="flex flex-wrap">
            <span :class="['col-12', {'flex justify-content-end':windowSize < 1400}]" v-if="data.admin_id != 0">
              {{data.admin}} <small>(admin)</small>
            </span>
            <span :class="['col-12', {'flex justify-content-end':windowSize < 1400}]" v-if="data.agente_id != 0">
              {{data.agente}} <small>{{data.agenteMaster != data.agente ? `(${data.agenteMaster})` : ''}}</small>
            </span>

          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="authors" placeholder="Filtra per agente" class="p-column-filter" :showClear="true" filter optionLabel="name" optionValue="name">
            <template #option="slotProps">
              <div class="flex align-items-center">
                {{ slotProps.option.name }}
              </div>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="rappresentante" header="Rappresentante" style="min-width: 40%" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }">
        <template #body="{data}">
          <div class="flex flex-wrap">
            <span :class="['col-12', {'flex justify-content-end':windowSize < 1400}]">{{data.rappresentante ? data.rappresentante : ''}}</span>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="rapps" placeholder="Filtra per rappresentante" class="p-column-filter" :showClear="true" filter optionLabel="name" optionValue="name">
            <template #option="slotProps">
              <div class="flex align-items-center">
                {{ slotProps.option.name }}
              </div>
            </template>
          </Dropdown>
        </template>

      </Column>
      <Column field="cliente" header="Cliente" style="min-width: 40%" :showFilterMenu="false" :filterMenuStyle="{ width: '14rem' }">
        <template #body="{data}">
          <div class="flex flex-wrap">
            <span :class="['col-12', {'flex justify-content-end':windowSize < 1400}]">{{data.cod_type_client != 3 ? data.cliente : ''}}</span>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="clients" placeholder="Filtra per cliente" class="p-column-filter" :showClear="true" filter optionLabel="name" optionValue="name">
            <template #option="slotProps">
              <div class="flex align-items-center">
                {{ slotProps.option.name }}
              </div>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field='data_caricamento' header="Data" :sortable="true" style="width: 8%">
        <template #body="{data}">
          {{moment(data.data_caricamento).format('DD-MM-YYYY HH:mm')}}
        </template>
      </Column>
      <Column field="data_firma" header="Data Firma" style="width: 8%">
        <template #body="{ data }">
          {{data.data_firma ? (!data.is_contratto_manuale ? moment(data.data_firma).format('DD-MM-YYYY HH:mm') : moment(data.data_firma).format('DD-MM-YYYY')) : '--'}}
        </template>
      </Column>
      <Column field="is_complete" header="Firmato" dataType="boolean" style="width: 6%">
        <template #body="{ data }">
          <i class="pi" :class="{ 'pi-check-circle text-green-500': data.is_complete, 'pi-times-circle p-error': !data.is_complete }"></i>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()" />
        </template>
      </Column>
      <Column  field='type' header="Tipo" :sortable="true" style="width: 5%;">
        <template #body="{data}">
          <span v-if="data.type === 'ee'">Energia</span>
          <span v-else-if="data.type === 'gas'">Gas</span>
          <span v-else>Energia & Gas</span>
        </template>
      </Column>
      <Column field='total_rows' header="Righe" :sortable="true" style="width: 4%;"/>
      <Column header="" style="width: 180px;justify-content: start;">
        <template #body="{data}" style="font-size: 10px">
          <div class="grid div-buttons">
            <Button v-if="data.cod_stato_caricamento == 3" @click="insertMissingDocuments(data)" label="Carica documenti mancanti" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-info btn-multisito"/>

            <Button v-if="!data.is_complete && data.cod_status_pratica < 2 && data.cod_stato_caricamento != 8 && currentUser.role == roles.agent.role" @click="deleteUpdate(data)" label="Elimina caricamento" type="button" icon="pi pi-trash" iconPos="left" class="p-button-danger btn-multisito" />

            <Button v-if="currentUser.role == roles.admin.role && data.cod_stato_caricamento != 8" @click="toggle($event, 'annulla_contratto', data)" label="Annulla contratto" type="button" icon="pi pi-trash" iconPos="left" class="p-button-danger btn-multisito" />

            <OverlayPanel ref="op_annulla_contratto" @hide="hideOverlayAnnullaContratto($event)">
              <div class="flex flex-column gap-3 w-25rem">
                <div>
                  <span class="font-medium text-900 block mb-2">Inserisci note annullamento</span>
                  <Textarea v-model="matrice_da_annullare.note_annullamento" rows="5" class="w-full"/>
                </div>
                <div class="m-3 flex justify-content-center">
                  <Button :disabled="!matrice_da_annullare.note_annullamento" label="Conferma annullamento" @click="deleteContratto($event, true, matrice_da_annullare)" size="small" class="p-button-success m-2"></Button>
                  <Button label="Annulla operazione" outlined @click="deleteContratto($event, false)" class="p-button-danger m-2" size="small" text></Button>
                </div>
              </div>
            </OverlayPanel>

            
            <Button v-if="!data.is_complete" @click="showPdf(data)" :label="'Scarica Contratto' + (data.is_complete ? ' firmato' : '')" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-danger btn-multisito" />
            
            <Button v-if="!data.is_complete_rapp && data.rappresentante_id != 0" @click="insertDataRapp(data)" label="Aggiorna Rappresentante" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-warning btn-multisito" />
            
            <Button v-if="data.need_pratiche && !data.data_ricezione_pratiche" @click="insertDataForPratiche(data)" label="Completa Pratiche" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-warning btn-multisito"/>
            
            <Button v-if="currentUser.role == roles.admin.role && data.cod_status_pratica == 1" @click="insertSignedContract(data)" label="Carica documenti firmati" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-info btn-multisito"/>

            <Button v-if="data.data_ricezione_pratiche && !data.is_complete_pratiche" @click="showPratiche(data.id)" :label="'Scarica Pratiche' + (data.cod_status_pratica_moduli == 3 ? ' firmate' : '')" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-danger btn-multisito"/>

            <Button v-if="data.cod_richiesta_firma != data.cod_richiesta_firma_pratiche && data.cod_status_pratica_moduli == 1 && data.data_ricezione_pratiche && !data.is_contratto_manuale && (data.cod_stato_caricamento != 8 && data.cod_stato_caricamento != 9)" @click="signPratiche(data.id)" label="Richiedi Firma pratiche" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-primary btn-multisito"/>
            
            <Button v-if="!data.is_complete && data.is_complete_rapp && (data.cod_stato_caricamento != 8 && data.cod_stato_caricamento != 9)" :disabled="data.cod_status_pratica > 1" @click="signPdf(data)" label="Richiedi Firma contratto" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-primary btn-multisito" />
 
            <div class="col-12" style="margin-left: -0.5rem;" v-if="data.is_complete_rapp">
              <div v-if="data.cod_status_pratica == 2" class="nota">
                <strong>Richiesta firma contratto già inviata</strong>
              </div>
              <div v-if="data.cod_status_pratica == 3" class="nota">
                <strong>Contratto Firmato</strong>
              </div>
              <div v-if="data.cod_status_pratica_moduli == 2" class="nota">
                <strong>Richiesta firma moduli già inviata</strong>
              </div>
              <div v-if="data.cod_status_pratica_moduli == 3" class="nota">
                <strong>Moduli Firmati</strong>
              </div>
            </div>
          </div>
        </template>
      </Column> 
      <template #expansion="slotProps">
        <div v-if="!loadingFilename" class="grid">
          <div class="col-12 flex" v-if="currentUser.role == roles.admin.role && !slotProps.data.is_contratto_manuale && ((!slotProps.data.is_complete && slotProps.data.cod_richiesta_firma) || (!slotProps.data.is_complete_pratiche && slotProps.data.cod_richiesta_firma_pratiche))">
            <Button @click="toggle($event, 'link')" label="Condividi il link di firma" type="button" icon="pi pi-external-link" iconPos="left" class="p-button-raised m-2 p-button-secondary p-button-outlined"  />
            <OverlayPanel ref="op" class="overlayDocs">
              <div class="inline-flex align-items-center justify-content-center mt-3">
                <h3>
                  Condividi il link della firma
                </h3>
              </div>
              
              <p class="p-text-secondary block mb-5">
                <strong>Questo link è personale. Assicuratevi che siano consapevoli del fatto che non devono condividere il loro link con nessuno.</strong>
                In ogni caso, devono inserire il loro indirizzo e-mail per sbloccare l'accesso, che sarà protetto dopo 48h (potranno generare un nuovo link).
              </p>
              
              <div v-for="link in links[slotProps.data.id]" :key="link.name" :header="link.name" class="mb-3">
                <Label><strong>{{ link.name.toUpperCase() }}</strong></Label>
                <br>
                <div class="flex justify-content-between">
                  <InputText class="w-full" v-model="link.link" disabled/>
                  <Button @click="copyLink(link.link)" v-tooltip="`Copia il link negli appunti`" type="button" icon="pi pi-clone" class="m-2 p-button-raised m-2 p-button-secondary p-button-outlined" :disabled="link.link.startsWith('CONTRATTO')"/>
                </div>
              </div>
            </OverlayPanel>

            <!-- Bottone modifica dati richiesta firma-->
            <Button @click="toggle($event, 'modifiche')" label="Modifica dati firmatario" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-raised m-2 p-button-warning"/>
            <OverlayPanel ref="op_modifiche" @hide="hideOverlay" class="overlayDocs">
              <i v-if="!sign_data[slotProps.data.id]" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              <div v-else>
                <div class="inline-flex align-items-center justify-content-center mt-3">
                  <h4>
                    Modifica i dati del firmatario
                  </h4>
                </div>
  
                <div class="flex justify-content-between">
                  <div class="col-6">
                    <Label><strong>Email</strong></Label>
                    <InputText class="w-full" v-model="sign_data_copy[slotProps.data.id].email" @input="setChangedSignData(slotProps.data.id)" @change="setChangedSignData(slotProps.data.id)"/>
                  </div>
                  <div class="col-6">
                    <Label><strong>Contatto</strong></Label>
                    <InputMask mask="999-999999?9" class="w-full" v-model="sign_data_copy[slotProps.data.id].contatto" @input="setChangedSignData(slotProps.data.id)" @change="setChangedSignData(slotProps.data.id)"/>
                  </div>
                </div>
                <div class="flex justify-content-center mt-3">
                  <Button :disabled="changed_sign_data[slotProps.data.id]" @click="sendSignData(slotProps.data.id)" label="Salva" type="button" class="m-2 p-button-success" style="width: fit-content;"/>
                </div>
              </div>
            </OverlayPanel>
          </div>

          <div class="col-12 flex" v-if="currentUser.role == roles.agent.role && !slotProps.data.is_contratto_manuale && ((!slotProps.data.is_complete && slotProps.data.cod_richiesta_firma) || (!slotProps.data.is_complete_pratiche && slotProps.data.cod_richiesta_firma_pratiche))">
            <!-- Bottone richiesta modifica dati richiesta firma-->
            <Button @click="toggle($event, 'richiesta_modifiche')" label="Richiedi modifica dati firmatario" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-raised m-2 p-button-warning"/>
            <OverlayPanel ref="op_richiesta_modifiche" @hide="hideOverlay" class="overlayDocs">
              <i v-if="!sign_data[slotProps.data.id]" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              <div v-else>
                <div class="inline-flex align-items-center justify-content-center mt-3">
                  <h4>
                    Modifica i dati del firmatario
                  </h4>
                </div>
  
                <div class="flex justify-content-between">
                  <div class="col-6">
                    <Label><strong>Email</strong></Label>
                    <InputText class="w-full" v-model="sign_data_copy[slotProps.data.id].email" @input="setChangedSignData(slotProps.data.id)" @change="setChangedSignData(slotProps.data.id)"/>
                  </div>
                  <div class="col-6">
                    <Label><strong>Contatto</strong></Label>
                    <InputMask mask="999-999999?9" class="w-full" v-model="sign_data_copy[slotProps.data.id].contatto" @input="setChangedSignData(slotProps.data.id)" @change="setChangedSignData(slotProps.data.id)"/>
                  </div>
                </div>
                <div class="flex justify-content-center mt-3">
                  <Button :disabled="changed_sign_data[slotProps.data.id]" @click="sendRequestSignData(slotProps.data.id)" label="Salva" type="button" class="m-2 p-button-success" style="width: fit-content;"/>
                </div>
              </div>
            </OverlayPanel>
          </div>

          <!-- Dettaglio Contratto-->
          <div class="col-12">
            <Fieldset legend="Dettagli contratto">
              Contratto {{ typesClient.find(type => type.id == slotProps.data.cod_type_client).name }} {{ slotProps.data.is_iva_agevolata ? 'con iva agevolata' : '' }}
              <br>
              Tipologia firma: {{ slotProps.data.is_contratto_manuale ? 'Cartaceo' : 'Digitale' }}
              {{ slotProps.data.pratica ? ' con ' + slotProps.data.pratica : '' }}
            </Fieldset>
          </div>

          <!-- Note -->
          <div class="col-12" v-if="slotProps.data.note">
            <Fieldset legend="Note">
              {{ slotProps.data.note }}
            </Fieldset>
          </div>

          <!-- Dettaglio Rappresentante/Cliente-->
          <div class="col-12 md:col-6">
            <Fieldset legend="Rappresentante" v-if="slotProps.data.rappresentante_id">
              {{ slotProps.data.rappresentante }}               
              <br>
              {{ slotProps.data.codfis }}
            </Fieldset>
            <Fieldset legend="Cliente" v-else>
              {{ slotProps.data.cliente }}               
              <br>
              {{ slotProps.data.forniture.ee ? slotProps.data.forniture.ee[0].cf_cliente : slotProps.data.forniture.gas[0].cf_cliente }}
            </Fieldset>
          </div>
          <!-- Dettaglio Contatto-->
          <div class="col-12 md:col-6">
            <Fieldset legend="Contatto">
              {{ slotProps.data.contatto.toponimo }} {{ slotProps.data.contatto.indirizzo }} {{ slotProps.data.contatto.civico }}, {{ slotProps.data.contatto.cap }} - {{ slotProps.data.contatto.name }}
              <br>
              {{ slotProps.data.contatto.email }} - {{ slotProps.data.contatto.cell }}
            </Fieldset>
          </div>

          <!-- Bottoni Documenti -->
          <div class="col-12">
            <Fieldset legend="Documenti">
              <span class="font-medium text-900 block mb-2">{{loadingFilename ? 'Download in corso...' : ''}}</span>
              <div v-if="loadingFilename == false">
                <div class="flex flex-wrap" v-if="newFilesView[slotProps.data.id] != null && newFilesView[slotProps.data.id].length != 0 ">
                  <Button @click="downloadZip(slotProps.data.id)" label="Scarica tutti i file" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-success m-2"/>

                  <Button v-for="file in newFilesView[slotProps.data.id]" :key="file.filename" @click="downloadPdf(slotProps.data.id, file.filename)" :label="file.filename" type="button" icon="pi pi-file-pdf" iconPos="left" class="p-button-info m-2"/>
                </div>
                <div v-else>
                  <span class="font-medium text-900 block mb-2">Non ci sono file da visualizzare</span>
                </div>
              </div>
            </Fieldset>
          </div>
          
          <!-- Dettaglio Forniture-->
          <div class="col-12">
            <Fieldset legend="Forniture">
              <div>
                <div class="col-12" v-if="slotProps.data.forniture.ee">
                  <h4>Elettrico</h4>
                  <DataTable :value="slotProps.data.forniture.ee" showGridlines :alwaysShowPaginator="false" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"  sortField="cod_esito_precheck" :sortOrder="-1">
                    <Column  field='status' header="Stato" style="width: 6rem;">
                      <template #body="{data}">
                        <p>{{ data.caricato ? 'Caricato' : '' }}</p>
                      </template>
                    </Column>
                    <Column field="denominazione_cliente" header="Cliente"></Column>
                    <Column header="CF/Piva">
                      <template #body="{data}">
                        {{data.cf_cliente}} {{data.piva_cliente ? '- '+data.piva_cliente : ''}}
                      </template>
                    </Column>
                    <Column field="iban_contratto" header="IBAN"  style="width: 20rem;"></Column>
                    <Column header="Indirizzo di fornitura">
                      <template #body="{data}">
                        <span v-if="data.toponimo_fornitura">{{data.toponimo_fornitura}} {{data.indirizzo_fornitura}} {{data.numero_civico_fornitura}}, {{data.cap_fornitura}} - {{data.citta_fornitura}}</span>
                      </template>
                    </Column>
                    <Column field="pod" header="POD" style="width: 10rem;"></Column>
                    <Column field="nome_offerta" header="Offerta"></Column>
                    <Column header="Tipo pratica" style="width: 10rem;">
                      <template #body="{data}">
                        <p v-if="data.in_voltura">Voltura</p>
                        <p v-else-if="data.in_subentro">Subentro</p>
                        <p v-else>Switch</p>
                      </template>
                    </Column>
                    <Column field="cod_esito_precheck" sortable header="Esito Precheck">
                      <template #body="{data}">
                        {{data.precheck != 2 ? stati_precheck.find(stato => stato.code == data.precheck).description : data.dettaglio_esito_precheck}}
                        <!-- {{data.dettaglio_esito_precheck}} -->

                        <!-- {{data.precheck == 1 ? 'OK' : data.dettaglio_esito_precheck}} -->
                      </template>
                    </Column>
                    <Column header="CF/Piva Precedente" v-if="slotProps.data.has_s1_v1">
                      <template #body="{data}">
                        {{data.cf_attivazione }} {{data.piva_attivazione ? '- '+data.piva_attivazione : ''}}
                      </template>
                    </Column>
                    <Column field="has_vt4" header="VT4" style="width: 5rem;">
                      <template #body="{data}">
                        {{data.has_vt4 ? 'Si' : ''}}
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div class="col-12" v-if="slotProps.data.forniture.gas">
                  <h4>Gas</h4>
                  <DataTable :value="slotProps.data.forniture.gas" showGridlines :alwaysShowPaginator="false" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" sortField="cod_esito_precheck" :sortOrder="-1">
                    <Column  field='status' header="Stato" style="width: 6rem;">
                      <template #body="{data}">
                        <p>{{ data.caricato ? 'Caricato' : '' }}</p>
                      </template>
                    </Column>
                    <Column field="denominazione_cliente" header="Cliente"></Column>
                    <Column header="CF/Piva">
                      <template #body="{data}">
                        {{data.cf_cliente}} {{data.piva_cliente ? '- '+data.piva_cliente : ''}}
                      </template>
                    </Column>
                    <Column field="iban_contratto" header="IBAN"  style="width: 20rem;"></Column>
                    <Column header="Indirizzo di fornitura">
                      <template #body="{data}">
                        <span v-if="data.toponimo_fornitura">{{data.toponimo_fornitura}} {{data.indirizzo_fornitura}} {{data.numero_civico_fornitura}}, {{data.cap_fornitura}} - {{data.citta_fornitura}}</span>
                      </template>
                    </Column>
                    <Column field="pdr" header="PDR" style="width: 10rem;"></Column>
                    <Column field="nome_offerta" header="Offerta"></Column>
                    <Column header="Tipo pratica" style="width: 10rem;">
                      <template #body="{data}">
                        <p v-if="data.in_voltura">Voltura</p>
                        <p v-else-if="data.in_subentro">Subentro</p>
                        <p v-else>Switch</p>
                      </template>
                    </Column>
                    <Column field="cod_esito_precheck" sortable header="Esito Precheck">
                      <template #body="{data}">
                        {{data.precheck != 2 ? stati_precheck.find(stato => stato.code == data.precheck).description : data.dettaglio_esito_precheck}}
                        <!-- {{data.dettaglio_esito_precheck}} -->


                        <!-- {{data.precheck == 1 ? 'OK' : data.dettaglio_esito_precheck}} -->
                      </template>
                    </Column>
                    <Column header="CF/Piva Precedente" v-if="slotProps.data.has_s1_v1">
                      <template #body="{data}">
                        {{data.cf_attivazione }} {{data.piva_attivazione ? '- '+data.piva_attivazione : ''}}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </Fieldset>
          </div>

          <!-- Dettaglio Note KO-->
          <div class="col-12" v-if="slotProps.data.note_ko.length">
            <Fieldset legend="Note KO Documentale">
              <div class="col-12">
                <li v-for="(nota) in slotProps.data.note_ko" :key="nota" class="flex justify-content-between nota">
                  {{ moment(nota.created_at).format('DD-MM-YYYY') }}: <strong>{{ nota.nota }}</strong>
                </li>
              </div>
            </Fieldset>
          </div>

        </div>
        <div v-else>
          <Loader/>
        </div>
      </template>
    </DataTable>
  </div>
  <div v-else class="field card">
    <Skeleton class="mb-2"></Skeleton>
    <Skeleton width="10rem" class="mb-2"></Skeleton>
    <Skeleton width="5rem" class="mb-2"></Skeleton>
    <Skeleton height="2rem" class="mb-2"></Skeleton>
    <Skeleton width="10rem" height="4rem"></Skeleton>
  </div>
  <!-- <div v-else>
    <ProgressSpinner style="width:150px;height:150px" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
  </div> -->

  <Dialog id="modalShow" v-model:visible="showModal" :style="{width: '450px'}" :modal="true">
    <div class="formgrid grid">  
      <div class="p-field col-12 md:col-6">
        <h4>{{ rappresentante.name }}</h4>
      </div>
      <div class="p-field col-12 md:col-12">
        <label for="cod_fiscale">Codice fiscale del rappresentante o p.iva dello studio </label>

        <br>
        <InputText id="cod_fiscale" v-model="rappresentante.cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.cod_fiscale && submitted}]" @change="onSelected($event, 'cod_fiscale')" />
        <div v-if="validationErrors.cod_fiscale && submitted" class="p-error">Formato errato.</div>
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="phone">Telefono</label><br>
          <InputText id="phone" @change="onSelected($event, 'phone')" v-model="rappresentante.phone" :class="['inputfield w-full',{'p-invalid': validationErrors.phone && submitted}]" />
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="mobile">Cellulare</label><br>                   
          <InputMask @change="onSelected($event, 'mobile')" id="mobile" mask="999-999999?9" v-model="rappresentante.mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.mobile && submitted}]" />
          <small v-show="validationErrors.mobile && submitted" class="p-error">Campo richiesto.</small>
          <small v-show="validationErrors.mobile" class="p-error">Ricontrolla il numero di cellulare inserito.</small>
      </div> 
      <Dialog id="modalShowMobile" v-model:visible="showModalMobile" :style="{width: '450px'}" :modal="true">
        <div class="confirmation-content">          
          <p>Hai inserito il numero telefonico: {{ rappresentante.mobile }}</p> 
          <p>É corretto?</p>   
        </div>
        <template #footer>
          <Button @click="isCorrect(true)" label="Si" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito mb-2" />
          <Button @click="isCorrect()" label="No" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito mb-2" />
        </template>
      </Dialog>
      <div class="p-field col-12 md:col-6">
          <label for="email">Email</label><br>
          <InputText @change="onSelected($event, 'email')" id="email" v-model="rappresentante.email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" />
          <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto o formato non consentito.</div>
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="pec">Pec</label><br>
          <InputText @change="onSelected($event, 'pec')" id="pec" v-model="rappresentante.pec" class="inputfield w-full"/>
      </div>
      <div class="p-field col-12  md:col-6">
          <label for="toponimo">Toponimo</label><br>
          <Dropdown @change="onSelected($event, 'toponimo')" id="toponimo" v-model="rappresentante.toponimo" :options="toponimi" optionLabel="name" :filter="true" :editable="true" optionValue="name" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo && submitted}]" />
          <small v-show="validationErrors.toponimo && submitted" class="p-error">Campo richiesto.</small>
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="address">Indirizzo</label><br>
          <InputText @change="onSelected($event, 'address')" id="address" v-model="rappresentante.address" :class="['inputfield w-full',{'p-invalid': validationErrors.address && submitted}]" />
          <small v-show="validationErrors.address && submitted" class="p-error">Campo richiesto.</small>
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="civico">Civico</label><br>
          <InputText @change="onSelected($event, 'civico')" id="civico" v-model="rappresentante.civico" :class="['inputfield w-full',{'p-invalid': validationErrors.civico && submitted}]" />
          <small v-show="validationErrors.civico && submitted" class="p-error">Campo richiesto.</small>
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="cap">Cap</label><br>
          <InputText @change="onSelected($event, 'cap')" id="cap" v-model="rappresentante.cap" :class="['inputfield w-full',{'p-invalid': validationErrors.cap && submitted}]" />
          <small v-show="validationErrors.cap && submitted" class="p-error">Campo richiesto.</small>
      </div>
      <div class="p-field col-12  md:col-6">
          <label for="district">Provincia</label><br>
          <Dropdown @change="onSelected($event, 'selectedDistrict')" id="district" v-model="rappresentante.selectedDistrict" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedDistrict && submitted}]" />
          <small v-show="validationErrors.selectedDistrict && submitted" class="p-error">Campo richiesto.</small>
      </div>
      <div class="p-field col-12 md:col-6">
          <label for="city">Comune</label><br>
          <Dropdown id="city" @change="onSelected($event, 'cityId')" v-model="rappresentante.cityId" :options="cities" optionLabel="name" optionValue="id" :filter="true" placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.cityId && submitted}]" />
          <small v-show="validationErrors.cityId && submitted" class="p-error">Campo richiesto.</small>
      </div>
      <div class="p-field col-12 flex align-items-center flex-column mt-3">
        <div class="text-center">
          <div class="button-wrapper mb-2">
            <span class="label">Carica File</span>
            <input id="upload" name="files" type="file" ref="file" @change="onSelected($event, 'files')" accept="application/pdf,image/gif, image/jpeg, image/jpg,image/png" multiple/>
          </div>
          <div>
            File selezionati:
            <ul>
              <li v-for="file in files" :key="file.name">{{ file.name }}</li>
            </ul>
          </div>
          <div v-show="validationErrors.files && submitted" class="p-error">Campo richiesto o formato non consentito.</div>
        </div>
        <div class="text-center">
          <small>Formati consentiti: {{allowedTypeDoc.join(", ")}}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <Button @click="updateRapp(rappresentante)" label="Salva" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito mb-2" />
      <Button @click="deleteChanges" label="Annulla" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito mb-2" />
    </template>

  </Dialog>

  <Dialog id="modalPraticheShow" v-model:visible="showModalPratiche" :style="{width: '1300px'}" :modal="true" :dismissableMask="true" @hide="deleteChangesPratiche()">
    <TabView>
      <TabPanel header="Gestisci pratiche">
        <div class="formgrid grid">  
          <div v-for="(array_pratica, pratica) in all_pratiche" :key="pratica">
            <div v-if="array_pratica.length">
              <div v-if="pratica == 'voltura'">
                <DataTable :value="array_pratica">
                  <template #header>
                    Pratiche di voltura
                  </template>
                  <Column header="CF NUOVO CLIENTE">
                    <template #body="{data}">
                      {{ data.cf }}
                    </template>
                  </Column>
                  <Column header="PIVA NUOVO CLIENTE" v-if="![2,3].includes(type_client_pratiche)">
                    <template #body="{data}">
                      {{ data.piva }}
                    </template>
                  </Column>
                  <Column header="FORNITURA">
                    <template #body="{data}">
                      {{ data.code }}
                    </template>
                  </Column>
                  <Column header="CF PRECEDENTE INTESTATARIO">
                    <template #body="{data, index}">
                      <InputText v-model="data[`cf_attivazione_${data.tipo}`]" autofocus @input="onInputCell($event, index, pratica, data.tipo, 'cf')"/>
                      
                      <div v-show="validationErrors[`cf_attivazione_${data.tipo}_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                      <div v-show="validationErrors[`cf_attivazione_${data.tipo}_format_${index}`]" class="p-error mt-1" style="font-size:11px">Formato non valido</div>
                      <div v-show="validationErrors[`cf_attivazione_${data.tipo}_invalid_${index}`]" class="p-error mt-1" style="font-size:11px">Lunghezza non valida</div>
                      <div v-show="validationErrors[`cf_attivazione_${data.tipo}_equal_${index}`]" class="p-error mt-1" style="font-size:11px">CF ATTIVAZIONE non può essere uguale al codice fiscale del cliente da attivare</div>
                    </template>
                  </Column>
                  <Column header="PIVA PRECEDENTE INTESTATARIO">
                    <template #body="{data, index}">
                      <InputMask mask="99999999999" v-model="data[`piva_attivazione_${data.tipo}`]" autofocus @update:modelValue="onInputCell($event, index, pratica, data.tipo, 'piva')"/>
                      <div v-show="validationErrors[`piva_attivazione_${data.tipo}_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                      <div v-show="validationErrors[`piva_attivazione_${data.tipo}_format_${index}`]" class="p-error mt-1" style="font-size:11px">Formato non valido</div>
                      <div v-show="validationErrors[`piva_attivazione_${data.tipo}_equal_${index}`]" class="p-error mt-1" style="font-size:11px">PIVA ATTIVAZIONE non può essere uguale alla partita iva del cliente da attivare</div>
                    </template>
                  </Column>
                  <Column header="DATA DECORRENZA">
                    <template #body="{data}">
                      <Calendar v-model="data['data_decorrenza']" :numberOfMonths="2" :minDate="minDate" :manualInput="false" dateFormat="dd/mm/yy" showIcon/>
                    </template>
                  </Column>
                  <Column header="FILE">
                    <template #body="{data}">
                      <div class="button-wrapper">
                        <span class="label flex justify-content-center align-items-center">Carica {{data.tipo_pratica}}</span>
                        <input class="uploadModuli" :id="`${data.documento.ref}`" :name="`${data.documento.ref}`" type="file" :ref="data.documento.ref" accept="application/pdf,image/gif,image/jpeg,image/jpg,image/png" @change="handleFilePratiche($event, data.documento.ref, pratica)"/>
                      </div>
                      <div class="m-1">{{data.documento.nome}}</div>
                      <div v-show="validationErrors[data.documento.ref]" class="p-error mt-1" style="font-size:11px">Documento necessario</div>

                    </template>
                  </Column>
                </DataTable>
              </div>
              <div v-else>
                <DataTable :value="array_pratica">
                  <template #header>
                    Pratiche di subentro
                  </template>
                  <Column header="FORNITURA">
                    <template #body="{data}">
                      {{ data.code }}
                    </template>
                  </Column>
                  <Column>
                    <template #body="{data}">
                      <div v-if="data.code.startsWith('IT')">
                        Nessun dato richiesto
                      </div>
                      <div v-else>
                        <Dropdown v-model="data['remi']" 
                        :options="remiOption[data.code]" 
                        placeholder="Seleziona Remi" 
                        @change="onSelectedRemi(data.code)"
                        optionValue="remi" optionLabel="remi"
                        />
                        <div v-show="validationErrors[`remi_${data.code}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                      </div>
                    </template>
                  </Column>
                  <Column header="DATA DECORRENZA">
                    <template #body="{data}">
                      <Calendar v-model="data['data_decorrenza']" :numberOfMonths="2" :minDate="minDate" :manualInput="false" dateFormat="dd/mm/yy" showIcon/>
                    </template>
                  </Column>
                  <Column header="FILE">
                    <template #body="{data}">
                      <div class="button-wrapper">
                        <span class="label">Carica {{data.tipo_pratica}}</span>
                        <input class="uploadModuli" :id="`${data.documento.ref}`" :name="`${data.documento.ref}`" type="file" :ref="data.documento.ref" accept="application/pdf,image/gif,image/jpeg,image/jpg,image/png" @change="handleFilePratiche($event, data.documento.ref, pratica)"/>
                      </div>
                      <div class="m-1">{{data.documento.nome}}</div>
                      <div v-show="validationErrors[data.documento.ref]" class="p-error mt-1" style="font-size:11px">Documento necessario</div>

                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>            
          <footer class="mt-5 col-12 flex justify-content-center">
            <div v-if="sent_pratica">
              <div v-if="is_contratto_manuale">
                <div class="m-2">
                  Dati inseriti correttamente.
                </div>
                <div class="flex justify-content-center m-2">
      
                  <Button @click="deleteChangesPratiche()" label="Chiudi" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito m-2"/>
                </div>
      
              </div>
              <div v-else>
                <Button @click="showPratiche()" label="Scarica Moduli Pratiche" type="button" icon="pi pi-eye" iconPos="left" class="p-button-danger btn-multisito mb-2" />
                <Button @click="signPratiche(id_matrice_pratiche)" label="Richiedi Firma pratiche" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-primary btn-multisito mb-2"/>
              </div>
            </div>
            <div v-else>
              <Button @click="sendPratiche" label="Invia pratiche" type="button" icon="pi pi-check" iconPos="left" class="p-button-success btn-multisito m-2" :disabled="isDisabledSendPratiche"/>
              <Button @click="deleteChangesPratiche()" label="Annulla" type="button" icon="pi pi-times" iconPos="left" class="p-button-danger btn-multisito m-2"/>
            </div>
          </footer> 
        </div>      
      </TabPanel>
      <TabPanel header="Modifica Dati">
        <div class="formgrid grid">  
          <div v-if="modifiche.length">
            <div class="card flex justify-content-around m-2" v-if="type_client_pratiche != 3">
              <div class="flex flex-column">
                <label>CODICE FISCALE</label>
                <InputMask  class="text_toupper" v-model="unique_cf" :mask="![2,4].includes(type_client_pratiche) ? '99999999999' : 'aaaaaa99a99a999a'" @update:modelValue="onInput($event, 'unique_cf')"/>
                <div v-show="validationErrors.unique_cf" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
              </div>

              <div class="flex flex-column" v-if="![2].includes(type_client_pratiche)">
                <label>Partita IVA</label>
                <InputMask v-model="unique_piva" mask="99999999999" @update:modelValue="onInput($event, 'unique_piva')"/>
                <div v-show="validationErrors.unique_piva" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
              </div>
            </div>

            <DataTable :value="modifiche" v-model:editingRows="editingRows" editMode="cell">
              <Column field="cf" header="CODICE FISCALE" v-if="type_client_pratiche == 3">
                <template #body="{ data, index }">
                  <div>{{data.cf == '' ? 'Inserisci...' : data.cf}}</div>
                  <div v-show="validationErrors[`cf_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                </template>

                <template #editor="{ data, index }">
                  <InputMask v-model="data.cf" mask="99999999999" @update:modelValue="onInput($event, 'cf', index)"/>
                </template>
              </Column>
              <Column field="code" header="FORNITURA">
                <template #body="{ data, index }">
                  <div>{{data.code == '' ? 'Inserisci...' : data.code}}</div>
                  <div v-show="validationErrors[`code_${index}`]" class="p-error mt-1" style="font-size:11px">Campo necessario</div>
                  <div v-show="validationErrors[`existent_${index}`]" class="p-error mt-1" style="font-size:11px">Duplicato</div>
                </template>
                <template #editor="{ data, index }">
                  <InputMask v-model="data.code" :mask="data.mask" @update:modelValue="onInput($event, 'code', index)"/>
                </template>
              </Column>
              <Column :exportable="false" v-if="all_supplies > 1">
                <template #body="{ data, index }">
                  <ConfirmPopup group="headless">
                    <template #container="{ message, acceptCallback, rejectCallback }">
                      <div class="border-round p-3">
                        <span>{{ message.message }}</span>
                        <div class="flex align-items-center gap-2 mt-3">
                          <Button label="Si" @click="acceptCallback" size="small" class="p-button-success"></Button>
                          <Button label="No" outlined @click="rejectCallback" class="p-button-decondary" size="small" text></Button>
                        </div>
                      </div>
                    </template>
                  </ConfirmPopup>
                  <div class="flex justify-content-center">
                    <Button @click="requireConfirmation($event, data, index)" class="p-button-danger" label="Elimina Fornitura"/>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
          <footer class="mt-5 col-12 flex justify-content-center">
            <Button @click="saveChangesMultisito()" label="Salva modifiche" type="button" icon="pi pi-times" iconPos="left" class="p-button-success btn-multisito m-2"/>
          </footer> 
        </div>      
      </TabPanel>
    </TabView>
  </Dialog>

  <!-- Dialog new Matrice -->
  <Dialog v-model:visible="newMatriceDialog" :style="{width: '80%'}" header="Aggiungi Matrice" :modal="true">
      <div class="grid form-file"> 
        <div class="col-12">
          <div>Tipo matrice: </div><br>
          <Dropdown v-model="typeMatrice" :options="typesMatrice" optionLabel="name" optionValue="val" placeholder="Seleziona tipo matrice" class="inputfield w-full" />
          <div v-show="validationErrors.type_matrice && submitted" class="p-error">Campo richiesto.</div>
        </div>
        <div class="col-12">
          <label>Tipo Clienti</label>
          <Dropdown v-model="selectedTypeClient" :options="typesClient" optionLabel="name" optionValue="id" placeholder="Seleziona tipo cliente"  :class="['inputfield w-full',{'p-invalid': validationErrors.selectedTipo && submitted}]" />
          <div v-show="validationErrors.type_client && submitted" class="p-error">Campo richiesto.</div>
        </div>
        <div class="col-12" style="display:none">
          <label class="mb-1">Offerte applicabili</label><br>
          <div v-for="offerta in offerte" :key="offerta.id" class="badge-offerta">
            {{offerta.name}}
          </div>
        </div>
        <div class="col-12 md:col-6 my-4">
          <Checkbox v-model="isIvaAgevolata" :binary="true"/>
          <label class="ml-2"> Iva Agevolata </label>
        </div>
        <div class="col-12">
          <label>File</label>
          <div class="button-wrapper">
            <span :class="['label', {'active': fileToUpload.length > 0}]">
              {{fileToUpload.length > 0 ? fileToUpload[0].name : 'Seleziona File' }}
            </span>
            <input id="upload" type="file" ref="file" @change="handleFileUpload( $event )" :accept="`${Object.values(allowedType).join(', ')}`" />
          </div>
          <div class="text-center allowed-format"> Formati consentiti: {{Object.keys(allowedType).join(', ')}} </div>
          <div v-show="validationErrors.files && submitted" class="p-error">Campo richiesto.</div>
        </div>
      </div>
      
      <template #footer>
        <Button label="Annulla" icon="pi pi-times" class="p-button-text p-button-secondary" @click="newMatriceDialog = false"/>
        <Button label="Aggiungi" icon="pi pi-check" class="p-button-text" @click="uploadNewMatrice" />
      </template>
  </Dialog>


  <!-- Dialog insert signed contract -->
  <Dialog v-model:visible="insertSignedContractDialog" :style="{width: '80%'}" header="Aggiungi documenti firmati" :modal="true" :dismissableMask="true" @hide="deleteInsertSignedContract()">
    <div class="flex flex-wrap justify-content-center">
      <div v-for="document in requiredDocuments" :key="document.name" class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2">
        <div class="flex align-items-center flex-column mb-3 justify-content-center">
          <div class="button-wrapper-file mb-2">
            <span class="label">Carica {{document.name}}</span>
            <input class="upload-file" :name="`${document.ref}`" type="file" :ref="document.ref" @change="handleFileNewUpload($event, document.ref)" accept="application/pdf,image/gif,image/jpeg,image/jpg,image/png" :multiple="document.multiple" :disabled="isDisableInput"/>
          </div>
          <div>
            <p class="text-center">
              File selezionati:
            </p>
            <ul>
              <li v-for="file in document.files" :key="file.name">{{ file.name }}</li>
            </ul>
          </div>
          <div v-show="validationErrors[`${ref}`] && submitted" class="p-error">Campo richiesto.</div>
          <div v-show="validationErrors[`type_${ref}`] && submitted" class="p-error">Formato non consentito</div>
        </div>
      </div>
    </div>      
    <div class="text-center">
      <small>Formati consentiti: {{allowedTypes.join(", ")}}</small>
    </div>
    <div class="mt-3 grid justify-content-center">
      <div class="flex flex-column">
        <Label>Data firma:</Label>
        <Calendar v-model="data_firma_cartaceo" :maxDate="maxDateSigned" :manualInput="false" dateFormat="dd/mm/yy" @update:modelValue="onChangeDataFirma"/>
        <div v-show="validationErrors.data_firma_cartaceo && submitted" class="p-error">Campo richiesto.</div>
      </div>
    </div>
    <template #footer>
      <Button :disabled="isDisabledSetFirmato" label="Invia documenti" icon="pi pi-check" class="p-button-success" @click="setFirmato($event, matrice_for_signed_contract.id)" />
    </template>
  </Dialog>

    <!-- Dialog insert missing documents -->
  <Dialog v-model:visible="insertMissingDocumentsDialog" :style="{width: '80%'}" header="Aggiungi documenti mancanti" :modal="true" :dismissableMask="true" @hide="deleteInsertMissingDocuments()">
    <div class="flex flex-wrap justify-content-center">
      <div v-for="document in missingDocuments" :key="document.name" class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2">
        <div class="flex align-items-center flex-column mb-3 justify-content-center">
          <div class="button-wrapper-file mb-2">
            <span class="label">Carica {{document.name}}</span>
            <input class="upload-file" :name="`${document.ref}`" type="file" :ref="document.ref" @change="handleFileMissing($event, document.ref)" accept="application/pdf,image/gif,image/jpeg,image/jpg,image/png" :multiple="document.multiple" :disabled="isDisableInputMissing"/>
          </div>
          <div>
            <p class="text-center">
              File selezionati:
            </p>
            <ul>
              <li v-for="file in document.files" :key="file.name">{{ file.name }}</li>
            </ul>
          </div>
          <div v-show="validationErrors[`${ref}`] && submitted" class="p-error">Campo richiesto.</div>
          <div v-show="validationErrors[`type_${ref}`] && submitted" class="p-error">Formato non consentito</div>
        </div>
      </div>
    </div>      
    <div class="text-center">
      <small>Formati consentiti: {{allowedTypes.join(", ")}}</small>
    </div>
    <template #footer>
      <Button :disabled="isDisabledUploadNewFile" label="Invia documenti" icon="pi pi-check" class="p-button-success" @click="uploadNewFile($event, matrice_for_missing_documents.id)" />
    </template>
  </Dialog>

  <Button v-if="currentUser.role == roles.admin.role && selectedItems.length > 0" @click="downloadsMatrici" class="p-button-warning btn-downloads-matrici p-3 m-1"  style="min-width:200px">
    Scarica matrici selezionate <i class="pi pi-download ml-5"></i>
  </Button>
  
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import { FilterMatchMode } from 'primevue/api';
import moment from 'moment'
import {ref, onMounted, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Loader from "@/components/Loader.vue"
import { useToast } from 'primevue/usetoast';
import roles from '@/config/roles'
import { useRoute, useRouter } from 'vue-router'
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    Loader
  },  
  setup (props, {emit}) {
    const dates_firma = ref();
    const authors = ref([]);
    const rapps = ref([]);
    const clients = ref([]);
    const ids = ref([]);
    const route = useRoute()
    const router = useRouter()
    var XLSX = require("xlsx");

    const parametroGet = route.query.matrice_id
    const filters = ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        id: { value: null, matchMode: FilterMatchMode.CONTAINS},
        agente: { value: null, matchMode: FilterMatchMode.CONTAINS },
        rappresentante: { value: null, matchMode: FilterMatchMode.CONTAINS },
        cliente: { value: null, matchMode: FilterMatchMode.CONTAINS },
        is_complete: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const toast = useToast();
    const store = useStore()
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const loading = ref(false)
    const currentUser = ref(store.state.auth.user)
    const items = ref([]);
    const selectedItems = ref([]);
    const contatori = ref([]);
    const newMatriceDialog = ref(null)
    const insertSignedContractDialog = ref(null)
    const insertMissingDocumentsDialog = ref(null)
    const typesMatrice = ref([
      {name: 'Energia', val: 'ee'},
      {name: 'Gas', val: 'gas'}
    ])
    const typeMatrice = ref()
    const matriceUploadKey = ref(0);
    const typesClient = ref([])
    const selectedTypeClient = ref(null)
    const offerte = ref([])
    const selectedOfferte = ref([])
    const isIvaAgevolata = ref(false)
    const loaded = ref(true)
    const fileToUpload = ref([])
    const allowedType = {
      'xlsx' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    const allowedTypes = ['pdf','jpeg','jpg','png']
    const vs = ref([])
    const ctr = ref([])
    const matrice_for_signed_contract = ref()
    const matrice_for_missing_documents = ref()
    const isDisableInput = ref(false)
    const isDisableInputMissing = ref(false)
    const maxDateSigned = new Date()
    const data_firma_cartaceo = ref()

    const validationErrors = ref({})
    const submitted = ref(false)
    const showModalMobile = ref(false)
    const showModal = ref(false)
    const showModalPratiche = ref(false)
    const districts = ref([])
    const cities = ref([])
    const toponimi = ref([])
    const allowedTypeDoc = ['pdf','jpeg','jpg','png']
    const files = ref([])
    const moduli = ref([])
    const matrice_id = ref('')
    // const pratiche_s1_ee = ref([])
    // const pratiche_s1_gas = ref([])
    // const pratiche_v1_ee = ref([])
    // const pratiche_v1_gas = ref([])
    const id_matrice_pratiche = ref('')
    const is_contratto_manuale = ref()
    const windowSize = ref(window.innerWidth)
    const all_supplies = ref(0)
    const file_caricati = ref(0)
    const all_file_pratiche = ref({})
    const all_pratiche = ref({
      voltura:[],
      subentro:[]
    })

    // const has_piva = ref()
    // const is_number = ref()
    const editingRows = ref([]);
    const modifiche = ref([])
    const confirm = useConfirm();

    const type_client_pratiche = ref()
    const unique_cf = ref()
    const unique_piva = ref()

    const sent_pratica = ref(false)
    const isDisableShowPratiche = ref(false);
    const now = new Date()
    const minDate = ref(new Date(new Date(now).setDate(now.getDate() + 2)))
    const selectedStatus = ref()
    const selectedDate = ref([
      moment().startOf("month").toDate(),
      new Date(),
    ]);
    const selectedSignDate = ref();
    const maxDate = ref(new Date())
    const rappresentante = ref({
      cod_fiscale:'',
      name: '',
      phone: '',
      mobile: null,
      email: '',
      pec: '',
      toponimo: null,
      address:'',
      civico: '',
      cap: '',
      selectedDistrict:null,
      selectedCity: null,
      cityId: 0,
      id_existent: '',
      contatto_id:''
    })
    const stati_precheck = ref()
    const stati = ref([
      {
        id:1,
        name:'in validazione'
      },
      {
        id:2,
        name:'in precheck'
      },
      {
        id:3,
        name:'ko documenti'
      },
      {
        id:6,
        name:'caricato'
      },
      {
        id:7,
        name:'caricato con ko'
      },
      {
        id:8,
        name:'annullato'
      },
      {
        id:9,
        name:'bloccato'
      },
      {
        id:11,
        name:'standby'
      }
    ]);
    const totali = ref({})
    const loadingFilename = ref(false)
    const filesView = ref();
    const newFilesView = ref([]);
    const expandedRows = ref([]);
    const links = ref([])
    const op = ref();
    const confirmDeleteRow = ref(false)
    const row_in_delete = ref()
    const op_annulla_contratto = ref()
    const matrice_da_annullare = ref()

    const op_modifiche = ref();
    const op_richiesta_modifiche = ref();

    const loaded_sign_data = ref(false)
    const sign_data = ref([])
    const sign_data_copy = ref([])
    const changed_sign_data = ref([])

    const requiredDocuments = ref([])
    const missingDocuments = ref([])
    const remiOption = ref([])

    const toggle = async (event, type_op, data = null) => {
      switch (type_op) {
        case 'link':
          op.value.toggle(event);
          
          break;
          case 'richiesta_modifiche':

          op_richiesta_modifiche.value.toggle(event);

          break;
          case 'annulla_contratto':
          matrice_da_annullare.value = data

          op_annulla_contratto.value.toggle(event);
          break;
      
        default:
          op_modifiche.value.toggle(event);

          break;
      }
    }
    const hideOverlay = () => {
      loaded_sign_data.value = false
    }

    const cancelDeletion = () => {
      confirmDeleteRow.value = false
      row_in_delete.value = null
    }
    const hideOverlayAnnullaContratto = (event = null) => {
      matrice_da_annullare.value = null
      if (event) {
        op_annulla_contratto.value.toggle(event);
      }
    }

    const createSignedContract = (dati) => {
      matrice_for_signed_contract.value = dati
      data_firma_cartaceo.value = new Date()

      requiredDocuments.value.push({
        name:'Contratto Cartaceo',
        ref:'ctr',
        files:[],
        multiple:false
      })
      if (dati.pratica != null) {
          Object.keys(dati.forniture).forEach(type => {
            let type_device = type == 'ee' ? 'pod' : 'pdr'
            dati.forniture[type].forEach(row => {
              requiredDocuments.value.push({
                name:`${dati.pratica} ${row[type_device]}`,
                ref:row[type_device],
                files:[],
                multiple:false
              })            
            })
          })
        }
      insertSignedContractDialog.value = true
    }

    const insertSignedContract = async (dati) => {
      if (!dati.loaded_details) {
        loading.value = true
        let event = {data:dati}
        let res = await onRowExpand(event)
        setTimeout(()=>{
          createSignedContract(res)
          loading.value = false
        }, 3000)

      }else{
        createSignedContract(dati)
      }
    }
    const createMissingDocuments = (dati) => {
      matrice_for_missing_documents.value = dati
      let docs_valid = [
        {prop:'has_documento_identita',name:'DOCUMENTO D\'IDENTITA', code:'di'}
      ]

      if (dati.is_contratto_manuale) {
        docs_valid.push({prop:'has_contratto',name:'CONTRATTO', code:'ctr'})
      }

      if (dati.pratica) {
        docs_valid.push({prop:'has_titolo_giust',name:'TITOLO GIUSTIFICATIVO', code:'tg'})
      }

      docs_valid.forEach(doc => {
        if (!dati[doc.prop]) {
          missingDocuments.value.push({
            name:doc.name,
            ref:doc.code,
            files:[],
            multiple:false
          }) 
        }
      })

      let docs_valid_supply = [
        {prop:'has_ft',name:'FATTURA', code:'ft', check: false},
        {prop:'insoluto',name:'CONTABILE DI PAGAMENTO', code:'cp', check: true}      
      ]

      if (dati.pratica) {
        docs_valid_supply.push({prop:`has_${dati.pratica[0].toLowerCase()}1`, name:dati.pratica, code:`${dati.pratica[0].toLowerCase()}`, check: false})
      }

      Object.keys(dati.forniture).forEach(type => {
        let type_device = type == 'ee' ? 'pod' : 'pdr'
        dati.forniture[type].forEach(row => {
          docs_valid_supply.forEach(doc => {
            if (row[doc.prop] == doc.check) {
              missingDocuments.value.push({
                name:`${doc.name} ${row[type_device]}`,
                ref:doc.code+type+row.id,
                files:[],
                multiple:false
              }) 
            }
          })
        })
      })
      insertMissingDocumentsDialog.value = true
    }

    const insertMissingDocuments = async (dati) => {
      if (!dati.loaded_details) {
        loading.value = true
        let event = {data:dati}
        let res = await onRowExpand(event)
        setTimeout(()=>{
          createMissingDocuments(res)
          loading.value = false
        }, 3000)
      }else{
        createMissingDocuments(dati)
      }
    }

    const onChangeDataFirma = () => {
      delete validationErrors.value.data_firma_cartaceo
    }
    const deleteInsertSignedContract = () => {
      isDisableInput.value = false
      insertSignedContractDialog.value = false
      matrice_for_signed_contract.value = null
      requiredDocuments.value = []
      data_firma_cartaceo.value = null
      vs.value = []
      ctr.value = []
    }

    const deleteInsertMissingDocuments = () => {
      matrice_for_missing_documents.value = null
      isDisableInputMissing.value = false
      insertMissingDocumentsDialog.value = false
      missingDocuments.value = []
    }

    const getRemi = (city, code) => {
      delete remiOption.value[code]
      service.getRemi(city).then((res) => {
        remiOption.value[code] = res.items
      })
    }

    onMounted(() => { 
      setResponsive()
      loading.value = true
      loaded.value = false
      getStatiPrecheck()
      const promises = [
        getContatori(),
        getData(true),
        getAllTipoClient(),
        getOfferte(),
      ]
      Promise.all(promises).then(()=>{
        loading.value = false
        loaded.value = true
      })
      files.value = []
    })

    // const esportaEsiti = (evt) => {
    //   let list = []
    //   let filtered = [...items.value]

    //   Object.keys(filters.value).forEach((filtro) => {
    //     if (filtro != 'global') {
    //       if (filters.value[filtro].value != null) {
    //         filtered = filtered.filter(item => item[filtro] == filters.value[filtro].value)
    //       }
    //     }
    //   })
    //   let types = [
    //     {type:'ee', name:'Energia'},
    //     {type:'gas', name:'Gas'},
    //     {type:'ee/gas', name:'Energia & Gas'}
    //   ]
    //   if (filtered.length) {

    //     let i = 0
    //     filtered.forEach((item) => {
    //       Object.keys(item.forniture).forEach((type_supplies) => {
    //         let type_device = type_supplies == 'ee' ? 'pod' : 'pdr'
    //         item.forniture[type_supplies].forEach((supply, index) => {
    //           list[i] = {
    //             'Id caricamento':item.id,
    //             'Stato caricamento': stati.value.find(stato => stato.id == item.cod_stato_caricamento).name,
    //             'Data caricamento':moment(item.data_caricamento).format("DD-MM-YYYY HH:mm:ss"),
    //             'Data firma':item.data_firma ? moment(item.data_firma).format("DD-MM-YYYY HH:mm:ss") : null,
    //             'Firmato':item.is_complete ? 'Si' : 'No',
    //             'Pratica': item.pratica,
    //             'Cliente':supply.denominazione_cliente,
    //             'Fornitura':supply[type_device],
    //             'Codice esito PK':supply.cod_esito_precheck,
    //             'Dettaglio esito PK':supply.dettaglio_esito_precheck,
    //             'Data risposta PK':supply.data_risposta_pk ? moment(supply.data_risposta_pk).format("DD-MM-YYYY HH:mm:ss") : null
    //           }
    //           i++
    //         })
    //       })
    //     })
    //     const worksheet = XLSX.utils.json_to_sheet(list);
  
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Matrice");
  
    //     let array_width = []
    //     for (let index = 0; index < 12; index++) {
    //       array_width[index] = {wch:15}
    //     }
    //     worksheet["!cols"] = array_width
  
    //     let data = new Date()
    //     let giorno = data.getDate()
    //     let mese = data.getMonth() + 1
    //     let anno = data.getFullYear()
  
    //     XLSX.writeFile(workbook, `ExportMatrici_${giorno}_${mese}_${anno}.xlsx`, { compression: true });
    //   }else{
    //     toast.add({severity:'warn', summary:'Attenzione', detail: 'Non ci sono matrici da scaricare', life:5000});
    //   }
    // }

    const esportaEsiti = async (evt) => {
      let filtered = [...items.value]
      Object.keys(filters.value).forEach((filtro) => {
        if (filtro != 'global') {
          if (filters.value[filtro].value != null) {
            filtered = filtered.filter(item => item[filtro] == filters.value[filtro].value)
          }
        }
      })
    
      if (filtered.length) {
        let ids = []
        filtered.forEach(item => {
          ids.push(item.id)
        })
  
        loading.value = true
  
        await service.downloadExportEsiti(JSON.stringify(ids))
        .finally(()=> {
          loading.value = false
        })
      }else{
        toast.add({severity: 'warn', detail: 'Non ci sono caricamenti da esportare con i filtri selezionati', life: 5000})
      }
    }

    // const esportaCaricamenti = (evt) => {
    //   console.log(evt, items.value);
    //   let list = []
    //   let filtered = [...items.value]

    //   Object.keys(filters.value).forEach((filtro) => {

    //     if (filtro != 'global') {
    //       if (filters.value[filtro].value != null) {
    //         filtered = filtered.filter(item => item[filtro] == filters.value[filtro].value)
    //       }
    //     }
    //   })
    //   let types = [
    //     {type:'ee',name:'Energia'},
    //     {type:'gas',name:'Gas'},
    //     {type:'ee/gas',name:'Energia & Gas'}
    //   ]
    //   if (filtered.length) {
    //     filtered.forEach((item, index) => {
    //       list[index] = {
    //         'Id caricamento':item.id,
    //         'Stato caricamento': stati.value.find(stato => stato.id == item.cod_stato_caricamento).name,
    //         'Agente':item.agente,
    //         'Rappresentante':item.rappresentante,
    //         'Cliente':item.cliente,
    //         'Tipo cliente':typesClient.value.find(type => type.id == item.cod_type_client).name,
    //         'Data caricamento':moment(item.data_caricamento).format("DD-MM-YYYY HH:mm:ss"),
    //         'Data firma':item.data_firma ? moment(item.data_firma).format("DD-MM-YYYY HH:mm:ss") : null,
    //         'Firmato':item.is_complete ? 'Si' : 'No',
    //         'Tipo Firma':item.is_contratto_manuale ? 'Cartaceo' : 'Digitale',
    //         'Tipo forniture':types.find(type => type.type == item.type).name,
    //         'Totale forniture':Number(item.total_rows),
    //         'Pratica': item.pratica
    //       }
    //     })
    //     const worksheet = XLSX.utils.json_to_sheet(list);
  
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Matrice");
  
    //     let array_width = []
    //     for (let index = 0; index < 12; index++) {
    //       array_width[index] = {wch:15}
    //     }
    //     worksheet["!cols"] = array_width
  
    //     let data = new Date()
    //     let giorno = data.getDate()
    //     let mese = data.getMonth() + 1
    //     let anno = data.getFullYear()
  
    //     XLSX.writeFile(workbook, `ExportMatrici_${giorno}_${mese}_${anno}.xlsx`, { compression: true });
    //   }else{
    //     toast.add({severity:'warn', summary:'Attenzione', detail: 'Non ci sono matrici da scaricare', life:5000});
    //   }  
    // }

    const esportaCaricamenti = async (evt) => {
      let filtered = [...items.value]
      Object.keys(filters.value).forEach((filtro) => {
        if (filtro != 'global') {
          if (filters.value[filtro].value != null) {
            filtered = filtered.filter(item => item[filtro] == filters.value[filtro].value)
          }
        }
      })
    
      if (filtered.length) {
        let ids = []
        filtered.forEach(item => {
          ids.push(item.id)
        })
  
        loading.value = true
  
        await service.downloadExport(JSON.stringify(ids))
        .finally(()=> {
          loading.value = false
        })
      }else{
        toast.add({severity: 'warn', detail: 'Non ci sono caricamenti da esportare con i filtri selezionati', life: 5000})
      }
    }

    const setResponsive = () => {
      window.addEventListener('resize', () => {
        windowSize.value = window.innerWidth
      })
    }

    const getStatiPrecheck = () => {
      service.getStatiPrecheck()
      .then((res) => {
        stati_precheck.value = res.items
      })
    }
    const getSignLink = async (id) => {
      await service.getSignLink(id)
      .then((res) => {
        links.value[id] = res.items
      })
    }
    const getSignData = async (id) => {
      await service.getSignData(id)
      .then((res) => {
        sign_data.value[id] = JSON.parse(JSON.stringify(res.items))
        sign_data_copy.value[id] = JSON.parse(JSON.stringify(res.items))
        changed_sign_data.value[id] = true
        loaded_sign_data.value = true
      })
    }

    const isDisabledSendSignData = (id) => {
      if (sign_data_copy.value[id].contatto == '' || sign_data_copy.value[id].email == '') {
        return true
      }
      sign_data_copy.value[id].contatto = sign_data_copy.value[id].contatto.replace('-','')
      const json1 = JSON.stringify(sign_data.value[id]);
      const json2 = JSON.stringify(sign_data_copy.value[id]);

      return json1 === json2
    }

    const setChangedSignData = (id) => {
      changed_sign_data.value[id] = isDisabledSendSignData(id)
    }

    const copyLink = (link) => {
      navigator.clipboard.writeText(link).then(() => {
        toast.add({severity:'success', summary:'', detail: `Link copiato negli appunti`, life: 5000});
      }, () => {
        console.log('Errore durante la copia del valore negli appunti');
      });
    }

    const sendSignData = (id) => {
      loading.value = true

      let fd = new FormData();
      fd.append('matrice_id', JSON.stringify(id));

      fd.append('sign_data', JSON.stringify(sign_data_copy.value[id]));

      service.sendSignData(fd)
      .then((res)=>{
        if (res.items) {
          toast.add({severity:'success', summary:'Modifica eseguita', detail: `Il cliente può firmare con i nuovi dati`, life: 10000});
        } else {
          toast.add({severity:'warn', summary:'Modifica fallita', detail: `Errore nella modifica dei dati del firmatario`, life: 10000});
        }
        getData()
      })
      .finally(()=> {
        loading.value = false
      })
    }
    const sendRequestSignData = (id) => {
      loading.value = true

      let fd = new FormData();
      fd.append('matrice_id', JSON.stringify(id));

      fd.append('sign_data', JSON.stringify(sign_data_copy.value[id]));

      service.sendRequestSignData(fd)
      .then((res)=>{
        let severity = res.items.error ? 'warn' : 'success' 
        let detail = res.items.response
        toast.add({severity:severity, summary:'', detail: detail, life:10000})

        getData()
      })
      .finally(()=> {
        loading.value = false
      })
    }
    const onRowExpand = async (event) => {
      let index_item = items.value.findIndex(item => item.id == event.data.id)

      if (!items.value[index_item].loaded_details) {
        loadingFilename.value = true
        const promises = [
          getFileCaricamentoDetail(event.data.id),
          getDetailsCaricamento(event.data.id)
        ]
  
        // await getFileCaricamentoDetail(event.data.id)
        
        Promise.all(promises)
        .then(()=>{
          if (!event.data.is_contratto_manuale) {
            if(currentUser.value.role == roles.admin.role){
              getSignLink(event.data.id)
            }
            getSignData(event.data.id)          
          }
          items.value[index_item].loaded_details = true
        })
        .finally(()=>{
          loadingFilename.value = false
        })
        return items.value[index_item]
      }
    }
    const hasParametro = () => {
      if (parametroGet) {
        let data = items.value.filter(el => el.id == parametroGet)
        filters.value.id.value = [parseInt(parametroGet)]
        if (!data[0].is_complete_rapp) {
          insertDataRapp(data[0])
        }else{
          getData()
        }
      }
    }
    // const uploadNewFile = async (event, id) => {
    //   loading.value = true
    //   service.uploadNewFile(event.files, id)
    //   .then((res)=>{
    //     if (res.success) {
    //       toast.add({severity:'success', summary:'Importazione eseguita', detail: `File caricati correttamente`, life: 10000});
    //     } else {
    //       toast.add({severity:'warn', summary:'Importazione fallita', detail: `Errore nel caricamento dei file`, life: 10000});
    //     }
    //   })
    //   .finally(()=> {
    //     getData(true)
    //     loading.value = false
    //   })
    // }
    const uploadNewFile = async (event, id) => {
      isDisableInputMissing.value = true
      loading.value = true
      let fd = new FormData();
      fd.append('matrice_id', JSON.stringify(id));
      
      missingDocuments.value.forEach((doc)=>{
        for (let i = 0 ; i < doc.files.length ; i++) {
          fd.append(`${doc.ref}[]`, doc.files[i]); 
        }
      })

      service.uploadNewFile(fd)
      .then((res)=>{
        if (res.success) {
          toast.add({severity:'success', summary:'Importazione eseguita', detail: `File caricati correttamente`, life: 10000});
        } else {
          toast.add({severity:'warn', summary:'Importazione fallita', detail: `Errore nel caricamento dei file`, life: 10000});
        }
      })
      .finally(()=> {
        deleteInsertMissingDocuments()
        getData(true)
        loading.value = false
      })
    }
    const setFirmato = async (event, id) => {
      isDisableInput.value = true
      loading.value = true
      let data_firma = setData(data_firma_cartaceo.value)

      let fd = new FormData();
      fd.append('matrice_id', JSON.stringify(id));
      fd.append('data_firma', JSON.stringify(data_firma));
      
      requiredDocuments.value.forEach((doc)=>{
        for (let i = 0 ; i < doc.files.length ; i++) {
          fd.append(`${doc.ref}[]`, doc.files[i]); 
        }
      })

      service.signedContract(fd)
      .then((res)=>{
        if (res.items) {
          toast.add({severity:'success', summary:'Importazione eseguita', detail: `Contratto caricato correttamente`, life: 10000});
        } else {
          toast.add({severity:'warn', summary:'Importazione fallita', detail: `Errore nel caricamento dei file`, life: 10000});
        }
        deleteInsertSignedContract()
        getData()
      })
      .finally(()=> {
        loading.value = false
      })
    }

    const getFileCaricamento = async (id) => {
      await service.getFileCaricamento(id)
      .then((res) => {
        filesView.value = res.items
      })
    }
    const getFileCaricamentoDetail = async (id) => {
      await service.getFileCaricamento(id)
      .then((res) => {
        newFilesView.value[id] = res.items
      })
    }
    const getDetailsCaricamento = async (id) => {
      let index_item = items.value.findIndex(item => item.id == id)
      let fd = new FormData()
      fd.append('matrice_id', JSON.stringify(id));

      await service.getDetailsCaricamento(fd)
      .then((res) => {
        Object.keys(res.items).forEach(item => {
          if (item == 'note_ko') {
            items.value[index_item][item] = []
            Object.keys(res.items[item]).forEach(nota => {
              items.value[index_item][item].push(res.items[item][nota])
            })
          }else{
            items.value[index_item][item] = res.items[item]
          }
        })
      })

    }  
    const onSelectedStatus = async (evt) => {
      selectedStatus.value = stati.value.find(el => el.name == evt.value)
      await getContatori()
      await getData()
    }
    const onSelectedRemi = (code) => {
      delete validationErrors.value[`remi_${code}`];
    }
    const initData = () => {
      getContatori()
      getData()

    }
    const onChangeDate = (evt) => {
      if (evt == 'data_firma') {
        if (selectedSignDate.value == null) {
          getContatori()
          getData()
        }else if (selectedSignDate.value[1]) {
          self.refs.calendarRefSign.overlayVisible = false;
          getContatori()
          getData()
        }
      } else {
        if (selectedDate.value == null) {
          getContatori()
          getData()
        }else if (selectedDate.value[1]) {
          self.refs.calendarRef.overlayVisible = false;
          getContatori()
          getData()
        }
      }
    }
    const getSeverity = (cod_stato_caricamento) => {
      switch (cod_stato_caricamento) {
        case 1:
        case 2:
          return 'text-yellow-300';

        case 3:
        case 5:
        case 7:
          return 'p-error';
      
        case 4:
        case 6:
          return 'text-green-400'
      }
    }
    const getSeverityTag = (cod_stato_caricamento) => {
      switch (cod_stato_caricamento) {
        case 1:
        case 2:
          return 'warning';

        case 3:
        case 5:
        case 7:
        case 8:
        case 9:
          return 'danger';
      
        case 4:
        case 6:
          return 'success'
      }
    }
    const getTooltip = (cod_stato_caricamento) => {
      return stati.value.find(stato => stato.id == cod_stato_caricamento).name
    }
    const goToPratiche = () => {
      router.push({path: '/precheck-iv'})
    }
    const getAllTipoClient = async () => {
      await service.getAllTipoClient().then((res) => {
        typesClient.value = res.items
      })
    }
    const getOfferte = async () => {
      await service.getOfferte()
      .then((res) => {
        offerte.value = res.items
        selectedOfferte.value = offerte.value
      })
    }

    const onTodayClick = (evt) => {
      if (evt == 'data_firma') {
        selectedSignDate.value[1] = selectedSignDate.value[0]
      } else {
        selectedDate.value[1] = selectedDate.value[0]
      }
      onChangeDate(evt)
    }

    const removeFilters = () => {
      selectedSignDate.value = null
      selectedDate.value = [
        moment().startOf("month").toDate(),
        new Date()
      ]
      selectedStatus.value = null
      getContatori()
      getData()
    }
    const resetOptionFilter = () => {
      items.value = []
      authors.value = [];
      rapps.value = [];
      clients.value = [];
      ids.value = [];
    }

    const getData = async (is_visible_dialog = false) => {
      resetOptionFilter()
      loading.value = true       
      let fd = new FormData();
      let range = []
      let rangeSign = []

      if (selectedDate.value) {
        selectedDate.value.forEach((data) => {
          let giorno = data.getDate() 
          giorno = giorno.toString().padStart(2,'0')
          let mese = data.getMonth()+1
          mese = mese.toString().padStart(2,'0')
          let anno = data.getFullYear() 
          data = `${anno}-${mese}-${giorno}`
          range.push(data)
        })        
      }
      fd.append('range', range);
      if (selectedSignDate.value) {
        selectedSignDate.value.forEach((data) => {
          let giorno = data.getDate() 
          giorno = giorno.toString().padStart(2,'0')
          let mese = data.getMonth()+1
          mese = mese.toString().padStart(2,'0')
          let anno = data.getFullYear() 
          data = `${anno}-${mese}-${giorno}`
          rangeSign.push(data)
        })        
      }
      fd.append('rangeSign', rangeSign);
      let filtro = 0
      if (selectedStatus.value) {
        filtro = selectedStatus.value.id
      }
      fd.append('filtro', filtro)

      await service.getAllCaricamentiMultisito(fd)
      .then((res) => {
        items.value = res.items
        items.value.forEach(item => {
          item.is_complete = Boolean(item.is_complete)
          item.loaded_details = false
        });
        // populateDatesFirmaArray(res.items)
        populateAuthorsArray(res.items)
        populateRappsArray(res.items)
        populateClientsArray(res.items)
        populateIdsArray(res.items)
        if(is_visible_dialog){
          hasParametro()
        }else{
          filters.value.id.value = null
        }
      })
      .finally(()=> {
        loading.value = false       
      })
    }
    const updateRapp = (data) => {
      submitted.value = true;
      if (Object.keys(validationErrors.value).length > 0) {
        toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3000});
      }else if (validateForm()) {
        loading.value = true
        let fd = new FormData();
        fd.append('rappresentante', JSON.stringify(rappresentante.value));
        fd.append('matrice_id', JSON.stringify(matrice_id.value));
        for (let i = 0 ; i < files.value.length ; i++) {
          fd.append('files[]', files.value[i]); 
        }
        service.updateRapp(fd)
        .then((res) => {
          if (res.item.success) {
            toast.add({severity:'success', summary: 'Successo', detail:`Rappresentante aggiornato correttamente.`});
          } else {
            toast.add({severity:'warn', summary:'Attenzione', detail: `Errore nell'aggiornamento del rappresentante`});
          }
        }).finally(() => {
          deleteChanges()
          showModal.value = false
          loading.value = false
          setTimeout(()=>{
            router.push({path: '/caricamenti-multisito'})
            getData()
          }, 5000)
        })
      }
    }
    const deleteUpdate = async (data) => {
      loading.value = true
      service.deleteUpdate(data)
      .then(()=>{
        getData(true)
      })
      .finally(() => {
        loading.value = false
        toast.add({severity:'success', summary:'Successo', detail: 'Caricamento eliminato'});
      })
    }
    const deleteChanges = () => {
      rappresentante.value = {
        cod_fiscale:'',
        name: rappresentante.value.name,
        phone: '',
        mobile: null,
        email: '',
        pec: '',
        toponimo: null,
        address:'',
        civico: '',
        cap: '',
        selectedDistrict:null,
        selectedCity: null,
        cityId: 0,
        selectedCityName: '',
      }
      files.value = []
      validationErrors.value = {}
      matrice_id.value = ''
      showModal.value = false
    }
    const insertDataRapp = (data) => {
      getDistricts()
      getToponimi()
      rappresentante.value.cod_fiscale = data.codfis,
      rappresentante.value.name = data.rappresentante,
      rappresentante.value.id_existent = data.rappresentante_id,
      rappresentante.value.contatto_id = data.contatto_id,
      rappresentante.value.mobile = data.cell,
      rappresentante.value.phone = data.tel,
      rappresentante.value.toponimo = data.toponimo,
      rappresentante.value.address = data.indirizzo,
      rappresentante.value.civico = data.civico,
      rappresentante.value.email = data.email,
      rappresentante.value.pec = data.pec,
      rappresentante.value.cap = data.cap,

      showModal.value = true
      matrice_id.value = data.id
    }

    const isCorrect = (is_correct = false) => {
      if (!is_correct) {
        validationErrors.value['mobile'] = true;
      }else{
        delete validationErrors.value['mobile'];
      }
      showModalMobile.value = false;
    }
    const onSelected = (evt, id) => {
      let value
      if(evt.value){
        value = evt.value
      }else{
        value = evt.target.value
      }
      switch (id) {
        case 'selectedDistrict':
          service.getCities(evt.value).then((res) => {
            cities.value = res.items
            rappresentante.value.cityId = 0
          })
          break;
        case 'files':
          files.value = self.refs.file.files;
          id = 'files'
          break;
        case 'city':
          rappresentante.value.city = evt.originalEvent.target.ariaLabel
          break;
        case 'mobile':
          showModalMobile.value = true;
          break;
          default:
          break;
      }
      delete validationErrors.value[id];
    }

    const getDistricts = () => {
      service.getDistricts().then((res) => {
        districts.value = res.items
      })     
    }
    const getToponimi = () =>{
      service.getToponimi().then((res) => {
        toponimi.value = res.items
      })
     }
    const validateForm = () => {
      validationErrors.value = []
      let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let regexPiva = /^[0-9]{11}$/
      let regex_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i

      if (rappresentante.value.cod_fiscale == null || rappresentante.value.cod_fiscale.trim() == '') 
        validationErrors.value['cod_fiscale'] = true;
      else
        delete validationErrors.value['cod_fiscale'];

      if (rappresentante.value.cod_fiscale.length == 16) {
        if (!regex_cf.test(rappresentante.value.cod_fiscale)) 
          validationErrors.value['cod_fiscale'] = true;
        else
          delete validationErrors.value['cod_fiscale'];
      } else {
          if (!regexPiva.test(rappresentante.value.cod_fiscale))
            validationErrors.value['cod_fiscale'] = true;
          else
            delete validationErrors.value['cod_fiscale'];            
      }

      if (rappresentante.value.name == null || rappresentante.value.name.trim() == '')
        validationErrors.value['name'] = true;
      else
        delete validationErrors.value['name'];
      
      if (regex_email.test(rappresentante.value.email)) {
        delete validationErrors.value['email'];
      } else {
        validationErrors.value['email'] = true;
      }

      if (rappresentante.value.mobile == null || rappresentante.value.mobile.trim() == '')
        validationErrors.value['mobile'] = true;
      else
        delete validationErrors.value['mobile'];

      if (rappresentante.value.toponimo == null || rappresentante.value.toponimo == '')
        validationErrors.value['toponimo'] = true;
      else
        delete validationErrors.value['toponimo'];
          
      if (rappresentante.value.address == null || rappresentante.value.address.trim() == '')
        validationErrors.value['address'] = true;
      else
        delete validationErrors.value['address'];
      
      if (rappresentante.value.civico == null || rappresentante.value.civico.trim() == '')
        validationErrors.value['civico'] = true;
      else
        delete validationErrors.value['civico'];

      if (!Number.isInteger(parseInt(rappresentante.value.cap)))          
          validationErrors.value['cap'] = true;
      else 
        delete validationErrors.value['cap'];

      if (rappresentante.value.selectedDistrict == '' || rappresentante.value.selectedDistrict == null)
        validationErrors.value['selectedDistrict'] = true;
      else
        delete validationErrors.value['selectedDistrict'];

      if (rappresentante.value.cityId == '' || rappresentante.value.cityId == null)
        validationErrors.value['cityId'] = true;
      else
        delete validationErrors.value['cityId'];

      if (files.value.length == 0) {
        validationErrors.value['files'] = true;
      } else {
        delete validationErrors.value['files'];
      }

      if (files.value.length > 0) {
        let valid = [...files.value].every(file => allowedTypeDoc.includes(file.type.replace(/(.*)\//g, '')), false);
        if (valid) 
          delete validationErrors.value['files'];
        else
          validationErrors.value['files'] = true;
      }

      return !Object.keys(validationErrors.value).length;

    }

    const populateDatesFirmaArray = (items) => {
      let dates = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.data_firma) {
            //moment(data.data_firma).format('DD-MM-YYYY HH:mm')
            dates.push(moment(el.data_firma).format('YYYY-MM-DD'))
          }
        });
        dates_firma.value = [...new Set(dates)];
      }
    }

    const populateAuthorsArray = (items) => {
      let list = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.agente) {
            list.push(el.agente)
          }
        });
        // authors.value = [...new Set(list)];

        let list_agent = [...new Set(list)];
        list_agent.forEach(agent => {
          authors.value.push({name:agent})
        })
      }
    }
    const populateRappsArray = (items) => {
      let list = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.rappresentante) {
            list.push(el.rappresentante)
          }
        });

        let list_rapp = [...new Set(list)];
        list_rapp.forEach(rapp => {
          rapps.value.push({name:rapp})
        })
      }

    }
    const populateClientsArray = (items) => {
      let list = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.cliente) {
            list.push(el.cliente)
          }
        });

        let list_client = [...new Set(list)];
        list_client.forEach(client => {
          clients.value.push({name:client})
        })
      }

    }
    const populateIdsArray = (items) => {
      let list = []
      if (items.length > 0) {
        items.forEach(el => {
          if (el.id) {
            list.push(el.id)
          }
        });
        let list_ids = [...new Set(list)];
        list_ids.forEach(id => {
          ids.value.push({key:id})
        })
        // ids.value = [...new Set(list)];
      }
    }

    const getContatori = async () => {
      contatori.value = []
      let fd = new FormData();
      let range = []
      if (selectedDate.value) {
        selectedDate.value.forEach((data) => {
          let giorno = data.getDate() 
          giorno = giorno.toString().padStart(2,'0')
          let mese = data.getMonth()+1
          mese = mese.toString().padStart(2,'0')
          let anno = data.getFullYear() 
          data = `${anno}-${mese}-${giorno}`
          range.push(data)
        })        
      }
      fd.append('range', range);

      let rangeSign = []

      if (selectedSignDate.value) {
        selectedSignDate.value.forEach((data) => {
          let giorno = data.getDate() 
          giorno = giorno.toString().padStart(2,'0')
          let mese = data.getMonth()+1
          mese = mese.toString().padStart(2,'0')
          let anno = data.getFullYear() 
          data = `${anno}-${mese}-${giorno}`
          rangeSign.push(data)
        })        
      }
      fd.append('rangeSign', rangeSign);


      let filtro = 0
      if (selectedStatus.value) {
        filtro = selectedStatus.value.id
      }
      fd.append('filtro', filtro)

      await service.getContatori(fd)
      .then((res) => {
        contatori.value = res.items
        if (contatori.value) {
          contatori.value[3] = {
            title: 'Forniture totali',
            totale: contatori.value[1].totale + contatori.value[2].totale,
            firmati: contatori.value[1].firmati + contatori.value[2].firmati,
            non_firmati: contatori.value[1].non_firmati + contatori.value[2].non_firmati
          }
        }
      })
    }

    const handleFileUpload = (evt) => {
      files.value = self.refs.file.files;
      delete validationErrors.value['files'];
      delete validationErrors.value['moduli'];
      delete validationErrors.value['type'];
      delete validationErrors.value['typeModuli'];
    };

    const uploadNewMatrice = async () => {
      submitted.value = true
      if (validateFormNewMatrice()) {
        loading.value = true
        service.uploadNewMatrice(
          typeMatrice.value, 
          fileToUpload.value, 
          selectedTypeClient.value, 
          isIvaAgevolata.value, 
          selectedOfferte.value
        )
        .then((res) => {
          if (res.items.success) {
            toast.add({severity:'success', summary:'Successo', detail: 'Matrice caricata'});
          } else {
            toast.add({severity:'error', summary:'Attenzione', detail: res.items.msg});
          }
        })
        .finally(()=> {
          matriceUploadKey.value = Date.now();
          loading.value = false
        })
      }
    };

    const uploadMatrice = async (event, data) => {
      loading.value = true
      service.uploadMatriceCaricata(data.id, event.files)
      .then((res) => {
        if (res.items.success) {
          toast.add({severity:'success', summary:'Successo', detail: 'Matrice aggiornata'});
        } else {
          toast.add({severity:'error', summary:'Attenzione', detail: res.items.msg});
        }
      })
      .finally(()=> {
        matriceUploadKey.value = Date.now();
        loading.value = false
      })
        
    };

    const downloadMatrice = (data) => {
      loading.value = true
      service.downloadMatriceCaricata(data.id)
      .finally(()=> {
        loading.value = false
      })
    }

    const downloadsMatrici = () => {
      let isSelectedNotSigned = selectedItems.value.some(el => el.is_complete == 0)
      selectedItems.value = selectedItems.value.filter(el => el.is_complete == 1)
      if (isSelectedNotSigned) {
        toast.add({severity:'warn', summary:'Attenzione', detail: 'Alcune Matrici rimosse perchè non firmate', life:3000});
        if (selectedItems.value == 0) {
          return;
        }
      }
      loading.value = true
      service.downloadsMatriciCaricate(selectedItems.value)
      .finally(()=> {
        loading.value = false
      })
    }

    // const onMatriceSelected = (evt) => {
    //   if (evt.data.is_complete != 1) {
    //     selectedItems.value = selectedItems.value.filter(item => item.id !== evt.data.id);
    //     toast.add({severity:'warn', summary:'Attenzione', detail: 'Matrice non selezionabile perchè non firmata'});
    //   }
    // }

    const showPdf = (data) => {
      loading.value = true
      service.showPdfMatrice(data.id)
      .then(()=>{
        getData()
      })
      .finally(()=> {
        loading.value = false
      })
    }

    const downloadPdf = async (id, name) => {
      loading.value = true
      await service.downloadPdf(id, name)
      .finally(()=> {
        loading.value = false
      })
    }

    const downloadZip = async (id) => {
      loading.value = true
      await service.downloadZip(id)
      .finally(()=> {
        loading.value = false
      })
    }

    const signPdf = (data) => {
      loading.value = true
      service.signPdfMatrice(data.id)
      .then((res)=> {
        // if (res.items) {
        //   toast.add({severity:'success', summary:'Successo', detail: 'Richiesta di firma inviata correttamente'});
        // }
        let severity = res.items.success ? 'success' : 'error'
        let summary = res.items.success ? 'Successo': 'Attenzione'
        let detail = res.items.errore
        
        toast.add({severity:severity, summary: summary, detail:detail});                            
      })
      .finally(()=> {
        loading.value = false
        getData(true)
      })
    }

    // const onCheckRowMultisito = (i) => {
    //   const targetId = items.value[i].id;


    //   if (selectedItems.value || selectedItems.value.length == 0) {
    //     selectedItems.value.push(items.value[i])
    //   } else {
    //     const isPresent = selectedItems.value.some(item => item.id === targetId);
    //     if (!isPresent) {
    //       selectedItems.value.push(items.value[i])
    //     } 
    //   }
    // }


    const validateFormNewMatrice = () => {
      if (selectedTypeClient.value) {
        delete validationErrors.value['type_client'];
      } else {
        validationErrors.value['type_client'] = true;
      }

      if (typeMatrice.value) {
        delete validationErrors.value['type_matrice'];
      } else {
        validationErrors.value['type_matrice'] = true;
      }

      if (fileToUpload.value.length == 0) {
          validationErrors.value['files'] = true;
          delete validationErrors.value['type'];
      } else {
        delete validationErrors.value['files'];
      }

      if (fileToUpload.value.length > 0) {
        let valid = [...fileToUpload.value].every(file => [Object.values(allowedType).join(', ')].includes(file.type), false);
        if (valid) 
          delete validationErrors.value['type'];
        else
          validationErrors.value['type'] = true;
      }

      return !Object.keys(validationErrors.value).length;
    }
    const createDataPratiche = (dati) => {
      if (dati.in_review) {
        toast.add({severity:'warn', summary:'Attenzione', detail: 'Modifiche in attesa di revisione', life: 5000});
      } else {
        id_matrice_pratiche.value = dati.id
        is_contratto_manuale.value = dati.is_contratto_manuale
        type_client_pratiche.value = dati.cod_type_client

        let tipi = ['ee', 'gas']
        tipi.forEach(tipo => {
          let tipo_fornitura = tipo == 'ee' ? 'pod' : 'pdr'
          if (dati.forniture[tipo]) {
            
            for (let index = 0; index < dati.forniture[tipo].length; index++) {
              let row = {};
              unique_cf.value = dati.cod_type_client != 3 ? dati.forniture[tipo][index].cf_cliente : null
              unique_piva.value = dati.cod_type_client != 2 && dati.cod_type_client != 3 ? dati.forniture[tipo][index].piva_cliente : null
              row.id = dati.forniture[tipo][index].id 
              row.code = dati.forniture[tipo][index][tipo_fornitura]
              row.data_decorrenza = minDate.value
              row.cf = dati.forniture[tipo][index].cf_cliente
              row.piva = dati.forniture[tipo][index].piva_cliente
              row.tipo = tipo
              
              let tipo_pratica = dati.forniture[tipo][index].in_subentro ? 's' : 'v'
              if (tipo_pratica == 's' && !row.code.startsWith('IT')) {
                row.remi = null
                row.citta = dati.forniture[tipo][index].citta_fornitura
                getRemi(dati.forniture[tipo][index].citta_fornitura, row.code)
              }
              row.nome = `pratiche_${tipo_pratica}1_${tipo}`
              row.tipo_pratica = tipo_pratica == 'v' ? 'voltura' : 'subentro'
              row.documento = {
                ref:tipo_pratica+tipo+row.id,
                file:[],
                nome:''
              }
              if (dati.forniture[tipo][index].precheck == 2 && (dati.forniture[tipo][index].in_subentro || dati.forniture[tipo][index].in_voltura)) {
                all_pratiche.value[row.tipo_pratica].push(row)
              }
              row.mask = tipo == 'ee' ? 'IT999E9999999*' : '99999999999999'
              modifiche.value.push({...row}) 
              all_supplies.value++
            }
          }
        })
        showModalPratiche.value = true
      }        
    }

    const insertDataForPratiche = async (dati) => {
      if (!dati.loaded_details) {
        loading.value = true
        let event = {data:dati}
        let res = await onRowExpand(event)
        setTimeout(()=>{
          createDataPratiche(res)
          loading.value = false
        }, 3000)
      }else{
        createDataPratiche(dati)
      }
    }
    const onInput = (evt, type, index = null) => {
      let value = evt.toUpperCase()
      let validator
      if (index == null) {
        validator = type        
        type = type.replace('unique_', '')
        modifiche.value.forEach(modifica => {
          modifica[type] = value
        }) 
      } else {
        modifiche.value[index][type] = value
        validator = `${type}_${index}`
        if (type == 'code') {
          delete validationErrors.value[`existent_${index}`]
        }           
      }
      delete validationErrors.value[validator]
      getDuplicate()                
    }
    const deleteChangesPratiche = (bool = false) => {
      // pratiche_s1_ee.value = []
      // pratiche_v1_ee.value = []
      // pratiche_s1_gas.value = []
      // pratiche_v1_gas.value = []
      all_pratiche.value = {
        voltura:[],
        subentro:[]
      }
      id_matrice_pratiche.value = bool ? id_matrice_pratiche.value : ''
      validationErrors.value = []
      modifiche.value = []
      all_supplies.value = 0
      file_caricati.value = 0
      showModalPratiche.value = bool
      if (showModalPratiche.value) {
        getData()
      }
      type_client_pratiche.value = null
      unique_cf.value = null
      unique_piva.value = null
    }
    const setData = (el) => {
      let giorno = el.getDate() 
      let mese = el.getMonth()+1
      mese = mese.toString().padStart(2,'0')
      let anno = el.getFullYear() 
      el = `${anno}-${mese}-${giorno}`
      return el
    } 

    const setDate = () => {
      Object.keys(all_pratiche.value).forEach(pratica=>{
        all_pratiche.value[pratica].forEach((array) => {
          array['data_decorrenza'] = setData(array['data_decorrenza'])
        })
      })
    }

    const sendPratiche = async () => {
      let volture = true
      let subentri = true
      let docs = true
      if(Object.keys(validationErrors.value).length > 0) {
          toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3500});
      }else{
        if (!is_contratto_manuale.value) {
          Object.keys(all_pratiche.value).forEach(pratica=>{
            all_pratiche.value[pratica].forEach((array) => {
              if (array.documento.file.length) {
                file_caricati.value++
              }
            })
          })
          docs = file_caricati.value == 0 || file_caricati.value == all_supplies.value      
        }
        if (!docs) {
          toast.add({severity: 'warn', detail: 'Carica tutti i documenti', life: 5000})
        }

        if (all_pratiche.value.voltura.length) {
          volture = await validatePratiche(all_pratiche.value.voltura, 'v')
        }
        if (all_pratiche.value.subentro.length) {
          subentri = await validatePratiche(all_pratiche.value.subentro, 's')
        }

        if(volture && subentri && (file_caricati.value == 0 || file_caricati.value == all_supplies.value)){
          setDate()
          loading.value = true
          let fd = new FormData();
          fd.append('matrice_id', JSON.stringify(id_matrice_pratiche.value));
          
          Object.keys(all_pratiche.value).forEach(pratica=>{
            all_pratiche.value[pratica].forEach((array) => {
              fd.append(array.code+'[]', array.documento.file[0]);
              if (array.documento.file.length) {
                is_contratto_manuale.value = true
              }
              delete array.documento.file
            })
          })
          fd.append('pratiche', JSON.stringify(all_pratiche.value));
          service.saveMultisitoPratiche(fd)
          .then((res) => {
            if (res.item.success) {
              let string = res.item.totals_pratiche > 1 ? 'pratiche gestite' : 'pratica gestita'
              toast.add({severity:'success', summary: 'Successo', detail:`${res.item.totals_pratiche} ${string}`});
              deleteChangesPratiche(true)
              sent_pratica.value = true
            } else {
              toast.add({severity:'warn', summary:'Attenzione', detail: `${res.item.error}`});
            }
          }).finally(() => {
            loading.value = false
          })
        }
        file_caricati.value = 0
      }
    }

    const validatePratiche = (array, type_pratica) => {
      console.log(array);
      // return 0
      if (type_pratica == 'v') {
        let regexPiva = /^[0-9]{11}$/
        let regex_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  
        array.forEach((el, i)=>{
          if (`cf_attivazione_${el.tipo}` in el) {
            if (el[`cf_attivazione_${el.tipo}`].length < 11 || el[`cf_attivazione_${el.tipo}`].length > 16) {
              validationErrors.value[`cf_attivazione_${el.tipo}_invalid_${i}`] = true
            }else{
              delete validationErrors.value[`cf_attivazione_${el.tipo}_invalid_${i}`]
              if (el[`cf_attivazione_${el.tipo}`].length == 16) {
                if (regex_cf.test(el[`cf_attivazione_${el.tipo}`])) {
                  delete validationErrors.value[`cf_attivazione_${el.tipo}_format_${i}`]
                } else {
                  validationErrors.value[`cf_attivazione_${el.tipo}_format_${i}`] = true
                }
              }else{
                if (regexPiva.test(el[`cf_attivazione_${el.tipo}`])) {
                  delete validationErrors.value[`cf_attivazione_${el.tipo}_format_${i}`]
                } else {
                  validationErrors.value[`cf_attivazione_${el.tipo}_format_${i}`] = true
                }
              }
              if (el.cf && el.cf == el[`cf_attivazione_${el.tipo}`]) {
                validationErrors.value[`cf_attivazione_${el.tipo}_equal_${i}`] = true
              } else {
                delete validationErrors.value[`cf_attivazione_${el.tipo}_equal_${i}`]
              }
            }
          }else{
            validationErrors.value[`cf_attivazione_${el.tipo}_${i}`] = true
          }
          if (`piva_attivazione_${el.tipo}` in el) {
            if (el[`piva_attivazione_${el.tipo}`].length != 11) {
              validationErrors.value[`piva_attivazione_${el.tipo}_invalid_${i}`] = true
            }else{
              delete validationErrors.value[`piva_attivazione_${el.tipo}_invalid_${i}`]
              if (regexPiva.test(el[`piva_attivazione_${el.tipo}`])) {
                delete validationErrors.value[`piva_attivazione_${el.tipo}_format_${i}`]
              } else {
                validationErrors.value[`piva_attivazione_${el.tipo}_format_${i}`] = true
              }
              if (el.piva && el.piva == el[`piva_attivazione_${el.tipo}`]) {
                validationErrors.value[`piva_attivazione_${el.tipo}_equal_${i}`] = true
              } else {
                delete validationErrors.value[`piva_attivazione_${el.tipo}_equal_${i}`]
              }
            }
          }
          if (is_contratto_manuale.value) {
            if (el.documento.file.length) {
              delete validationErrors.value[el.documento.ref]
            }else{
              validationErrors.value[el.documento.ref] = true
            }
          }
        })        
      }
      if (type_pratica == 's') {
        array.forEach((el, i)=>{
          if ('remi' in el) {
            if (el.remi == '' || el.remi == null) {
              validationErrors.value[`remi_${el.code}`] = true
            }else{
              delete validationErrors.value[`remi_${el.code}`]
            }
          }
          if (is_contratto_manuale.value) {
            if (el.documento.file.length) {
              delete validationErrors.value[el.documento.ref]
            }else{
              validationErrors.value[el.documento.ref] = true
            }
          }
        })
      }
      return !Object.keys(validationErrors.value).length;
    }

    const newValidatePratiche = (array_v1, type) => {
      let regexPiva = /^[0-9]{11}$/
      let regex_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i

      array_v1.forEach((el, i)=>{
          if (`cf_attivazione_${type}` in el) {
            if (el[`cf_attivazione_${type}`].length < 11 || el[`cf_attivazione_${type}`].length > 16) {
              validationErrors.value[`cf_attivazione_${type}_invalid_${i}`] = true
            }else{
              delete validationErrors.value[`cf_attivazione_${type}_invalid_${i}`]
              if (el[`cf_attivazione_${type}`].length == 16) {
                if (regex_cf.test(el[`cf_attivazione_${type}`])) {
                  delete validationErrors.value[`cf_attivazione_${type}_format_${i}`]
                } else {
                  validationErrors.value[`cf_attivazione_${type}_format_${i}`] = true
                }
              }else{
                if (regexPiva.test(el[`cf_attivazione_${type}`])) {
                  delete validationErrors.value[`cf_attivazione_${type}_format_${i}`]
                } else {
                  validationErrors.value[`cf_attivazione_${type}_format_${i}`] = true
                }
              }
              if (el.cf && el.cf == el[`cf_attivazione_${type}`]) {
                validationErrors.value[`cf_attivazione_${type}_equal_${i}`] = true
              } else {
                delete validationErrors.value[`cf_attivazione_${type}_equal_${i}`]
              }
            }
          }else{
            validationErrors.value[`cf_attivazione_${type}_${i}`] = true
          }
          if (`piva_attivazione_${type}` in el) {
            if (el[`piva_attivazione_${type}`].length != 11) {
              validationErrors.value[`piva_attivazione_${type}_invalid_${i}`] = true
            }else{
              delete validationErrors.value[`piva_attivazione_${type}_invalid_${i}`]
              if (regexPiva.test(el[`piva_attivazione_${type}`])) {
                delete validationErrors.value[`piva_attivazione_${type}_format_${i}`]
              } else {
                validationErrors.value[`piva_attivazione_${type}_format_${i}`] = true
              }
              if (el.piva && el.piva == el[`piva_attivazione_${type}`]) {
                validationErrors.value[`piva_attivazione_${type}_equal_${i}`] = true
              } else {
                delete validationErrors.value[`piva_attivazione_${type}_equal_${i}`]
              }

            }
          }
      })
      return !Object.keys(validationErrors.value).length;
    }

    const onInputCell = (evt, index, type_pratica, type, type_code) => {
      if (type_code == 'cf') {
        if (type == 'ee') {
          if (evt.target.value) {
            all_pratiche.value[type_pratica][index].cf_attivazione_ee = evt.target.value.toUpperCase()
          } else {
            delete all_pratiche.value[type_pratica][index].cf_attivazione_ee
          }
        } else {
          if (evt.target.value) {
            all_pratiche.value[type_pratica][index].cf_attivazione_gas = evt.target.value.toUpperCase()
          } else {
            delete all_pratiche.value[type_pratica][index].cf_attivazione_gas
          }
        }
      } else {
        if (type == 'ee') {
          if (evt) {
            all_pratiche.value[type_pratica][index].piva_attivazione_ee = evt.toUpperCase()
          } else {
            delete all_pratiche.value[type_pratica][index].piva_attivazione_ee
          }
        } else {
          if (evt) {
            all_pratiche.value[type_pratica][index].piva_attivazione_gas = evt.toUpperCase()
          } else {
            delete all_pratiche.value[type_pratica][index].piva_attivazione_gas
          }
        }
      }
      
      delete validationErrors.value[`${type_code}_attivazione_${type}_format_${index}`]
      delete validationErrors.value[`${type_code}_attivazione_${type}_invalid_${index}`]
      delete validationErrors.value[`${type_code}_attivazione_${type}_equal_${index}`]
      delete validationErrors.value[`${type_code}_attivazione_${type}_${index}`]
    }

    const isDisabledSendPratiche = computed(()=>{
      if (currentUser.value.role == roles.admin.role || !is_contratto_manuale.value) {
        return false
      }
      let n_pratiche = 0
      let n_file = 0
      Object.keys(all_pratiche.value).forEach(pratica => {
        all_pratiche.value[pratica].forEach(item => {
          n_pratiche++
          if (item.documento.file.length) {
            n_file++
          }
        })
      })
      return n_pratiche != n_file
    })

    const showPratiche = (id = null) => {
      loading.value = true
      service.showPdfPratiche(id ?? id_matrice_pratiche.value)
      .then(()=>{
        getData()
      })
      .finally(()=> {
        // isDisableShowPratiche.value = true
        loading.value = false
      })
    }

    const signPratiche = (id_matrice) => {
      loading.value = true
      service.signPdfPratiche(id_matrice)
      .then((res)=> {
        // if (res.items) {
        //   toast.add({severity:'success', summary:'Successo', detail: 'Richiesta di firma inviata correttamente'});
        // }
        let severity = res.items.success ? 'success' : 'error'
        let summary = res.items.success ? 'Successo': 'Attenzione'
        let detail = res.items.errore != '' ? res.items.errore : 'Richiesta di firma inviata correttamente'
        
        toast.add({severity:severity, summary: summary, detail:detail});
        deleteChangesPratiche()
        getData()
      })
      .finally(()=> {
        loading.value = false
      })
    }

    const requireConfirmation = (event, data, index) => {
      confirm.require({
        target: event.currentTarget,
        group: 'headless',
        message: 'Vuoi eliminare la fornitura?',
        rejectClass: 'p-button-danger p-button-sm',
        acceptClass: 'p-button-success p-button-sm',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        accept: () => {
          deleteSupply(data, index)
          toast.add({severity:'info', summary:'', detail:'Fornitura eliminata', life: 3000});
        },
        reject: () => {
          // toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
        }
      });
    }

    const deleteContratto = async (event, in_delete, row = null) => {
      if (in_delete) {
        toast.add({severity:'info', summary:'', detail:'Eliminazione in corso', life: 3000});

        loading.value = true
        let fd = new FormData()
        fd.append('matrice_id', JSON.stringify(row.id));
        fd.append('note', JSON.stringify(row.note_annullamento));
  
        await service.sendDeleteContratto(fd)
        .then((res)=>{
          let severity = res.items.error ? 'warn' : 'success' 
          let detail = res.items.response
          toast.add({severity:severity, summary:'', detail: detail, life: 5000})
          getContatori()
          getData(true)
        })
        .finally(()=> {
          loading.value = false
        })
      }else{
        hideOverlayAnnullaContratto(event)
        toast.add({severity:'success', summary:'', detail:'Eliminazione annullata', life: 3000});
      }
    }
    
    const saveChangesMultisito = async () => {
      if(Object.keys(validationErrors.value).length > 0 || !validateModifiche()) {
          toast.add({severity:'warn', summary:'Campi Errati', detail: 'Alcuni campi sembrano vuoti o errati', life:3500});
      }
      else{
        loading.value = true
        let fd = new FormData()
        fd.append('matrice_id', JSON.stringify(id_matrice_pratiche.value));
        fd.append('modifiche', JSON.stringify(modifiche.value));
  
        await service.saveChangesMultisito(fd)
        .then((res)=>{
          let severity = res.items.error ? 'warn' : 'success' 
          let detail = res.items.response
          toast.add({severity:severity, summary:'', detail: detail, life:3500})

          deleteChangesPratiche()
          getData()
        })
        .finally(()=> {
          loading.value = false
        })
      }
    }
    const validateModifiche = () => {
      let validation_prop = [
        'code'
      ]
      if (type_client_pratiche.value != 3) {
        validation_prop.push('unique_cf')
        if (type_client_pratiche.value != 2) {
          validation_prop.push('unique_piva')
        }
      }else{
        validation_prop.push('cf')
      }

      modifiche.value.forEach((modifica, index) => {
        validation_prop.forEach(prop => {
          let validator = `${prop}_${index}`
          let validation
          if (prop.includes('unique_')) {
            validator = prop 
            validation = eval(`${prop}.value`)
          }else{
            validation = modifica[prop] 
          }
          if (!validation) {
            validationErrors.value[validator] = true
          } else {
            delete validationErrors.value[validator]
          }
        })
      })

      getDuplicate()

      return !Object.keys(validationErrors.value).length;
    }
    const getDuplicate = () => {
      let supplies = []
      
      modifiche.value.forEach((modifica, index) => {
        supplies.push(modifica.code);
      })
      
      let duplicate = []
      supplies.forEach((supply, i) => {
        let new_list = [...supplies]
        new_list.splice(i, 1);
        
        if (new_list.includes(supply)) {
          let objs = modifiche.value.filter(modifica => modifica.code == supply)
          Object.keys(objs).forEach((obj,id) => {
            duplicate.push(id)
          })
        }else{
          let id = modifiche.value.findIndex(modifica => modifica.code == supply)
          delete validationErrors.value[`existent_${id}`]
        }
      })
      duplicate = [...new Set(duplicate)]
      
      if (duplicate.length) {
        duplicate.forEach(id => {
          validationErrors.value[`existent_${id}`] = true
        })
      }
      
    }

    const deleteSupply = (row, index) => {
      modifiche.value.splice(index, 1);
      if (modifiche.value.length == 0){
        saveChangesMultisito()
        deleteChangesPratiche()
      }
    }

    const handleFileNewUpload = (evt, ref) => {
      let index = requiredDocuments.value.findIndex(el => el.ref == ref)
      requiredDocuments.value[index].files = []
      requiredDocuments.value[index].files = self.refs[`${ref}`].files
    }
    const handleFileMissing = (evt, ref) => {
      let index = missingDocuments.value.findIndex(el => el.ref == ref)
      missingDocuments.value[index].files = []
      missingDocuments.value[index].files = self.refs[`${ref}`].files
    }
    const handleFilePratiche = (evt, ref, type) => {
      let index = all_pratiche.value[type].findIndex(el => el.documento.ref == ref)
      all_pratiche.value[type][index].documento.file = []
      all_pratiche.value[type][index].documento.nome = ''
      if (self.refs[`${ref}`].files[0]) {
        all_pratiche.value[type][index].documento.file = self.refs[`${ref}`].files
        all_pratiche.value[type][index].documento.nome = self.refs[`${ref}`].files[0].name        
      }
      delete validationErrors.value[ref]
    }

    const isDisabledSetFirmato = computed( () => {
      let disabled = true
      let has_files = []
      requiredDocuments.value.forEach((doc)=>{
        if (doc.files.length) {
          has_files.push(true)
        }
      })
      disabled = has_files.length == requiredDocuments.value.length ? false : true
      return disabled
    })
    const isDisabledUploadNewFile = computed( () => {
      let has_files = []
      missingDocuments.value.forEach((doc)=>{
        if (doc.files.length) {
          has_files.push(true)
        }
      })

      return has_files.length != missingDocuments.value.length
    })

    return {
      moment,
      filters,
      dates_firma,
      authors,
      rapps,
      clients,
      ids,
      items,
      selectedItems,
      loading,
      loaded,
      uploadNewMatrice,
      downloadMatrice,
      downloadsMatrici,
      // onMatriceSelected,
      uploadMatrice,
      newMatriceDialog,
      insertSignedContractDialog,
      insertSignedContract,
      handleFileNewUpload,
      handleFilePratiche,
      handleFileMissing,
      all_file_pratiche,
      all_pratiche,
      isDisabledSendPratiche,
      typeMatrice,
      typesMatrice,
      matriceUploadKey,
      typesClient,
      selectedTypeClient,
      offerte,
      selectedOfferte,
      isIvaAgevolata,
      showPdf,
      signPdf,
      fileToUpload,
      handleFileUpload,
      allowedType,
      // onCheckRowMultisito,
      validationErrors,
      submitted,
      roles,
      currentUser,
      contatori,
      updateRapp,
      deleteChanges,
      showModal,
      showModalMobile,
      showModalPratiche,
      isCorrect,
      insertDataRapp,
      rappresentante,
      districts,
      cities,
      toponimi,
      onSelected,
      allowedTypeDoc,
      files,
      deleteUpdate,
      goToPratiche,
      insertDataForPratiche,
      // pratiche_s1_ee,
      // pratiche_s1_gas,
      // pratiche_v1_ee,
      // pratiche_v1_gas,
      deleteChangesPratiche,
      sendPratiche,
      onInputCell,
      sent_pratica,
      isDisableShowPratiche,
      showPratiche,
      signPratiche,
      minDate,
      is_contratto_manuale,
      // has_piva,
      // is_number,
      getSeverity,
      getTooltip,
      stati,
      uploadNewFile,
      onSelectedStatus,
      selectedStatus,
      getSeverityTag,
      onChangeDate,
      selectedDate,
      maxDate,
      onTodayClick,
      selectedSignDate,
      removeFilters,
      toggle,
      op,
      op_modifiche,
      op_richiesta_modifiche,
      filesView,
      newFilesView,
      getFileCaricamento,
      hideOverlay,
      loadingFilename,
      downloadPdf,
      downloadZip,
      newValidatePratiche,
      expandedRows,
      onRowExpand,
      stati_precheck,
      getSignLink,
      getSignData,
      links,
      sign_data,
      sign_data_copy,
      changed_sign_data,
      copyLink,
      windowSize,
      esportaCaricamenti,
      editingRows,
      modifiche,
      saveChangesMultisito,
      deleteSupply,
      requireConfirmation,
      confirm,
      type_client_pratiche,
      onInput,
      unique_cf,
      unique_piva,
      esportaEsiti,
      all_supplies,
      file_caricati,
      setFirmato,
      requiredDocuments,
      allowedTypes,      
      vs,
      ctr,
      matrice_for_signed_contract,
      deleteInsertSignedContract,
      isDisabledSetFirmato,
      isDisableInput,
      isDisableInputMissing,
      maxDateSigned,
      data_firma_cartaceo,
      onChangeDataFirma,
      id_matrice_pratiche,
      sendSignData,
      isDisabledSendSignData,
      loaded_sign_data,
      setChangedSignData,
      sendRequestSignData,
      initData,
      totali,
      insertMissingDocumentsDialog,
      insertMissingDocuments,
      deleteInsertMissingDocuments,
      missingDocuments,
      matrice_for_missing_documents,
      isDisabledUploadNewFile,
      remiOption,
      onSelectedRemi,
      op_annulla_contratto,
      hideOverlayAnnullaContratto,
      matrice_da_annullare,
      deleteContratto,
      cancelDeletion,
    }
  },
}
</script>

<style>
.caricamenti-multisito .btn-multisito, .caricamenti-multisito .p-fileupload-choose {
  width: 138px;
  height: 60px;
  font-size: 0.9rem;
  margin: 0 5px 5px 0;
}
.p-column-header-content .p-column-title{
  white-space: normal;
}
/* @media (min-width: 960px) and (max-width: 1250px) {
  .caricamenti-multisito {
    width: 1210px;
  }
} */

@media (max-width: 960px) {
  .caricamenti-multisito .btn-multisito, .caricamenti-multisito .p-fileupload-choose {
    margin: 5px;
  }
}

@media (max-width: 400px){
  .layout-main > .card{
    padding-left: unset;
  }
}

.overlayDocs{
  width: 400px !important;
}

.overlayDocs .p-component{
  max-width: 350px !important;
}

</style>
<style scoped>
.removeFilter{
  position: relative;
  top: 5px;
}
.layout-main > .card{
  min-width: 370px !important;
  padding: 1rem;
}
@media (max-width: 400px){
  .p-column-header-content .p-column-title{
    font-size: 1vw;
  }

  /* .layout-main > .card{
    padding-left: unset;
  } */
  .div-buttons{
    padding: 1rem;
  }
}
.div-buttons{
  padding: 1rem 1rem 1rem 0;
}

.form-file .button-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.form-file .allowed-format {
  font-size: 12px;
  color: rgb(186, 186, 186);
  margin-top: 2px;
}

.form-file .button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgb(217, 217, 217) !important; 
  cursor: pointer;
  color: rgb(168, 168, 168);
  padding: 10px 2px;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  box-shadow: none;
}

.button-wrapper-file {
  position: relative;
  width: 150px;
  text-align: center;
}

.button-wrapper-file span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #00bfff;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0);
}


.form-file .button-wrapper span.label.active {
  background: #2196F3;
  color: white;
}

#upload, #uploadModuli, .uploadModuli {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.btn-downloads-matrici {
  position:fixed;
  bottom:60px; 
  right:40px;
  z-index:2;
}
.nota{
  border-bottom: 1px solid #6c757d3d;
  margin-bottom: 10px;
}
.button-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #00bfff;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0);
}

#upload, #uploadCartaceo , #di, #vs, #ft, #ctr{
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

</style>

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Riepilogo
            </template>
            <template v-slot:subtitle>
                I dati che hai inserito
            </template>
            <template v-slot:content>
                <div class="grid">
                    <div class="col-12 md:col-6"><b>Tipo Contratto:</b>{{formData.tipo.name}}</div>
                    <!-- <div class="col-12 md:col-4"><b>Data Inizio:</b>{{reverseDate(formData.dataInizio)}}</div> -->
                    <div class="col-12 md:col-6"><b>Nome Offerta:</b>{{formData.nomeOfferta}}</div>
                    <div class="col-12 md:col-6"><b>Tipo Offerta:</b>{{formData.tipoOfferta.name}}</div>
                    <div class="col-12 md:col-6"><b>Tipo Tariffa:</b>{{formData.tariffazione.name}}</div>
                    <div class="col-12 md:col-6"><b>PCV Fix:</b>{{formData.pvcFix}}</div>
                    <div class="col-12 md:col-6"><b>PCV Variabile:</b>{{formData.pvcVariabile}}</div>
                    <div class="col-12 md:col-6"><b>Pa:</b>{{formData.pa}}</div>
                    <div class="col-12 md:col-6"><b>Sbilanciamento:</b>{{formData.sbilanciamento}}</div>
                    <div class="col-12 md:col-6"><b>Dep.Cauzionale:</b>{{formData.dep_cauzionale}}</div>
                    <div class="col-12 md:col-6"><b>Area Clienti:</b>{{formData.area_clienti}}</div>
                    <div class="col-12 md:col-6"><b>Rinnovo:</b>{{formData.rinnovo}}</div>
                </div>
                <div class="grid"  v-if="formData.tariffazione.id == 1">
                    <div class="col-12"><b>Mono:</b>{{formData.mono}}</div>
                </div>
                <div class="grid"  v-if="formData.tariffazione.id == 2">
                     <div class="col-12 md:col-6"><b>Peak:</b>{{formData.peak}}</div>
                     <div class="col-12 md:col-6"><b>OPeak:</b>{{formData.opeak}}</div>
                </div>
                <div class="grid"  v-if="formData.tariffazione.id == 3">   
                    <div class="col-12 md:col-4"><b>F1:</b>{{formData.f1}}</div>
                    <div class="col-12 md:col-4"><b>F2:</b>{{formData.f2}}</div>
                    <div class="col-12 md:col-4"><b>F3:</b>{{formData.f3}}</div>
                </div>
                <div class="grid">
                    <div class="col-12 md:col-6"><b>Data Inizio:</b>{{reverseDate(formData.dataInizio)}}</div>
                    <div class="col-12 md:col-6"><b>Data Fine:</b>{{reverseDate(formData.dataFine)}}</div>

                    <div class="col-12 md:col-6"><b>Gettone1 <br><small>Valore</small></b>{{formData.gettone1}}</div>
                    <div class="col-12 md:col-6"><b>Gettone1 <br><small>Mesi</small></b>{{formData.gettone1_giorni}}</div>
                    <div class="col-12 md:col-6"><b>Gettone2 <br><small>Valore</small></b>{{formData.gettone2}}</div>
                    <div class="col-12 md:col-6"><b>Gettone2 <br><small>Mesi</small></b>{{formData.gettone2_giorni}}</div>
                    <div class="col-12 md:col-6"><b>Gettone3 <br><small>Valore</small></b>{{formData.gettone3}}</div>
                    <div class="col-12 md:col-6"><b>Gettone3 <br><small>Mesi</small></b>{{formData.gettone3_giorni}}</div>

                    <div class="col-12 md:col-4"><b>Gettone consumo:</b>{{formData.gettone_consumo}}</div>
                    <div class="col-12 md:col-4"><b>Gettone ricorrente punto:</b>{{formData.gettone_ric_punto}}</div>
                    <div class="col-12 md:col-4"><b>Gettone ricorrente consumo:</b>{{formData.gettone_ric_consumo}}</div>
       
                </div>     
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                    <Button  label="Salva" :disabled="isDisable" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import moment from 'moment'
import {ref, getCurrentInstance} from 'vue'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import Button from 'primevue/button';


export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {


        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const isDisable = ref(false);
   
        const prevPage = () => {
            emit('prev-page', {pageIndex: 3});
        }
        const complete = async () => { 
            emit("activeLoader", true)                
            service.saveOfferta(props.formData).then((res) => {   
              isDisable.value = true
              if (!res) {
                setTimeout(function() {
                  emit('complete');
                  }, 
                1800); 
              }          
            }).finally(() => {
              emit("activeLoader", false)
              isDisable.value = false
            })
        }

        const reverseDate = (data) => {
            return moment(data).format("DD-MM-YYYY")
        }

        return {prevPage, complete, reverseDate, isDisable}
    },
    
}
</script>
<style scoped>
.rid {
    border: 1px solid rgba(51, 51, 51, 0.068);
    margin-top: 20px;
}
</style>

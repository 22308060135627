<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Anagrafica
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="p-field col-12  md:col-6">
                        <label for="voltaggio">Voltaggio</label><br>
                        <InputNumber v-model="voltaggio" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.voltaggio && submitted}]" />         
                        <small v-show="validationErrors.voltaggio && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="potenza_imp">Potenza Impegnata</label><br>
                        <InputNumber v-model="potenza_imp" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.potenza_imp && submitted}]" />         
                        <small v-show="validationErrors.potenza_imp && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="potenza_disp">Potenza Disponibile</label><br>
                        <InputNumber v-model="potenza_disp" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.potenza_disp && submitted}]" />         
                        <small v-show="validationErrors.potenza_disp && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="consumo_annuo">Consumo annuo</label><br>
                        <InputNumber v-model="consumo_annuo" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.consumo_annuo && submitted}]" />         
                        <small v-show="validationErrors.consumo_annuo && submitted" class="p-error">Campo richiesto.</small>
                    </div>              
                    <div class="p-field col-12  md:col-6">
                        <label for="distributore">Distributore</label><br>
                        <Dropdown  v-model="selectedDistributore" :options="distributori" optionLabel="name" placeholder="Seleziona distributore" :class="['inputfield w-full',{'p-invalid': validationErrors.distributore && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.distributore && submitted" class="p-error">Campo richiesto.</small>
                    </div>         
                    <div class="p-field col-12  md:col-6">
                        <label for="misuratore">Misuratore</label><br>
                        <Dropdown  v-model="selectedMisuratore" :options="misuratori" optionLabel="name" placeholder="Seleziona misuratore" :class="['inputfield w-full',{'p-invalid': validationErrors.misuratore && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.misuratore && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="uso">Uso</label><br>
                        <Dropdown  v-model="selectedUso" :options="usi" optionLabel="name" placeholder="Seleziona uso" :class="['inputfield w-full',{'p-invalid': validationErrors.uso && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.uso && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="residente">Residente</label><br>
                        <Dropdown  v-model="selectedResidente" :options="residenti" optionLabel="name" placeholder="Seleziona residente" :class="['inputfield w-full',{'p-invalid': validationErrors.residente && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.residente && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="tensione">Tensione</label><br>
                        <Dropdown  v-model="selectedTensione" :options="tensioni" optionLabel="name" placeholder="Seleziona tensione" :class="['inputfield w-full',{'p-invalid': validationErrors.tensione && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.tensione && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="stato">Stato</label><br>
                         <Dropdown  v-model="selectedStato" :options="stati" optionLabel="name" placeholder="Seleziona stato" :class="['inputfield w-full',{'p-invalid': validationErrors.stato && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.stato && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="stima">Stima</label><br>
                        <Dropdown  v-model="selectedStima" :options="stime" optionLabel="name" placeholder="Seleziona stime" :class="['inputfield w-full',{'p-invalid': validationErrors.stima && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.stima && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="cod_fiscale">Cf attivazione</label><br>
                        <InputText id="cod_fiscale" v-model="cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.format_cf && submitted}]" />
                        <div v-if="validationErrors.format_cf && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="partita_iva">P.iva attivazione</label><br>
                        <InputText type="number" id="partita_iva" v-model="partita_iva" :class="['inputfield w-full',{'p-invalid': validationErrors.format_piva && submitted}]" />
                        <div v-if="validationErrors.format_piva && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="note">Note</label><br>
                        <InputText id="note" v-model="note" class="inputfield w-full" />
                    </div>          
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <i></i>
                     <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />  
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';

export default {
    components: {
        Card,
        InputText,
        Button,
        Dropdown,
        InputNumber
    },
    props: {
        formData: Object
    },
    setup (props, {emit}) {
        const voltaggio = ref(null)
        const potenza_imp = ref(null)
        const potenza_disp = ref(null)
        const consumo_annuo = ref(null)
        const distributori = ref([])
        const selectedDistributore = ref(null)
        const misuratori = ref([])
        const selectedMisuratore = ref(null)
        const usi = ref([])
        const selectedUso = ref(null)
        const residenti = ref([])
        const selectedResidente = ref(null)
        const tensioni = ref([])
        const selectedTensione = ref(null)
        const stati = ref([])
        const selectedStato = ref(null)
        const stime = ref([])
        const selectedStima = ref(null)
        const cod_fiscale = ref('')
        const partita_iva = ref('')
        const note = ref('')
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

       

        onMounted(() => {
            service.getDistributori().then((res) => {
                distributori.value = res.items
            })
            service.getMisuratori().then((res) => {
                misuratori.value = res.items
            })
            service.getUsiEnergy().then((res) => {
                usi.value = res.items
            })
            service.getResidenti().then((res) => {
                residenti.value = res.items
            })
            service.getTensioni().then((res) => {
                tensioni.value = res.items
            })
            service.getStati().then((res) => {
                stati.value = res.items
            })
            service.getStime().then((res) => {
                stime.value = res.items
            })    
        })


        const prevPage = () => {
            emit('prev-page', {pageIndex: 4});
        }
        const nextPage = () => {

            submitted.value = true;
            if (validateForm()) {
                emit('next-page',{
                    formData: {
                        voltaggio: voltaggio.value,
                        potenza_disp: potenza_disp.value,
                        potenza_imp: potenza_imp.value,
                        consumo_annuo: consumo_annuo.value,
                        distributore: selectedDistributore.value,
                        misuratore: selectedMisuratore.value,
                        uso: selectedUso.value,
                        residente: selectedResidente.value,
                        tensione: selectedTensione.value,
                        stato: selectedStato.value,
                        stima: selectedStima.value,
                        cod_fiscale: cod_fiscale.value,
                        partita_iva: partita_iva.value,
                        note: note.value
                    },
                    pageIndex: 4
                });
            }        
        }

        const validateForm = () => {
            let reg_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i   
            let reg_piva = /^\d+$/;

            if (partita_iva.value != '') {
                if (reg_piva.test(partita_iva.value) && partita_iva.value.length == 11)
                     delete validationErrors.value['format_piva'];     
                else 
                    validationErrors.value['format_piva'] = true;                      
            }
            
            if (cod_fiscale.value != '') {
               if (!reg_cf.test(cod_fiscale.value)) 
                    validationErrors.value['format_cf'] = true;
                else 
                    delete validationErrors.value['format_cf']; 
            }
            

            if (voltaggio.value == null || voltaggio.value == '')
                validationErrors.value['voltaggio'] = true;
            else
                delete validationErrors.value['voltaggio'];
            
            if (potenza_imp.value == null || potenza_imp.value == '')
                validationErrors.value['potenza_imp'] = true;
            else
                delete validationErrors.value['potenza_imp'];

            if (potenza_disp.value == null || potenza_disp.value == '')
                validationErrors.value['potenza_disp'] = true;
            else
                delete validationErrors.value['potenza_disp'];
            
            if (consumo_annuo.value == null || consumo_annuo.value == '')
                validationErrors.value['consumo_annuo'] = true;
            else
                delete validationErrors.value['consumo_annuo'];

            if (selectedDistributore.value == null || selectedDistributore.value == '')
                validationErrors.value['distributore'] = true;
            else
                delete validationErrors.value['distributore'];

            if (selectedMisuratore.value == null || selectedMisuratore.value == '')
                validationErrors.value['misuratore'] = true;
            else
                delete validationErrors.value['misuratore'];
            
            if (selectedUso.value == null || selectedUso.value == '')
                validationErrors.value['uso'] = true;
            else
                delete validationErrors.value['uso'];
            
             if (selectedResidente.value == null || selectedResidente.value == '')
                validationErrors.value['residente'] = true;
            else
                delete validationErrors.value['residente'];
            
            if (selectedTensione.value == null || selectedTensione.value == '')
                validationErrors.value['tensione'] = true;
            else
                delete validationErrors.value['tensione'];
            
            if (selectedStato.value == null || selectedStato.value == '')
                validationErrors.value['stato'] = true;
            else
                delete validationErrors.value['stato'];

             if (selectedStima.value == null || selectedStima.value == '')
                validationErrors.value['stima'] = true;
            else
                delete validationErrors.value['stima'];

            return !Object.keys(validationErrors.value).length;
        }

        return {voltaggio, potenza_imp, potenza_disp, consumo_annuo, distributori, selectedDistributore,
                misuratori, selectedMisuratore, usi, selectedUso, residenti, selectedResidente, tensioni,
                selectedTensione, stati, selectedStato, stime, selectedStima, cod_fiscale, partita_iva,
                note, validationErrors, submitted, prevPage, validateForm, nextPage
        }
    }
 
}
</script>

<template>
    <div class="stepsdemo-content">
        <Card >
            <template v-slot:title>
                Documenti
            </template>
            <template v-slot:subtitle>
                Upload Documenti
            </template>
            <template v-slot:content>
              <div class="flex align-items-center flex-column ">
                <div class="button-wrapper mb-2">
                  <span class="label">{{store.state.all.data.files.length == 0 ? 'Carica File' : store.state.all.data.files.length +' files'}}</span>
                  <input id="upload" type="file" ref="file" @change="handleFileUpload( $event )" accept="application/pdf,image/gif, image/jpeg, image/jpg,image/png" multiple/>
                </div>
                <div v-show="validationErrors.files && submitted" class="p-error">Campo richiesto.</div>
                <div v-show="validationErrors.type && submitted" class="p-error">Formato non consentito</div>
              </div>
              <div class="text-center">
                <small>Formati consentiti: {{allowedType.join(", ")}}</small>
              </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>


</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated} from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'   
import Card from 'primevue/card';
import Button from 'primevue/button';
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'


export default {
    components: {
        Card,
        Button,
    },
    props: {
        formData: Object
    },

    emits: ["next-page","prev-page"],

    setup (props, {emit}) {
        const store = useStore()
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)

        const currentUser = ref(store.state.auth.user)
        const stepIndex = 4
        const allowedType = ['pdf','jpeg','jpg','png']
        const validationErrors = ref({})
        const submitted = ref(false)
        onUpdated(()=>{
        })
        onMounted(()=>{
          store.state.all.data.files = []
        })

        const nextPage = () => {
          submitted.value = true;
          if (validateForm() ) {

            store.state.all.data.realod = true
            store.state.all.data.step = 'documenti'
            store.state.all.data.wizard = 'agenti/inserimento'
            store.state.all.data.azienda = currentUser.value.area
            store.state.all.data.pageIndex = stepIndex

            if(store.state.all.bozzaId == 0){

              praticheWizardBozzeService.create("agenti/inserimento", JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
                if(res.success){
                  store.state.all.bozzaId = res.item.id
                  emit('next-page', stepIndex);
                }
              })

              }else{
                praticheWizardBozzeService.update(store.state.all.bozzaId, JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
                  if(res.success){
                    store.state.all.bozzaId = res.item.id
                      emit('next-page', stepIndex);
                  }
                })
            }
          }       
        }

        const prevPage = () => {
            emit('prev-page', stepIndex);
        }

        
        const handleFileUpload = (evt) => {
          evt.preventDefault()
          submitted.value = false;
          store.state.all.data.files = self.refs.file.files
        }

        

        const validateForm = () => {
          if (store.state.all.data.files.length == 0) {
              validationErrors.value['files'] = true;
              delete validationErrors.value['type'];
          } else {
            delete validationErrors.value['files'];
          }

          if (store.state.all.data.files.length > 0) {
            let valid = [...store.state.all.data.files].every(file => allowedType.includes(file.type.replace(/(.*)\//g, '')), false);
            if (valid) 
              delete validationErrors.value['type'];
            else
              validationErrors.value['type'] = true;
          }

          return !Object.keys(validationErrors.value).length;
        }

        return {
            store,
           validationErrors, 
           submitted, 
           service, 
           nextPage, 
           prevPage, 
           handleFileUpload,
           allowedType
        }

    },
}
</script>
<style>
.button-wrapper {
  position: relative;
  width: 150px;
  text-align: center;
}

.button-wrapper span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  background: #00bfff;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
  text-transform:uppercase;
  font-size:12px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #00000050, 5px 5px 15px 5px rgba(0,0,0,0);
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
</style>

<template>

    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Codice Fiscale
            </template>
            <template v-slot:subtitle>
                Inserisci il codice fiscale
            </template>
            <template v-slot:content>
                <div class="p-fluid">
                    <div class="p-field">
                        <InputText :disabled="disabled" id="cod_fiscale" v-model="cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.cod_fiscale || validationErrors.format || validationErrors.exist && submitted}]" />
                        <div v-if="validationErrors.cod_fiscale && submitted" class="p-error">Campo richiesto.</div>
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                        <div v-if="validationErrors.exist && submitted" class="p-error">Codice fiscale esistente.</div>
                    </div>
                    <div class="p-field-checkbox">
                        <Checkbox  @change="onCheck" id="binary" v-model="checked" :binary="true" class="mr-2" />
                        <label for="binary">Non conosco il codice fiscale</label>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance} from 'vue';
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { useToast } from 'primevue/usetoast';


export default {
    components: {
        Card,
        InputText,
        Button,
        Checkbox
    },
     props: {
        formData: Object
    },
    emits: ["next-page", "complete"],
    inheritAttrs: false,
    setup (props, {emit}) {

        const toast = useToast();
        const cod_fiscale = ref('')
        const format = ref(false) 
        const exist = ref(false)
        const checked = ref(false); 
        const disabled = ref(false); 
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

       
        const nextPage = () => {
            submitted.value = true;
            if (checked.value) {
                goNext()
            } else {
                validateForm ()
            }
            
        }
        const validateForm = () => {
            validationErrors.value = []           
            if (validateInput() && validateCf()) {
               validateCfExist()
            }               
        }

        const validateInput = () => {
            if (cod_fiscale.value == "") {
                validationErrors.value['cod_fiscale'] = true;          
                return false
            } else {
                delete validationErrors.value['cod_fiscale'];
              return true  
            }
        }
        const validateCf = () => {
            // validate regex cf
            let regCf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
            let regPiva = /^[0-9]{11}$/
            if (cod_fiscale.value.length == 16) {
              if (!regCf.test(cod_fiscale.value)) {
                validationErrors.value['format'] = true;
                 return false
              } else {
                  delete validationErrors.value['format'];
                  return true
              }  
            } else {
              if (!regPiva.test(cod_fiscale.value)) {
                validationErrors.value['format'] = true;
                 return false
              } else {
                  delete validationErrors.value['format'];
                  return true
              }
            }
                           
        }
        const validateCfExist = () => {
            service.checkCodeRappExist(cod_fiscale.value).then((res) => {
                if (res.items == true) {
                   goNext()
                } else {
                    validationErrors.value['exist'] = true;
                    toast.add({severity:'warn', summary:'Esistente:', detail: res.items[0].name});        
                }
            })            
        }

        const onCheck = () => {
            if (checked.value) {
                submitted.value = false
                disabled.value = true
                cod_fiscale.value = ''
            } else {
                disabled.value = false
            }          
        }

        const goNext = () => {
            emit('next-page', {formData: {cod_fiscale: cod_fiscale.value}, pageIndex: 0});
            delete validationErrors.value['exist'];
            toast.removeAllGroups();
        }
        

        return {cod_fiscale, format, exist, validationErrors, self, service, submitted, nextPage,
         validateForm, validateInput, validateCf, validateCfExist, checked, goNext, onCheck, disabled}
    },    

}
</script>

<style scoped>
input[disabled] {
  cursor: not-allowed;;
}
</style>

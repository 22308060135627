<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Anagrafica
            </template>
            <template v-slot:subtitle>
                Inserisci i dati
            </template>
            <template v-slot:content>
                <div class="formgrid grid">         
                    <div class="p-field col-12 md:col-6">
                        <label for="name">Nome e Cognome (Ragsoc)</label><br>
                        <InputText id="name" v-model="name" :class="['inputfield w-full',{'p-invalid': validationErrors.name && submitted}]" />
                        <small v-show="validationErrors.name && submitted" class="p-error">Campo richiesto.</small>
                    </div>     
                    <div class="p-field col-12 md:col-6">
                        <label for="mobile">Cellulare</label><br>                   
                        <InputMask id="mobile" mask="9999999999" v-model="mobile" :class="['inputfield w-full',{'p-invalid': validationErrors.mobile && submitted}]" />
                        <small v-show="validationErrors.mobile && submitted" class="p-error">Campo richiesto.</small>
                    </div>               
                    <div class="p-field col-12 md:col-6">
                        <label for="email">Email</label><br>
                        <InputText id="email" v-model="email" :class="['inputfield w-full',{'p-invalid': validationErrors.email || validationErrors.isEmail && submitted}]" />
                        <div v-show="validationErrors.email && submitted" class="p-error">Campo richiesto.</div>
                        <div v-show="validationErrors.isEmail && submitted" class="p-error">Formato errato.</div>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="note">Note</label><br>
                        <InputText id="note" v-model="note" :class="['inputfield w-full',{'p-invalid': validationErrors.note && submitted}]" />
                        <div v-show="validationErrors.note && submitted" class="p-error">Campo richiesto.</div>
                    </div>              
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button  label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref} from 'vue';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';


export default {
    components: {
        Card,
        InputText,
        Button,
        InputMask,
    },
     props: {
        formData: Object
    },
    setup (props, {emit}) {
        const name = ref('')
        const mobile = ref(null) 
        const email = ref('')   
        const note= ref('')   
        const isEmail = ref(false)
        const validationErrors = ref({})
        const submitted = ref(false)
        
      

        const nextPage = () => {

            submitted.value = true;
             if (validateForm()) { 
                 emit('next-page', {
                    formData: {
                        name: name.value,
                        mobile: mobile.value,
                        email: email.value,
                        note: note.value,
                    },
                        pageIndex: 0
                });    
             }
        }

        

        const validateForm = () => {
            let regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (!name.value.trim())
                validationErrors.value['name'] = true;
            else 
                delete validationErrors.value['name'];

             
            if (email.value != '') {
                if (regex_email.test(email.value)) {
                    delete validationErrors.value['isEmail'];
                } else {
                    validationErrors.value['isEmail'] = true;
                }
            }
            
              
            return !Object.keys(validationErrors.value).length;
        }

        return {
            name,  mobile, email, note, validationErrors, submitted, nextPage, isEmail
        }

    },
}
</script>

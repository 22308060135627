<template>

    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Fornitura
            </template>
            <template v-slot:subtitle>
                Inserisci i dati della Fornitura
            </template>         
            <template v-slot:content>

              <!--//////////// Fornitura Power  /////////////-->
              <div v-if="store.state.all.data.contratto.tipoCtr.name.toLowerCase() == 'power'">
                <div class="p-fluid">         
                    <div class="p-field">
                      <label for="pod">POD</label><br>
                        <InputText :disabled="store.state.all.data.contratto.acquisizione.id==4" id="pod"  v-model="store.state.all.data.fornitura_power.data.pod" :class="['inputfield w-full',{'p-invalid': validationErrors.pod || validationErrors.format || validationErrors.lunghezza || validationErrors.exist && submitted}]"  />
                        <small v-show="validationErrors.pod && submitted" class="p-error">Campo richiesto.<br></small>
                        <small v-show="validationErrors.format && submitted" class="p-error">Formato non valido.<br></small>
                        <small v-show="validationErrors.lunghezza && submitted" class="p-error">Lunghezza non valida.<br></small>
                        <small v-show="validationErrors.exist && submitted" class="p-error">Pod già registrato.<br></small>
                        <small v-show="store.state.all.data.contratto.acquisizione.id==4" > <b>Pod Disabilitato</b> Per nuova attivazione il pod verrà inserito successivamente<br></small>
                    </div>
                </div>
                <div class="formgrid grid">
                     <div class="p-field col-12  md:col-3">
                        <label for="toponimo">Toponimo</label><br>
                        <Dropdown  v-model="store.state.all.data.fornitura_power.data.toponimo" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo && submitted}]" />
                        <small v-show="validationErrors.toponimo && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-9">
                        <label for="address">Indirizzo  <small> (sede fornitura)</small></label><br>
                        <InputText id="address" v-model="store.state.all.data.fornitura_power.data.address" :class="['inputfield w-full',{'p-invalid': validationErrors.address && submitted}]" />
                        <small v-show="validationErrors.address && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="civico">Civico</label><br>
                        <InputText id="civico" v-model="store.state.all.data.fornitura_power.data.civico" :class="['inputfield w-full',{'p-invalid': validationErrors.civico && submitted}]" />
                        <small v-show="validationErrors.civico && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12 md:col-6">
                        <label for="cap">Cap</label><br>
                        <InputText  id="cap" v-model="store.state.all.data.fornitura_power.data.cap" :class="['inputfield w-full',{'p-invalid': validationErrors.cap && submitted}]" />
                        <small v-show="validationErrors.cap && submitted" class="p-error">Campo richiesto.</small>
                    </div>                
                    <div class="p-field col-12  md:col-6">
                        <label for="district">Provincia</label><br>
                        <Dropdown  @change="onSelectedDistrict($event)" v-model="store.state.all.data.fornitura_power.data.selectedDistrict" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedDistrict && submitted}]" />
                        <small v-show="validationErrors.selectedDistrict && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                     <div class="p-field col-12  md:col-6">
                        <label for="city">Comune</label><br>
                        <Dropdown id="city" @change="onSelectedCity($event)" v-model="store.state.all.data.fornitura_power.data.cityId" :options="cities" optionLabel="name" optionValue="id" :filter="true"  placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedCity && submitted}]" />
                        <small v-show="validationErrors.selectedCity && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                </div>   


                  <div class="formgrid grid">
                    <div class="p-field col-12  md:col-6">
                        <label for="tensione">Tipologia tensione</label><br>
                        <Dropdown  v-model="store.state.all.data.fornitura_power.data.tensione" :options="tensioni" optionLabel="name" 
                        placeholder="Seleziona tensione" :class="['inputfield w-full',{'p-invalid': validationErrors.tensione && submitted}]"
                        @change="setTensione"
                         />
                        <small v-show="validationErrors.tensione && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="voltaggio">Tensione</label><br>
                        <Dropdown v-model="store.state.all.data.fornitura_power.data.voltaggio" :options="voltaggi" optionLabel="value" placeholder="Seleziona tensione" :class="['inputfield w-full',{'p-invalid': validationErrors.voltaggio && submitted}]"
                        @change="setPotenzaImp"
                         />
                        <small v-show="validationErrors.voltaggio && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="potenza_imp">Potenza Impegnata</label><br>
                        <Dropdown editable v-model="store.state.all.data.fornitura_power.data.potenza_imp" :options="potenze_imp" optionLabel="value" placeholder="Potenza Impegnata" :class="['inputfield w-full',{'p-invalid': validationErrors.potenza_imp && submitted}]"
                        @change="setPotenzaDisp"
                        />
                        <small v-show="validationErrors.potenza_imp && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="potenza_disp">Potenza Disponibile</label><br>
                        <Dropdown editable v-model="store.state.all.data.fornitura_power.data.potenza_disp" :options="potenze_disp" optionLabel="value" placeholder="Potenza Disponibile" :class="['inputfield w-full',{'p-invalid': validationErrors.potenza_disp && submitted}]"
                        
                        />
                        <small v-show="validationErrors.potenza_imp && submitted" class="p-error">Campo richiesto.</small>
                    </div>

                    <!-- <div class="p-field col-12  md:col-6">
                        <label for="voltaggio">Tensione</label><br>
                        <InputNumber v-model="store.state.all.data.fornitura_power.data.voltaggio" :min="calculateMin('volt')" :max="calculateMax('volt')" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.voltaggio && submitted}]" />         
                        <small v-show="validationErrors.voltaggio && submitted" class="p-error">Campo richiesto.</small>
                    </div> -->
                    <!-- <div class="p-field col-12  md:col-6">
                        <label for="potenza_imp">Potenza Impegnata</label><br>
                        <InputNumber v-model="store.state.all.data.fornitura_power.data.potenza_imp" :min="calculateMin('pot_imp')" :max="calculateMax('pot_imp')" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.potenza_imp && submitted}]" />         
                        <small v-show="validationErrors.potenza_imp && submitted" class="p-error">Campo richiesto.</small>
                    </div> -->
                    <!-- <div class="p-field col-12  md:col-6">
                        <label for="potenza_disp">Potenza Disponibile</label><br>
                        <InputNumber v-model="store.state.all.data.fornitura_power.data.potenza_disp" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.potenza_disp && submitted}]" />         
                        <small v-show="validationErrors.potenza_disp && submitted" class="p-error">Campo richiesto.</small>
                    </div> -->




                    <!-- <div class="p-field col-12  md:col-6">
                        <label for="consumo_annuo">Consumo annuo</label><br>
                        <InputNumber v-model="store.state.all.data.fornitura_power.data.consumo_annuo" mode="decimal" :minFractionDigits="2" :maxFractionDigits="5" :class="['inputfield w-full',{'p-invalid': validationErrors.consumo_annuo && submitted}]" />         
                        <small v-show="validationErrors.consumo_annuo && submitted" class="p-error">Campo richiesto.</small>
                    </div>              
                    <div class="p-field col-12  md:col-6">
                        <label for="distributore">Distributore</label><br>
                        <Dropdown  v-model="store.state.all.data.fornitura_power.data.distributore" :options="distributori" optionLabel="name" placeholder="Seleziona distributore" :class="['inputfield w-full',{'p-invalid': validationErrors.distributore && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.distributore && submitted" class="p-error">Campo richiesto.</small>
                    </div>         
                    <div v-if="currentUser.role == roles.admin.role" class="p-field col-12  md:col-6">
                        <label for="misuratore">Misuratore</label><br>
                        <Dropdown  v-model="store.state.all.data.fornitura_power.data.misuratore" :options="misuratori" optionLabel="name" placeholder="Seleziona misuratore" :class="['inputfield w-full',{'p-invalid': validationErrors.misuratore && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.misuratore && submitted" class="p-error">Campo richiesto.</small>
                    </div> -->
                    <div class="p-field col-12  md:col-6">
                        <label for="uso">Uso</label><br>
                        <Dropdown v-model="store.state.all.data.fornitura_power.data.uso" :options="usi_ee" optionLabel="name" placeholder="Seleziona uso" :class="['inputfield w-full',{'p-invalid': validationErrors.uso && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.uso && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="residente">Residente</label><br>
                        <Dropdown  v-model="store.state.all.data.fornitura_power.data.residente" :options="residenti" optionLabel="name" placeholder="Seleziona residente" :class="['inputfield w-full',{'p-invalid': validationErrors.residente && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.residente && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <!-- <div v-if="currentUser.role == roles.admin.role" class="p-field col-12  md:col-6">
                        <label for="stato">Stato</label><br>
                         <Dropdown  v-model="store.state.all.data.fornitura_power.data.stato" :options="stati" optionLabel="name" placeholder="Seleziona stato" :class="['inputfield w-full',{'p-invalid': validationErrors.stato && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.stato && submitted" class="p-error">Campo richiesto.</small>
                    </div>
                    <div  v-if="currentUser.role == roles.admin.role" class="p-field col-12  md:col-6">
                        <label for="stima">Stima</label><br>
                        <Dropdown  v-model="store.state.all.data.fornitura_power.data.stima" :options="stime" optionLabel="name" placeholder="Seleziona stime" :class="['inputfield w-full',{'p-invalid': validationErrors.stima && submitted}]" >
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-show="validationErrors.stima && submitted" class="p-error">Campo richiesto.</small>
                    </div> -->
                    <div class="p-field col-12  md:col-6">
                        <label for="cod_fiscale">Cf attivazione</label><br>
                        <InputText id="cod_fiscale" v-model="store.state.all.data.fornitura_power.data.cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.format_cf || validationErrors.cf_required ||  validationErrors.cf_or_piva && submitted}]" />
                        <div v-if="validationErrors.format_cf && submitted" class="p-error">Formato errato.</div>
                        <div v-if="validationErrors.cf_required && submitted" class="p-error">Campo obbligatorio per voltura.</div>
                        <div v-if="validationErrors.cf_or_piva && submitted" class="p-error">Compila c.f. e/o p.iva.</div>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="partita_iva">P.iva attivazione</label><br>
                        <InputText type="number" id="partita_iva" v-model="store.state.all.data.fornitura_power.data.partita_iva" :class="['inputfield w-full',{'p-invalid': validationErrors.format_piva || validationErrors.piva_required || validationErrors.cf_or_piva && submitted}]" />
                        <div v-if="validationErrors.format_piva && submitted" class="p-error">Formato errato.</div>
                        <div v-if="validationErrors.piva_required && submitted" class="p-error">Campo obbligatorio per voltura.</div>
                        <div v-if="validationErrors.cf_or_piva && submitted" class="p-error">Compila c.f. e/o p.iva.</div>
                    </div>
                    <div class="p-field col-12  md:col-6">
                        <label for="note">Note</label><br>
                        <InputText id="note" v-model="store.state.all.data.fornitura_power.data.note" class="inputfield w-full" />
                    </div>          
                </div>
              </div>
              <!--//////////// Fornitura Power  /////////////-->

              <!--//////////// Fornitura Gas  /////////////-->
              <div v-if="store.state.all.data.contratto.tipoCtr.name.toLowerCase() == 'gas'">
                <div class="p-fluid">         
                    <div class="p-field">
                      <label for="pdr">PDR</label>
                      <InputText id="pdr"  v-model="store.state.all.data.fornitura_gas.data.pdr" :class="['inputfield w-full',{'p-invalid': validationErrors.pdr || validationErrors.formatPdr || validationErrors.lunghezzaPdr || validationErrors.existPdr && submitted}]"  />
                      <small v-show="validationErrors.pdr && submitted" class="p-error">Campo richiesto.<br></small>
                      <small v-show="validationErrors.formatPdr && submitted" class="p-error">Formato non valido.<br></small>
                      <small v-show="validationErrors.lunghezzaPdr && submitted" class="p-error">Lunghezza non valida.<br></small>
                      <small v-show="validationErrors.existPdr && submitted" class="p-error">Pdr già registrato.<br></small>
                    </div>
                </div>        
              <div class="formgrid grid">
                    <div class="p-field col-12  md:col-3">
                      <label for="toponimo">Toponimo</label><br>
                      <Dropdown  v-model="store.state.all.data.fornitura_gas.data.toponimo" :options="toponimi" optionLabel="name" optionValue="name" :filter="true" placeholder="Seleziona toponimo" :class="['inputfield w-full',{'p-invalid': validationErrors.toponimo && submitted}]" />
                      <small v-show="validationErrors.toponimo && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 md:col-9">
                      <label for="address">Indirizzo  <small> (sede fornitura)</small></label><br>
                      <InputText id="address" v-model="store.state.all.data.fornitura_gas.data.address" :class="['inputfield w-full',{'p-invalid': validationErrors.address && submitted}]" />
                      <small v-show="validationErrors.address && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="civico">Civico</label><br>
                      <InputText id="civico" v-model="store.state.all.data.fornitura_gas.data.civico" :class="['inputfield w-full',{'p-invalid': validationErrors.civico && submitted}]" />
                      <small v-show="validationErrors.civico && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="cap">Cap</label><br>
                      <InputText  id="cap" v-model="store.state.all.data.fornitura_gas.data.cap" :class="['inputfield w-full',{'p-invalid': validationErrors.cap && submitted}]" />
                      <small v-show="validationErrors.cap && submitted" class="p-error">Campo richiesto.</small>
                  </div>                
                  <div class="p-field col-12  md:col-6">
                      <label for="district">Provincia</label><br>
                      <Dropdown  @change="onSelectedDistrict($event)" v-model="store.state.all.data.fornitura_gas.data.selectedDistrict" :options="districts" optionLabel="name" optionValue="code" :filter="true" placeholder="Seleziona provincia" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedDistrict && submitted}]" />
                      <small v-show="validationErrors.selectedDistrict && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                    <div class="p-field col-12  md:col-6">
                      <label for="city">Comune</label><br>
                      <Dropdown id="city" @change="onSelectedCity($event)" v-model="store.state.all.data.fornitura_gas.data.cityId" :options="cities" optionLabel="name" optionValue="id" :filter="true"  placeholder="Seleziona comune" :class="['inputfield w-full',{'p-invalid': validationErrors.selectedCity && submitted}]" />
                      <small v-show="validationErrors.selectedCity && submitted" class="p-error">Campo richiesto.</small>
                  </div>
              </div>
              <div class="formgrid grid">
                  <div class="p-field col-12  md:col-6">
                      <label for="consumo_annuo">Consumo annuo</label><br>
                      <InputNumber v-model="store.state.all.data.fornitura_gas.data.consumo_annuo" mode="decimal" :minFractionDigits="2" :maxFractionDigits="6" :class="['inputfield w-full',{'p-invalid': validationErrors.consumo_annuo && submitted}]" />         
                      <small v-show="validationErrors.consumo_annuo && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12  md:col-6">
                      <label for="remi">Remi</label><br>
                      <InputText v-model="store.state.all.data.fornitura_gas.data.remi" :class="['inputfield w-full',{'p-invalid': validationErrors.remi && submitted}]" />         
                      <small v-show="validationErrors.remi && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12  md:col-6">
                      <label for="matricola">Matricola</label><br>
                      <InputText v-model="store.state.all.data.fornitura_gas.data.matricola"  :class="['inputfield w-full',{'p-invalid': validationErrors.matricola && submitted}]" />         
                      <small v-show="validationErrors.matricola && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12 md:col-6">
                      <label for="classe">Classe</label><br>
                      <Dropdown v-model="store.state.all.data.fornitura_gas.data.classe" :options="classi" optionLabel="name" optionValue="name" placeholder="Seleziona classe" :class="['inputfield w-full',{'p-invalid': validationErrors.classe && submitted}]" />
                      <small v-show="validationErrors.classe && submitted" class="p-error">Campo richiesto.</small> 
                  </div>              
                  <div class="p-field col-12  md:col-6">
                      <label for="uso">Uso</label><br>
                      <Dropdown  v-model="store.state.all.data.fornitura_gas.data.uso" :options="usi_gas" optionLabel="name" placeholder="Seleziona uso" :class="['inputfield w-full',{'p-invalid': validationErrors.uso && submitted}]" >
                          <template #value="slotProps">
                              <div v-if="slotProps.value">
                                  <div>{{slotProps.value.name}}</div>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                              <div>
                                  <div>{{slotProps.option.name}}</div>
                              </div>
                          </template>
                      </Dropdown>
                      <small v-show="validationErrors.uso && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12  md:col-6">
                      <label for="categoria">Categoria</label><br>
                      <Dropdown  v-model="store.state.all.data.fornitura_gas.data.categoria" :options="categorie" optionLabel="name" placeholder="Seleziona categoria" :class="['inputfield w-full',{'p-invalid': validationErrors.categoria && submitted}]" >
                          <template #value="slotProps">
                              <div v-if="slotProps.value">
                                  <div>{{slotProps.value.name}}</div>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                              <div>
                                  <div>{{slotProps.option.name}}</div>
                              </div>
                          </template>
                      </Dropdown>
                      <small v-show="validationErrors.categoria && submitted" class="p-error">Campo richiesto.</small>
                  </div>
                  <div class="p-field col-12  md:col-6">
                      <label for="cod_fiscale">Cf attivazione</label><br>
                      <InputText id="cod_fiscale" v-model="store.state.all.data.fornitura_gas.data.cod_fiscale" :class="['inputfield w-full',{'p-invalid': validationErrors.format_cf && submitted}]" />
                      <div v-if="validationErrors.format_cf && submitted" class="p-error">Formato errato.</div>
                  </div>
                  <div class="p-field col-12  md:col-6">
                      <label for="partita_iva">P.iva attivazione</label><br>
                      <InputText type="number" id="partita_iva" v-model="store.state.all.data.fornitura_gas.data.partita_iva" :class="['inputfield w-full',{'p-invalid': validationErrors.format_piva && submitted}]" />
                      <div v-if="validationErrors.format_piva && submitted" class="p-error">Formato errato.</div>
                  </div>
                  <div class="p-field col-12">
                      <label for="note">Note</label><br>
                      <InputText id="note" v-model="store.state.all.data.fornitura_gas.data.note" class="inputfield w-full" />
                  </div>          
                </div>
              </div> 
            
              <!--//////////// Fornitura Gas  /////////////-->

            </template>          
            <template v-slot:footer>
                <div class="grid justify-content-between">
                  <Button label="" @click="prevPage()" icon="pi pi-angle-left" iconPos="right" />
                  <i></i>
                  <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex'

import Service from '@/services/ask-pratiche/pratiche-wizard'
import PraticheWizardBozzeService from '@/services/ask-pratiche/pratiche-wizard-bozze'
import roles from '@/config/roles'

import Card from 'primevue/card';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import { useToast } from "primevue/usetoast";
// import Dropdown from 'primevue/dropdown';
// import { useToast } from 'primevue/usetoast';



export default {
    components: {
        Card,
        Button,
        InputText,
        Dropdown,
        InputNumber
    },

    props: {
      formData: Object
    },

    setup (props, {emit}) {
        const store = useStore()
        const currentUser = ref(store.state.auth.user)

        const toast = useToast();
        const stepIndex = 3

        const toponimi = ref([])
        const districts = ref([])
        const cities = ref([])
        const distributori = ref([])
        const misuratori = ref([])
        const usi_ee = ref([])
        const usi_gas = ref([])
        const residenti = ref([])
        const tensioni = ref([])

        const voltaggi = ref([
          {value:'220-230'},
          {value:'380-400'}
        ])

        const potenze_imp = ref([
          {value:'0.1'},
          {value:'0.5'},
          {value:'0.8'},
          {value:'1'},
          {value:'1.5'},
          {value:'3'},
          {value:'3.5'},
          {value:'4'},
          {value:'4.5'},
          {value:'5'},
          {value:'5.5'},
          {value:'6'}
        ])

        const potenze_disp = ref([{value: '3.3'}])
        
        const stati = ref([])
        const stime = ref([])


        const toponimo = ref(null)
        const address = ref('')
        const civico = ref('')
        const cap = ref('')
        const selectedRegionName = ref('')
        const selectedDistrict = ref(null)
        const selectedCity = ref(null)
        const selectedCityName = ref('')
        
        const pod = ref('')
        const voltaggio = ref(null)
        const potenza_imp = ref(null)
        const potenza_disp = ref(null)
        const consumo_annuo = ref(null)
        const distributore = ref(null)
        const misuratore = ref(null)
        const uso = ref(null)
        const residente = ref(null)
        const tensione = ref(null)
        const stato = ref(null)
        const stima = ref(null)
        const cod_fiscale = ref('')
        const partita_iva = ref('')
        const note = ref('')

        const classi = ref([])
        const categorie = ref([])

        const validationErrors = ref({})
        const submitted = ref(false)

        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)
        const praticheWizardBozzeService = new PraticheWizardBozzeService(self.$eventBus)

        onUpdated(() => { 
          validationErrors.value = []
          filterResidente()

          // remvove pod if nuova attivazione
          if (store.state.all.data.contratto.acquisizione.id == 4) {
            store.state.all.data.fornitura_power.data.pod = ""
          }
        })
        
        onMounted(() => {
          const apiPromises = [
            service.getTensioni().then((res) => {
                tensioni.value = res.items
                store.state.all.data.fornitura_power.data.tensione = res.items[1]
                store.state.all.data.fornitura_power.data.voltaggio = voltaggi.value[0].value
                store.state.all.data.fornitura_power.data.potenza_imp = '3'
                store.state.all.data.fornitura_power.data.potenza_disp = '3.3'
            }),


            service.getToponimi().then((res) => {
                toponimi.value = res.items
                store.state.all.data.fornitura_power.data.toponimo = 'VIA'


            }),

            service.getDistricts().then((res) => {
              districts.value = res.items


            }) , 

            service.getDistributori().then((res) => {
                distributori.value = res.items
                store.state.all.data.fornitura_power.data.distributore = res.items[0]

            }),

            service.getMisuratori().then((res) => {
                misuratori.value = res.items
                store.state.all.data.fornitura_power.data.misuratore = res.items[0]

            }),

            service.getUsiEnergy().then((res) => {
                usi_ee.value = res.items
                store.state.all.data.fornitura_power.data.uso = res.items[1]

            }),

            
            filterResidente(),
            
            service.getStati().then((res) => {
                stati.value = res.items
                store.state.all.data.fornitura_power.data.stato = res.items[0]
            }),

            service.getStime().then((res) => {
                stime.value = res.items
                store.state.all.data.fornitura_power.data.stima = res.items[0]

            }),
            service.getClassiGas().then((res) => {
                  classi.value = res.items


            }),
            service.getCategorieGas().then((res) => {
                categorie.value = res.items

            }),
            service.getUsiGas().then((res) => {
                usi_gas.value = res.items
    
              
            }),
            service.getCities().then((res) => {
                  cities.value = res.items

            })
          ]
          emit('activeLoader', true)

          Promise.all(apiPromises)
          .finally(() => {
            emit('activeLoader', false)
          })
          
          store.state.all.data.fornitura_power.data.cod_fiscale = store.state.all.data.cliente.data.codfis ?? store.state.all.data.cliente.data.cod_fiscale
          store.state.all.data.fornitura_power.data.partita_iva = store.state.all.data.cliente.data.piva
        })
        
      
        const calculateMin = (input) =>{
          let min;
          const voltaggio = store.state.all.data.fornitura_power.data.voltaggio;
          const potenzaImp = store.state.all.data.fornitura_power.data.potenza_imp;

          switch (input) {
            case 'pot_disp':
              switch (potenzaImp) {
                case '0.1':
                  min = 0.11;
                  break;
                case 'MT':
                  min = 6400;
                break;
                default:
                  min=132000;
                  break;
              }            

              break;
          
            case 'pot_imp':
              switch (voltaggio) {
                case '220-230':
                  min = 0.1
                  break;
                case '380-400':
                  min = 6
                  break;

                default:
                  min = 50
                  break;
              }
              // if (voltaggio <= 380) {
              //   min = 0.1;
              // } else if (voltaggio <= 6000) {
              //   min = 6;
              // } else if (voltaggio <= 130000) {
              //   min = 50;
              // }else{
              //   min = null;
              // }
              break;
          }
          return min
        }
        const calculateMax = (input) =>{
          let max;
          const voltaggio = store.state.all.data.fornitura_power.data.voltaggio;
          switch (input) {
            case 'pot_disp':
              switch (store.state.all.data.fornitura_power.data.tensione.name) {
                case 'BT':
                  max = 400;
                  break;
                case 'MT':
                  max = 130000;
                break;
                default:
                  max=null;
                  break;
              }
              break;
            case 'pot_imp':
              if (voltaggio < 380) {
                  max = 6;
                } else if (voltaggio <= 6000) {
                  max = 150;
                } else {
                  max = null;
                }

              break;
          }
          return max
        }
        const setPotenzaImp = () => {
          let potenzaImpValue;
          let item = 0

          switch (store.state.all.data.fornitura_power.data.voltaggio.value) {
            case '220-230':
              item = 5
              potenzaImpValue = [{value:'0.1'},{value:'0.5'},{value:'0.8'},{value:'1'},{value:'1.5'},{value:'3'},{value:'3.5'},{value:'4'},{value:'4.5'},{value:'5'},{value:'5.5'},{value:'6'}];
              break;
            case '380-400':

              potenzaImpValue = [{value:'6'},{value:'7'}, {value:'8'}, {value:'9'}, {value:'10'}, {value:'15'}, {value:'20'}, {value:'25'}, {value:'30'}];
            break;
            default:
              potenzaImpValue = [{value:null}];
              break;
          }            
          potenze_imp.value = potenzaImpValue

          store.state.all.data.fornitura_power.data.potenza_imp = potenze_imp.value[item]
          setPotenzaDisp()
          
        }
        const setPotenzaDisp = () => {
          let potenzaDispValue;
          switch (store.state.all.data.fornitura_power.data.potenza_imp.value) {
                case '0.1':
                  potenzaDispValue = [{value:'0.11'}];
                  break;
                case '0.5':
                  potenzaDispValue = [{value:'0.6'}];
                  break;
                case '0.8':
                  potenzaDispValue = [{value:'0.9'}];
                  break;
                case '1':
                  potenzaDispValue = [{value:'1.1'}];
                  break;
                case '1.5':
                  potenzaDispValue = [{value:'1.7'}];
                  break;
                case '3':
                  potenzaDispValue = [{value:'3.3'}];
                  break;
                case '4.5':
                  potenzaDispValue = [{value:'5'}];
                  break;
                case '6':
                  potenzaDispValue = [{value:'6.6'}];
                  break;
                case '10':
                  potenzaDispValue = [{value:'11'}];
                  break;
                case '15':
                  potenzaDispValue = [{value:'16.5'}];
                  break;
                case '30':
                  potenzaDispValue = [{value:'33'}];
                  break;
                default:
                  potenzaDispValue = [{value:store.state.all.data.fornitura_power.data.potenza_imp.value}];
                  break;
              }            
          potenze_disp.value = potenzaDispValue
          let item = 0
          if (potenzaDispValue.length > 2) {
            item = 5
          }
          store.state.all.data.fornitura_power.data.potenza_disp = potenze_disp.value[item].value
        }


        const setTensione = () => {
          let voltaggiValue
          switch (store.state.all.data.fornitura_power.data.tensione.name) {
                case 'BT':
                  voltaggiValue = [{value:'220-230'},{value:'380-400'}];
                  break;
                case 'MT':
                  voltaggiValue = [{value:'6400'}, {value:'8400'}, {value:'9000'}, {value:'10000'}, {value:'15000'}, {value:'20000'}, {value:'22000'}, {value:'23000'}];
                break;
                default:
                  voltaggiValue = [{value:'132000'}, {value:'220000'}];
                  break;
              }            
          voltaggi.value = voltaggiValue
          store.state.all.data.fornitura_power.data.voltaggio = voltaggi.value[0]
          setPotenzaImp()
        }



        // force residente no if client business
        const filterResidente = () =>{
          service.getResidenti().then((res) => {

            if (res.items.length > 0) {
              
              if (store.state.all.data.cliente.type == 'new') {
                if (store.state.all.data.cliente.data.tipo_client == 2) {
                    residenti.value = res.items
                    store.state.all.data.fornitura_power.data.residente = res.items[0]

                } else {
                  // residenti.value = res.items.filter(el => el.name == 'No')
                  store.state.all.data.fornitura_power.data.residente = res.items.filter(el => el.name == 'No')[0]
                }
              } else if(store.state.all.data.cliente.type == 'existent'){
                if (store.state.all.data.cliente.data.typeClient == 2) {
                    residenti.value = res.items
                    store.state.all.data.fornitura_power.data.residente = res.items[0]
                } else {
                  // residenti.value = res.items.filter(el => el.name == 'No')
                  store.state.all.data.fornitura_power.data.residente = res.items.filter(el => el.name == 'No')[0]
                }
              }
            }  

          })
        }

        const onSelectedDistrict = (evt) => {
            service.getCities(evt.value).then((res) => {
                cities.value = res.items
            })
        }


        const checkPodExist = async () => {
            
            const res = await service.checkPodExist(store.state.all.data.fornitura_power.data.pod)

            if (!res.item.exist) {
                toast.removeAllGroups();
                 delete validationErrors.value['exist'];           
            } else {
                toast.add({severity:'warn', summary:'Attenzione:', detail: 'Pod già registrato'});
                validationErrors.value['exist'] = true;                 
            }
            
            //return true  // test
            return !res.item.exist         
        }

        const precheckPod = async () => {
          // console.log(store.state.all)
          toast.add({severity:'info', summary: `Precheck in corso...`, detail: 'Non chiudere la schermata'});
          emit("activeLoader", true)
          let cliente = {
            codfis: '',
            piva: '',
          }

          if (store.state.all.data.cliente.type == 'new') {
            // let tipoId = store.state.all.data.cliente.data.tipo_client
            if (store.state.all.data.contratto.acquisizione.id == 2) {
              if (store.state.all.data.fornitura_power.data.cod_fiscale != null && store.state.all.data.fornitura_power.data.cod_fiscale !="") {
                cliente.codfis = store.state.all.data.fornitura_power.data.cod_fiscale
              }
              if (store.state.all.data.fornitura_power.data.partita_iva != null && store.state.all.data.fornitura_power.data.partita_iva !="") {
                cliente.piva = store.state.all.data.fornitura_power.data.partita_iva
              }
              // prefer cf if both are filled
              if (cliente.codfis.length > 0 && cliente.piva.length == 11) {
                cliente.piva = ''
              }
            } else {
              
              if (store.state.all.data.cliente.data.cod_fiscale != null && store.state.all.data.cliente.data.cod_fiscale !="") {
                cliente.codfis = store.state.all.data.cliente.data.cod_fiscale
              }
              if (store.state.all.data.cliente.data.partita_iva != null && store.state.all.data.cliente.data.partita_iva !="") {
                cliente.piva = store.state.all.data.cliente.data.partita_iva
              }
              // prefer cf if both are filled
              if (cliente.codfis.length > 0 && cliente.piva.length == 11) {
                cliente.piva = ''
              }
            }
     
          } else if (store.state.all.data.cliente.type == 'existent') {
            // let tipoId = store.state.all.data.cliente.data.typeClient
            if (store.state.all.data.contratto.acquisizione.id == 2) {
              if (store.state.all.data.fornitura_power.data.cod_fiscale != null && store.state.all.data.fornitura_power.data.cod_fiscale !="") {
                cliente.codfis = store.state.all.data.fornitura_power.data.cod_fiscale
              }
              if (store.state.all.data.fornitura_power.data.partita_iva != null && store.state.all.data.fornitura_power.data.partita_iva !="") {
                cliente.piva = store.state.all.data.fornitura_power.data.partita_iva
              }
              // prefer cf if both are filled
              if (cliente.codfis.length > 0 && cliente.piva.length == 11) {
                cliente.piva = ''
              }

            } else {
              
              if (store.state.all.data.cliente.data.codfis != null && store.state.all.data.cliente.data.codfis !="") {
                cliente.codfis = store.state.all.data.cliente.data.codfis
              }
              if (store.state.all.data.cliente.data.piva != null && store.state.all.data.cliente.data.piva !="") {
                cliente.piva = store.state.all.data.cliente.data.piva
              }
              // prefer cf if both are filled
              if (cliente.codfis.length > 0 && cliente.piva.length == 11) {
                cliente.piva = ''
              }
 
            }
          }
          const res = await service.precheckPod(store.state.all.data.fornitura_power.data.pod, cliente)
          .finally(()=>{
            emit("activeLoader", false)
          })
          //return true
          if (res.valid.length == 0) {
            toast.add({severity:'error', summary:'Errore', detail: 'Provider (SII) non raggiungibile'});
            store.state.statusSII =  false
            return false
          } else {
            if (res.valid.data.esito) {
              toast.removeAllGroups();
              return true            
            } else {
              toast.add({severity:'warn', summary:'Precheck:', detail: res.valid.data.causale}); 
              return false                  
            }
          }
        }

        const onSelectedCity = (evt) => {
            selectedCityName.value = evt.originalEvent.target.ariaLabel
        }

        const nextPage = async () => {
          submitted.value = true;
            let check = false
            if(store.state.all.data.contratto.tipoCtr.name.toLowerCase() == 'power'){
              
                if (validateFormPod()) {
                  if (store.state.all.data.contratto.acquisizione.id != 4 && store.state.all.data.contratto.acquisizione.id != 3) {
                    if (await checkPodExist()) {
                      check = await precheckPod()
                    }
                  } else {
                    if (store.state.all.data.contratto.acquisizione.id == 4) {
                      store.state.all.data.fornitura_power.data.pod = ''
                    }

                    check = true

                  }
                  
                }
              
            } else if (store.state.all.data.contratto.tipoCtr.name.toLowerCase() == 'gas'){
              if (validateFormPdr()) {
                if (await checkPdrExist()) {
                  check = true
                }
              }

            }

              
            if (check) {
              store.state.all.data.realod = true
              store.state.all.data.step = 'fornitura'
              store.state.all.data.wizard = 'agenti/inserimento'
              store.state.all.data.azienda = currentUser.value.area
              store.state.all.data.pageIndex = stepIndex
              // set default value for agent (hidden input)
              store.state.all.data.fornitura_power.data.stato = currentUser.value.role == roles.agent.role ? {id:0} : store.state.all.data.fornitura_power.data.stato
              store.state.all.data.fornitura_power.data.stima = currentUser.value.role == roles.agent.role ? {id:2} : store.state.all.data.fornitura_power.data.stima
              store.state.all.data.fornitura_power.data.misuratore = currentUser.value.role == roles.agent.role ? {id:0} : store.state.all.data.fornitura_power.data.misuratore

              if(store.state.all.bozzaId == 0){

              praticheWizardBozzeService.create("agenti/inserimento", JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
                if(res.success){
                  store.state.all.bozzaId = res.item.id
                  emit('next-page', stepIndex);
                }
              })

              }else{
                praticheWizardBozzeService.update(store.state.all.bozzaId, JSON.parse(JSON.stringify(store.state.all.data))).then((res) => {
                  if(res.success){
                    store.state.all.bozzaId = res.item.id
                      emit('next-page', stepIndex);
                  }
                })

              }
              
            }
            
                
        }
 
        const prevPage = () => {
            emit('prev-page', stepIndex);
        }

      const isValid = () => {
          //regex validate code pod
          return  /(IT)(\d{3})([A-Z]{1})(\d{7})([a-zA-Z\d]{1})/.test(store.state.all.data.fornitura_power.data.pod);
      }

      const validateFormPod = () => {
        validationErrors.value = []

        let reg_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
        // let reg_piva = /^\d+$/;

        if (store.state.all.data.contratto.acquisizione.id != 4) {
          if (store.state.all.data.fornitura_power.data.pod == null || !store.state.all.data.fornitura_power.data.pod.trim()) 
            validationErrors.value['pod'] = true;
          else 
            delete validationErrors.value['pod'];

          if (store.state.all.data.fornitura_power.data.pod != null) {

            if (store.state.all.data.fornitura_power.data.pod.length > 13 && store.state.all.data.fornitura_power.data.pod.length < 15) 
                delete validationErrors.value['lunghezza'];
            else 
                validationErrors.value['lunghezza'] = true;

            if (isValid()) {
                delete validationErrors.value['format'];
                delete validationErrors.value['exist'];
            }else{
                validationErrors.value['format'] = true;
            }

          }
        }

        
        if (store.state.all.data.fornitura_power.data.toponimo == null || store.state.all.data.fornitura_power.data.toponimo == '')
          validationErrors.value['toponimo'] = true;
        else
          delete validationErrors.value['toponimo'];

        if (store.state.all.data.fornitura_power.data.address == null || store.state.all.data.fornitura_power.data.address == '')
          validationErrors.value['address'] = true;
        else
          delete validationErrors.value['address'];

        if (store.state.all.data.fornitura_power.data.civico == null || store.state.all.data.fornitura_power.data.civico == '')
          validationErrors.value['civico'] = true;
        else
          delete validationErrors.value['civico'];

        if (store.state.all.data.fornitura_power.data.cap == null || store.state.all.data.fornitura_power.data.cap == '')
          validationErrors.value['cap'] = true;
        else
          delete validationErrors.value['cap'];
        
        if (store.state.all.data.fornitura_power.data.selectedDistrict == null || store.state.all.data.fornitura_power.data.selectedDistrict == '')
          validationErrors.value['selectedDistrict'] = true;
        else 
          delete validationErrors.value['selectedDistrict'];

        if (store.state.all.data.fornitura_power.data.cityId == null || store.state.all.data.fornitura_power.data.cityId== '')
          validationErrors.value['selectedCity'] = true;
        else 
          delete validationErrors.value['selectedCity'];

        if (store.state.all.data.fornitura_power.data.voltaggio == null || store.state.all.data.fornitura_power.data.voltaggio == '')
          validationErrors.value['voltaggio'] = true;
        else
          delete validationErrors.value['voltaggio'];
        
        if (store.state.all.data.fornitura_power.data.potenza_imp == null || store.state.all.data.fornitura_power.data.potenza_imp == '')
          validationErrors.value['potenza_imp'] = true;
        else
          delete validationErrors.value['potenza_imp'];
        
        if (store.state.all.data.fornitura_power.data.potenza_disp == null || store.state.all.data.fornitura_power.data.potenza_disp == '')
          validationErrors.value['potenza_disp'] = true;
        else
          delete validationErrors.value['potenza_disp'];
        
        // if (store.state.all.data.fornitura_power.data.consumo_annuo == null || store.state.all.data.fornitura_power.data.consumo_annuo == '')
        //   validationErrors.value['consumo_annuo'] = true;
        // else
        //   delete validationErrors.value['consumo_annuo'];

        if (store.state.all.data.fornitura_power.data.distributore == '' || Object.keys(store.state.all.data.fornitura_power.data.distributore).length === 0)
          validationErrors.value['distributore'] = true;
        else
          delete validationErrors.value['distributore'];

        if (currentUser.value.role == roles.admin.role) {
          if (store.state.all.data.fornitura_power.data.misuratore == '' || Object.keys(store.state.all.data.fornitura_power.data.misuratore).length === 0)
            validationErrors.value['misuratore'] = true;
          else
            delete validationErrors.value['misuratore'];
        }

        if (store.state.all.data.fornitura_power.data.uso == '' || Object.keys(store.state.all.data.fornitura_power.data.uso).length === 0)
          validationErrors.value['uso'] = true;
        else
          delete validationErrors.value['uso'];

        if (store.state.all.data.fornitura_power.data.residente == '' || Object.keys(store.state.all.data.fornitura_power.data.residente).length === 0)
          validationErrors.value['residente'] = true;
        else
          delete validationErrors.value['residente'];

        if (store.state.all.data.fornitura_power.data.tensione == '' || Object.keys(store.state.all.data.fornitura_power.data.tensione).length === 0)
          validationErrors.value['tensione'] = true;
        else
          delete validationErrors.value['tensione'];
        
        if (currentUser.value.role == roles.admin.role) {
          if (store.state.all.data.fornitura_power.data.stato == '' || Object.keys(store.state.all.data.fornitura_power.data.stato).length === 0)
            validationErrors.value['stato'] = true;
          else
            delete validationErrors.value['stato'];
        }
        
        if (currentUser.value.role == roles.admin.role) {
          if (store.state.all.data.fornitura_power.data.stima == '' || Object.keys(store.state.all.data.fornitura_power.data.stima).length === 0)
            validationErrors.value['stima'] = true;
          else
            delete validationErrors.value['stima'];
        }

        
        let tipo = store.state.all.data.cliente.type == 'new' ? store.state.all.data.cliente.data.tipo_client : store.state.all.data.cliente.data.typeClient
        store.state.all.data.fornitura_power.data.cod_fiscale = store.state.all.data.fornitura_power.data.cod_fiscale == null ? '' : store.state.all.data.fornitura_power.data.cod_fiscale
        store.state.all.data.fornitura_power.data.partita_iva = store.state.all.data.fornitura_power.data.partita_iva == null ? '' : store.state.all.data.fornitura_power.data.partita_iva

        // if voltura -> cod_fiscale or piva are set - or both
        if (store.state.all.data.contratto.acquisizione.id == 2) {
          if (store.state.all.data.fornitura_power.data.cod_fiscale == "" && store.state.all.data.fornitura_power.data.partita_iva == "") {
            validationErrors.value['cf_or_piva'] = true;
          } else {
            delete validationErrors.value['cf_or_piva'];
            if (store.state.all.data.fornitura_power.data.cod_fiscale != "") {
              if (store.state.all.data.fornitura_power.data.cod_fiscale.length == 11) {
                delete validationErrors.value['format_cf'];
              } else if(store.state.all.data.fornitura_power.data.cod_fiscale.length == 16) {
                if ( reg_cf.test(store.state.all.data.fornitura_power.data.cod_fiscale)) 
                  delete validationErrors.value['format_cf'];
                else
                validationErrors.value['format_cf'] = true;
              } else {
                validationErrors.value['format_cf'] = true;
              }
              
            }

            if (store.state.all.data.fornitura_power.data.partita_iva != "") {
              if (store.state.all.data.fornitura_power.data.partita_iva.length != 11)
              validationErrors.value['format_piva'] = true;
            else
              delete validationErrors.value['format_piva'];
            }

          }
        } else {

          if (store.state.all.data.fornitura_power.data.cod_fiscale != null) {
            if (store.state.all.data.fornitura_power.data.cod_fiscale != "") {
              if (!reg_cf.test(store.state.all.data.fornitura_power.data.cod_fiscale)) 
                  validationErrors.value['format_cf'] = true;
              else 
                  delete validationErrors.value['format_cf'];    
            } else {
              delete validationErrors.value['format_cf'];
            }     
          }

          if (store.state.all.data.fornitura_power.data.partita_iva != null) {
            if (store.state.all.data.fornitura_power.data.partita_iva != "") {
              if (store.state.all.data.fornitura_power.data.partita_iva.length != 11) 
                  validationErrors.value['format_piva'] = true;
              else 
                  delete validationErrors.value['format_piva']; 
            } else {
              delete validationErrors.value['format_piva'];
            }
          }

        }

 
        return !Object.keys(validationErrors.value).length;
      }

      const isValidPdr = () => {
           //regex validate code pdr
            return  /^\d+$/.test(store.state.all.data.fornitura_gas.data.pdr);
        }

        const checkPdrExist = async () => {
            
            const res = await service.checkPdrExist(store.state.all.data.fornitura_gas.data.pdr)

            if (!res.item.exist) {
                toast.removeAllGroups();
                delete validationErrors.value['existPdr'];           
            } else {
                validationErrors.value['existPdr'] = true;
                toast.add({severity:'warn', summary:'Attenzione:', detail: 'Pdr già registrato'});                   
            }
            
            //return true
            return !res.item.exist         
        }

      const validateFormPdr = () => {
        validationErrors.value = []
        let reg_cf = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
        delete validationErrors.value['existPdr'];

        if (store.state.all.data.fornitura_gas.data.pdr == null || store.state.all.data.fornitura_gas.data.pdr == '') 
            validationErrors.value['pdr'] = true;
        else 
          delete validationErrors.value['pdr'];
        if (store.state.all.data.fornitura_gas.data.pdr != null ) {
          if (store.state.all.data.fornitura_gas.data.pdr.length == 14) 
            delete validationErrors.value['lunghezzaPdr'];
          else 
            validationErrors.value['lunghezzaPdr'] = true;
        }
         
        if (!isValidPdr() && store.state.all.data.fornitura_gas.data.pdr != '') 
            validationErrors.value['formatPdr'] = true;
        else 
          delete validationErrors.value['formatPdr'];

        if (store.state.all.data.fornitura_gas.data.toponimo == null || store.state.all.data.fornitura_gas.data.toponimo == '')
          validationErrors.value['toponimo'] = true;
        else
          delete validationErrors.value['toponimo'];

        if (store.state.all.data.fornitura_gas.data.address == null || store.state.all.data.fornitura_gas.data.address == '')
          validationErrors.value['address'] = true;
        else
          delete validationErrors.value['address'];

        if (store.state.all.data.fornitura_gas.data.civico == null || store.state.all.data.fornitura_gas.data.civico == '')
          validationErrors.value['civico'] = true;
        else
          delete validationErrors.value['civico'];

        if (store.state.all.data.fornitura_gas.data.cap == null || store.state.all.data.fornitura_gas.data.cap == '')
          validationErrors.value['cap'] = true;
        else
          delete validationErrors.value['cap'];

        if (store.state.all.data.fornitura_gas.data.selectedDistrict == null || store.state.all.data.fornitura_gas.data.selectedDistrict == '')
          validationErrors.value['selectedDistrict'] = true;
        else 
          delete validationErrors.value['selectedDistrict'];

        if (store.state.all.data.fornitura_gas.data.cityId == null || store.state.all.data.fornitura_gas.data.cityId== '')
          validationErrors.value['selectedCity'] = true;
        else 
          delete validationErrors.value['selectedCity'];
          
        if (store.state.all.data.fornitura_gas.data.consumo_annuo == null || store.state.all.data.fornitura_gas.data.consumo_annuo == '')
          validationErrors.value['consumo_annuo'] = true;
        else
          delete validationErrors.value['consumo_annuo'];

        if (store.state.all.data.fornitura_gas.data.remi == null || store.state.all.data.fornitura_gas.data.remi == '')
          validationErrors.value['remi'] = true;
        else
          delete validationErrors.value['remi'];  
        
        if (store.state.all.data.fornitura_gas.data.matricola == null || store.state.all.data.fornitura_gas.data.matricola == '')
          validationErrors.value['matricola'] = true;
        else
          delete validationErrors.value['matricola'];

        if (store.state.all.data.fornitura_gas.data.classe == '' || store.state.all.data.fornitura_gas.data.classe == null)
          validationErrors.value['classe'] = true;
        else
          delete validationErrors.value['classe'];
        
        if (store.state.all.data.fornitura_gas.data.uso == '' || Object.keys(store.state.all.data.fornitura_gas.data.uso).length === 0)
          validationErrors.value['uso'] = true;
        else
          delete validationErrors.value['uso'];

        if (store.state.all.data.fornitura_gas.data.categoria == '' || Object.keys(store.state.all.data.fornitura_gas.data.categoria).length === 0)
          validationErrors.value['categoria'] = true;
        else
          delete validationErrors.value['categoria'];

         if (store.state.all.data.fornitura_gas.data.cod_fiscale != null) {
          if (store.state.all.data.fornitura_gas.data.cod_fiscale != "") {
            if (!reg_cf.test(store.state.all.data.fornitura_gas.data.cod_fiscale)) 
                validationErrors.value['format_cf'] = true;
            else 
                delete validationErrors.value['format_cf'];    
          } else {
             delete validationErrors.value['format_cf'];
          }     
        }

        if (store.state.all.data.fornitura_gas.data.partita_iva != null) {
          if (store.state.all.data.fornitura_gas.data.partita_iva != "") {
             if (store.state.all.data.fornitura_gas.data.partita_iva.length != 11) 
                validationErrors.value['format_piva'] = true;
            else 
                delete validationErrors.value['format_piva']; 
          } else {
             delete validationErrors.value['format_piva'];
          }
        }

        return !Object.keys(validationErrors.value).length;
      }

        return {
          store, currentUser, roles,
          voltaggio, potenza_imp, potenza_disp, consumo_annuo, distributori, distributore,
          misuratori, misuratore, usi_ee, usi_gas, uso, residenti, residente, tensioni, voltaggi,
          tensione, stati, stato, stime, stima, cod_fiscale, partita_iva,note,isValid, 
          checkPodExist,pod, classi, categorie,
          toponimi, toponimo, address, civico, cap, validationErrors, submitted, prevPage, validateFormPod, nextPage,
          selectedRegionName, districts, selectedDistrict, cities, selectedCity,
         selectedCityName, onSelectedDistrict, onSelectedCity, calculateMin, calculateMax, potenze_imp, potenze_disp, setTensione, setPotenzaImp, setPotenzaDisp,
         }
        
    },
}
</script>

<style>
.form-step .p-card-title, .form-step .p-card-subtitle {
    text-align: center;
}
#cod_fiscale {
    text-transform: uppercase;
}

</style>

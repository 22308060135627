<template>
  <Loader v-if="loading" />
  <div class="card flex">
    <Button label="Scarica PdP switchabili" @click="downloadSwitchable"/>
  </div>
  <Card>
    <template #title>Forniture contrattualizzate</template>
    <template #subtitle>Ricerca la fornitura da sbloccare</template>
    <template #content>
      <div class="flex mb-4">
        <SelectButton v-model="selectedSupply" :options="options" aria-labelledby="basic" @update:modelValue="initData()"/>
      </div>
      <div class="flex">
        <InputMask v-if="selectedSupply == 'Pod'" v-model="supply_ee" mask="aa999a9999999*" :placeholder="getPlaceholder" autofocus :autoClear="false" slotChar="" class="mr-4 text_toupper"/>
        <InputMask v-if="selectedSupply == 'Pdr'" v-model="supply_gas" mask="99999999999999" :placeholder="getPlaceholder" autofocus :autoClear="false" slotChar="" class="mr-4"/>
        
        <Button icon="pi pi-search" v-tooltip="`Ricerca fornitura`" v-if="canSearch" class="p-button-text" @click="searchSupply"/>
      </div>
      <div v-if="has_items" class="mt-3">
        <h4>Risultati fornitura: {{ selectedSupply == 'Pod' ? supply_ee.toUpperCase() : supply_gas }}</h4>
        
        <div class="flex align-items-center">
          <label class="mr-3">Caricabile</label>
          <Checkbox v-model="is_caricabile" :binary="true" class="m-3"/>
          <Button v-tooltip="getTooltip()" icon="pi pi-send" v-if="is_caricabile_stato_iniziale != is_caricabile" class="p-button-text p-button-secondary" @click="setSupply()"/>
        </div>
        <div class="w-full flex justify-content-between align-items-center mb-3">
          <InlineMessage :severity="getSeverityInlineMessage(items[0])">{{getInlineMessage(items[0])}}</InlineMessage>
          <InlineMessage severity="secondary">{{items[0].nome_cod_internal_pratica}}</InlineMessage>
        </div>
        <DataTable :value="items" tableStyle="min-width: 50rem">
          <Column field="matrice_id" header="#"></Column>
          <Column header="Data Caricamento">
            <template #body="{data}">
              {{ moment(data.data_caricamento).format('DD/MM/Y H:mm:ss') }}
            </template>
          </Column>
          <Column field="nome_stato_caricamento" header="Stato Caricamento"></Column>
          <Column header="Cliente">
            <template #body="{data}">
              <Button class="p-button-link" :label="data.nome_cliente" @click="goTo($event, data, 'cliente')"/>
            </template>
          </Column>
        </DataTable>
      </div>
      
    </template>
  </Card>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import moment from 'moment'
import {ref, onMounted, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Loader from "@/components/Loader.vue"
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    Loader
  },  
  setup () {
    // default setup
    const route = useRoute()
    const router = useRouter()
    const toast = useToast();
    const store = useStore()
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const loading = ref(false)
    const basePath = ref()
    const currentUser = ref(store.state.auth.user)
    
    // custom setup
    const option = {
      Pod:{mask: 'aa999a99999999', placeholder: 'INSERISCI UN POD'},
      Pdr:{mask: '99999999999999', placeholder: 'INSERISCI UN PDR'}
    }
    const tooltip = {
      true: 'blocca',
      false: 'sblocca'
    }

    const option_toast = {
      true: {
        severity: 'success', detail: 'Operazione eseguita correttamente'
      },
      false: {
        severity: 'warn', detail: 'Operazione non eseguita correttamente'
      }
    }
    const supply_ee = ref('')
    const supply_gas = ref('')
    const selectedSupply = ref('Pod');
    const options = ref(['Pod', 'Pdr']);
    const items = ref([])
    const getPlaceholder = computed (()=>{
      return option[selectedSupply.value].placeholder
    })
    const canSearch = computed (()=>{
      let supply = selectedSupply.value == 'Pod' ? supply_ee.value : supply_gas.value
      return supply.length == 14
    })
    const is_caricabile = ref()
    const is_caricabile_stato_iniziale = ref()
    const stati = ref([])

    const getAllStati = async () => {
      await service.getAllStati()
      .then((res)=>{
        stati.value = res.items
      })
    }

    const searchSupply = () => {
      items.value = []
      loading.value = true
      let fd = new FormData();
      let supply = selectedSupply.value == 'Pod' ? supply_ee.value : supply_gas.value

      fd.append('supply', JSON.stringify(supply));
      fd.append('type_supply', JSON.stringify(selectedSupply.value));

      service.searchSupply(fd)
      .then((res) => {
        items.value = res.items
        if (!items.value.length) {
          toast.add({severity:'warn', detail:'Non ci sono caricamenti per la fornitura inserita', life: 5000});
        }else{
          is_caricabile_stato_iniziale.value = computed_caricabile.value
          is_caricabile.value = computed_caricabile.value
        }
      }).finally(() => {
        loading.value = false
      })
    }
    const getIsInFornitura = (data) => {
      let in_fornitura = false
      let now = moment()
      
      if (data.data_fine) {
        in_fornitura = moment(now).isBefore(data.data_fine)
      }
      
      return in_fornitura
    }
    const has_items = computed (()=>{
      return items.value.length
    })

    const computed_caricabile = computed (()=>{
      let is_caricabile = true
      if (has_items.value) {
        for (let index = 0; index < items.value.length; index++) {
          if(!items.value[index].caricabile){
            is_caricabile = false
            break;
          } 
        }
      }
      return is_caricabile
    })

    const setSupply = () => {
      loading.value = true

      let ids = []
      items.value.forEach(item => {
        ids.push(item.id)
      })
      let fd = new FormData();
      fd.append('ids', JSON.stringify(ids));
      fd.append('type_supply', JSON.stringify(selectedSupply.value));
      fd.append('is_caricabile', is_caricabile.value);

      service.setSupply(fd)
      .then((res) => {
        let result = Boolean(res.items)
        
        toast.add({severity: option_toast[result].severity, detail:option_toast[result].detail, life: 5000});
      }).finally(() => {
        initData()
        loading.value = false
      })
    }

    const initData = async () => {      
      supply_ee.value = ''
      supply_gas.value = ''
      items.value = []
      is_caricabile.value = null 
      is_caricabile_stato_iniziale.value = null
      basePath.value = await service.getBaseAdminUrl()
    }

    const getTooltip = () => {
      return tooltip[!is_caricabile.value]
    }

    const goTo = async (evt, data, type_path) => {      
      let path
      let type_data
      switch (type_path) {
        case 'cliente':
          path = `clienti`
          type_data = `id_${type_path}`
          break;
        case 'numero':
          path = `clienti/fatture`
          type_data = type_path
          break;

        default:
          path = `${data.type_fornitura}s`
          type_data = `id_${data.type_fornitura}`
          break;
      }
      window.open(`${basePath.value}/ask/${path}/${data[type_data]}/dettagli`, '_blank')
    }

    const getSeverityInlineMessage = (data) => {
      let severity = 'error'
      
      if (getIsInFornitura(data)){
        severity = 'success'
      }else if(data.data_presunta_switch != null) {
        severity = 'warn'
      }
      
      return severity
    }
    const getInlineMessage = (data) => {      
      let msg = 'cessato il '+moment(data.data_fine).format('DD/MM/YYYY')

      if (getIsInFornitura(data)){
        msg = 'in fornitura dal '+moment(data.data_inizio).format('DD/MM/YYYY')
      }else if(data.data_presunta_switch != null) {
        msg = 'in switch al '+moment(data.data_presunta_switch).format('DD/MM/YYYY')
      }
      
      return msg
    }

    const downloadSwitchable = async () => {
      loading.value = true
      await service.downloadSwitchable()
        .finally(()=> {
          loading.value = false
        })        
    }

    onMounted(() => {
      getAllStati()
      initData()
    })

    return {
      moment,
      loading,
      supply_ee,
      supply_gas,
      selectedSupply,
      options,
      getPlaceholder,
      canSearch,
      searchSupply,
      items,
      has_items,
      is_caricabile,
      is_caricabile_stato_iniziale,
      getTooltip,
      setSupply,
      initData,
      stati,
      goTo,
      getSeverityInlineMessage,
      getInlineMessage,
      downloadSwitchable
    }
  },
}
</script>
import axios from "axios"
import router from '@/router'
import Cookies from "js-cookie";
import service from '@/config/services'

const API_URL = `${process.env.VUE_APP_ASK_API_URL}/api/`;
const GOOGLE_INFO_TOKEN_URL = `${process.env.VUE_APP_GOOGLE_INFO_TOKEN_URL}`;

export default {
    async getLifetimeToken (cookie) {
        if (cookie.source == 'google') {
          const accessToken = cookie.token
          const response = await fetch(GOOGLE_INFO_TOKEN_URL + accessToken);
          let info = await response.json();

          if (info.error == 'invalid_token') {       
            return 0
          }
          
          return info.expires_in
          
        } else if(cookie.source == 'jwt'){
          return 500
        }
        
    },

    async getNewAccessToken (cookieUser){
      try {
        cookieUser = JSON.stringify(cookieUser)
        const response = await axios.post(API_URL + 'auth/refresh-token-cookie', {
          cookie: cookieUser
        })
        const accessToken = response.data.accessToken
        let cookie = JSON.parse(Cookies.get('google_access_token'))
        cookie.token = accessToken
        Cookies.remove('google_access_token',{domain: `${service.main_domain.cookie}` })
        Cookies.set('google_access_token', JSON.stringify(cookie),{ domain: `${service.main_domain.cookie}` })
   
      } catch (err) {
        console.log(err)
        router.push('/unauthorized')
      }
      
    }
}

<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Partita iva
            </template>
            <template v-slot:subtitle>
                Inserisci la partita iva
            </template>
            <template v-slot:content>
                <div class="formgrid grid">
                    <div class="p-field col-12">
                        <InputText type="number" id="partita_iva" v-model="partita_iva" :class="['inputfield w-full',{'p-invalid': validationErrors.partita_iva || validationErrors.existIva || validationErrors.format && submitted}]" />
                        <div v-if="validationErrors.partita_iva && submitted" class="p-error">Lunghezza campo non valida.</div>
                        <div v-if="validationErrors.existIva && submitted" class="p-error">Partita Iva esistente.</div>
                        <div v-if="validationErrors.format && submitted" class="p-error">Formato errato.</div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="grid justify-content-between">
                    <i></i>
                    <Button label="" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onMounted} from 'vue';
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';

export default {
    components: {
        Card,
        InputText,
        Button,
    },
     props: {
        formData: Object
    },
    emits: ["next-page","prevPage", 'activeLoader'],
    inheritAttrs: false,
    setup (props, {emit}) {

        const store = useStore()
        const currentUser = ref(store.state.auth.user)
        const toast = useToast();
        const partita_iva = ref('')
        const format = ref(false) 
        const exist = ref(false)
        const validationErrors = ref({})
        const submitted = ref(false)
        const self = getCurrentInstance();
        self.$eventBus = self.appContext.config.globalProperties.$eventBus
        const service = new Service(self.$eventBus)

     
        onMounted (() => {

        })
       
        const nextPage = () => {
            submitted.value = true;
            validationErrors.value = []
             if (validateFormIva() && ivaIsNumeric()) {
                 validateIvaExist()                                                     
            }
        }

       

        const validateFormIva = () => {
            if (partita_iva.value.toString().length < 11)
                validationErrors.value['partita_iva'] = true;
            else
                delete validationErrors.value['partita_iva'];
                
            return !Object.keys(validationErrors.value).length;
        }

        const ivaIsNumeric = () => {
            let isNum = /^\d+$/.test(partita_iva.value);
             if (isNum) {
                 delete validationErrors.value['format'];     
            } else {
                validationErrors.value['format'] = true;             
            }
            return isNum    
        }
       
        const validateIvaExist = () => {
            service.checkIvaFornitoreExist(partita_iva.value).then((res) => {
                if (res.items == true) {    
                    delete validationErrors.value['existIva'];
                    toast.removeAllGroups();
                    getInfoCreditSafe()
                } else {
                    validationErrors.value['existIva'] = true;
                    toast.add({severity:'warn', summary:'Esistente:', detail: res.items[0].ragsoc});        
                }
            })              
        }

        const getInfoCreditSafe = () => {
            emit("activeLoader", true)
            service.getInfoCreditSafe(partita_iva.value).then((res) => {
                updateData(res)
            }).finally(() => {
                emit("activeLoader", false)
            })
        }

        const updateData = (res) => {
            if (res.item.totalSize > 0) {
                let company = res.item.companies[0] 
                emit('next-page', {
                    formData: {         
                        partita_iva: partita_iva.value,
                        azienda: currentUser.value.area,
                        rag_sociale: company.name,
                        phone: ("phoneNumbers" in company) ? company.phoneNumbers[0] : '',
                        mobile: '',
                        address: company.address.street,
                        civico: company.address.houseNo,
                        cap: company.address.postCode,
                        city: company.address.city,
                        district: company.address.province
                    }, pageIndex: 0});
            } else {
                emit('next-page', {
                    formData: {
                        partita_iva: partita_iva.value,
                        azienda: currentUser.value.area,
                    },
                    pageIndex: 0});
            }
        }


        return {
          format, 
          exist, 
          validationErrors, 
          submitted, 
          nextPage,
          validateIvaExist, 
          partita_iva, 
          getInfoCreditSafe
        }
    },    

}
</script>

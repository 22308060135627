<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Nuova fornitura
            </template>
            <template v-slot:subtitle>
                <div class="grid">
                  <div class="col-12">Vuoi inserire una nuova fornitura per questo contratto? </div>    
                  <div v-if="route.params.tipo == 2" class="col-12 text-center">
                    <router-link :to="`/forniture-gas/step-pdr/${route.params.idContratto}/${route.params.idCliente}`" custom v-slot="{ navigate }">
                      <Button label="Si, Gas" class="p-button-warning btn-new-fornitura" @click="navigate" />
                    </router-link>
                  </div>
                  <div v-else class="col-12 text-center">
                    <router-link :to="`/forniture-ee/step-pod/${route.params.idContratto}/${route.params.idCliente}`" custom v-slot="{ navigate }">
                      <Button label="Si, Energia" class="p-button-success btn-new-fornitura" @click="navigate" />
                    </router-link>
                  </div>
                </div>
            </template>      
        </Card>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import Card from 'primevue/card';
import Button from 'primevue/button';


export default {
    components: {
        Card,
        Button
        
    },
    props: {
        formData: Object
    },
    setup () {

        //  const router = useRouter();
         const route = useRoute();


        return {route}
    },
    
}
</script>
<style lang="css">
.btn-new-fornitura {
  min-width: 120px!important;
}
</style>
